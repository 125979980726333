import React, { useState, useEffect } from "react";
import { WhiteTooltip } from "../../../default_components/Tooltips";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import Api from "../../../../Api";
import { defaultProjectIcons } from "../../../default_values/DefaultProjectIcons";
import CreateTimeOff from "./modals/CreateTimeOff";
import EditTimeOff from "./modals/EditTimeOff";
import DeleteTimeOff from "./modals/DeleteTimeOff";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { icons } from "../../../default_images/IconsList";

const TimeOff = () => {
  const { t } = useTranslation();

  const [projects, setProjects] = useState([]);
  const [openTimeOffCreation, setOpenTimeOffCreation] = useState(false);
  const [openTimeOffEditing, setOpenTimeOffEditing] = useState(false);
  const [openTimeOffDeletion, setOpenTimeOffDeletion] = useState(false);
  const [selectedTimeOff, setSelectedTimeOff] = useState({});

  const getProjects = () => {
    Api.Projects.time_off().then((response) => setProjects(response.data));
  };

  useEffect(() => {
    getProjects();
  }, []);

  const openEditing = (project) => {
    setOpenTimeOffEditing(true);
    setSelectedTimeOff(project);
  };

  const openDeleteQuestionDialog = (project) => {
    setOpenTimeOffDeletion(true);
    setSelectedTimeOff(project);
  };

  return (
    <>
      <div className="time-off__control-row">
        <p className="tb__text__light-black">{t("settings.time_off.time_off_mngmt")}</p>

        <WhiteTooltip title={t("settings.time_off.create_time_off")}>
          <div className="teambook-icon-frame" onClick={() => setOpenTimeOffCreation(true)}>
            <TeambookIcon name={icons.ADD} color="alternative_default" />
          </div>
        </WhiteTooltip>
      </div>

      <div className="time-off__scroll-component">
        {projects
          .sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1))
          .map((project) => (
            <div className="time-off__block" key={project.name} aria-label="block time off">
              <div className="time-off__description">
                {defaultProjectIcons(project.icon_id, "var(--text-2)")}
                <p
                  style={{
                    textAlign: "left",
                    margin: "0 0 0 20px",
                  }}
                >
                  {project.name}
                </p>
              </div>

              <div className="time-off__block-content">
                <WhiteTooltip title={t("edit")}>
                  <div className="teambook-icon-frame" onClick={() => openEditing(project)}>
                    <TeambookIcon name={icons.PENCIL} color="alternative_default" />
                  </div>
                </WhiteTooltip>

                <WhiteTooltip title={t("delete")}>
                  <div className="teambook-icon-frame" onClick={() => openDeleteQuestionDialog(project)}>
                    <TeambookIcon name={icons.TRASH} color="alternative_default" />
                  </div>
                </WhiteTooltip>
              </div>
            </div>
          ))}
      </div>

      {openTimeOffCreation && (
        <CreateTimeOff open={openTimeOffCreation} setOpen={setOpenTimeOffCreation} getProjects={getProjects} />
      )}

      {openTimeOffEditing && (
        <EditTimeOff
          open={openTimeOffEditing}
          setOpen={setOpenTimeOffEditing}
          getProjects={getProjects}
          selectedTimeOff={selectedTimeOff}
        />
      )}

      {openTimeOffDeletion && (
        <DeleteTimeOff
          open={openTimeOffDeletion}
          setOpen={setOpenTimeOffDeletion}
          setProjects={setProjects}
          selectedTimeOff={selectedTimeOff}
        />
      )}
    </>
  );
};

export default TimeOff;

import React, { useState } from "react";
import { Menu } from "@mui/material";
import { CirclePicker, SketchPicker } from "react-color";
import * as DefaultColors from "../default_values/DefaultColors";
import TeambookWhiteButton from "./TeambookWhiteButton";
import { useTranslation } from "react-i18next";

export default function TeambookColorPicker({
  setColor,
  color,
  changeColorsOpened,
  setChangeColorsOpened,
  anchorRef,
}) {
  const { t } = useTranslation();

  const [colorPickerChoice, setColorPickerChoice] = useState(0);

  return (
    <div>
      <Menu
        anchorEl={anchorRef.current}
        keepMounted
        open={changeColorsOpened}
        onClose={() => setChangeColorsOpened(false)}
        className="project-circle-picker__container"
      >
        <div className="project-circle-picker">
          {colorPickerChoice === 0 ? (
            <CirclePicker
              color={color}
              onChangeComplete={(color) => {
                setChangeColorsOpened(false);
                setColor(color.hex);
              }}
              colors={DefaultColors.projectColors}
            />
          ) : (
            <SketchPicker
              color={color}
              onChangeComplete={(color) => {
                setColor(color.hex);
              }}
            />
          )}

          <TeambookWhiteButton
            text={t(
              colorPickerChoice === 0
                ? "projects.custom_colors"
                : "projects.regular_colors"
            )}
            onClick={() => setColorPickerChoice((colorPickerChoice + 1) % 2)}
            color="primary"
            style={{ marginTop: "10px" }}
          />
        </div>
      </Menu>
    </div>
  );
}

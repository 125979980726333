import React from "react";

const htmlLegendPlugin = (type) => {
  const getOrCreateLegendList = (chart, id) => {
    const legendContainer = document.getElementById(id);
    let listContainer = legendContainer.querySelector("ul");

    if (!listContainer) {
      listContainer = document.createElement("ul");
      listContainer.style.display = "flex";
      listContainer.style.flexDirection = "row";
      listContainer.style.margin = 0;
      listContainer.style.padding = 0;

      legendContainer.appendChild(listContainer);
    }

    return listContainer;
  };

  return {
    id: "htmlLegend",
    afterUpdate(chart, args, options) {
      const ul = getOrCreateLegendList(chart, options.containerID);

      // Remove old legend items
      while (ul.firstChild) {
        ul.firstChild.remove();
      }

      // Reuse the built-in legendItems generator
      let items = chart.options.plugins.legend.labels.generateLabels(chart);

      if (type === "capacity") {
        items = [
          {
            fillStyle: "#4C54A0",
            hidden: false,
            lineWidth: 2,
            strokeStyle: "#4C54A0",
            text: "Users Data",
            textAlign: "left",
            datasetIndexes: [0, 1, 2],
          },
          {
            fillStyle: "#9199E7",
            hidden: false,
            lineWidth: 2,
            strokeStyle: "#4C54A0",
            text: "Projects Data",
            textAlign: "left",
            datasetIndexes: [3, 4, 5],
          },
          {
            fillStyle: "rgba(255, 99, 132, 0)",
            hidden: false,
            lineWidth: 2,
            strokeStyle: "#4C54A0",
            text: "Total Line",
            textAlign: "left",
            datasetIndexes: [6],
            underlineColor: "#4C54A0",
          },
        ];
      }

      items.forEach((item) => {
        const li = document.createElement("li");
        li.style.alignItems = "center";
        li.style.cursor = "pointer";
        li.style.display = "flex";
        li.style.flexDirection = "row";
        li.style.marginLeft = "10px";

        li.onclick = () => {
          const { type } = chart.config;

          if (type === "pie" || type === "doughnut") {
            // Pie and doughnut charts only have a single dataset and visibility is per item
            chart.toggleDataVisibility(item.index);
          } else {
            if (chart.config._config.chartType === "capacity") {
              item.datasetIndexes.forEach((index) => {
                chart.setDatasetVisibility(index, !chart.isDatasetVisible(index));
              });
            } else {
              chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
            }
          }
          chart.update();
        };

        // Color box
        const boxSpan = document.createElement("span");
        boxSpan.style.background = item.fillStyle;
        boxSpan.style.borderColor = item.strokeStyle;
        boxSpan.style.borderWidth = item.lineWidth + "px";
        boxSpan.style.display = "inline-block";
        boxSpan.style.flexShrink = 0;
        boxSpan.style.height = "20px";
        boxSpan.style.marginRight = "10px";
        boxSpan.style.width = "20px";

        // Text
        const textContainer = document.createElement("p");
        textContainer.style.color = item.fontColor;
        textContainer.style.margin = 0;
        textContainer.style.padding = 0;

        const isDotted = item?.lineDash?.length > 0;

        if (type === "capacity") {
          if (item.underlineColor) {
            textContainer.style.borderBottom = `1px solid ${item.underlineColor}`;
          }
        } else {
          if (!item.hidden) {
            if (isDotted) {
              textContainer.style.borderBottom = "1px dashed var(--text-2)";
            } else if (!isDotted) {
              textContainer.style.borderBottom = "1px solid var(--text-2)";
            }
          } else {
            textContainer.style.color = "red";
          }
        }

        const text = document.createTextNode(item.text);
        textContainer.appendChild(text);

        li.appendChild(boxSpan);
        li.appendChild(textContainer);
        ul.appendChild(li);
      });
    },
  };
};

export default htmlLegendPlugin;

import React, { useEffect, useState } from "react";
import SeparatorRow from "./SeparatorRow";
import UsersBlock from "./UsersBlock";
import { useTranslation } from "react-i18next";
import moment from "moment/moment";
import XLSExport from "../../../../../lib/XLSExport";
import { useTeambookFilter } from "../../../../../stores/planner";
import { calculateUsersFilters } from "../../../../capacity_planning/lib/calculateFilters";
import { recalculateCapacityArray } from "../../../../capacity_planning/lib/recalculateCapacityArray";
import DatesRow from "../DatesRow";
import Api from "../../../../../Api";
import CompanyUtilization from "./CompanyUtilization";
import { DateTime } from "luxon";
import { useAccountStore } from "../../../../../stores/accountStore";
import { shallow } from "zustand/shallow";
import { calculateDaysInMonth } from "../../../../capacity_planning/lib/calculateHeatStyles";

const ReportingUsers = ({ date, users, tags, projects, selectedViewType, setSplitBy }) => {
  const { filterValues, filterType } = useTeambookFilter();
  const { t } = useTranslation();

  const [capacityReservations, setCapacityReservations] = useState([]);
  const [projectReservations, setProjectReservations] = useState([]);
  const [showedUsers, setShowedUsers] = useState(users);
  const [dropdownEntity, setDropdownEntity] = useState();
  const [userShowedId, setUserShowedId] = useState();
  const [isUsersOpened, setIsUserOpened] = useState(false);
  const [showPercentage, setShowPercentage] = useState(false);
  const [percentageFormat, setPercentageFormat] = useState("all");
  const [sortOrder, setSortOrder] = useState("a");
  const [sortedDate, setSortedDate] = useState(DateTime.now());
  const [sortType, setSortType] = useState("alphabetical"); //alphabetical || by_month
  const [areAllUsersExpanded, setAreAllUsersExpanded] = useState(false);

  const [account] = useAccountStore((state) => [state.account], shallow);

  useEffect(() => {
    Api.CapacityReservations.get({
      user_ids: showedUsers.map((u) => u.id),
      start_date: date.toISODate(),
      end_date: date.plus({ years: 2 }).toISODate(),
    }).then((res) => {
      setCapacityReservations(res.data);
    });

    Api.CapacityReservations.get_project_reservations({
      project_ids: projects.filter((p) => p.active).map((p) => p.id),
      start_date: date.toISODate(),
      end_date: date.plus({ years: 2 }).toISODate(),
    }).then((res) => {
      setProjectReservations(res.data);
    });
  }, [date, projects, showedUsers]);

  const getProjectReservations = (id) => {
    return capacityReservations
      .filter((pr) => {
        const iso_date = DateTime.fromISO(pr.date);
        return pr.user_id === id && iso_date.month === sortedDate.month && iso_date.year === sortedDate.year;
      })
      .reduce((prev, cur) => prev + cur.days_reserved, 0);
  };

  useEffect(() => {
    let sorted_users = [...showedUsers.filter((user) => user.team_ids.length > 0)];

    if (sortType === "alphabetical") {
      sorted_users = [...sorted_users].sort((a, b) => {
        if (sortOrder === "a") {
          return a.last_name > b.last_name ? 1 : -1;
        }

        if (sortOrder === "z") {
          return a.last_name > b.last_name ? -1 : 1;
        }
      });
    } else if (sortType === "by_month") {
      sorted_users = [...sorted_users].sort((a, b) => {
        if (sortOrder === "a") {
          return getProjectReservations(a.id) > getProjectReservations(b.id) ? 1 : -1;
        }

        if (sortOrder === "z") {
          return getProjectReservations(a.id) > getProjectReservations(b.id) ? -1 : 1;
        }
      });
    }

    setShowedUsers(sorted_users);
  }, [users, sortOrder, sortType, sortedDate, showPercentage, percentageFormat]);

  useEffect(() => {
    setShowedUsers(calculateUsersFilters(users, tags, filterValues, filterType, t));
  }, [users, projects, filterValues, filterType, tags]);

  const exportUsersData = () => {
    let exportArray = [
      ["", ...[...Array(selectedViewType)].map((x, i) => date.plus({ months: i }).toFormat("LLL yy"))],
    ];

    exportArray.push(...getExportData("Users", showedUsers, capacityReservations));

    exportArray.push(["Overall Company"]);
    exportArray.push(["Staffing Ratio", ...summarizeStaffingRatio(capacityReservations, users?.length)]);

    XLSExport.exportCapacityReporting("Users", exportArray);
  };

  const getExportData = (type, objects, reservations) => {
    let exportArray = [[type]];

    objects.forEach((object) => {
      exportArray.push([
        object.name,
        ...summarizeCapacities(
          reservations.filter((cr) => cr.user_id === object.id),
          object
        ),
      ]);
    });

    return exportArray;
  };

  const summarizeCapacities = (reservations, user) => {
    if (showPercentage) {
      let percentageReserved = [];

      if (percentageFormat === "all") {
        percentageReserved = [...Array(selectedViewType)].map((x, i) => {
          const calculatedDate = date.plus({ months: i }).toISODate();

          return reservations
            .filter((cr) => moment(cr.date).format("DD.MM.YY") === moment(calculatedDate).format("DD.MM.YY"))
            .map((cr) => cr.days_reserved)
            .reduce((ps, a) => ps + a, 0);
        });
      } else if (percentageFormat === "billable") {
        percentageReserved = [...Array(selectedViewType)].map((x, i) => {
          const calculatedDate = date.plus({ months: i }).toISODate();

          return reservations
            .filter((cr) => moment(cr.date).format("DD.MM.YY") === moment(calculatedDate).format("DD.MM.YY"))
            .filter((cr) => projects.filter((p) => p.id === cr.project_id)[0]?.kind === "billable")
            .map((cr) => cr.days_reserved)
            .reduce((ps, a) => ps + a, 0);
        });
      } else if (percentageFormat === "non_billable") {
        percentageReserved = [...Array(selectedViewType)].map((x, i) => {
          const calculatedDate = date.plus({ months: i }).toISODate();

          return reservations
            .filter((cr) => moment(cr.date).format("DD.MM.YY") === moment(calculatedDate).format("DD.MM.YY"))
            .filter((cr) => projects.filter((p) => p.id === cr.project_id)[0]?.kind === "non_billable")
            .map((cr) => cr.days_reserved)
            .reduce((ps, a) => ps + a, 0);
        });
      } else if (percentageFormat === "time_off") {
        percentageReserved = [...Array(selectedViewType)].map((x, i) => {
          const calculatedDate = date.plus({ months: i }).toISODate();

          return reservations
            .filter((cr) => moment(cr.date).format("DD.MM.YY") === moment(calculatedDate).format("DD.MM.YY"))
            .filter((cr) => projects.filter((p) => p.id === cr.project_id)[0]?.kind === "time_off")
            .map((cr) => cr.days_reserved)
            .reduce((ps, a) => ps + a, 0);
        });
      }

      if (monthDuration(user) === 0) {
        return percentageReserved.map((p) => `0%`);
      }

      return percentageReserved.map((p) => `${((p / monthDuration(user)) * 100).toFixed(0)}%`);
    } else {
      return recalculateCapacityArray(date, reservations, selectedViewType);
    }
  };

  const monthDuration = (user) => {
    let duration = 0;

    for (let i = 0; i < date.endOf("month").day; i += 1) {
      const newDateWeekday = date.plus({ days: i }).weekday - 1;

      if (user.schedule[newDateWeekday][0] + user.schedule[newDateWeekday][2] > 0) {
        duration += 1;
      }
    }

    return duration;
  };

  const summarizeStaffingRatio = (reservations, usersCount) => {
    let newCapacityDurations = recalculateCapacityArray(date, reservations, selectedViewType);

    return newCapacityDurations.map((duration) => {
      return `${((duration / (usersCount * 22)) * 100).toFixed(0)}%`;
    });
  };

  const toggleAllUsers = () => {
    setAreAllUsersExpanded((prev) => !prev);
  };

  return (
    <div className="reporting-capacity__component overflow-scrolled">
      <DatesRow
        date={date}
        selectedViewType={selectedViewType}
        showPercentage={showPercentage}
        setShowPercentage={setShowPercentage}
        percentageFormat={percentageFormat}
        setPercentageFormat={setPercentageFormat}
        type="reporting"
        setSortOrder={setSortOrder}
        sortOrder={sortOrder}
        sortedDate={sortedDate}
        setSortedDate={setSortedDate}
        setSortType={setSortType}
        sortType={sortType}
        splitBy={"capacity_user"}
        setSplitBy={setSplitBy}
      />

      <SeparatorRow
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        setSortType={setSortType}
        sortData={true}
        subtext={t("planning.total_days")}
        name={""}
        setDropdownEntity={setIsUserOpened}
        separationKind={!isUsersOpened}
        selectedViewType={selectedViewType}
        reservations={capacityReservations}
        date={date}
        areAllUsersExpanded={areAllUsersExpanded}
        toggleAllUsers={toggleAllUsers}
        isExpand
        style={{ position: "sticky", top: "96px", zIndex: "2" }}
      />

      {showedUsers.map((u) => (
        <UsersBlock
          user={u}
          date={date}
          reservations={capacityReservations.filter((cr) => {
            return cr.user_id === u.id;
          })}
          userShowedId={userShowedId}
          setUserShowedId={setUserShowedId}
          projects={projects}
          selectedViewType={selectedViewType}
          showPercentage={showPercentage}
          percentageFormat={percentageFormat}
          areAllUsersExpanded={areAllUsersExpanded}
        />
      ))}

      <SeparatorRow name={"Total"} selectedViewType={selectedViewType} />

      <CompanyUtilization
        usersCount={showedUsers?.length || 0}
        date={date}
        reservations={capacityReservations}
        selectedViewType={selectedViewType}
      />
    </div>
  );
};

export default ReportingUsers;

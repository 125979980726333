import { WhiteTooltip } from "../../../default_components/Tooltips";
import React from "react";
import { useTranslation } from "react-i18next";
import { isoCodes } from "../../../default_values/defaultDateFormats";

const linkToAnchor = (text) => {
  let urlRegex = /(?:(?:https?|ftp):\/\/)?[\w/\-?=%.]+\.[\w/\-&?=%.]+/gi;
  return (text = text.replace(
    urlRegex,
    (url) => '<a rel="noopener noreferrer" href="http://' + url + '">' + url + "</a>"
  ));
};

const ShowFields = (name, value, setValue, type, user, userTeams, account, changeTooltip, disableTooltip) => {
  const { t } = useTranslation();

  if (type === "teams") {
    return (
      <>
        <p className="user-form__field">{name}</p>

        <div className="edit-form__teams">
          {[...userTeams].slice(0, 3).map((team) => (
            <WhiteTooltip key={`team-${team?.id}`} title={team?.name}>
              <div className="user-form__team">
                <p>{team?.name}</p>
              </div>
            </WhiteTooltip>
          ))}

          {userTeams.length - 3 > 0 && (
            <WhiteTooltip
              title={
                <div
                  style={{
                    flexWrap: "nowrap",
                    maxHeight: 250,
                    overflow: "auto",
                  }}
                  className="tooltip_teams"
                >
                  {[...userTeams].slice(3, userTeams.length).map((team) => (
                    <div style={{ minHeight: 24 }} className="user-form__team">
                      <p>{team?.name}</p>
                    </div>
                  ))}
                </div>
              }
            >
              <div className="user-form__team extra_teams">+{userTeams.length - 3}</div>
            </WhiteTooltip>
          )}
        </div>
      </>
    );
  } else if (type === "text") {
    return (
      <>
        <p className="user-form__field ">{name}</p>

        <p className="user-form__value">{value}</p>
      </>
    );
  } else if (type === "email") {
    return (
      <>
        <p className="user-form__field">{name}</p>
        <p className="user-form__value">{user.email || "-"}</p>
      </>
    );
  } else if (type === "array") {
    return (
      <>
        <p className="user-form__field">{name}</p>
        <p className="user-form__value">{value || "-"}</p>
      </>
    );
  } else if (type === "role") {
    return (
      <>
        <p className="user-form__field">{name}</p>
        <p className="user-form__value">{value || "-"}</p>
      </>
    );
  } else if (type === "phone") {
    return (
      <>
        <p className="user-form__field">{name}</p>
        <p className="user-form__value">{value || "-"}</p>
      </>
    );
  } else if (type === "tags") {
    return (
      <>
        <p className="user-form__field">{t("users.tags")}</p>

        <div className="edit-form__teams">
          {[...user.tags].slice(0, 3).map(({ id, name, color }) => (
            <div
              style={{
                backgroundColor: color + "66",
                border: "2px solid " + color,
                color: "var(--text-10)",
                whiteSpace: "nowrap",
              }}
              className="user-form__team tag"
            >
              <p>{name}</p>
            </div>
          ))}

          {user.tags.length - 3 > 0 && (
            <WhiteTooltip
              title={
                <div
                  style={{
                    flexWrap: "nowrap",
                    maxHeight: 250,
                    overflow: "auto",
                  }}
                  className="tooltip_teams"
                >
                  {[...user.tags].slice(3, user.tags.length).map((tag) => (
                    <div
                      style={{ backgroundColor: tag.color + "66", border: "2px solid " + tag.color, minHeight: 24 }}
                      className="user-form__team"
                    >
                      <p>{tag?.name}</p>
                    </div>
                  ))}
                </div>
              }
            >
              <div className="user-form__team extra_teams">+{user.tags.length - 3}</div>
            </WhiteTooltip>
          )}

          {user.tags.length === 0 && "-"}
        </div>
      </>
    );
  } else if (type === "date") {
    return (
      <>
        <p className="user-form__field ">{name}</p>

        <p className="user-form__value">
          {value ? new Date(value).toLocaleDateString(isoCodes(account.date_format)) : "-"}
        </p>
      </>
    );
  } else if (type === "richText") {
    return (
      <>
        <p className="user-form__field ">{name}</p>

        <p className="user-form__value">
          <span
            style={{
              wordBreak: "break-word",
              textAlign: "left",
            }}
            dangerouslySetInnerHTML={{
              __html: linkToAnchor(!value || value === "" ? "<p>-</p>" : value) || "<p>-</p>",
            }}
          />
        </p>
      </>
    );
  }
};

export default ShowFields;

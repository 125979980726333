import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import TeambookTextForm from "../../../default_components/TeambookTextForm";
import TeambookBlueButton from "../../../default_components/TeambookBlueButton";
import Api from "../../../../Api";
import { InfoTooltip } from "../../../default_components/InfoTooltip";
import { useAccountStore } from "../../../../stores/accountStore";
import { shallow } from "zustand/shallow";

const CustomProjectFields = ({}) => {
  const { t } = useTranslation();
  const [account] = useAccountStore((state) => [state.account], shallow);

  const [customProjectField1, setCustomProjectField1] = useState(account.custom_project_fields[0] || "");
  const [customProjectField2, setCustomProjectField2] = useState(account.custom_project_fields[1] || "");

  const submitValues = () => {
    Api.Account.update_custom_project_fields([customProjectField1, customProjectField2]).then(() => {
      window.location.reload();
    });
  };

  return (
    <div className="customization-sub-component">
      <div className="flex">
        <p className="customization-sub-component__title">{t("settings.custom_project_fields")}</p>

        <InfoTooltip
          style={{ marginLeft: "10px" }}
          content={<p style={{ marginBottom: "0" }}>{t("settings.custom_project_fields_tooltip")}</p>}
        />
      </div>

      <div className="custom-field">
        <TeambookTextForm
          fieldName={"Field Name 1"}
          fieldValue={customProjectField1}
          onChange={(text) => setCustomProjectField1(text)}
          placeholder={"e.g. Language"}
        />

        <TeambookTextForm
          fieldName={"Field Name 2"}
          fieldValue={customProjectField2}
          onChange={(text) => setCustomProjectField2(text)}
          placeholder={"e.g. Location"}
        />
      </div>

      <div className="customization-action-buttons">
        <TeambookBlueButton onClick={submitValues} variant="contained" text={t("save")}>
          {t("save")}
        </TeambookBlueButton>
      </div>
    </div>
  );
};

export default CustomProjectFields;

import { useTranslation } from "react-i18next";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";
import { WhiteTooltip } from "../Tooltips";
import { components } from "react-select";
import React, { useState } from "react";
import TeambookMenuComponent from "../TeambookMenuComponent";

const FilterAccessories = (props) => {
  const { t } = useTranslation();

  const {
    filterType,
    changeFilterType,
    enableSortTypes,
    enableSavedFilter,
    closeDropdown,
    setStorageOptionsOpened,
    setStorageOptionsAnchor,
    filterPage,
  } = props.selectProps;

  const openStorageOptions = (e) => {
    e.stopPropagation();
    e.preventDefault();
    closeDropdown();
    setStorageOptionsAnchor(e.currentTarget);
    setStorageOptionsOpened(true);
  };

  const isPlanner = ["userPlanner", "groupedPlanner"].includes(filterPage);

  return (
    <>
      <TeambookIcon
        alt="filter"
        color={isPlanner ? "darkblue_new" : "darkblue"}
        tooltipTitle={t("planning.add_filter")}
        className="planner-control__filter-icon"
        name={icons.FILTER}
      />

      <div className="teambook-filter__right-side">
        {enableSortTypes && (
          <WhiteTooltip title={t("planning.filter_type")}>
            <div className="teambook-filter__type-switcher" onClick={changeFilterType}>
              <p>{filterType}</p>
            </div>
          </WhiteTooltip>
        )}

        {enableSavedFilter && (
          <WhiteTooltip>
            <div className="teambook-filter__type-switcher" onClick={openStorageOptions}>
              <TeambookIcon
                color={isPlanner ? "darkblue_new" : "default"}
                name={"save_icon"}
                style={{
                  width: "22px",
                  height: "22px",
                }}
              />
            </div>
          </WhiteTooltip>
        )}
      </div>

      <components.ValueContainer {...props}>{props.children}</components.ValueContainer>
    </>
  );
};

export default FilterAccessories;

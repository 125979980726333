import React, { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Api from "../../../../../Api";
import { useTranslation } from "react-i18next";
import TeambookBlueButton from "../../../../default_components/TeambookBlueButton";
import { TeambookUsersPicker } from "../../../../default_components/TeambookDefaultPickers";
import { TeambookIcon } from "../../../../default_images/TeambookIcon";
import { icons } from "../../../../default_images/IconsList";
import { shallow } from "zustand/shallow";
import { useNotificationStore } from "../../../../../stores/notificationStore";

const EditHoliday = ({
  onClose,
  editingHoliday,
  holidayUsers,
  setHolidayUsers,
  extraOptions,
  onChangeUsersPicker,
  users,
  getHolidays,
}) => {
  const { t } = useTranslation();
  const [setInAppNotification] = useNotificationStore((state) => [state.setInAppNotification], shallow);
  useEffect(() => {
    setHolidayUsers(editingHoliday.users);
  }, []);

  const handleSubmit = () => {
    Api.Integrations.Holidays.edit(
      holidayUsers.map((u) => u.id),
      editingHoliday.id
    ).then(() => {
      onClose();
      getHolidays();
      setInAppNotification(t("notifications.settings.timeoff_holidays.holiday.edit"));
    });
  };

  return (
    <Dialog
      open
      fullWidth
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth={"xs"}
      className="tb-default-dialog"
    >
      <DialogTitle style={{ fontFamily: "Montserrat", fontWeight: 500 }} id="form-dialog-title">
        {t("settings.time_off.edit_holiday")}
        <TeambookIcon name={icons.CROSS} onClick={onClose} />
      </DialogTitle>

      <DialogContent style={{ margin: "16px 0px" }}>
        <div className="selector-container">
          <p>{t("users.tag_users")}</p>

          <TeambookUsersPicker
            styles={{
              control: (base) => ({
                ...base,
                width: "100%",
                maxWidth: "unset",
                height: "100%",
                maxHeight: 88,
              }),
              valueContainer: (base) => ({
                ...base,
                maxHeight: 84,
                height: "fit-content",
                flexWrap: "wrap !important",
                overflowY: "auto",
                overflowX: "auto !important",
                padding: "0px 4px",
              }),
            }}
            value={holidayUsers}
            id={"selectUsers"}
            onChange={onChangeUsersPicker}
            className="multiline__react-select"
            type="multiple"
            users={[...extraOptions, ...users]}
            defaultValue={holidayUsers}
          />
        </div>
      </DialogContent>

      <DialogActions>
        <TeambookBlueButton text={t("save")} onClick={handleSubmit} id="saveEditHolidayButton" />
      </DialogActions>
    </Dialog>
  );
};

export default EditHoliday;

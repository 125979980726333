import React, { useContext, useState } from "react";
import { Dialog, DialogTitle, DialogContent, Slider, Switch } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";
import lsKeys from "../../default_values/defaultKeys";
import { ActualsContext } from "../context/ActualsContext";

const ActualsVisualSettings = ({
  open,
  closeWindow,
  setActualsZoom,
  approvalMode,
  disableTooltip,
  setDisableTooltip,
  showWeekendLogs,
  setShowWeekendLogs,
}) => {
  const { t } = useTranslation();
  const { zoom } = useContext(ActualsContext);

  const marks = {
    week: 0,
    month: 1,
  };

  const language = localStorage.getItem(lsKeys.LANGUAGE);
  const [currentView, setCurrentView] = useState(marks[zoom]);

  const viewOptions = [
    {
      value: 0,
      label: t("dashboard.week"),
    },
    {
      value: 1,
      label: t("actuals.month"),
    },
  ];

  const viewValues = ["week", "month"];

  return (
    <Dialog className="tb-default-dialog" open={open || false} onClose={() => closeWindow()}>
      <DialogTitle>
        <p style={{ margin: "auto 0" }}>{t("planning.control_panel.visual_settings")}</p>

        <TeambookIcon name={icons.CROSS} onClick={() => closeWindow()} />
      </DialogTitle>
      <DialogContent language={language} className="planner-settings-dialog__content actuals-settings">
        {!approvalMode && (
          <>
            <div className="planner-settings-dialog__setting">
              <p className="planner-settings-dialog__setting-text">{t("planning.hide_weekends")}</p>
              <div className="planner-settings-dialog__setting-control">
                <Switch
                  id={"hideWeekends"}
                  checked={showWeekendLogs}
                  onChange={(event) => {
                    setShowWeekendLogs(event.target.checked);
                    localStorage.setItem(lsKeys.ACTUALS_SHOW_WEEKEND_LOGS, event.target.checked);
                  }}
                  color="primary"
                  name="checkedB"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </div>
            </div>
            <div className="planner-settings-dialog__setting">
              <p className="planner-settings-dialog__setting-text">{t("planning.disable_tooltip")}</p>
              <div className="planner-settings-dialog__setting-control">
                <Switch
                  id={"disableTooltip"}
                  checked={disableTooltip}
                  onChange={(event) => {
                    setDisableTooltip(event.target.checked);
                    localStorage.setItem(lsKeys.ACTUALS_DISABLE_TOOLTIP, !event.target.checked);
                  }}
                  color="primary"
                  name="checkedB"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </div>
            </div>

            <div style={{ margin: 0 }} className="planner-settings-dialog__setting">
              <p className="planner-settings-dialog__setting-text">{t("planning.planner_zoom")}</p>

              <div
                className="planner-settings-dialog__setting-control"
                style={{ width: "220px", alignItems: "center", margin: "25px 7px 0px 0px" }}
              >
                <Slider
                  id={"cellsSize"}
                  className={`planner-settings-dialog__slider actuals-dialog__slider ${language}`}
                  aria-label="Restricted values"
                  defaultValue={0}
                  step={null}
                  marks={viewOptions}
                  max={1}
                  track={false}
                  value={currentView}
                  onChange={(e, view) => {
                    setActualsZoom(viewValues[view]);
                    setCurrentView(view);
                    // localStorage.setItem(lsKeys.LAST_CAPACITY_ZOOM, activity.value);
                  }}
                  sx={{
                    "& .MuiSlider-markLabel": {
                      opacity: 0.6,
                    },
                    [`& .MuiSlider-markLabel[data-index="${currentView}"]`]: {
                      opacity: 1,
                    },
                  }}
                />
              </div>
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ActualsVisualSettings;

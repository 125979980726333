import { DialogTitle, Dialog, DialogActions, DialogContent } from "@mui/material";
import { useTranslation } from "react-i18next";
import TeambookBlueButton from "../default_components/TeambookBlueButton";
import DangerPic from "../default_images/danger_deletion.svg";
import TeambookOutlinedButton from "./TeambookOutlinedButton";
import { TeambookIcon } from "../default_images/TeambookIcon";
import { icons } from "../default_images/IconsList";

export default function DeleteDialog({ open, setOpen, contentText, deleteRequest, deletionObject, arrOfDeletionObjects, buttonText }) {
  const { t } = useTranslation();
  return (
    <Dialog className="delete-dialog" open={open} onClose={() => setOpen(false)}>
      <DialogTitle className="delete-dialog__title">
        <img src={DangerPic} alt={"danger"} />
        <TeambookIcon
          style={{ position: "absolute", right: 40, top: 0, cursor: "pointer" }}
          name={icons.CROSS}
          onClick={() => setOpen(false)}
        />
      </DialogTitle>
      <DialogContent style={{ padding: "0px 40px 20px 40px" }} className="delete-dialog__content">
        <div style={{ marginBottom: "0px" }}>{contentText}</div>
      </DialogContent>
      <DialogActions style={{ padding: "0px 40px 30px 40px" }}>
        <TeambookBlueButton
          text={buttonText || t("delete")}
          onClick={() => {
            deleteRequest(deletionObject || arrOfDeletionObjects);
            setOpen(false);
          }}
          style={{
            border: "1px solid #4C54A0 !important",
            backgroundColor: "#4C54A0",
          }}
        />
        <TeambookOutlinedButton text={t("cancel")} style={{ height: 40 }} onClick={() => setOpen(false)} />
      </DialogActions>
    </Dialog>
  );
}

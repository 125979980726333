import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TeambookBlueButton from "../../default_components/TeambookBlueButton";
import ControlPanel from "../ControlPanel";
import "../administration.scss";
import axios from "axios";
import Api from "../../../Api";
import TeambookWhiteButton from "../../default_components/TeambookWhiteButton";
import { Dialog } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TeambookTextForm from "../../default_components/TeambookTextForm";

export default function LocalesPage() {
  const adminToken = localStorage.getItem("tb-admin-token");

  const [translationEN, setTranslationEN] = useState("");
  const [translationFR, setTranslationFR] = useState("");
  const [translationDE, setTranslationDE] = useState("");
  const [translationES, setTranslationES] = useState("");
  const [translationIT, setTranslationIT] = useState("");
  const [scrollPosition, setScrollPosition] = useState(0);
  const [requestIsOnTheWay, setRequestIsOnTheWay] = useState(false);
  const [insertLocaleModalOpenned, setInsertLocaleModalOpenned] = useState(false);
  const [insertionKey, setInsertionKey] = useState("");
  const [insertionText, setInsertionText] = useState("");

  useEffect(() => {
    fetchTranslations();
  }, []);

  const awsUrl = () => {
    if (process.env.REACT_APP_ENV === "production") {
      return "https://teambook-account-images-production.s3.amazonaws.com/i18n";
    } else {
      return "https://teambook-account-images-production.s3.amazonaws.com/i18n/development";
    }
  };

  const fetchTranslations = () => {
    axios.get(`${awsUrl()}/en.json?_=` + Date.now()).then((res) => {
      setTranslationEN(JSON.stringify(res.data, null, 4));
    });
    axios.get(`${awsUrl()}/fr.json?_=` + Date.now()).then((res) => {
      setTranslationFR(JSON.stringify(res.data, null, 4));
    });
    axios.get(`${awsUrl()}/de.json?_=` + Date.now()).then((res) => {
      setTranslationDE(JSON.stringify(res.data, null, 4));
    });
    axios.get(`${awsUrl()}/es.json?_=` + Date.now()).then((res) => {
      setTranslationES(JSON.stringify(res.data, null, 4));
    });
    axios.get(`${awsUrl()}/it.json?_=` + Date.now()).then((res) => {
      setTranslationIT(JSON.stringify(res.data, null, 4));
    });
  };

  const saveLanguageChanges = (language, newTranslation) => {
    setRequestIsOnTheWay(true);

    Api.Integrations.Locales.update(language, newTranslation, adminToken)
      .then((res) => {
        setRequestIsOnTheWay(false);
      })
      .catch((error) => {
        alert(error.response.data.error);
        setRequestIsOnTheWay(false);
      });
  };

  const handleScroll = (event) => {
    setScrollPosition(event.target.scrollTop);
  };

  const insertNewLocale = () => {
    Api.Integrations.Locales.insert(insertionKey, insertionText, localStorage.getItem("tb-admin-token")).then(() => {
      setInsertionKey("");
      setInsertionText("");
      setInsertLocaleModalOpenned(false);
      fetchTranslations();
    });
  };

  return (
    <>
      <Link to="/administration">
        <TeambookBlueButton
          style={{
            zIndex: "1",
            position: "fixed",
            left: "5px",
            top: "5px",
          }}
          text={"To Administration"}
        />
      </Link>

      <TeambookWhiteButton
        text={"Insert Locale"}
        onClick={() => setInsertLocaleModalOpenned(true)}
        style={{ position: "fixed", left: "180px", top: "5px" }}
      />

      <div className="flex locales-component">
        <div className="language-column">
          <div className="flex language-header">
            <p>EN</p>

            <TeambookBlueButton
              text="Save"
              onClick={() => saveLanguageChanges("en", translationEN)}
              disabled={requestIsOnTheWay}
            />
          </div>

          <textarea
            onScroll={handleScroll}
            ref={(textarea) => {
              if (textarea) textarea.scrollTop = scrollPosition;
            }}
            value={translationEN}
            onChange={(e) => setTranslationEN(e.target.value)}
          />
        </div>

        <div className="language-column">
          <div className="flex language-header">
            <p>FR</p>

            <TeambookBlueButton
              text="Save"
              onClick={() => saveLanguageChanges("fr", translationFR)}
              disabled={requestIsOnTheWay}
            />
          </div>

          <textarea
            onScroll={handleScroll}
            ref={(textarea) => {
              if (textarea) textarea.scrollTop = scrollPosition;
            }}
            value={translationFR}
            onChange={(e) => setTranslationFR(e.target.value)}
          />
        </div>

        <div className="language-column">
          <div className="flex language-header">
            <p>DE</p>

            <TeambookBlueButton
              text="Save"
              onClick={() => saveLanguageChanges("de", translationDE)}
              disabled={requestIsOnTheWay}
            />
          </div>

          <textarea
            onScroll={handleScroll}
            ref={(textarea) => {
              if (textarea) textarea.scrollTop = scrollPosition;
            }}
            value={translationDE}
            onChange={(e) => setTranslationDE(e.target.value)}
          />
        </div>

        <div className="language-column">
          <div className="flex language-header">
            <p>ES</p>

            <TeambookBlueButton
              text="Save"
              onClick={() => saveLanguageChanges("es", translationES)}
              disabled={requestIsOnTheWay}
            />
          </div>

          <textarea
            onScroll={handleScroll}
            ref={(textarea) => {
              if (textarea) textarea.scrollTop = scrollPosition;
            }}
            value={translationES}
            onChange={(e) => setTranslationES(e.target.value)}
          />
        </div>

        <div className="language-column">
          <div className="flex language-header">
            <p>IT</p>

            <TeambookBlueButton
              text="Save"
              onClick={() => saveLanguageChanges("it", translationIT)}
              disabled={requestIsOnTheWay}
            />
          </div>

          <textarea
            onScroll={handleScroll}
            ref={(textarea) => {
              if (textarea) textarea.scrollTop = scrollPosition;
            }}
            value={translationIT}
            onChange={(e) => setTranslationIT(e.target.value)}
          />
        </div>
      </div>

      <ControlPanel />

      {insertLocaleModalOpenned && (
        <Dialog
          className="tb-default-dialog"
          open={true}
          onClose={() => setInsertLocaleModalOpenned(false)}
          aria-labelledby="add-user-dialog"
          maxWidth={"sm"}
        >
          <DialogTitle className="planner-add-user-dialog__dialog-title" id="add-user-dialog">
            <p>Insert Locale (create new one OR overwrite)</p>
            <TeambookIcon name={icons.CROSS} onClick={() => setInsertLocaleModalOpenned(false)} />
          </DialogTitle>

          <DialogContent style={{ width: "800px" }}>
            <TeambookTextForm
              onChange={setInsertionKey}
              fieldValue={insertionKey}
              type={"text"}
              placeholder={"settings.example.locate"}
            />
            <TeambookTextForm
              onChange={setInsertionText}
              fieldValue={insertionText}
              type={"text"}
              placeholder={"New Translation"}
            />
          </DialogContent>

          <DialogActions>
            <TeambookBlueButton
              text={"Insert in EN/FR/DE/ES/IT"}
              onClick={() => {
                insertNewLocale();
              }}
            />
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

import "../users/users.scss";
import React from "react";

const ListSeparator = (props) => {
  return (
    <div className="user-list__separator">
      <p className="user-list__separator__text">{props.text}</p>
      <div className="user-list__separator__line" />
    </div>
  );
};

export default ListSeparator;

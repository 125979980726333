import React, { useEffect, useState } from "react";
import "./profile.scss";
import "../default_styles/tb-colors.css";
import "../organization_settings/settings.scss";
import Header from "../header/Header";
import Api from "../../Api";
import LeftSide from "./sub_components/LeftSide";
import RightSide from "./sub_components/RightSide";
import { useProfileStore } from "../../stores/profileStore";
import { shallow } from "zustand/shallow";

const Profile = () => {
  const [user] = useProfileStore((state) => [state.profile], shallow);
  const [menuIndex, setMenuIndex] = useState(0);

  if (user.id === undefined) {
    return <div />;
  }

  return (
    <div className="row settings_page" style={{ margin: "0" }}>
      <Header user={user} />

      <div className="profile__show-container">
        <LeftSide menuIndex={menuIndex} setMenuIndex={setMenuIndex} />

        <RightSide menuIndex={menuIndex} />
      </div>
    </div>
  );
};

export default Profile;

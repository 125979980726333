import React, { useEffect, useRef, useState } from "react";
import { WhiteTooltip } from "../../default_components/Tooltips";
import { useTranslation } from "react-i18next";
import UploadProjectsDialog from "./UploadProjectsDialog";
import ExceedProjectsDialog from "./ExceedProjectsDialog";
import AddPlus from "../../default_images/add_plus.svg";
import TeambookBlueButton from "../../default_components/TeambookBlueButton";
import WhiteFolder from "../../default_images/white_folder.svg";
import { TeambookReactSelect } from "../../default_components/TeambookReactSelect";
import ArrowDown from "../../default_images/white_arrow_bottom.svg";
import { ClickAwayListener } from "@mui/base";
import { TeambookCheckbox } from "../../default_components/TeambookCheckbox";
import lsKeys from "../../default_values/defaultKeys";
import { shallow } from "zustand/shallow";
import { useProjectsVisibilityColumnsStore } from "../../../stores/projects";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";
import { useAccountStore } from "../../../stores/accountStore";
import TeambookFilterComponent from "../../default_components/teambook_filter/TeambookFilterComponent";
import Api from "../../../Api";

const WAIT_INTERVAL = 350;
const availableColumns = [
  "short_name",
  "client",
  "billable",
  "start_date",
  "end_date",
  "manager",
  "status",
  "business_unit",
  "planned_hours",
  "logged_hours",
  "capacity_days",
];

const defaultData = {
  short_name: true,
  client: true,
  billable: true,
  start_date: true,
  end_date: true,
  planned_hours: true,
  logged_hours: true,
  manager: false,
  status: false,
  business_unit: false,
  capacity_days: false,
};

const ProjectsFilterRow = ({
  setArchivedSelect,
  setClientsOpen,
  archivedSelect,
  openCreation,
  getActiveProjects,
  setEmptyFiltered,
  filterProjects,
  exceedWindowOpened,
  setExceedWindowOpened,
  activeProjects,
  deactivatedProjects,
  getAllClients,
  clients,
}) => {
  const { t } = useTranslation();
  const timeoutRef = useRef(null);
  const [uploadDialogOpened, setUploadDialogOpened] = useState(false);
  const [uploadDropdownShow, setUploadDropdownShow] = useState(false);
  const [columnsSettingsOpened, setColumnsSettingsOpened] = useState(false);
  const [managers, setManagers] = useState([]);
  const [businessUnits, setBusinessUnits] = useState([]);
  const columnsAnchorRef = useRef(null);

  const [account] = useAccountStore((state) => [state.account], shallow);

  const { custom_project_fields } = account;

  const [columnsVisibility, setColumnsVisibility] = useProjectsVisibilityColumnsStore(
    (state) => [state.columnsVisibility, state.setColumnsVisibility],
    shallow
  );

  const selectOptions = [
    { value: "Active", label: t("projects.active") },
    { value: "Archived", label: t("projects.deactivated") },
  ];

  useEffect(() => {
    if (localStorage.getItem(lsKeys.PROJECTS_COLUMNS_DISPLAY)) {
      setColumnsVisibility(JSON.parse(localStorage.getItem(lsKeys.PROJECTS_COLUMNS_DISPLAY)));
    } else {
      setColumnsVisibility(defaultData);
    }
  }, []);

  useEffect(() => {
    let availableProjects = archivedSelect === "Active" ? activeProjects : deactivatedProjects;

    Api.Projects.managers(availableProjects.map(({ id }) => id)).then((response) => {
      setManagers(response.data);
    });

    Api.Projects.business_units(availableProjects.map(({ id }) => id)).then((response) => {
      setBusinessUnits(response.data.business_units);
    });
  }, [archivedSelect]);

  useEffect(() => {
    localStorage.setItem(lsKeys.PROJECTS_COLUMNS_DISPLAY, JSON.stringify(columnsVisibility));
  }, [columnsVisibility]);

  // useEffect(() => {
  //   if (timeoutRef.current !== null) {
  //     clearTimeout(timeoutRef.current);
  //   }

  //   timeoutRef.current = setTimeout(
  //     () => {
  //       timeoutRef.current = null;
  //       let filteredProjects;

  //       if (archivedSelect === "Active") {
  //         filteredProjects = filterProjects(activeProjects, filterValue);
  //       } else {
  //         filteredProjects = filterProjects(deactivatedProjects, filterValue);
  //       }

  //       filteredProjects.length ? setEmptyFiltered(false) : setEmptyFiltered(true);
  //     },

  //     WAIT_INTERVAL
  //   );
  // }, [filterValue, activeProjects, deactivatedProjects]);

  const getFilterClients = () => {
    const clientsWithBookings = [];

    let availableProjects = archivedSelect === "Active" ? activeProjects : deactivatedProjects;

    availableProjects.forEach((pr) => {
      if (pr.client_id && !clientsWithBookings.includes(pr.client_id)) {
        clientsWithBookings.push(pr.client_id);
      }
    });

    return clients.filter((client) => clientsWithBookings.includes(client.id));
  };

  const switchDropdownShow = () => {
    setUploadDropdownShow(!uploadDropdownShow);
  };

  const closeDropdowns = () => {
    setUploadDropdownShow(false);
    setColumnsSettingsOpened(false);
  };

  const handleChangeArchived = (newValue) => {
    setArchivedSelect(newValue.value);
  };

  const openUploadProjectsWindow = () => {
    account?.active_projects >= account?.subscription?.projects_available
      ? setExceedWindowOpened(true)
      : setUploadDialogOpened(true);
  };

  const switchColumnsVisibility = (field) => {
    let oldObject = { ...columnsVisibility };

    if (columnsVisibility[field] !== undefined) {
      oldObject[field] = !columnsVisibility[field];
    } else {
      oldObject[field] = !defaultData[field];
    }

    setColumnsVisibility(oldObject);
  };

  return (
    <div className="filter-row">
      <div className="filter-row__left-side">
        <div className="planner-control__filter-text-field users-page-filter mobile_hidden">
          <TeambookFilterComponent
            filterPage="projectsPage"
            users={[]}
            teams={[]}
            tags={[]}
            businessUnits={businessUnits}
            clients={getFilterClients()}
            managers={managers}
            projects={archivedSelect === "Active" ? activeProjects : deactivatedProjects}
          />
        </div>
        <div className="deactivate-switch">
          {selectOptions.map((value) => (
            <div
              onClick={() => handleChangeArchived(value)}
              className={`${
                value.value === archivedSelect ? "active" : ""
              } projects__active-entity-switch__option pointer`}
            >
              <p>{value.label}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="filter-row__right-side">
        <div className="manage-clients-button disabled mobile_hidden" onClick={() => setClientsOpen(true)}>
          <div className="filter-row__button-caption ">
            <TeambookIcon name={icons.TASKS} color="darkblue" />
          </div>
        </div>

        <div
          className="manage-clients-button mobile_hidden"
          title={t("projects.manage_clients")}
          onClick={() => setClientsOpen(true)}
        >
          <div className="filter-row__button-caption">
            <TeambookIcon name={icons.PLACE} color="darkblue" tooltipTitle={t("projects.clients")} />
          </div>
        </div>

        <div
          ref={columnsAnchorRef}
          className="columns-settings-button mobile_hidden"
          onClick={() => {
            setColumnsSettingsOpened(true);
          }}
        >
          <TeambookIcon
            id="settings"
            classname="planner-control-panel__setting-icon"
            name={icons.EYE_OPENED}
            tooltipTitle={t("planning.control_panel.visual_settings")}
            color="darkblue"
          />

          {columnsSettingsOpened && (
            <ClickAwayListener onClickAway={closeDropdowns}>
              <div
                style={{
                  left: columnsAnchorRef.current.getBoundingClientRect().x - 50,
                  top: columnsAnchorRef.current.getBoundingClientRect().y - 15,
                }}
                className="control-bar__dropdown-fields"
              >
                {[...availableColumns, ...custom_project_fields.filter((field) => field)].map((key) => (
                  <div className="columns-selection-option" key={`columns-setting-${key}`}>
                    <TeambookCheckbox
                      checked={columnsVisibility[key] || false}
                      onChange={() => switchColumnsVisibility(key)}
                    />
                    <p onClick={() => switchColumnsVisibility(key)}>
                      {custom_project_fields.find((field) => field === key)
                        ? key
                        : t(`projects.selected_fields.${key}`)}
                    </p>
                  </div>
                ))}
              </div>
            </ClickAwayListener>
          )}
        </div>

        <div style={{ display: "flex", position: "relative" }}>
          {account?.active_projects >= account?.subscription?.projects_available ? (
            <div>
              <WhiteTooltip title={t("projects.no_more_projects")}>
                <TeambookBlueButton
                  alt="mobile-add"
                  className="mobile_hidden filter-row__add-project-button filter-row__exceed-button"
                  onClick={() => setExceedWindowOpened(true)}
                  variant="contained"
                  borderRadius="7px 0px 0px 7px"
                  disabledAnimations
                  text={
                    <div className="filter-row__add-user-button_text">
                      <TeambookIcon color="white" name={icons.PROJECT_PLUS} />
                      {t("projects.create_project")}
                    </div>
                  }
                />
              </WhiteTooltip>
              <img
                alt="mobile-add"
                className="desktop_hidden filter-row__mobile-button"
                src={AddPlus}
                onClick={() => setExceedWindowOpened(true)}
              />
            </div>
          ) : (
            <div>
              <TeambookBlueButton
                style={{
                  border: "1px solid var(--accent-dark) !important",
                  backgroundColor: "var(--accent-dark)",
                }}
                alt="mobile-add"
                className="mobile_hidden filter-row__add-project-button"
                onClick={openCreation}
                variant="contained"
                borderRadius="4px 0px 0px 4px !important"
                disabledAnimations
                text={
                  <div className="filter-row__add-user-button_text">
                    <TeambookIcon color="white" name={icons.PROJECT_PLUS} />
                    {t("projects.create_project")}
                  </div>
                }
              />
              <img
                alt="mobile-add"
                className="desktop_hidden filter-row__mobile-button"
                src={AddPlus}
                onClick={openCreation}
              />
            </div>
          )}

          <div
            style={{
              opacity: account?.active_projects >= account?.subscription?.projects_available ? "0.6" : "1",
            }}
            onClick={() => {
              if (!(account?.active_projects >= account?.subscription?.projects_available)) {
                switchDropdownShow();
              }
            }}
            className="create-dropdown-trigger"
          >
            <span className="button-separator" />
            <img style={{ rotate: uploadDropdownShow ? "180deg" : "0deg" }} src={ArrowDown} />
          </div>

          {uploadDropdownShow && (
            <ClickAwayListener onClickAway={closeDropdowns}>
              <div className="control-bar__dropdown" onClick={() => openUploadProjectsWindow()}>
                <TeambookIcon name={icons.UPLOAD} color="alternative_default" />
                <p>{t("projects.upload_projects")}</p>
              </div>
            </ClickAwayListener>
          )}
        </div>
      </div>

      <UploadProjectsDialog
        open={uploadDialogOpened}
        handleClose={() => {
          setUploadDialogOpened(false);
        }}
        getActiveProjects={getActiveProjects}
        getAllClients={getAllClients}
      />

      <ExceedProjectsDialog
        open={exceedWindowOpened}
        handleClose={() => {
          setExceedWindowOpened(false);
        }}
      />
    </div>
  );
};

export default ProjectsFilterRow;

import React, { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { WhiteTooltip } from "../../default_components/Tooltips";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";

const usersWithData = (users, reservations) => {
  return users.filter((u) =>
    reservations
      .filter((ur) => ur.days_reserved > 0)
      .map((ur) => ur.user_id)
      .includes(u.id)
  );
};

const StaffingTotalRow = ({
  switchUsersOpen,
  usersOpen,
  userReservations,
  date,
  selectedViewType,
  users,
  addedUsers,
}) => {
  const { t } = useTranslation();
  const [staffingCalculations, setStaffingCalculations] = useState([]);

  useEffect(() => {
    recalculateStuffing();
  }, [userReservations]);

  const recalculateStuffing = () => {
    setStaffingCalculations(
      [...Array(selectedViewType)].map((x, i) => {
        const calculatedDate = date.plus({ months: i }).toISODate();

        return userReservations
          .filter((cr) => DateTime.fromISO(cr.date).toISODate() === calculatedDate)
          .map((cr) => cr.days_reserved)
          .reduce((ps, a) => ps + a, 0);
      })
    );
  };

  const shownUsers = () => {
    const usersInCapacity = usersWithData(users, userReservations);

    return [...usersInCapacity.filter((u) => !addedUsers.map((_u) => _u.id).includes(u.id)), ...addedUsers];
  };

  return (
    <div className="flex project-row">
      <div className="users-block__project-info greyed" onClick={switchUsersOpen} style={{ cursor: "pointer" }}>
        <p className="font-italic">
          {t("planning.capacity.days_staffed")} ({shownUsers().length}{" "}
          {shownUsers().length === 1 ? t("planning.filter.user") : t("header.users")})
        </p>

        <WhiteTooltip title={t(`planning.capacity.${usersOpen ? "hide" : "show"}_users`)}>
          <TeambookIcon
            className="arrow-switch"
            name={usersOpen ? icons.ARROW_UP : icons.ARROW_DOWN}
            alt={"Show All Users"}
          />
        </WhiteTooltip>
      </div>

      {[...Array(selectedViewType)].map((x, i, arr) => (
        <div
          style={{ borderRight: arr.length - 1 === i ? "1px solid var(--stroke)" : "" }}
          className="table-cell font-italic greyed"
        >
          <p>{staffingCalculations[i]}</p>
        </div>
      ))}
    </div>
  );
};

export default StaffingTotalRow;

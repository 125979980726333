import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { OffsetDays, GetDaysInZoom, getCellWidthForZoom } from "../../../../lib/DateUtils";
import UserAvatarCell from "../../default_components/UserAvatar";
import GroupedUserBookings from "./GroupedUserBookings";
import UserHoursLeft from "../../default_components/UserHoursLeft";
import DayInfo from "../../default_components/DayInfo";
import UserBlockedSpace from "../../default_components/UserBlockedSpace";
import GroupedUserRowWeek from "./GroupedUserRowWeek";
import { PlannerContext } from "../../context/PlannerContext";
import { Dropzone } from "../../default_components/Dropzone";
import {
  useBookingsCreatingStore,
  useBookingsStore,
  useCopyBookingsStore,
  useDateStore,
  useMoveBookingStore,
  useZoomStore,
} from "../../../../stores/planner";
import { useDrop } from "react-dnd";
import $ from "jquery";
import { shallow } from "zustand/shallow";
import GroupedCreationBooking from "./GroupedCreationBooking";
import { GROUPED_PLANNER_USER_WIDTH, usersMaxScheduleTime } from "../../../../lib/PlannerUtils";
import { WhiteTooltip } from "../../../default_components/Tooltips";
import lsKeys from "../../../default_values/defaultKeys";
import { useProfileStore } from "../../../../stores/profileStore";

const GroupedUserRow = React.forwardRef(
  (
    {
      index,
      user,
      setTeams,
      setRemoveUser,
      mouseDowned,
      mouseUped,
      weekendsHidden,
      deleteBookingsRequest,
      setUserSwapId,
      userSwapId,
      swapUsersRequest,
      firstPlannerDate,
      activeProjects,
      changeTooltip,
      topOffset,
      mouseMoved,
      selectBooking,
      addBookingToCreation,
      filteredUsers,
      bookingFormat,
      teams,
      project,
      usersAdded,
      changeContextMenu,
      updateBookingsRequest,
      virtualProps,
      groupedProjectUsers,
      moveBookingRequest,
      groupClients,
      userIndex,
      clients,
      tasks,
      weekendInfoRef,
      changePlannerRightClickRef,
    },
    ref
  ) => {
    const { projects } = useContext(PlannerContext);
    const [date] = useDateStore((state) => [state.date], shallow);
    const [zoom] = useZoomStore((state) => [state.zoom], shallow);
    const [bookings] = useBookingsStore((state) => [state.bookings], shallow);
    const { bookingsCreating } = useBookingsCreatingStore();
    const [profile] = useProfileStore((state) => [state.profile], shallow);

    const formatMapping = {
      0: 5,
      1: 10,
      2: 15,
    };

    const [reloadedRow, setReloadedRow] = useState(false);
    const [rowBookings, setRowBookings] = useState(
      useBookingsStore.getState().bookings.filter((booking) => booking.user_id === user.id)
    );
    const [dateOpened, setDateOpened] = useState();
    const [bookingsDate, setBookingsDate] = useState();
    const bookingsRef = useRef([]);
    let days = GetDaysInZoom(zoom);
    const { movingHash } = useMoveBookingStore();

    const [copyBookingIds] = useCopyBookingsStore((state) => [state.copyBookingIds], shallow);

    const columns = useMemo(() => {
      let userRow = [];

      userRow.push(
        <UserAvatarCell
          isDraggable={false}
          offsetTop={topOffset}
          user={user}
          setUserSwapId={setUserSwapId}
          userSwapId={userSwapId}
          swapUsersRequest={swapUsersRequest}
          setTeams={setTeams}
          setRemoveUser={setRemoveUser}
          bookingFormat={bookingFormat}
          showButtons={false}
          height={(usersMaxScheduleTime(user) / 60) * formatMapping[bookingFormat] + 17}
          groupClients={groupClients}
          groupedView={true}
          isLastUser={groupedProjectUsers.flat().length - 1 === index}
        />
      );

      for (let i = 0; i < days / 7; i++) {
        userRow.push(
          <GroupedUserRowWeek
            mouseDowned={mouseDowned}
            user={user}
            startDate={OffsetDays(firstPlannerDate, i * 7)}
            mouseMoved={mouseMoved}
            mouseUped={mouseUped}
            weekendsHidden={weekendsHidden}
            changeTooltip={changeTooltip}
            filteredUsers={filteredUsers}
            project={project}
            isLastWeek={i + 1 === days / 7}
            isLastUser={groupedProjectUsers.flat().length - 1 === index}
          />
        );
      }

      return userRow;
    }, [
      rowBookings,
      userSwapId,
      zoom,
      reloadedRow,
      weekendsHidden,
      projects,
      copyBookingIds,
      filteredUsers,
      usersAdded,
    ]);

    const updateCells = () => {
      for (let i = 0; i < days; i++) {
        bookingsRef.current[i].updateBookings();
      }
    };

    const updateCellField = (fieldName, value, dateIndex) => {
      if (bookingsRef.current[dateIndex]) {
        switch (fieldName) {
          case "booking_duration":
            bookingsRef.current[dateIndex].updateBookingDuration(value);
            break;
          case "project_id":
            bookingsRef.current[dateIndex].updateProjectId(value);
            break;
        }
      }
    };

    const reloadRow = () => {
      setReloadedRow(!reloadedRow);
    };

    const updateBookings = () => {
      setRowBookings(useBookingsStore.getState().bookings.filter((booking) => booking.user_id === user.id));
    };

    React.useImperativeHandle(ref, () => {
      return {
        updateCells: updateCells,
        updateCellField: updateCellField,
        closeCreation: closeCreation,
        reloadRow: reloadRow,
        updateBookings: updateBookings,
        filterOutProject: filterOutProject,
      };
    });

    const closeCreation = (dateIndex) => {
      if (bookingsRef.current[dateIndex]) {
        bookingsRef.current[dateIndex].closeCreation();
      }
    };

    const filterOutProject = (filteredProjects) => {
      if (bookingsRef.current.length === 0) {
        setTimeout(() => {
          filterOutProject(filteredProjects);
        }, 500);
      }

      bookingsRef.current.forEach((cellRef) => {
        if (cellRef) {
          cellRef.filterOutProject(filteredProjects);
        }
      });
    };

    const moveBooking = (hash) => {
      let { offsetX, zoom, date, moveBookingRequest } = hash;

      if (groupClients) {
        offsetX -= 50;
      }

      const weekendsHidden = !(localStorage.getItem(lsKeys.WEEKENDS_HIDDEN_KEY) === "false");

      let toDate;

      const weekendWidth = weekendsHidden ? 10 : getCellWidthForZoom(zoom);
      const dayWidth = getCellWidthForZoom(zoom);

      let dateIteration = 0;
      let calculatedOffsetX = 0;

      while (calculatedOffsetX < offsetX) {
        calculatedOffsetX += dateIteration % 7 < 5 ? dayWidth : weekendWidth;
        dateIteration++;
      }

      if (zoom === 1) {
        toDate = date;
      } else {
        toDate = OffsetDays(date, dateIteration - 1);
      }

      return moveBookingRequest({ ...hash, to_date: toDate });
    };

    const maxHeight = (usersMaxScheduleTime(user) / 60) * formatMapping[bookingFormat] + 17;

    const [collectedProps, drop] = useDrop(() => ({
      accept: "BOOKING",
      drop: (item, monitor) => {
        const currentBookings = useBookingsStore.getState().bookings;
        const scrollElement = $("#planner-scroll-list");
        const offsetX = Math.abs(
          monitor.getClientOffset().x + scrollElement.scrollLeft() - (30 + GROUPED_PLANNER_USER_WIDTH)
        );

        const booking = currentBookings.find(({ id }) => id === item.id);

        moveBooking({
          ...movingHash,
          booking: booking,
          to_user: user.id,
          from_date: booking.date,
          from_user: booking.user_id,
          offsetX: offsetX,
          zoom: zoom,
          date: date,
          moveBookingRequest: moveBookingRequest,
          updateBookings: updateBookings,
        });
      },
    }));

    return (
      <Dropzone isDraggable={true} drop={drop}>
        <div
          className="user-row"
          ref={ref}
          style={{
            ...virtualProps,
            zIndex: dateOpened ? 1 : "unset",
          }}
          id={`${user.id}-${index}`}
          key={`${user.id}-${firstPlannerDate}`}
          onContextMenu={(e) => {
            e.preventDefault();
            if (useCopyBookingsStore.getState().copyBookingIds.length > 0) {
              changePlannerRightClickRef({
                open: true,
                posX: e.pageX,
                posY: e.pageY,
                userId: user.id,
                date: date,
                index: index,
              });
            }

            e.stopPropagation();
          }}
        >
          {groupClients && (
            <WhiteTooltip title={clients.filter((client) => client.id === project.client_id)[0]?.name}>
              <div
                style={{ borderBottomLeftRadius: groupedProjectUsers.flat().length - 1 === index && 7 }}
                className="project-group__client-holder"
              >
                {userIndex === 0 && (
                  <p className="project-group__client-name">
                    {clients.filter((client) => client.id === project.client_id)[0]?.name || "No Client"}
                  </p>
                )}
              </div>
            </WhiteTooltip>
          )}

          {columns}

          <UserHoursLeft
            bookingsArray={rowBookings}
            schedule={user.schedule.map((schedule) => schedule[0] + schedule[2])}
            days={days}
            date={firstPlannerDate}
            weekendsHidden={weekendsHidden}
            topOffset={topOffset}
            user={user}
            selectBooking={selectBooking}
            userIndex={index}
            deleteBookingsRequest={deleteBookingsRequest}
            setBookingsDate={setBookingsDate}
            bookingFormat={bookingFormat}
            groupClients={groupClients}
            hoursType={"grouped"}
            projects={projects}
            groupedView={true}
            weekendInfoRef={weekendInfoRef}
            isLastWeek={groupedProjectUsers.flat().length - 1 === index}
          />

          {dateOpened && (
            <DayInfo
              bookings={bookingsDate}
              user={user}
              closeDayInfo={() => {
                setDateOpened(null);
              }}
              editBookings={(booking) => selectBooking(booking, project.id)}
              deleteBookingsRequest={(booking) => deleteBookingsRequest([booking])}
              OpenCreationMenu={() => {
                addBookingToCreation(index, new Date(dateOpened), project);
              }}
              date={dateOpened}
              maxHeight={maxHeight}
              weekendsHidden={weekendsHidden}
              plannerDate={firstPlannerDate}
            />
          )}

          {bookingsCreating
            .filter((booking) => booking[0] === index)
            .map((booking) => (
              <GroupedCreationBooking
                userIndex={booking[0]}
                date={booking[1]}
                filteredUsers={groupedProjectUsers}
                mouseMoved={mouseMoved}
                weekendsHidden={weekendsHidden}
                bookingFormat={bookingFormat}
                groupClients={groupClients}
              />
            ))}

          <GroupedUserBookings
            bookingsArray={rowBookings}
            date={firstPlannerDate}
            topOffset={topOffset}
            weekendsHidden={weekendsHidden}
            maxHeight={maxHeight}
            selectBooking={selectBooking}
            activeProjects={activeProjects}
            changeTooltip={changeTooltip}
            ref={(el) => (bookingsRef.current[0] = el)}
            bookingFormat={bookingFormat}
            teams={teams}
            project={project}
            changeContextMenu={changeContextMenu}
            updateBookingsRequest={updateBookingsRequest}
            user={user}
            mouseUped={mouseUped}
            groupClients={groupClients}
            mouseMoved={mouseMoved}
            mouseDowned={mouseDowned}
            tasks={tasks}
            index={index}
            weekendInfoRef={weekendInfoRef}
          />

          <UserBlockedSpace
            maxHeight={maxHeight}
            bookingFormat={bookingFormat}
            date={firstPlannerDate}
            weekendsHidden={weekendsHidden}
            schedule={user.schedule}
            topOffset={topOffset}
            mouseMoved={mouseMoved}
            mouseUped={mouseUped}
            mouseDowned={mouseDowned}
            filteredUsers={filteredUsers}
            user={user}
            groupClients={groupClients}
            groupedView={true}
          />
        </div>
      </Dropzone>
    );
  }
);

export default React.memo(GroupedUserRow);

import React from "react";
import EditProfile from "./display_general_info/EditProfile";
import EmailPasswordUpdateForm from "./EmailPasswordUpdateForm";
import ScheduleSettings from "./ScheduleSettings";
import NotificationsSettings from "./NotificationsSettings";

const RightSide = ({ menuIndex }) => {
  return (
    <div className="profile__show-right-side">
      {menuIndex === 0 && <EditProfile />}
      {menuIndex === 1 && <EmailPasswordUpdateForm />}
      {menuIndex === 2 && <ScheduleSettings />}

      {menuIndex === 3 && <NotificationsSettings />}
    </div>
  );
};

export default RightSide;

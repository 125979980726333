import React from "react";
import { colorsPerType } from "./colorsPerType";
import lsKeys from "../../default_values/defaultKeys";

const calculateDatasets = (stats, from, isDarkTheme, type, t, graphRange) => {
  const datasets = [];
  const labels = [];
  let both = [];
  let billable = [];
  const lang = localStorage.getItem(lsKeys.LANGUAGE);
  const colors = [
    localStorage.getItem(lsKeys.THEME) === "light" ? "#4c54a0" : "#49587a",
    localStorage.getItem(lsKeys.THEME) === "light" ? "#9199e7" : "#99A0E0",
  ];
  const isComparing = type === "compare";

  if (type === "capacity") {
    Object.keys(stats[0].line_chart)
      .sort(function (a, b) {
        return a - b;
      })
      .forEach((key) => {
        stats[0].line_chart[key].forEach((period_stats, i) => {
          labels.push(`${from.plus({ month: key }).setLocale(lang).toFormat("MMM, yyyy")}`);
        });
      });

    const statsValues = graphRange === "man_days" ? "days" : "percentage";

    datasets.push({
      label: t("dashboard.capacity_user_billable"),
      data: Object.values(stats[0].line_chart).map((data) => data[0].user_billable?.[statsValues]),
      backgroundColor: isDarkTheme ? "#49587A" : "#4C54A0",
      borderColor: isDarkTheme ? "#49587A" : "#4C54A0",
      borderRadius: 8,
      borderWidth: 2,
      stack: "user_capacity",
    });

    datasets.push({
      label: t("dashboard.capacity_user_non_billable"),
      data: Object.values(stats[0].line_chart).map((data) => data[0].user_non_billable?.[statsValues]),
      backgroundColor: "#FFFFFF",
      borderColor: isDarkTheme ? "#49587A" : "#4C54A0",
      borderWidth: 2,
      borderRadius: 8,
      stack: "user_capacity",
    });

    datasets.push({
      label: t("dashboard.capacity_user_time_off"),
      data: Object.values(stats[0].line_chart).map((data) => data[0].user_time_offs?.[statsValues]),
      backgroundColor: isDarkTheme ? "#484E5C" : "#c8d2e6",
      borderColor: isDarkTheme ? "#49587A" : "#4C54A0",
      borderWidth: 2,
      borderRadius: 8,
      stack: "user_capacity",
    });

    datasets.push({
      label: t("dashboard.capacity_project_billable"),
      data: Object.values(stats[0].line_chart).map((data) => data[0].project_billable?.[statsValues]),
      backgroundColor: isDarkTheme ? "#99A0E0" : "#9199e7",
      borderColor: isDarkTheme ? "#99A0E0" : "#9199e7",
      borderRadius: 8,
      borderWidth: 2,
      stack: "project_capacity",
    });

    datasets.push({
      label: t("dashboard.capacity_project_non_billable"),
      data: Object.values(stats[0].line_chart).map((data) => data[0].project_non_billable?.[statsValues]),
      backgroundColor: "rgba(255, 99, 132, 0)",
      borderColor: isDarkTheme ? "#99A0E0" : "#9199e7",
      borderRadius: 8,
      borderWidth: 2,
      stack: "project_capacity",
    });

    datasets.push({
      label: t("dashboard.capacity_project_time_off"),
      data: Object.values(stats[0].line_chart).map((data) => data[0].project_time_offs?.[statsValues]),
      backgroundColor: isDarkTheme ? "#484E5C" : "#c8d2e6",
      borderColor: isDarkTheme ? "#99A0E0" : "#9199e7",
      borderRadius: 8,
      borderWidth: 2,
      stack: "project_capacity",
    });

    if (graphRange === "man_days")
      datasets.push({
        label: t("dashboard.capacity_total_man_days"),
        type: "line",
        fill: false,
        backgroundColor: isDarkTheme ? "#49587A" : "#4C54A0",
        borderColor: isDarkTheme ? "#49587A" : "#4C54A0",
        pointBackgroundColor: "transparent",
        pointBorderColor: "transparent",
        pointHitRadius: 6,
        pointHoverRadius: 6,
        pointRadius: 6,
        pointHoverBackgroundColor: isDarkTheme ? "#49587A" : "#4C54A0",
        data: Object.values(stats[0].line_chart).map((data) => data[0].user_total?.days),
      });
  } else {
    Object.keys(stats[0].line_chart)
      .sort(function (a, b) {
        return a - b;
      })
      .forEach((key) => {
        stats[0].line_chart[key].forEach((period_stats, i) => {
          labels.push(`${period_stats.day} ${from.plus({ month: key }).setLocale(lang).toFormat("MMM")}`);
        });
      });

    stats.forEach((stat, i) => {
      let line_data = [];
      let billable_line_data = [];
      Object.keys(stat.line_chart)
        .sort(function (a, b) {
          return a - b;
        })
        .forEach((key) => {
          stat.line_chart[key].forEach((period_stats, j) => {
            line_data.push(period_stats.billable?.percentage + period_stats.non_billable?.percentage);

            billable_line_data.push(period_stats.billable?.percentage);
          });
        });

      billable.push({ line: i, data: billable_line_data });
      both.push({ line: i, data: line_data });
    });

    billable.forEach((data, i) => {
      const color = isComparing ? colors[i] : colorsPerType(type).baseColor;
      datasets.push({
        label: t("projects.billable") + " %",
        fill: false,
        borderDash: [12, 12],
        pointBackgroundColor: "transparent",
        pointBorderColor: "transparent",
        pointHitRadius: 6,
        pointHoverRadius: 6,
        pointRadius: 6,
        pointHoverBackgroundColor: color,
        backgroundColor: color,
        borderColor: color,
        data: data.data,
      });
    });

    both.forEach((data, i) => {
      const color = isComparing ? colors[i] : colorsPerType(type).baseColor;
      datasets.push({
        label: t("dashboard.all") + " %",
        fill: false,
        backgroundColor: color,
        borderColor: color,
        pointBackgroundColor: "transparent",
        pointBorderColor: "transparent",
        pointHitRadius: 6,
        pointHoverRadius: 6,
        pointRadius: 6,
        pointHoverBackgroundColor: color,
        data: data.data,
      });
    });
  }

  return { datasets, labels };
};

export default calculateDatasets;

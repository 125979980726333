import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import "../dashboard.scss";
import { PieChart } from "react-minimal-pie-chart";
import { useTranslation } from "react-i18next";
import { WhiteTooltip } from "../../default_components/Tooltips";
import { colorsPerType } from "../line_chart/colorsPerType";

const DashboardPercentage = ({ stats, version, type }) => {
  const { t } = useTranslation();

  const FormDataHash = (value, color, name) => {
    return [
      { title: "None", value: 100 - value, color: "var(--background-2)" },
      { title: name, value: value, color: color },
    ];
  };

  const pieData = stats.pie;
  const pieChartColor = colorsPerType(type).baseColor;
  const colors = ["var(--accent-dark)", "var(--accent-light)"];

  const percentage = [
    {
      title: t("dashboard.productivity_hint"),
      field: "productivity",
      value: "Productivity",
      percentage_name: t("dashboard.productivity"),
    },
    {
      title: t("dashboard.utilization_hint"),
      field: "utilization",
      value: "Utiliation",
      percentage_name: t("dashboard.utilization"),
    },
    {
      title: t("dashboard.availability_hint"),
      field: "availability",
      value: "Availability",
      percentage_name: t("dashboard.availability"),
    },
    {
      title: t("dashboard.time_off"),
      field: "time_off",
      value: "Time_off",
      percentage_name: t("dashboard.time_off"),
    },
  ];

  return (
    <div className="dashboard__percentage-container">
      {percentage.map((percentage_pie) => (
        <WhiteTooltip title={percentage_pie.title}>
          <div style={{ height: type === "compare" ? 155 : 142 }} className="percentage__wrapper">
            <p>{percentage_pie.percentage_name}</p>
            <div className="percentage__circles">
              {stats.map(({ pie }, i) => {
                return (
                  <PieChart
                    data={FormDataHash(pie[percentage_pie.field], type === "compare" ? colors[i] : pieChartColor, percentage_pie.value)}
                    lineWidth={30}
                    style={{ height: "70px", width: "70px" }}
                    totalValue={100}
                    label={({ dataEntry }) => `${pie[percentage_pie.field]}%`}
                    labelStyle={{
                      fontSize: "18px",
                      fontWeight: 600,
                      fontFamily: "Montserrat",
                      fill: type === "compare" ? colors[i] : pieChartColor,
                      userSelect: "none",
                    }}
                    labelPosition={0}
                    rounded
                  />
                );
              })}
            </div>
          </div>
        </WhiteTooltip>
      ))}
    </div>
  );
};

export default DashboardPercentage;

import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import React from "react";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { useTranslation } from "react-i18next";
import Cross from "../../default_images/cross.svg";
import TeambookBlueButton from "../../default_components/TeambookBlueButton";
import { icons } from "../../default_images/IconsList";
import { TeambookIcon } from "../../default_images/TeambookIcon";

const RepetitionReachedModal = ({ isOpen, close }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={isOpen}
      onClose={close}
      aria-labelledby="export-bookings-dialog"
      className="tb-default-dialog"
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle id="add-user-dialog">
        <p> {t("planning.repetition_reached_title")}</p>
        <TeambookIcon name={icons.CROSS} onClick={close} />
      </DialogTitle>
      <DialogContent style={{ margin: "20px 0px" }}>
        <p style={{ margin: 0 }}> {t("planning.repetition_reached_text")}</p>
      </DialogContent>
      <DialogActions>
        <TeambookBlueButton text={t("got_it")} onClick={close} />
      </DialogActions>
    </Dialog>
  );
};

export default RepetitionReachedModal;

import React, { useEffect, useState } from "react";
import NewUser from "./NewUser/NewUser";
import ShowUser from "./ShowUser/ShowUser";
import EditUser from "./EditUser/EditUser";
import { useAccountStore } from "../../../stores/accountStore";
import { shallow } from "zustand/shallow";
import { useProfileStore } from "../../../stores/profileStore";

const UserForm = ({
  userFormState,
  setUserFormState,
  user,
  onEdit,
  tags,
  teams,
  analyzeAllUsersResponse,
  getAllUsers,
  setDeactivatedSelectUsers,
  closeShow,
  deleteUserFunction,
  users,
  activateUserFunction,
  deactivatedSelectUsers,
  tab,
  setTab,
  copyCalendar,
}) => {
  const [userTeams, setUserTeams] = useState([]);
  const [currentUser] = useProfileStore((state) => [state.profile], shallow);

  useEffect(() => {
    if (userFormState !== "create") {
      setUserTeams(
        user.team_ids.map((user_team) => {
          return teams.find((team) => team.id === user_team);
        })
      );
    }
  }, [user]);

  if (userFormState === "create") {
    return (
      <NewUser
        tags={tags}
        teams={teams}
        setUserFormState={setUserFormState}
        analyzeAllUsersResponse={analyzeAllUsersResponse}
        setDeactivatedSelectUsers={setDeactivatedSelectUsers}
        userFormState={userFormState}
        tab={tab}
        setTab={setTab}
      />
    );
  } else if (userFormState === "show") {
    return (
      <ShowUser
        teams={userTeams}
        user={user}
        onEdit={onEdit}
        closeShow={closeShow}
        userFormState={userFormState}
        deleteUserFunction={deleteUserFunction}
        users={users}
        activateUserFunction={activateUserFunction}
        deactivatedSelectUsers={deactivatedSelectUsers}
        tab={tab}
        setTab={setTab}
        copyCalendar={copyCalendar}
      />
    );
  } else if (userFormState === "edit") {
    return (
      <EditUser
        user={user}
        users={users}
        tags={tags}
        setUserFormState={setUserFormState}
        getAllUsers={getAllUsers}
        teams={teams}
        userFormState={userFormState}
        tab={tab}
        setTab={setTab}
      />
    );
  }

  return <div />;
};

export default UserForm;

import React from "react";
import "../default_styles/tb-colors.css";

export default ({
  style,
  size,
  user,
  userImage,
  header,
  className,
  bgColor,
}) => {
  let fullName = user?.first_name;
  let initials = "";

  if (user?.first_name) {
    initials = user?.first_name[0];
  } else {
    initials = user?.name.split(" ")[0][0] + user?.name.split(" ")[1][0];
  }

  if (user?.last_name) {
    fullName += " " + user?.last_name;
    initials += user?.last_name[0];
  }

  const renderBg = () => {
    if (userImage) {
      return "url(" + userImage + ")";
    } else {
      if (bgColor) {
        return bgColor;
      } else {
        return "var(--user-profile-avatar)";
      }
    }
  };

  return (
    <div
      className={className}
      style={{
        minWidth: size,
        width: size,
        minHeight: size,
        height: size,
        fontSize: size * (size <= 50 ? 0.4 : 0.3),
        color: "var(--text-2)",
        background: renderBg(),
        borderRadius: "100%",
        fontWeight: "400",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...style,
      }}
    >
      {userImage ? null : <span>{initials || ""}</span>}
    </div>
  );
};

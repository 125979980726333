import React from "react";
import TeambookTextForm from "../../default_components/TeambookTextForm";
import TeambookBlueButton from "../../default_components/TeambookBlueButton";

const WhatsNewOptions = ({
  whatsNew,
  updatedValue,
  updateWhatsNew,
  deleteWhatsNew,
}) => {
  return (
    <>
      {whatsNew.map((whatsNewElement) => (
        <tr>
          <td style={{ width: "42.5%" }}>
            <TeambookTextForm
              onChange={(text) =>
                updatedValue(
                  whatsNewElement.id,
                  text,
                  whatsNewElement.description
                )
              }
              type={"text"}
              fieldValue={whatsNewElement.title}
              width={"100%"}
              style={{ margin: "0px" }}
            />
          </td>
          <td style={{ width: "42.5%" }}>
            <TeambookTextForm
              onChange={(text) =>
                updatedValue(whatsNewElement.id, whatsNewElement.title, text)
              }
              type={"text"}
              fieldValue={whatsNewElement.description}
              width={"100%"}
              style={{ margin: "0px" }}
            />
          </td>
          <td style={{ width: "15%" }}>
            <TeambookBlueButton
              text={"Delete"}
              onClick={() => deleteWhatsNew(whatsNewElement.id)}
              color="primary"
            />
            <TeambookBlueButton
              text={"Save"}
              onClick={() => updateWhatsNew(whatsNewElement.id)}
              color="primary"
            />
          </td>
        </tr>
      ))}
    </>
  );
};

export default WhatsNewOptions;

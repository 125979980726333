import React from "react";
import Button from "@mui/material/Button";

const TeambookWhiteButton = ({ text, onClick, style, reference, id, className }) => {
  return (
    <Button
      onClick={onClick}
      style={{
        ...style,
        color: style?.color || "var(--text-2)",
      }}
      ref={reference}
      id={id}
      className={`teambook-white-button ${className}`}
    >
      <p>{text}</p>
    </Button>
  );
};

export default TeambookWhiteButton;

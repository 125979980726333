export const statusOptions = (t) => {
  return statuses(t).map((val) => ({
    value: val,
    name: val,
  }));
};

export const statuses = (t) => {
  return [t("projects.to_be_confirmed"), t("projects.on_going"), t("projects.done")];
};

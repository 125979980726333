import React, { useEffect, useState } from "react";
import TeambookOutlinedButton from "../../../default_components/TeambookOutlinedButton";
import { useTranslation } from "react-i18next";
import { DialogActions, DialogTitle } from "@mui/material";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { icons } from "../../../default_images/IconsList";
import DialogContent from "@mui/material/DialogContent";
import TeambookBlueButton from "../../../default_components/TeambookBlueButton";
import TeambookTextForm from "../../../default_components/TeambookTextForm";
import Dialog from "@mui/material/Dialog";
import Api from "../../../../Api";
import { TeambookReactSelect } from "../../../default_components/TeambookReactSelect";
import BlueArrowDown from "../../../default_images/darkblue_arrow_down.svg";
import { shallow } from "zustand/shallow";
import { useAccountStore } from "../../../../stores/accountStore";

const PersonioBlock = ({}) => {
  const { t } = useTranslation();
  const [account, fetchAccount] = useAccountStore((state) => [state.account, state.fetchAccount], shallow);

  const [personioModalOpened, setPersonioModalOpened] = useState(false);
  const [personioEnteringKey, setPersonioEnteringKey] = useState("");
  const [timeOffTypes, setTimeOffTypes] = useState([]);
  const [teambookTimeOffIds, setTeambookTimeOffIds] = useState([]);
  const [teambookTimeOffs, setTeambookTimeOffs] = useState([]);
  const [initializing, setInitializing] = useState(false);

  const [clientId, setClientId] = useState("");
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    Api.Integrations.Personio.get_time_off_types().then((res) => {
      setTimeOffTypes(res.data);
      setTeambookTimeOffIds(res.data.map((type) => type.teambook_project_id));
    });

    Api.Projects.time_off().then((res) => setTeambookTimeOffs(res.data.filter((timeOff) => timeOff.active === true)));
  }, [account]);

  const updateIntegration = () => {
    Api.Integrations.Personio.update_time_off_types(
      timeOffTypes.map((type) => type.id),
      teambookTimeOffIds
    ).then(() => setPersonioModalOpened(false));
  };

  const initialiseIntegration = () => {
    setInitializing(true);

    Api.Integrations.Personio.init(clientId, clientSecret).then(() => {
      fetchAccount();
      setInitializing(false);
    });
  };

  const deleteIntegration = () => {
    Api.Integrations.Personio.delete().then(() => {
      setPersonioModalOpened(false);
      fetchAccount();
    });
  };

  return (
    <div className="integration-block">
      <div className="integration-name">
        <TeambookIcon name={icons.PERSONIO_INTEGRATION_ICON} alt="personio logo" />
      </div>

      <div className="integration-status">
        <p className={account.personio_integration ? "active" : "not_active"}>
          {account.personio_integration ? "Active" : "Not connected"}
        </p>
      </div>

      <TeambookOutlinedButton
        onClick={() => setPersonioModalOpened(true)}
        variant="contained"
        text={account.personio_integration ? t("settings.integration.manage") : t("settings.integration.connect")}
        style={{
          margin: "13px 0 0 0",
          width: "171px",
        }}
      />

      <Dialog
        fullWidth={true}
        open={personioModalOpened}
        onClose={() => setPersonioModalOpened(false)}
        aria-labelledby="max-width-dialog-title"
        className="tb-default-dialog personio-dialog"
      >
        <DialogTitle id="add-user-dialog">
          <p>Personio</p>
          <TeambookIcon name={icons.CROSS} onClick={() => setPersonioModalOpened(false)} />
        </DialogTitle>

        <DialogContent style={{ margin: "20px 0px" }}>
          {account.personio_integration ? (
            <div style={{ maxHeight: "500px", overflowY: "auto" }}>
              <p>Configure integration</p>

              {timeOffTypes.map((type, index) => (
                <div className="personio-time-off">
                  <p>{type.name}</p>

                  <TeambookReactSelect
                    height={40}
                    width={150}
                    onChange={(option) => {
                      let array = [...teambookTimeOffIds];
                      array[index] = option.value;
                      setTeambookTimeOffIds(array);
                    }}
                    value={teambookTimeOffs
                      .map((timeOff) => ({
                        label: timeOff.name,
                        value: timeOff.id,
                      }))
                      .find((element) => element.value === teambookTimeOffIds[index])}
                    options={teambookTimeOffs.map((timeOff) => ({
                      label: timeOff.name,
                      value: timeOff.id,
                    }))}
                    dropdownArrow={BlueArrowDown}
                  />
                </div>
              ))}
            </div>
          ) : (
            <div style={{ marginBottom: 60 }}>
              <p>
                Please insert your API credentials. (
                <a href={"https://support.personio.de/hc/en-us/articles/4404623630993-Generate-and-manage-API-credentials"} target="_blank">
                  What's that?
                </a>
                )
              </p>

              <TeambookTextForm
                fieldName={"Client Id"}
                onChange={setClientId}
                fieldValue={clientId}
                type={"text"}
                width={"400px"}
                placeholder={"papi-...."}
              />

              <TeambookTextForm
                fieldName={"Client Secret"}
                onChange={setClientSecret}
                fieldValue={clientSecret}
                type={"text"}
                width={"400px"}
                placeholder={"papi-...."}
              />
            </div>
          )}
        </DialogContent>

        <DialogActions>
          {account.personio_integration ? (
            <>
              <TeambookBlueButton onClick={updateIntegration} variant="contained" text={t("save")} />

              <TeambookIcon
                id="deleteUserFormButton"
                name={icons.TRASH}
                onClick={deleteIntegration}
                color="alternative_default"
                tooltipTitle={t("delete")}
              />
            </>
          ) : (
            <TeambookBlueButton
              onClick={initialiseIntegration}
              variant="contained"
              text={t("submit")}
              style={{
                cursor: initializing ? "wait" : "pointer",
                position: "absolute",
                right: 40,
                bottom: 30,
              }}
            />
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PersonioBlock;

import englishFlag from "../default_images/flags/english_flag.svg";
import frenchFlag from "../default_images/flags/french_flag.svg";
import deutschFlag from "../default_images/flags/deutsch_flag.svg";
import spanishFlag from "../default_images/flags/spanish_flag.svg";
import italianFlag from "../default_images/flags/italian_flag.svg";
import { useTranslation } from "react-i18next";
import { WhiteTooltip } from "./Tooltips";

export const LanguagePicker = ({
  type = "image",
  currentLang = "en",
  showCurrent = false,
  changeLanguage,
  style,
  flagStyle,
  className,
}) => {
  const { t } = useTranslation();

  const languages = [
    {
      lang: "en",
      text: t("english"),
      image: englishFlag,
    },
    {
      lang: "de",
      text: t("german"),
      image: deutschFlag,
    },
    {
      lang: "fr",
      text: t("french"),
      image: frenchFlag,
    },
    {
      lang: "es",
      text: t("spanish"),
      image: spanishFlag,
    },
    {
      lang: "it",
      text: t("italian"),
      image: italianFlag,
    },
  ];

  if (showCurrent) {
    return type === "image" ? (
      <img
        style={{ width: 40, height: 40, borderRadius: 35 }}
        src={languages.find((language) => language.lang === currentLang).image}
      />
    ) : (
      <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
        <img
          style={{ width: 24, height: 24, borderRadius: 35 }}
          src={
            languages.find((language) => language.lang === currentLang).image
          }
        />
        <p style={{ margin: 0, color: "var(--text-2)" }}>
          {languages.find((language) => language.lang === currentLang).text}
        </p>
      </div>
    );
  }

  return (
    <div style={{ ...style }} className={`${className} language-picker`}>
      {languages.map((language) => {
        return (
          <WhiteTooltip
            title={language.text}
            key={`picker-language-${language.lang}`}
          >
            <img
              style={{
                width: 32,
                height: 32,
                borderRadius: 35,
                cursor: "pointer",
                border:
                  language.lang === currentLang
                    ? "2px solid var(--accent-dark)"
                    : "unset",
                ...flagStyle,
              }}
              key={"flag-" + language.lang}
              src={language.image}
              onClick={() => changeLanguage(language.lang)}
            />
          </WhiteTooltip>
        );
      })}
    </div>
  );
};

import { useEffect, useState } from "react";

export const useDataChange = (data = {}, old_data = {}) => {
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    let isFieldsChanged = false;
    for (const key in data) {
      if (Array.isArray(data[key])) {
        if (
          JSON.stringify([...data[key]].sort((a, b) => (a <= b ? 1 : -1))) !==
          JSON.stringify([...old_data[key]].sort((a, b) => (a <= b ? 1 : -1)))
        ) {
          isFieldsChanged = true;
        }
      } else if (typeof data[key] === "object") {
        if (JSON.stringify(data[key]) !== JSON.stringify(old_data[key])) {
          isFieldsChanged = true;
        }
      } else if (data[key] !== old_data[key]) {
        isFieldsChanged = true;
      }
    }

    setIsChanged(isFieldsChanged);
  }, [data]);

  return isChanged;
};

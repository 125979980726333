import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { icons } from "../../../default_images/IconsList";
import TeambookTextForm from "../../../default_components/TeambookTextForm";
import PasswordRules from "../../../register/PasswordRules";
import FormErrorMessage from "../../../default_components/FormErrorMessage";
import TeambookBlueButton from "../../../default_components/TeambookBlueButton";
import { useTranslation } from "react-i18next";

export const PasswordChangingModal = ({
  passwordChangingMenuOpen,
  closePasswordChangingModal,
  setNewPassword,
  newPassword,
  newPasswordShown,
  setNewPasswordShown,
  setPasswordRulesVisible,
  setNewPasswordConf,
  newPasswordConf,
  confNewPasswordShown,
  setConfNewPasswordShown,
  passwordRulesVisible,
  errorMessage,
  errorField,
  closeErrorMessage,
  updateUserPassword,
}) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={passwordChangingMenuOpen}
      onClose={closePasswordChangingModal}
      aria-labelledby="email-token-dialog"
      className="tb-default-dialog credentials__password-changing__modal"
    >
      <DialogTitle>
        <p> {t("profile.password.password_changing")}</p>
        <TeambookIcon name={icons.NEW_CROSS} onClick={closePasswordChangingModal} />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <p>{t("profile.password.text_1")}</p>
        </DialogContentText>
        <div className="password-changing__inputs">
          <TeambookTextForm
            placeholder={t("profile.password.new_password_placeholder")}
            className={"password-changing-first__input"}
            fieldName={t("profile.password.new_password")}
            onChange={setNewPassword}
            fieldValue={newPassword}
            type={"password"}
            width={"270px"}
            isPassword={true}
            showPass={newPasswordShown}
            setShowPass={() => setNewPasswordShown(!newPasswordShown)}
            onFocus={() => setPasswordRulesVisible(true)}
            onBlur={() => setPasswordRulesVisible(true)}
          />
          <TeambookTextForm
            placeholder={t("profile.password.new_password_placeholder")}
            className={"password-changing-second__input"}
            whiteSpace={"noWrap"}
            fieldName={t("profile.password.confirm_new_password")}
            onChange={setNewPasswordConf}
            fieldValue={newPasswordConf}
            isPassword={true}
            showPass={confNewPasswordShown}
            setShowPass={() => setConfNewPasswordShown(!confNewPasswordShown)}
            type={"password"}
            width={"270px"}
          />
        </div>
        <div style={{ display: "flex" }}>
          <PasswordRules password={newPassword} visible={passwordRulesVisible} />
        </div>

        {errorMessage !== null && errorField === "new_password" ? (
          <FormErrorMessage style={{ marginTop: "10px", width: "100%" }} text={errorMessage} closeErrorMessage={closeErrorMessage} />
        ) : null}

        {errorMessage !== null && errorField === "confirmation_password" ? (
          <FormErrorMessage style={{ marginTop: "10px", width: "100%" }} text={errorMessage} closeErrorMessage={closeErrorMessage} />
        ) : null}
      </DialogContent>
      <DialogActions>
        <TeambookBlueButton onClick={updateUserPassword} variant="contained" text={t("submit")} />
      </DialogActions>
    </Dialog>
  );
};

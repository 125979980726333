import React from "react";
import Button from "@mui/material/Button";

const TeambookOutlinedButton = React.forwardRef(
  ({ text, htmlText, border, style, onClick, color, className, disabled }, ref) => {
    return (
      <Button
        border={border || "1px solid var(--icon-stroke)"}
        style={{
          ...style,
          fontFamily: "Montserrat",
          border: border || "1px solid var(--icon-stroke)",
        }}
        variant="contained"
        onClick={onClick}
        innerRef={ref}
        color={color}
        className={`teambook-outlined-button ${className}`}
        disabled={disabled}
      >
        {htmlText && <>{htmlText}</>}
        {text && <p>{text}</p>}
      </Button>
    );
  }
);

export default TeambookOutlinedButton;

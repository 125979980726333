import React from "react";

import TimeOffComponent from "./TimeOff";
import HolidaysComponent from "./Holidays";

const TimeOffHolidaysSettings = ({ users }) => {
  const UserList = () => users.map((user) => (user = { ...user, value: user.id }));

  return (
    <div className="settings__time-off">
      <TimeOffComponent />

      <HolidaysComponent users={UserList()} />
    </div>
  );
};

export default TimeOffHolidaysSettings;

import React, { useEffect, useState } from "react";
import Api from "../../../../../Api";
import { useTranslation } from "react-i18next";
import GetTeamsRequest from "../../../../planner/requests/GetTeamsRequest";
import DeleteHoliday from "./DeleteHoliday";
import CreateHoliday from "./CreateHoliday";
import EditHoliday from "./EditHoliday";

const ActionsHoliday = ({ selectedModal, onCloseSelectedModal, users, getHolidays, holidays }) => {
  const { t } = useTranslation();

  const [teams, setTeams] = useState();

  const [holidayUsers, setHolidayUsers] = useState([]);
  const [holidayCountries, setHolidayCountries] = useState([]);
  const [holidayRegions, setHolidayRegions] = useState([]);

  const [extraOptions, setExtraOptions] = useState([
    {
      value: "All Users",
      name: t("users.all_users"),
    },
  ]);

  useEffect(() => {
    GetTeamsRequest(setTeams);
    getHolidaysCountries();
  }, []);

  useEffect(() => {
    if (teams && extraOptions.length === 1) {
      const teamsArray = Array.from(teams, ([, value]) => value).map((team) => {
        return {
          ...team,
          name: t("users.select_team") + team.name,
        };
      });
      setExtraOptions([...extraOptions, ...teamsArray]);
    }
  }, [teams]);

  const getHolidaysCountries = () => {
    Api.Integrations.Holidays.countries().then((response) => {
      setHolidayRegions(
        response.data.countries.map((el) => ({
          code: el.code,
          regions: el.regions,
        }))
      );
      setHolidayCountries(
        response.data.countries.map((el) => ({
          id: el.code,
          name: el.name,
        }))
      );
    });
  };

  const onCloseModal = () => {
    onCloseSelectedModal();
    setHolidayUsers([]);
  };

  const getUsersWithoutHoldiays = (usersArray) => {
    return [...usersArray].filter(
      (user) => !holidays.some((holiday) => holiday.users.find((holidayUser) => user.id === holidayUser.id))
    );
  };

  const onChangeUsersPicker = (newUsers, selectedUser) => {
    const isTeam = Boolean(selectedUser.option?.hasOwnProperty("team_users"));
    const isAllUsers = Boolean(selectedUser.option?.value === "All Users");

    if (isTeam) {
      const selectedTeam = newUsers[newUsers.length - 1];
      setHolidayUsers(getUsersWithoutHoldiays(selectedTeam.team_users));
    } else {
      setHolidayUsers(isAllUsers ? getUsersWithoutHoldiays(users) : newUsers);
    }
  };

  switch (selectedModal.name) {
    case "CREATION": {
      return (
        <CreateHoliday
          onClose={onCloseModal}
          holidayCountries={holidayCountries}
          holidayRegions={holidayRegions}
          extraOptions={getUsersWithoutHoldiays(users).length > 0 ? extraOptions : []}
          holidayUsers={holidayUsers}
          onChangeUsersPicker={onChangeUsersPicker}
          users={getUsersWithoutHoldiays(users)}
          getHolidays={getHolidays}
        />
      );
    }

    case "EDITING": {
      return (
        <EditHoliday
          onClose={onCloseModal}
          editingHoliday={selectedModal.props.holiday}
          holidayUsers={holidayUsers}
          setHolidayUsers={setHolidayUsers}
          extraOptions={getUsersWithoutHoldiays(users).length > 0 ? extraOptions : []}
          onChangeUsersPicker={onChangeUsersPicker}
          users={getUsersWithoutHoldiays(users)}
          getHolidays={getHolidays}
        />
      );
    }

    case "DELETING": {
      return (
        <DeleteHoliday
          onCloseSelectedModal={onCloseSelectedModal}
          selectedHoliday={selectedModal.props.holidayId}
          getHolidays={getHolidays}
        />
      );
    }

    default:
      return <></>;
  }
};

export default ActionsHoliday;

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Api from "../../../../Api";
import SubscriptionModal from "./SubscriptionModal";
import Dialog from "@mui/material/Dialog";
import { CentsToDollarString } from "../../../../lib/DataFormat";
import ChangePaymentModal from "./ChangePaymentModal";
import InvoicesModal from "../InvoicesModal";
import SubscribeToPlanModal from "./SubscribeToPlanModal";
import CouponApplicationModal from "./CouponApplicationModal";
import ChangeBillingInformationModal from "./ChangeBillingInformationModal";
import SubscriptionKindDialog from "./SubscriptionKindDialog";
import TeambookOutlinedButton from "../../../default_components/TeambookOutlinedButton";
import { icons } from "../../../default_images/IconsList";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { SelectModuleModal } from "./SelectModuleModal";
import { useAccountStore } from "../../../../stores/accountStore";
import { shallow } from "zustand/shallow";
import { useNotificationStore } from "../../../../stores/notificationStore";

const ANNUAL_PLANS = ["V2_S_Plan", "V2_M_Plan", "V2_L_Plan", "V2_Starter_Plan"];
const YEARLY_PLAN_MODIFIER = "Yearly";

const SubscriptionComponent = () => {
  const { t } = useTranslation();
  const [account, fetchAccount] = useAccountStore((state) => [state.account, state.fetchAccount], shallow);
  const [setInAppNotification] = useNotificationStore((state) => [state.setInAppNotification], shallow);
  const [modalOpened, setModalOpened] = useState(false);
  const [changePaymentDialogOpened, setChangePaymentDialogOpened] = useState(false);
  const [changeBillingInformationOpened, setChangeBillingInformationOpened] = useState(false);
  const [invoicesDialogOpened, setInvoicesDialogOpened] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState();
  const [manualPlan, setManualPlan] = useState();
  const [plans, setPlans] = useState([]);
  const [currentPlan, setCurrentPlan] = useState(account?.braintree_plan);
  const [loading, setLoading] = useState(false);
  const [creditCard, setCreditCard] = useState();
  const [modalSubscribeOpen, setModalSubscribeOpen] = useState(false);
  const [applyCouponOpened, setApplyCouponOpened] = useState(false);
  const [subscriptionKindOpened, setSubscriptionKindOpened] = useState(false);
  const [savedNonce, setSavedNonce] = useState();
  const [selectModuleModalOpened, setSelectModuleModalOpened] = useState(false);
  const [isActualsSelected, setIsActualsSelected] = useState(false);

  useEffect(() => {
    if (account.braintree_customer_id) {
      Api.Billing.credit_card().then((response) => {
        setCreditCard(response.data.credit_card);
      });
    }
  }, []);

  useEffect(() => {
    Api.Billing.plans()
      .then((response) => {
        setPlans(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    setCurrentPlan(account?.braintree_plan);
  }, [account]);

  async function subscribeRequest(nonce) {
    setLoading(true);

    Api.Billing.create_subscription(nonce, selectedPlan.id, isActualsSelected)
      .then((response) => {
        fetchAccount();
        setInAppNotification(t("notifications.settings.subscription.change_plan"));
        handleClose();
      })
      .catch((error) => {
        handleClose();
        alert(error.response.data.error);
      });
  }

  async function changePaymentRequest(nonce) {
    setLoading(true);

    Api.Billing.change_payment_method(nonce).then(() => {
      Api.Billing.credit_card().then((response) => {
        setCreditCard(response.data.credit_card);
        setChangePaymentDialogOpened(false);
        setLoading(false);
        setInAppNotification(t("notifications.settings.subscription.payment_method"));
      });
    });
  }

  const planFrequencyChanged = () => {
    return selectedPlan.yearly !== account.braintree_plan.yearly;
  };

  const selectPlanClick = (plan) => {
    setSelectedPlan(plan);
    setManualPlan(plan);

    if (ANNUAL_PLANS.includes(plan.plan_id)) {
      openSelectModuleModalopened();
    } else {
      setModalSubscribeOpen(true);
    }
  };

  const openSelectModuleModalopened = () => {
    setSelectModuleModalOpened(true);
  };

  const finishSubscriptionKindSelect = (type, isActualsSelected) => {
    if (type === "yearly") {
      setSelectedPlan(plans.filter((plan) => plan.plan_id === `${selectedPlan.plan_id}_Yearly`)[0]);
    }

    setIsActualsSelected(isActualsSelected);

    setModalSubscribeOpen(true);
  };

  const subscribeToPlan = () => {
    setModalSubscribeOpen(false);
    setSubscriptionKindOpened(false);
    setSelectModuleModalOpened(false);

    if (selectedPlan.plan_id === "Free") {
      Api.Billing.create_subscription("nonce", selectedPlan.id, false)
        .then((response) => {
          fetchAccount();
          handleClose();
        })
        .catch((error) => {
          handleClose();
          alert(error.response.data.error);
        });
    } else {
      if (creditCard) {
        if (currentPlan?.plan_name === "Free" || planFrequencyChanged()) {
          setModalOpened(true);
        } else {
          Api.Billing.create_subscription(savedNonce, selectedPlan.id, isActualsSelected)
            .then((response) => {
              fetchAccount();
              setInAppNotification(t("notifications.settings.subscription.billing_info"));
              handleClose();
            })
            .catch((error) => {
              handleClose();
              alert(error.response.data.error);
            });
        }
      } else {
        setModalOpened(true);
      }
    }
  };

  const createManualSubscription = () => {
    setModalOpened(false);
    setLoading(true);

    Api.Billing.create_manual_subscription(manualPlan.id, isActualsSelected)
      .then((response) => {
        fetchAccount();
        setInAppNotification(t("notifications.settings.subscription.change_plan"));
        handleClose();
      })
      .catch((error) => {
        handleClose();
        alert(error.response.data.error);
      });
  };

  const handleClose = () => {
    setSelectedPlan();
    setModalOpened(false);
    setLoading(false);
  };

  const getCouponProjects = (coupon) => {
    if (coupon.system === "PG-S") {
      return 40;
    } else if (coupon.system === "PG-M") {
      return 100;
    } else if (coupon.system === "PG-L") {
      return 200;
    }
  };

  const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const addons = () => {
    if (account?.subscription?.addons?.length > 0) {
      return account?.subscription?.addons.map((addon) => ` + ${capitalize(addon)}`);
    } else {
      return "";
    }
  };

  const formattedPlanName = (planName) => {
    if (planName) {
      if (!planName.includes("Trial")) {
        return planName;
      }
    }

    return `Trial - ${account.trial_days_left} ${t("days_left")}`;
  };

  return (
    <div className="row subscription-container">
      <div className="col-md-12 selected-subscription" style={{ marginBottom: "20px" }}>
        <div>
          <p style={{ marginBottom: "10px" }} aria-label="current plan">
            {t("settings.subscription.current_plan")} {formattedPlanName(currentPlan?.plan_name)}
            {addons()}
          </p>

          <p aria-label="current price">
            {t("settings.subscription.current_price")} {CentsToDollarString(account?.subscription?.price_cents)}
          </p>
        </div>

        <div>
          <p style={{ marginBottom: "10px" }} aria-label="available projects">
            {t("settings.subscription.available_projects")} {account?.subscription?.projects_available}
          </p>

          <p aria-label="number of projects">
            {t("settings.subscription.you_have_projects")} {account.active_projects} {t("planning.filter_projects")}
          </p>
        </div>
      </div>

      {account.coupon && (
        <div style={{ padding: "10px" }} className="col-md-12">
          <div className="row subscription__card-plan">
            <p className="col-md-9 subscription__name-plan">Life Time Deal Coupon</p>

            <p className="col-md-3 subscription__projects-plan">{getCouponProjects(account.coupon)} projects</p>
          </div>
        </div>
      )}

      {plans
        .filter((plan) => plan.plan_id !== "Free" || account.subscription.subscription_id !== "Trial")
        .filter((plan) => plan.yearly === false)
        .map((plan) => (
          <div
            style={{ marginBottom: 15, padding: 0 }}
            className="col-md-12"
            key={plan.id}
            aria-label={`${plan.plan_name} plan`}
          >
            <div className="row subscription__card-plan">
              <p className="col-md-4 subscription__name-plan" aria-label="plan name">
                {plan.plan_name}

                {plan.plan_id === "V2_M_Plan" && <div className="popular-plan">Popular</div>}
              </p>

              <p className="col-md-4 subscription__projects-plan" aria-label="number of projects">
                {plan.plan_projects} projects
              </p>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
                className="col-md-4"
                aria-label="subscription plan row"
              >
                <TeambookOutlinedButton
                  id={"selectSubscription"}
                  onClick={() => selectPlanClick(plan)}
                  variant="contained"
                  style={{
                    height: 34,
                    minWidth: 75,
                    color: "#4C54A0 !important",
                  }}
                  text={t("select")}
                />
              </div>
            </div>
          </div>
        ))}

      {account.braintree_customer_id ? (
        <>
          <div
            className="col-md-12 subscription__change-payment__container"
            style={{
              padding: "0px",
              display: "flex",
              justifyContent: "space-between",
              marginTop: "16px",
            }}
          >
            <div style={{ display: "flex" }} className="col-md-8">
              <div
                style={{
                  display: "flex",
                  marginBottom: "10px",
                  width: "100%",
                }}
                onClick={() => setChangePaymentDialogOpened(true)}
              >
                <a
                  className="subscription__link"
                  style={{
                    margin: "0 0 0 0",
                  }}
                >
                  {t("settings.subscription.customer_text")}
                </a>

                <TeambookIcon
                  alt={"edit"}
                  style={{
                    width: "20px",
                    height: "20px",
                    marginLeft: "12px",
                    cursor: "pointer",
                  }}
                  name={icons.PENCIL}
                  tooltipTitle={t("edit")}
                  placement="right"
                />
              </div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                }}
                onClick={() => setChangeBillingInformationOpened(true)}
              >
                <a
                  className="subscription__link"
                  style={{
                    margin: "0 0 0 0",
                  }}
                >
                  {t("settings.subscription.billing_information")}
                </a>

                <TeambookIcon
                  alt={"edit"}
                  style={{
                    width: "20px",
                    height: "20px",
                    marginLeft: "12px",
                    cursor: "pointer",
                  }}
                  name={icons.PENCIL}
                  tooltipTitle={t("edit")}
                  placement="right"
                />
              </div>
            </div>

            {/*<div>{creditCard && <p>Credit card: **** **** **** {creditCard.last_4}</p>}</div>*/}

            <div className="col-md-4" style={{ textAlign: "end" }}>
              <a
                className="subscription__link"
                onClick={() => setInvoicesDialogOpened(true)}
                style={{
                  margin: "0 0 0 0",
                }}
              >
                {t("settings.subscription.invoices")}
              </a>
            </div>
          </div>
        </>
      ) : (
        !account.coupon && (
          <div
            aira-label="apply coupon"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <a
              className="subscription__link"
              onClick={() => {
                setApplyCouponOpened(true);
              }}
              style={{
                margin: "0 0 0 0",
              }}
            >
              {t("settings.subscription.coupon_apply")}
            </a>
          </div>
        )
      )}

      {(account.subscription.subscription_id === "Trial" ||
        (account.subscription.subscription_id === "Free" && account.subscription.projects_available === 10)) && (
        <div style={{ marginTop: "5px", fontStyle: "italic" }}>
          <p>{t("settings.subscription.annual_opportunity")}</p>
        </div>
      )}

      {applyCouponOpened && <CouponApplicationModal closeModal={() => setApplyCouponOpened(false)} />}

      {changePaymentDialogOpened && (
        <ChangePaymentModal
          changePaymentRequest={changePaymentRequest}
          loading={loading}
          closeModal={() => setChangePaymentDialogOpened(false)}
          creditCard={creditCard}
          setChangePaymentDialogOpened={setChangePaymentDialogOpened}
        />
      )}

      <Dialog
        fullWidth={true}
        open={changeBillingInformationOpened}
        onClose={() => setChangeBillingInformationOpened(false)}
        aria-labelledby="max-width-dialog-title"
        className="tb-default-dialog"
      >
        {changeBillingInformationOpened && (
          <ChangeBillingInformationModal
            closeModal={() => setChangeBillingInformationOpened(false)}
            setChangeBillingInformationOpened={setChangeBillingInformationOpened}
          />
        )}
      </Dialog>

      <Dialog
        open={modalOpened}
        onClose={() => setModalOpened(false)}
        aria-labelledby="max-width-dialog-title"
        className="tb-default-dialog"
        aria-label="add new card modal"
      >
        <SubscriptionModal
          subscribeRequest={subscribeRequest}
          loading={loading}
          closeModal={() => setModalOpened(false)}
          selectedPlan={selectedPlan}
          createManualSubscription={createManualSubscription}
        />
      </Dialog>

      <Dialog
        fullWidth={true}
        open={invoicesDialogOpened}
        onClose={() => setInvoicesDialogOpened(false)}
        aria-labelledby="max-width-dialog-title"
        className="tb-default-dialog"
      >
        <InvoicesModal closeModal={() => setInvoicesDialogOpened(false)} />
      </Dialog>

      <Dialog
        fullWidth={true}
        open={modalSubscribeOpen}
        onClose={() => setModalSubscribeOpen(false)}
        aria-labelledby="max-width-dialog-title"
        className="tb-default-dialog"
      >
        <SubscribeToPlanModal
          subscribeToPlan={subscribeToPlan}
          closeModal={() => {
            setModalSubscribeOpen(false);
            setSubscriptionKindOpened(false);
          }}
          selectedPlan={selectedPlan}
          isActualsSelected={isActualsSelected}
        />
      </Dialog>

      {selectModuleModalOpened && (
        <SelectModuleModal
          finishSubscriptionKindSelect={finishSubscriptionKindSelect}
          close={() => setSelectModuleModalOpened(false)}
          plan={selectedPlan}
        />
      )}

      {subscriptionKindOpened && (
        <SubscriptionKindDialog
          finishSubscriptionKindSelect={finishSubscriptionKindSelect}
          closeWindow={() => setSubscriptionKindOpened(false)}
          plan={selectedPlan}
        />
      )}
    </div>
  );
};

export default SubscriptionComponent;

import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import TeambookBlueButton from "./TeambookBlueButton";
import TeambookWhiteButton from "./TeambookWhiteButton";
import { useTranslation } from "react-i18next";

const TeambookDialogYesNo = ({ open, setOpen, question, questionTitle, yesCallback, noCallback }) => {
  const { t } = useTranslation();

  const noButtonClicked = () => {
    noCallback();
    setOpen(false);
  };

  const yesButtonClicked = () => {
    yesCallback();
    setOpen(false);
  };

  return (
    <Dialog open={open} className="tb-default-dialog" onClose={() => setOpen(false)}>
      <DialogTitle>{questionTitle}</DialogTitle>

      <DialogContent>
        <p>{question}</p>
      </DialogContent>

      <DialogActions>
        <TeambookWhiteButton onClick={noButtonClicked} text={t("no")} />
        <TeambookBlueButton onClick={yesButtonClicked} text={t("yes")} />
      </DialogActions>
    </Dialog>
  );
};

export default TeambookDialogYesNo;

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import React, { useState } from "react";
import Api from "../../../Api";
import Cross from "../../default_images/cross.svg";
import TeambookTextForm from "../../default_components/TeambookTextForm";
import TeambookBlueButton from "../../default_components/TeambookBlueButton";

const CreateWhatsNew = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  let adminToken = localStorage.getItem("tb-admin-token");

  const handleClose = () => {
    window.location.reload();
  };

  const handleSubmit = () => {
    Api.Administrators.create_whats_new(adminToken, title, description).then(
      () => {
        handleClose();
      }
    );
  };

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth={"xs"}
      className="tb-default-dialog"
    >
      <DialogTitle id="form-dialog-title">
        <p>Create Whats New</p>
        <img src={Cross} onClick={handleClose} />
      </DialogTitle>
      <DialogContent style={{ margin: "20px 0px" }}>
        <TeambookTextForm
          className="text-field"
          fieldName={"Title"}
          type={"text"}
          onChange={setTitle}
          value={title}
        />

        <TeambookTextForm
          className="text-field"
          fieldName={"Description"}
          type={"text"}
          onChange={setDescription}
          value={description}
        />
      </DialogContent>
      <DialogActions>
        <TeambookBlueButton
          text={"Create"}
          id="createHolidaysButton"
          onClick={handleSubmit}
        />
      </DialogActions>
    </Dialog>
  );
};

export default CreateWhatsNew;

import React, { useContext } from "react";

import { useTranslation } from "react-i18next";
import { getPlannerOffsetX, getPlannerOffsetY } from "../../../../lib/PlannerUtils";
import { useShiftPressedStore, useZoomStore } from "../../../../stores/planner";
import { useProfileStore } from "../../../../stores/profileStore";
import { shallow } from "zustand/shallow";
import { useNotificationStore } from "../../../../stores/notificationStore";
import { useAccountStore } from "../../../../stores/accountStore";

const UserRowWeek = ({
  mouseDowned,
  user,
  startDate,
  mouseMoved,
  mouseUped,
  weekendsHidden,
  changeTooltip,
  filteredUsers,
  isLastWeek,
  isLastUser,
}) => {
  const userRole = localStorage.getItem("tb-role") || "regular";
  const { t } = useTranslation();
  const [zoom] = useZoomStore((state) => [state.zoom], shallow);
  const [setInAppNotification] = useNotificationStore((state) => [state.setInAppNotification], shallow);

  const [account] = useAccountStore((state) => [state.account], shallow);
  const [profile] = useProfileStore((state) => [state.profile], shallow);

  const calculateWidth = () => {
    let width;

    if (zoom === 28) {
      width = 360;
    } else if (zoom === 14) {
      width = 700;
    } else {
      width = 100;
    }

    return {
      width: `${width}px`,
      borderRight: weekendsHidden && "1px solid transparent",
      background: `repeating-linear-gradient(90deg,var(--background-1),var(--background-1) ${
        width / 5 - 1
      }px,var(--stroke) 1px,var(--stroke) ${width / 5}px)`,
    };
  };

  const calculateWeekendStyle = (index) => {
    if (weekendsHidden) {
      return {
        width: 20,
        borderLeft: "1px solid var(--text-3)",
      };
    } else {
      if (zoom === 28) {
        return {
          width: "144px",
        };
      } else if (zoom === 14) {
        return {
          width: "280px",
        };
      } else {
        return {
          width: "40px",
        };
      }
    }
  };

  return (
    <>
      <div
        style={{
          ...calculateWidth(),
        }}
        className={"user-row-week"}
        onMouseDown={(e) => {
          if (e.button === 1) return;

          if (["contractor", "regular"].includes(userRole)) {
            setInAppNotification(t("notifications.planning.create_bookings_permissions"));
            return;
          }

          if (account.subscription.subscription_id === "Free" && account.subscription.projects_available === 10) {
            return;
          }

          if (userRole === "self_planner" && user.id !== profile.id) {
            setInAppNotification(t("notifications.planning.self_planner_creating_error"));
            return;
          }

          mouseDowned(getPlannerOffsetX(e), getPlannerOffsetY(e, zoom), filteredUsers);
        }}
        onMouseMove={(e) => {
          if (["contractor", "regular"].includes(userRole) || (userRole === "self_planner" && user.id !== profile.id))
            return;

          if (account.subscription.subscription_id === "Free" && account.subscription.projects_available === 10) {
            return;
          }

          mouseMoved(getPlannerOffsetX(e), getPlannerOffsetY(e, zoom), filteredUsers, e);
        }}
        onMouseUp={(e) => {
          mouseUped(getPlannerOffsetX(e), getPlannerOffsetY(e, zoom), filteredUsers, e);
        }}
        onDragOver={(e) => {
          changeTooltip({ open: false });
          e.preventDefault();
          e.stopPropagation();
        }}
      />

      <div className="user-row-weekends">
        <div
          key={`user-row-weekend-${user.id}`}
          className="user-row-weekends__day"
          onMouseDown={(e) => {
            if (
              (["contractor", "regular"].includes(userRole) ||
                (userRole === "self_planner" && user.id !== profile.id) ||
                weekendsHidden) &&
              !useShiftPressedStore.getState().shiftPressed
            ) {
              return;
            }

            mouseDowned(getPlannerOffsetX(e), getPlannerOffsetY(e, zoom), filteredUsers);
          }}
          onMouseMove={(e) => {
            if (weekendsHidden && !useShiftPressedStore.getState().shiftPressed) {
              return;
            }
            if (["contractor", "regular"].includes(userRole) || (userRole === "self_planner" && user.id !== profile.id))
              return;

            mouseMoved(getPlannerOffsetX(e), getPlannerOffsetY(e, zoom), filteredUsers, e);
          }}
          onMouseUp={(e) => {
            if (
              weekendsHidden &&
              (!useShiftPressedStore.getState().shiftPressed || !useShiftPressedStore.getState().startSelected)
            ) {
              return;
            }

            mouseUped(getPlannerOffsetX(e), getPlannerOffsetY(e, zoom), filteredUsers, e);
          }}
          onDragOver={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          style={{
            ...calculateWeekendStyle(),
            borderRight: isLastWeek ? "1px solid var(--stroke)" : "unset",
            borderBottomRightRadius: isLastWeek && isLastUser && 7,
          }}
        />

        {weekendsHidden && !isLastWeek && <div className="date-row__weekend-cell__right-border" />}
      </div>
    </>
  );
};

export default UserRowWeek;

import { ClickAwayListener } from "@mui/base";
import { forwardRef, useImperativeHandle, useState } from "react";
import UsersProfileImage from "../../../default_components/UsersProfileImage";

export const Tooltip = forwardRef(({ tagsArray, allUsers }, ref) => {
  const [open, setOpen] = useState(false);
  const [pos, setPos] = useState({ x: null, y: null });

  let users = [];
  tagsArray.forEach((tag) => {
    users.push(allUsers.filter((user) => +user.id === tag));
  });

  useImperativeHandle(ref, () => ({
    open: open,
    setOpen: setOpen,
    pos: pos,
    setPos: setPos,
  }));

  return (
    open && (
      <ClickAwayListener
        onClickAway={() => {
          setOpen(false);
        }}
      >
        <div
          ref={ref}
          style={{
            position: "fixed",
            left: pos.x,
            top: pos.y,
            zIndex: "1500",
          }}
          id="tooltip"
        >
          {tagsArray.length > 0 && (
            <div className="tags-tooltip">
              <div
                style={{
                  maxHeight: 120,
                  gap: 10,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {users.flat().map((user) => (
                  <div style={{ display: "flex", gap: "10px" }}>
                    <UsersProfileImage size={24} user={user} userImage={user.avatars["24"]} />
                    <p style={{ marginBottom: "0px" }}>{user.name}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </ClickAwayListener>
    )
  );
});

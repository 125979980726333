import React, { useEffect, useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Api from "../../../Api";
import { useTranslation } from "react-i18next";
import GetAllUsersRequest from "../requests/GetAllUsersRequest";
import GetTeamUsers from "../requests/GetTeamUsers";
import TeambookBlueButton from "../../default_components/TeambookBlueButton";
import CreateUserDialog from "./CreateUserDialog";
import { TeambookUsersPicker } from "../../default_components/TeambookDefaultPickers";
import DeleteUserFromTeamRequest from "../requests/DeleteUserFromTeamRequest";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";
import { useDataChange } from "../../../lib/ussDataChange";
import WarningModal from "../../default_components/WarningModal";
import { useAccountStore } from "../../../stores/accountStore";
import { shallow } from "zustand/shallow";
import { useNotificationStore } from "../../../stores/notificationStore";

const AddUserDialog = ({ open, handleClose, teamId, teams, setAddUser, setTeams }) => {
  const { t } = useTranslation();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [allUsers, setAllUsers] = useState();
  const [teamUsers, setTeamUsers] = useState();
  const [addUserWindowOpen, setAddUserWindowOpen] = useState(false);
  const [submitDisable, setSubmitDisable] = useState(false);
  const [teamsArray, setTeamsArray] = useState([]);
  const currentTeam = teams?.get(teamId);
  const [createdUsers, setCreatedUsers] = useState([]);
  const [creatingUserName, setCreatingUserName] = useState("");
  const [closeConfrimationWindowOpened, setCloseConfirmationWindowOpened] = useState(false);
  const [setInAppNotification] = useNotificationStore((state) => [state.setInAppNotification], shallow);
  const [account] = useAccountStore((state) => [state.account], shallow);

  //TODO: THERE IS A PROBLEM HERE, INFINITE RE-RENDER OF PAGE. NEED FIX!
  // const isChanged = useDataChange(
  //   {
  //     users: [],
  //   },
  //   { users: selectedUsers }
  // );

  const profileRole = localStorage.getItem("tb-role") || "regular";

  useEffect(() => {
    if (open) {
      GetAllUsersRequest(setAllUsers);
      GetTeamUsers(setTeamUsers, teamId);
    }
  }, [open, addUserWindowOpen]);

  useEffect(() => {
    if (teams) {
      setTeamsArray(Array.from(teams).map((team) => team[1]));
    }
  }, [teams]);

  const addUserToSelected = (users) => {
    let deletedUser = selectedUsers.filter((x) => !users.includes(x));
    if (deletedUser.length) {
      const [user] = deletedUser;
      if (user.created) {
        DeleteUserFromTeamRequest(teamId, [user.id], false);
      }
    }

    setSelectedUsers(users);
  };

  const filterUsers = () => {
    let filteredUsers = allUsers.filter((user) => {
      user.value = user.first_name + user.id;
      return user.active === true;
    });

    if (teamUsers === null) {
      return filteredUsers;
    }

    filteredUsers = filteredUsers.filter((user) => {
      return !teamUsers
        .map((u) => {
          return u.id;
        })
        .includes(user.id);
    });

    return filteredUsers;
  };

  const openCloseConfirmationWindow = () => {
    setCloseConfirmationWindowOpened(true);
  };

  async function addUsers() {
    let userIds = selectedUsers
      .filter(({ created }) => !created)
      .map((user) => {
        return parseInt(user.id);
      })
      .filter((userId) => {
        return !currentTeam.team_users.includes(userId);
      });
    handleClose();

    if (userIds.length > 0) {
      setSubmitDisable(true);

      await Api.Teams.add_users(teamId, userIds).then((response) => {
        let updatedTeam = response.data;
        let oldTeams = teams;

        oldTeams.set(updatedTeam.id.toString(), updatedTeam);

        setSelectedUsers([]);
        setTeams(oldTeams);
        setAddUser(true);
        setSubmitDisable(false);

        if (createdUsers.length !== 0 || selectedUsers.length !== 0) {
          setInAppNotification(`${t("notifications.planning.add_users_to_team")} ${updatedTeam.name}`);
        }
      });
    }
  }

  const closeCreationWindow = () => {
    // if (isChanged) {
    //   openCloseConfirmationWindow();
    // } else {
    handleClose();
    setSelectedUsers([]);
    // }

    if (createdUsers.length) {
      setCreatedUsers([]);
    }
  };

  return (
    <>
      <Dialog
        className="planner-add-user-dialog tb-default-dialog"
        open={open}
        onClose={closeCreationWindow}
        aria-labelledby="add-user-dialog"
        maxWidth={"sm"}
        id={"add-user-dialog-popup"}
      >
        <DialogTitle className="planner-add-user-dialog__dialog-title" id="add-user-dialog">
          <p>{t("planning.add_user_text")}</p>
          <TeambookIcon
            onClick={closeCreationWindow}
            className="addUserDialog__closingCross"
            name={icons.CROSS}
            alt={"cross"}
          />
        </DialogTitle>
        <DialogContent style={{ width: 450, padding: "0px 40px 20px 40px" }}>
          {allUsers && teamUsers !== undefined && teams && (
            <>
              <TeambookUsersPicker
                nameStyles={{ margin: "5px auto" }}
                fieldName={t("planning.select_users")}
                onChange={(newUser) => addUserToSelected(newUser)}
                users={filterUsers()}
                classNamePrefix="tb-react-select"
                closeMenuOnSelect={false}
                value={selectedUsers}
                type="multiple"
                placeholder=""
                onCreateOption={(option) => {
                  setCreatingUserName(option);
                  setAddUserWindowOpen(!addUserWindowOpen);
                }}
                isSearchable={true}
                isCreatable={true}
              />
            </>
          )}
        </DialogContent>
        <DialogActions className="add-user__dialog-actions">
          <TeambookBlueButton
            disabled={submitDisable}
            onClick={addUsers}
            color="primary"
            text={t("save")}
            style={{
              border: "1px solid #4C54A0 !important",
              backgroundColor: "#4C54A0",
            }}
          />
        </DialogActions>
        {addUserWindowOpen && (
          <CreateUserDialog
            open={addUserWindowOpen}
            setAddUserWindowOpen={setAddUserWindowOpen}
            profileRole={profileRole}
            teamId={teamId}
            selectedUsers={selectedUsers}
            teams={teamsArray}
            currentTeam={currentTeam}
            creatingUserName={creatingUserName}
            setCreatedUsers={setCreatedUsers}
            setCreatingUserName={setCreatingUserName}
            handleClose={handleClose}
            setAddUser={setAddUser}
          />
        )}

        {closeConfrimationWindowOpened && (
          <WarningModal
            onNo={() => {
              handleClose();
              setSelectedUsers([]);
              setCloseConfirmationWindowOpened(false);
            }}
            onYes={() => {
              addUsers();
              handleClose();
              setSelectedUsers([]);
              setCloseConfirmationWindowOpened(false);
            }}
            closeModal={() => setCloseConfirmationWindowOpened(false)}
          />
        )}
      </Dialog>
    </>
  );
};

export default AddUserDialog;

import React from "react";

const WeekTotal = ({ weeklyLogs, isLast }) => {
  return (
    <div className={`actuals-total-cell ${isLast ? "last" : ""}`}>
      <p>{weeklyLogs.reduce((ps, a) => ps + a.duration, 0) / 60}h</p>
    </div>
  );
};

export default WeekTotal;

import { TeambookIcon } from "../default_images/TeambookIcon";
import { icons } from "../default_images/IconsList";
import React from "react";

export const TeambookCheckbox = ({ checked, onChange, style, className }) => {
  return !checked ? (
    <TeambookIcon
      style={{ cursor: "pointer", ...style }}
      name={icons.CHECKBOX_UNCHECKED}
      onClick={onChange}
      aria-label="not checked"
      className={className}
    />
  ) : (
    <TeambookIcon
      style={{ cursor: "pointer", ...style, height: 24, width: 24 }}
      color="blue"
      name={icons.CHECKBOX_CHECKED}
      onClick={onChange}
      aria-label="checked"
      className={className}
    />
  );
};

let CLIENT_ID, REDIRECT_URI;

switch (process.env.REACT_APP_ENV) {
  case "production":
    CLIENT_ID = "dbc04e1e-443a-4106-bdb7-561c674ce6be";
    REDIRECT_URI = "https://web.teambookapp.com/azure_login";
    break;
  case "staging":
    CLIENT_ID = "e210f551-63ef-41be-8f8c-8f098a440e5e";
    REDIRECT_URI = "https://web.teambooktest.com/azure_login";
    break;
  default:
    CLIENT_ID = "9df8d470-d902-439f-ad4d-6fb2eea83cc5";
    REDIRECT_URI = "http://localhost:3000/azure_login";
    break;
}

export const msalConfig = {
  auth: {
    clientId: CLIENT_ID,
    redirectUri: REDIRECT_URI,
    authority: "https://login.microsoftonline.com/common/",
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: ["User.Read"],
};

export const calendarIntegrationRequest = {
  scopes: ["User.Read", "Calendars.ReadWrite", "offline_access"],
};

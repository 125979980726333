import { useDrag } from "react-dnd";

export const DraggableBooking = ({
  children,
  booking,
  pos,
  isDragging,
  canDrag = true,
}) => {
  const [collected, drag] = useDrag(() => ({
    type: "BOOKING",
    item: { id: booking.id },
    canDrag: canDrag && !isDragging,
  }));

  return (
    <div
      style={{
        position: "absolute",
        zIndex: 1,
        ...pos,
      }}
      ref={drag}
    >
      {children}
    </div>
  );
};

import React, { useEffect, useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import { WhiteTooltip } from "./Tooltips";
import { TeambookIcon } from "../default_images/TeambookIcon";
import { icons } from "../default_images/IconsList";

const TeambookTextForm = ({
  fieldName,
  onChange,
  fieldValue,
  type,
  inputProps,
  width,
  rows,
  multiline,
  style,
  autoFocus,
  placeholder,
  id,
  whiteSpace,
  onFocus = () => {},
  onBlur = () => {},
  className,
  disabled,
  isPassword = false,
  onKeyDown,
  showPass = false,
  setShowPass = () => {},
}) => {
  const { t } = useTranslation();
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div
      className={`input-form-component text-form__container ${className || ""}`}
      style={{ width, ...style, position: "relative" }}
    >
      {fieldName && (
        <p className="input-form__form-text text-form__paragraph" style={{ whiteSpace: whiteSpace }}>
          {fieldName}
        </p>
      )}

      <div className={`input-container ${isFocused ? "focused" : ""}`}>
        <TextField
          onKeyDown={onKeyDown}
          id={id}
          className={`text-field input-field ${isPassword ? "password" : ""}`}
          variant="outlined"
          InputProps={{
            style: {
              height: "42px",
              border: "unset !important",
            },
          }}
          type={isPassword ? (showPass ? "text" : "password") : type}
          onChange={(event) => onChange(event.target.value)}
          value={fieldValue}
          inputProps={inputProps}
          rows={rows}
          multiline={!!multiline}
          autoFocus={autoFocus}
          placeholder={placeholder}
          onFocus={(e) => {
            onFocus(e);
            setIsFocused(true);
          }}
          onBlur={(e) => {
            onBlur(e);
            setIsFocused(false);
          }}
          disabled={disabled}
        />

        {isPassword && (
          <WhiteTooltip title={t(`profile.password.${showPass ? "hide_password" : "show_password"}`)}>
            <div className={`text-form__password-eye`} onClick={setShowPass}>
              <TeambookIcon name={showPass ? icons.EYE_OPENED : icons.EYE_CLOSED} />
            </div>
          </WhiteTooltip>
        )}
      </div>
    </div>
  );
};

export default TeambookTextForm;

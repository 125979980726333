import React, { forwardRef, useState } from "react";

const UserInfoTooltip = forwardRef(({}, ref) => {
  const [open, setOpen] = useState(false);
  const [posX, setPosX] = useState(null);
  const [posY, setPosY] = useState(null);
  const [type, setType] = useState("");
  const [data, setData] = useState([]);

  React.useImperativeHandle(ref, () => {
    return {
      setOpen: setOpen,
      setPosX: setPosX,
      setPosY: setPosY,
      setType: setType,
      setData: setData,
    };
  });

  const displayData = () => {
    if (type === "tags") {
      return data.map((tag) => {
        return (
          <div style={{ background: tag.color }} className="info-tooltip__tag">
            <p>{tag.name}</p>
          </div>
        );
      });
    } else if (type === "teams") {
      return data.map((team) => {
        return (
          <div style={{ background: "#f5f5f5" }} className="info-tooltip__team">
            <p>{team.name}</p>
          </div>
        );
      });
    }
  };

  const calculateWindowSize = () => {
    if (data.length < 4) {
      return {
        height: data.length * 35,
        top: posY - data.length * 35 - 10,
      };
    } else {
      return {
        height: data.length * 25 + data.length * 5,
        top: posY - (data.length * 25 + data.length * 5) - 10,
      };
    }
  };

  return (
    open && (
      <>
        <div
          className="info-tooltip"
          style={{
            left: posX - 45,
            ...calculateWindowSize(),
          }}
        >
          <div className="info-tooltip__data">{displayData()}</div>
        </div>
      </>
    )
  );
});

export default UserInfoTooltip;

import React, { useState } from "react";
import "../header.scss";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import FormErrorMessage from "../../default_components/FormErrorMessage";
import TeambookBlueButton from "../../default_components/TeambookBlueButton";
import TeambookOutlinedButton from "../../default_components/TeambookOutlinedButton";
import { useTranslation } from "react-i18next";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";

const FeedbackModal = ({
  setIsFeedbackModalOpen,
  errorMessage,
  sendMessage,
}) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState("");

  return (
    <Dialog
      onClose={() => setIsFeedbackModalOpen(false)}
      className="tb-default-dialog"
      open
    >
      <DialogTitle>
        <p>{t("auth.feedback")}</p>
        <TeambookIcon
          name={icons.CROSS}
          onClick={() => setIsFeedbackModalOpen(false)}
        />
      </DialogTitle>

      <DialogContent
        className="tb-banner__content"
        style={{ margin: "20px 0", width: 600 }}
      >
        <textarea
          placeholder={t("auth.feedback_placeholder")}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />

        {errorMessage && <FormErrorMessage text={errorMessage} />}
      </DialogContent>

      <DialogActions>
        <div style={{ display: "flex", gap: 20 }}>
          <TeambookOutlinedButton
            onClick={() => setIsFeedbackModalOpen(false)}
            text={t("cancel")}
          />
          <TeambookBlueButton
            text={t("auth.banner_send")}
            onClick={() => sendMessage(message)}
          />
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default FeedbackModal;

import UsersProfileImage from "../../../../default_components/UsersProfileImage";
import moment from "moment";
import { isWeekend, OffsetDays } from "../../../../../lib/DateUtils";
import React from "react";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { useAccountStore } from "../../../../../stores/accountStore";
import { shallow } from "zustand/shallow";

const formatValues = [
  {
    label: "DD/MM/YYYY",
    value: 0,
  },
  {
    label: "MM/DD/YYYY",
    value: 1,
  },
  {
    label: "YYYY/MM/DD",
    value: 2,
  },
];

const ProjectRow = ({ index, startDate, isProjects, stats }) => {
  const { t } = useTranslation();
  let projectHoursColumns = [];
  let currentDate = startDate;
  const indexedStat = stats.data[index];
  const [account] = useAccountStore((state) => [state.account], shallow);

  const getClassName = (j) => {
    if (j === 6) {
      return "saturday";
    } else if (j === 7) {
      return "sunday";
    } else {
      return "";
    }
  };

  projectHoursColumns.push(
    <td style={{ borderLeft: "1px solid var(--stroke)" }} className="reporting__fixed-project-column">
      <div className="flex">
        {isProjects ? (
          <div
            style={{
              width: 10,
              height: 24,
              background: indexedStat.color,
              borderRadius: 7,
            }}
          />
        ) : (
          <>
            {indexedStat.avatar ? (
              <UsersProfileImage
                size={24}
                user={indexedStat.color}
                userImage={indexedStat.avatar}
                className="user-form__avatar"
                style={{
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              />
            ) : (
              <span className="reporting__user-initials">{indexedStat.name[0][0] + indexedStat.name[1][0]}</span>
            )}
          </>
        )}

        <p className="reporting__column-text">{indexedStat.name}</p>
      </div>
    </td>
  );

  for (let j = 0; j < stats.amount_of_days; j++) {
    const date = moment(currentDate).format(formatValues[account?.date_format]?.label);
    const isDayWeekend = isWeekend(currentDate);

    projectHoursColumns.push(
      <td className={`main-table__project-row ${getClassName(DateTime.fromJSDate(currentDate).weekday)}`}>
        <p
          title={
            t(`dashboard.reporting_data.main_row.${isProjects ? "hours_for_project" : "hours_for_user"}`, {
              projectName: indexedStat.name,
              userName: indexedStat.name,
            }) + `${date}`
          }
          className={`column-text fs-16 ${isDayWeekend ? "weekend" : ""}`}
        >
          {indexedStat.daily_total[j]}
        </p>
      </td>
    );

    currentDate = OffsetDays(currentDate, 1);
  }

  projectHoursColumns.push(
    <td className="fixed-total-color main-row__total">
      <p className={`column-text fs-16`}>{indexedStat.daily_total.reduce((x, y) => x + y, 0)}</p>
    </td>
  );

  return <tr className="main-row">{projectHoursColumns}</tr>;
};

export default ProjectRow;

import React from "react";
import { useTranslation } from "react-i18next";
import ProjectBudget from "../../../default_components/ProjectBudget";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { icons } from "../../../default_images/IconsList";
import LinearProgress from "@mui/material/LinearProgress";
import { InfoTooltip } from "../../../default_components/InfoTooltip";

const StatisticsContent = ({ project }) => {
  const { t } = useTranslation();
  const capacityEstimation = project.estimated ? Math.round(project.estimated / 8) : 0;

  const budgetRatio = (values, comparingValue) => {
    if (values > comparingValue) {
      return 100;
    } else {
      return (values / comparingValue) * 100;
    }
  };

  return (
    <div className="project-view__statistics">
      <div className="statistics-box">
        <div className="statistics-icon">
          <TeambookIcon name={icons.FILLED_CALENDAR} />
          <TeambookIcon name={icons.HOURS} style={{ marginTop: "55px" }} />
        </div>

        <div className="statistics-value">
          <p>
            <b>{t("projects.booked_hours")}</b> <br /> {project.bookings_duration} hrs
          </p>
          <p style={{ marginTop: "37px" }}>
            <b>{t("projects.selected_fields.logged_hours")}</b> <br /> {project.actual_logs_duration} hrs
          </p>
        </div>

        <div className="statistics-linear-bar">
          <div>
            <p data-active={project.bookings_duration > project.estimated}>
              {project.bookings_duration > project.estimated
                ? `${project.bookings_duration - project.estimated}h ${t("projects.overbooked")}`
                : `${project.estimated - project.bookings_duration}h ${t("projects.underbooked")}`}
            </p>
            <LinearProgress
              variant="determinate"
              color={budgetRatio(project.bookings_duration, project.estimated) === 100 ? "secondary" : "primary"}
              value={budgetRatio(project.bookings_duration, project.estimated)}
            />
          </div>

          <div>
            <p data-active={project.actual_logs_duration > project.estimated} style={{ marginTop: "48px" }}>
              {project.actual_logs_duration > project.estimated
                ? `${project.actual_logs_duration - project.estimated}h ${t("projects.overbooked")}`
                : `${project.estimated - project.actual_logs_duration}h ${t("projects.underbooked")}`}
            </p>

            <LinearProgress
              variant="determinate"
              color={budgetRatio(project.actual_logs_duration, project.estimated) === 100 ? "secondary" : "primary"}
              value={budgetRatio(project.actual_logs_duration, project.estimated)}
            />
          </div>
        </div>

        <div className="statistics-estimated-hours">
          <p>
            <b>{t("projects.estimated_hours")}</b>
            <InfoTooltip content={t("projects.statistics_estimation_tooltip")} style={{ width: "16px", margin: "0 0 5px 5px" }} />
            <br />
            {project.estimated ? `${project.estimated} hrs` : "-"}
          </p>
        </div>
      </div>

      <div className="statistics-box">
        <div className="statistics-icon">
          <TeambookIcon name={icons.INFINITY} style={{ marginTop: "58px" }} />
        </div>

        <div className="statistics-value">
          <p>
            <b>{t("projects.user_capacity")}</b> <br /> {project.user_capacity_duration} d
          </p>
          <p style={{ marginTop: "37px" }}>
            <b>{t("projects.project_capacity")}</b> <br /> {project.project_capacity_duration} d
          </p>
        </div>

        <div className="statistics-linear-bar">
          <div>
            <p data-active={project.user_capacity_duration > capacityEstimation}>
              {project.user_capacity_duration > capacityEstimation
                ? `${project.user_capacity_duration - capacityEstimation} d ${t("projects.overbooked")}`
                : `${capacityEstimation - project.user_capacity_duration} d ${t("projects.underbooked")}`}
            </p>
            <LinearProgress
              variant="determinate"
              color={budgetRatio(project.user_capacity_duration, capacityEstimation) === 100 ? "secondary" : "primary"}
              value={budgetRatio(project.user_capacity_duration, capacityEstimation)}
            />
          </div>

          <div>
            <p data-active={project.project_capacity_duration > capacityEstimation} style={{ marginTop: "48px" }}>
              {project.project_capacity_duration > capacityEstimation
                ? `${project.project_capacity_duration - capacityEstimation} d ${t("projects.overbooked")}`
                : `${capacityEstimation - project.project_capacity_duration} d ${t("projects.underbooked")}`}
            </p>

            <LinearProgress
              variant="determinate"
              color={budgetRatio(project.project_capacity_duration, capacityEstimation) === 100 ? "secondary" : "primary"}
              value={budgetRatio(project.project_capacity_duration, capacityEstimation)}
            />
          </div>
        </div>

        <div className="statistics-estimated-hours">
          <p>
            <b>{t("projects.estimated_hours")}</b>
            <InfoTooltip content={t("projects.statistics_estimation_tooltip")} style={{ width: "16px", margin: "0 0 5px 5px" }} />
            <br />
            {project.estimated ? `${capacityEstimation} d` : "-"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default StatisticsContent;

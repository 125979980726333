import { useTranslation } from "react-i18next";
import React from "react";
import { TeambookIcon } from "../default_images/TeambookIcon";
import { icons } from "../default_images/IconsList";

const PasswordRules = ({ visible, password }) => {
  const { t } = useTranslation();
  let letterRegex = /.*[^0-9].*/;
  let numberRegex = /.*[0-9].*/;
  let lengthRule = password.length >= 8;
  let letterRule = letterRegex.test(password);
  let numberRule = numberRegex.test(password);

  return (
    visible && (
      <div className="password-rules__container">
        <div className="password-rule">
          {lengthRule ? (
            <>
              <TeambookIcon name={icons.CHECK} color="green" />
              <p class="success-rule">{t("auth.password_rule_1")}</p>
            </>
          ) : (
            <>
              <TeambookIcon name={icons.WARNING} color="red" />
              <p class="error-rule">{t("auth.password_rule_1")}</p>
            </>
          )}
        </div>
        <div className="password-rule">
          {letterRule ? (
            <>
              <TeambookIcon name={icons.CHECK} color="green" />
              <p class="success-rule">{t("auth.password_rule_2")}</p>
            </>
          ) : (
            <>
              <TeambookIcon name={icons.WARNING} color="red" />
              <p class="error-rule">{t("auth.password_rule_2")}</p>
            </>
          )}
        </div>
        <div className="password-rule">
          {numberRule ? (
            <>
              <TeambookIcon name={icons.CHECK} color="green" />
              <p class="success-rule">{t("auth.password_rule_3")}</p>
            </>
          ) : (
            <>
              <TeambookIcon name={icons.WARNING} color="red" />
              <p class="error-rule">{t("auth.password_rule_3")}</p>
            </>
          )}
        </div>
      </div>
    )
  );
};

export default PasswordRules;

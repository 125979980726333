import $ from "jquery";
import { SliceStringWithDots } from "../../../lib/StringFormat";

const TooltipChanger = (newTooltip, scrollTop = 0, scrollLeft = 0) => {
  const removeHTMLTags = (str) => {
    return SliceStringWithDots(str.replace(/(<([^>]+)>)/gi, ""), 30);
  };

  if (newTooltip.positionX + 230 > document.body.offsetWidth) {
    newTooltip.positionX -= 15;
  }

  $("#tooltip").css({
    display: `${newTooltip.open ? "block" : "none"}`,
    top: `${newTooltip.positionY}px`,
    left: `${newTooltip.positionX}px`,
  });

  if (newTooltip.open) {
    $("#tooltip-projectName").text(SliceStringWithDots(newTooltip.projectName, 24));

    $("#tooltip-occupation").text(SliceStringWithDots(newTooltip.occupation, 24));

    $("#tooltip-activity").text(SliceStringWithDots(newTooltip.activity, 24));

    $("#tooltip-bookingDuration").text(`${newTooltip.bookingDuration / 60}h`);
    $("#tooltip-comment").text(newTooltip.comment && SliceStringWithDots(removeHTMLTags(newTooltip.comment), 25));

    $("#tooltip-team").text(SliceStringWithDots(newTooltip.team?.name || "", 25));

    let clientNameField = $("#tooltip-clientName");

    if (newTooltip.clientName) {
      clientNameField.css({ display: "block" });
      clientNameField.text(SliceStringWithDots(newTooltip.clientName, 25));
    } else {
      clientNameField.css({ display: "none" });
    }
  }
};

export default TooltipChanger;

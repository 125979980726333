import { useTranslation } from "react-i18next";

export default function GetStringRole({ role }) {
  const { t } = useTranslation();
  switch (role) {
    case "admin":
      return t("users.admin");
    case "regular":
      return t("users.regular");
    case "contractor":
      return t("users.contractor");
    case "self_planner":
      return t("users.self_planner");
    case "planner":
      return t("users.planner");
  }
}

import "./form_error_message.css";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";

function FormErrorMessage(props) {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem("tb-lang") || "en");
  }, []);

  return (
    <div
      className={`form-error__error-message ${props.className}`}
      style={props.style}
    >
      <div className="form-error__error-message__text">
        <p className="form-error__main-text">{t("there_was_error")}</p>
        <p className="form-error__error-text">{props.text}</p>
      </div>

      <IconButton
        onClick={() => props.closeErrorMessage()}
        style={{ height: "40px", width: "40px" }}
      >
        <CloseIcon />
      </IconButton>
    </div>
  );
}

export default FormErrorMessage;

import { useEffect, useRef, useState } from "react";
import { DateTime, Info } from "luxon";
import "../default_styles.scss";
import Calendar from "./Calendar";
import { ClickAwayListener, Portal } from "@mui/base";
import { TeambookReactSelect } from "../TeambookReactSelect";
import lsKeys from "../../default_values/defaultKeys";
import { useTranslation } from "react-i18next";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";

const MONTH_VAL = "month";
const THREE_MONTHS = "3_months";
const ONE_YEAR = "1_year";
const TWO_YEARS = "2_years";
const SIX_MONTH = "6_months";
const QUARTER = "quarter";
const CUSTOM = "custom";

function TeambookDateSelector({
  range,
  setRange,
  setSelectedDates,
  selectedDates,
  datePickerRef,
  closeDatePicker,
  isRangePicker,
  onDayClick,
  closeOnSelect,
  highlight,
  selectOnArrowClick,
  disabledRanges,
  variant,
  menuPosition,
  theme,
}) {
  const { t } = useTranslation();

  const ranges = [
    {
      id: 1,
      label: t("dashboard.1_month"),
      name: "month",
      value: MONTH_VAL,
      changeDate: () => {
        return 1;
      },
      months: 0,
    },
    {
      id: 2,
      label: t("dashboard.3_months"),
      name: "3_months",
      value: THREE_MONTHS,
      changeDate: () => {
        return 1;
      },
      months: 3,
    },
    {
      id: 3,
      label: t("dashboard.1_year"),
      name: "1_year",
      value: ONE_YEAR,
      changeDate: () => {
        return 1;
      },
      months: 11,
    },
    {
      id: 4,
      label: t("planning.custom"),
      name: "custom",
      value: CUSTOM,
      changeDate: () => {
        return 1;
      },
      months: 0,
    },
    {
      id: 5,
      label: t("dashboard.2_years"),
      name: "2_years",
      value: TWO_YEARS,
      changeDate: () => {
        return 1;
      },
      months: 23,
    },
    {
      id: 6,
      label: t("dashboard.6_months"),
      name: "6_months",
      value: SIX_MONTH,
      changeDate: () => {
        return 1;
      },
      months: 5,
    },
  ]
    .filter((_range) => !disabledRanges.includes(_range.name))
    .sort((a, b) => (a.months > b.months ? 1 : -1));

  const [selectedRange, setSelectedRange] = useState(
    ranges.filter((_range) => !disabledRanges.includes(_range.name)).find((r) => r.id === +range)
  );

  const lang = localStorage.getItem(lsKeys.LANGUAGE);
  let dropdownAnchor = useRef();

  const { left, y } = datePickerRef.current.getBoundingClientRect();

  const [showDates, setShowDates] = useState({
    start: selectedDates.start.startOf("month"),
    end: selectedDates.end.endOf("month"),
  });

  const handleLeftArrowClick = () => {
    if ([1, 2, 3, 4, 5, 6].includes(selectedRange.id)) {
      setShowDates((pr) => ({
        start: pr.start.minus({ month: selectedRange.changeDate() }).startOf("month"),
        end: pr.end.minus({ month: selectedRange.changeDate() }).endOf("month"),
      }));
    }

    if ([1, 2, 3, 5, 6].includes(selectedRange.id) && isRangePicker && selectOnArrowClick) {
      setSelectedDates((pr) => ({
        start: pr.start.minus({ month: selectedRange.changeDate() }).startOf("month"),
        end: pr.end.minus({ month: selectedRange.changeDate() }).endOf("month"),
      }));
    }
  };

  const handleRightArrowClick = () => {
    if ([1, 2, 3, 4, 5, 6].includes(selectedRange.id)) {
      setShowDates((pr) => ({
        start: pr.start.plus({ month: selectedRange.changeDate() }).startOf("month"),
        end: pr.end.plus({ month: selectedRange.changeDate() }).endOf("month"),
      }));
    }

    if ([1, 2, 3, 5, 6].includes(selectedRange.id) && isRangePicker && selectOnArrowClick) {
      setSelectedDates((pr) => ({
        start: pr.start.plus({ month: selectedRange.changeDate() }).startOf("month"),
        end: pr.end.plus({ month: selectedRange.changeDate() }).endOf("month"),
      }));
    }
  };

  useEffect(() => {
    if (!selectedRange?.id) {
      changeRange(ranges[0]);
    }
  }, [selectedRange]);

  const changeRange = (newRange) => {
    setRange(newRange.id);
    setSelectedRange(newRange);
    changeDropdownState("all");

    const calculateNewDates = (pr) => {
      if (newRange.value === "month") {
        return {
          start: pr.start.startOf("month"),
          end: pr.start.endOf("month"),
        };
      }

      if (newRange.value === "custom") {
        return {
          start: pr.start.startOf("month"),
          end: pr.start.endOf("month"),
        };
      }

      if (newRange.value === "3_months") {
        return {
          start: pr.start.startOf("month"),
          end: pr.start.plus({ month: 2 }).endOf("month"),
        };
      }

      if (newRange.value === "1_year") {
        return {
          start: pr.start.startOf("month"),
          end: pr.start.plus({ months: newRange.months }).endOf("month"),
        };
      }

      if (newRange.value === "2_years") {
        return {
          start: pr.start.startOf("month"),
          end: pr.start.plus({ months: newRange.months }).endOf("month"),
        };
      }

      if (newRange.value === "6_months") {
        return {
          start: pr.start.startOf("month"),
          end: pr.start.plus({ months: newRange.months }).endOf("month"),
        };
      }
    };

    setShowDates((pr) => calculateNewDates(pr));
    setSelectedDates((pr) => calculateNewDates(showDates));
  };

  const size = [2, 3, 5, 6].includes(selectedRange?.id || 1) ? "large" : "small";

  const selectStyles = {
    container: (provided) => ({
      ...provided,
      width: "100%",
    }),

    control: (provided) => ({
      ...provided,
      width: "100%",
      minHeight: "32px !important",
      height: "32px !important",
      fontFamily: "Montserrat",
      background: "var(--background-1) !important",
      border: "1px solid var(--stroke) !important",
    }),

    indicatorsContainer: (provided) => ({
      ...provided,
      height: "32px",
    }),

    valueContainer: (provided) => ({
      ...provided,
      height: "32px",
      padding: "0 6px",
    }),

    input: (provided) => ({
      ...provided,
      margin: "0px",
    }),
  };

  const [dropdownsState, setDropdownsState] = useState({
    start_month: false,
    start_year: false,
    end_month: false,
    end_year: false,
  });

  const changeDropdownState = (dropdown_type) => {
    if (dropdown_type !== "all") {
      let new_dropdown_state = { ...dropdownsState };
      new_dropdown_state[dropdown_type] = !new_dropdown_state[dropdown_type];

      setDropdownsState(new_dropdown_state);
    } else {
      setDropdownsState({
        start_month: false,
        start_year: false,
        end_month: false,
        end_year: false,
      });
    }
  };

  const months = Info.months("long", { locale: lang });

  const changeMonth = (place, i) => {
    changeDropdownState("all");

    if (place === "start") {
      setShowDates((pr) => {
        if (+selectedRange.id !== 4) {
          return {
            start: pr.start.set({ month: i + 1 }).startOf("month"),
            end: pr.start
              .set({ month: i + 1 })
              .plus({ months: selectedRange.months })
              .endOf("month"),
          };
        } else {
          return {
            start: pr.start.set({ month: i + 1 }).startOf("month"),
            end: pr.start.set({ month: i + 3 }).endOf("month"),
          };
        }
      });

      if (isRangePicker) {
        setSelectedDates((pr) => {
          if (+selectedRange.id !== 4) {
            return {
              start: pr.start.set({ month: i + 1 }).startOf("month"),
              end: pr.start
                .set({ month: i + 1 })
                .plus({ months: selectedRange.months })
                .endOf("month"),
            };
          } else {
            return {
              start: pr.start.set({ month: i + 1 }).startOf("month"),
              end: pr.start.set({ month: i + 3 }).endOf("month"),
            };
          }
        });
      }
    }

    if (place === "end") {
      setShowDates((pr) => {
        if (selectedRange.id === 4) {
          return {
            start: pr.end
              .set({ month: i + 1 })
              .minus({ month: 2 })
              .startOf("month"),
            end: pr.end.set({ month: i + 1 }).endOf("month"),
          };
        } else {
          return {
            start: pr.end
              .set({ month: i + 1 })
              .minus({ month: selectedRange.months })
              .startOf("month"),
            end: pr.end.set({ month: i + 1 }).endOf("month"),
          };
        }
      });

      if (isRangePicker) {
        setSelectedDates((pr) => {
          if (+range === +ranges[0].id) {
            return {
              start: pr.end
                .set({ month: i + 1 })
                .minus({ month: 1 })
                .startOf("month"),
              end: pr.end.set({ month: i + 1 }).endOf("month"),
            };
          } else {
            return {
              start: pr.end
                .set({ month: i + 1 })
                .minus({ month: selectedRange.months })
                .startOf("month"),
              end: pr.end.set({ month: i + 1 }).endOf("month"),
            };
          }
        });
      }
    }
  };

  const changeYear = (year, position) => {
    setShowDates((pr) => {
      if (position === "start") {
        return {
          start: pr.start.set({ year: year }),
          end: pr.start.set({ year: year }).plus({ months: selectedRange.months }).endOf("month"),
        };
      } else {
        if (position === "end") {
          return {
            start: pr.end.set({ year: year }).minus({ months: selectedRange.months }).startOf("month"),
            end: pr.end.set({ year: year }).endOf("month"),
          };
        }
      }
    });

    if (selectedRange.value === "month" && !isRangePicker) {
      setSelectedDates(selectedDates.set({ year: year }));
    } else {
      setSelectedDates((pr) => {
        if (position === "start") {
          return {
            start: pr.start.set({ year: year }).startOf("month"),
            end: pr.start.set({ year: year }).plus({ months: selectedRange.months }).endOf("month"),
          };
        } else {
          if (position === "end") {
            return {
              start: pr.end.set({ year: year }).minus({ months: selectedRange.months }).startOf("month"),
              end: pr.end.set({ year: year }).endOf("month"),
            };
          }
        }
      });
    }
  };

  return (
    <Portal>
      <ClickAwayListener
        onClickAway={() => {
          closeDatePicker();
          changeDropdownState("all");
        }}
      >
        <div
          style={{
            width: size === "large" ? 600 : 300,
            left: variant === "inline" ? left - 115 : left - 40,
            top: variant === "inline" ? y - 350 : y + 40,
            justifyContent: size === "large" ? "space-between" : "center",
          }}
          className={`date-picker ${isRangePicker ? "ranged-picker" : "default-picker"}`}
        >
          <div className="date-picker__left-side">
            {isRangePicker && (
              <TeambookReactSelect
                styles={selectStyles}
                options={ranges}
                value={selectedRange}
                onChange={changeRange}
                grayPlaceholder
              />
            )}

            <div style={{ marginTop: isRangePicker ? 22 : 0 }} className="date-picker__date-switcher">
              <div className="date-picker__date-switcher-left">
                <TeambookIcon
                  onClick={handleLeftArrowClick}
                  size={16}
                  name={icons.ARROW_DOWN}
                  style={{ transform: "rotate(90deg)" }}
                  theme={theme}
                />

                {size === "small" ? (
                  <div
                    className="date-picker__small-dropdown-trigger"
                    style={{ margin: "0px auto", display: "flex", gap: 5 }}
                  >
                    <p
                      onClick={(e) => {
                        dropdownAnchor.current = e.currentTarget;
                        changeDropdownState("all");
                        changeDropdownState("start_month");
                      }}
                      style={{ margin: 0, textTransform: "capitalize" }}
                    >
                      {showDates.start.setLocale(lang).toFormat("MMMM")}
                    </p>
                    <p
                      onClick={(e) => {
                        dropdownAnchor.current = e.currentTarget;
                        changeDropdownState("all");
                        changeDropdownState("start_year");
                      }}
                      style={{ margin: 0 }}
                    >
                      {showDates.start.setLocale(lang).toFormat("yyyy")}
                    </p>

                    {dropdownsState.start_month && (
                      <ClickAwayListener
                        onClickAway={() => {
                          changeDropdownState("all");
                        }}
                      >
                        <div
                          style={{
                            left: dropdownAnchor.current.offsetLeft - 30,
                            top: 30,
                          }}
                          className="date-picker__dropdown-months"
                        >
                          {months.map((m, i) => (
                            <p
                              className={`${showDates.start.month === i + 1 ? "active" : ""}`}
                              onClick={() => {
                                changeMonth("start", i);
                              }}
                            >
                              {m}
                            </p>
                          ))}
                        </div>
                      </ClickAwayListener>
                    )}

                    {dropdownsState.start_year && (
                      <ClickAwayListener
                        onClickAway={() => {
                          changeDropdownState("all");
                        }}
                      >
                        <div
                          style={{
                            left: dropdownAnchor.current.offsetLeft - 30,
                            top: 30,
                          }}
                          className="date-picker__dropdown-months"
                        >
                          {[
                            DateTime.now().minus({ year: 1 }).year,
                            DateTime.now().year,
                            DateTime.now().plus({ year: 1 }).year,
                            DateTime.now().plus({ year: 2 }).year,
                          ].map((year, i) => (
                            <p
                              className={`${showDates.start.startOf("year").year === year ? "active" : ""}`}
                              onClick={() => {
                                changeYear(year, "start");
                              }}
                            >
                              {year}
                            </p>
                          ))}
                        </div>
                      </ClickAwayListener>
                    )}
                  </div>
                ) : (
                  <div className="date-picker__start-range">
                    {size === "large" && <p className="date-picker__range-text">{t("planning.from_export")}</p>}
                    <div className="flex">
                      <p
                        onClick={(e) => {
                          dropdownAnchor.current = e.currentTarget;
                          changeDropdownState("all");
                          changeDropdownState("start_month");
                        }}
                        style={{ margin: "0px auto" }}
                        className="pointer date-picker__range-month"
                      >
                        {showDates.start.setLocale(lang).toFormat("MMMM")}
                      </p>
                      {dropdownsState.start_month && (
                        <ClickAwayListener
                          onClickAway={() => {
                            changeDropdownState("all");
                          }}
                        >
                          <div
                            style={{
                              left: dropdownAnchor.current.offsetLeft - 30,
                              top: size === "small" ? 90 : 30,
                            }}
                            className="date-picker__dropdown-months"
                          >
                            {months.map((m, i) => (
                              <p
                                className={`${showDates.start.month === i + 1 ? "active" : ""}`}
                                onClick={() => {
                                  changeMonth("start", i);
                                }}
                              >
                                {m}
                              </p>
                            ))}
                          </div>
                        </ClickAwayListener>
                      )}
                      &nbsp;
                      <p
                        onClick={(e) => {
                          dropdownAnchor.current = e.currentTarget;
                          changeDropdownState("all");
                          changeDropdownState("start_year");
                        }}
                        className="pointer date-picker__range-month"
                        style={{ margin: "0px auto" }}
                      >
                        {showDates.start.setLocale(lang).toFormat("yyyy")}
                      </p>
                      {dropdownsState.start_year && (
                        <ClickAwayListener
                          onClickAway={() => {
                            changeDropdownState("all");
                          }}
                        >
                          <div
                            style={{
                              left: dropdownAnchor.current.offsetLeft - 30,
                              top: size === "small" ? 90 : 30,
                            }}
                            className="date-picker__dropdown-months"
                          >
                            {[
                              DateTime.now().minus({ year: 1 }).year,
                              DateTime.now().year,
                              DateTime.now().plus({ year: 1 }).year,
                              DateTime.now().plus({ year: 2 }).year,
                            ].map((year, i) => (
                              <p
                                className={`${showDates.start.startOf("year").year === year ? "active" : ""}`}
                                onClick={() => {
                                  changeYear(year, "start");
                                }}
                              >
                                {year}
                              </p>
                            ))}
                          </div>
                        </ClickAwayListener>
                      )}
                    </div>
                  </div>
                )}

                {size === "small" && (
                  <TeambookIcon
                    onClick={handleRightArrowClick}
                    name={icons.ARROW_DOWN}
                    color="alternative_default"
                    theme={theme}
                    style={{ transform: "rotate(270deg)", right: 0 }}
                  />
                )}
              </div>
            </div>

            <Calendar
              position="start"
              displayedMonth={showDates.start}
              showDates={showDates}
              selectedDates={selectedDates}
              setSelectedDates={setSelectedDates}
              range={range}
              onDayClick={onDayClick}
              isRangePicker={isRangePicker}
              closeOnSelect={closeOnSelect}
              closeDatePicker={closeDatePicker}
              highlight={highlight}
            />
          </div>

          {size === "large" ? (
            <div className="date-picker__right-side">
              <div style={{ height: 32 }}></div>

              <div className="date-picker__date-switcher">
                <div className="date-picker__date-switcher-right">
                  <div className="date-picker__end-range">
                    {size === "large" && (
                      <>
                        <p className="date-picker__range-text">{t("planning.to_export")}</p>
                        <div className="flex">
                          <p
                            className="pointer date-picker__range-month"
                            onClick={(e) => {
                              dropdownAnchor.current = e.currentTarget;
                              changeDropdownState("all");
                              changeDropdownState("end_month");
                            }}
                            style={{ margin: "0px auto" }}
                          >
                            {showDates.end.setLocale(lang).toFormat("MMMM")}
                          </p>
                          {dropdownsState.end_month && (
                            <ClickAwayListener
                              onClickAway={() => {
                                changeDropdownState("all");
                              }}
                            >
                              <div
                                style={{
                                  left: dropdownAnchor.current.getBoundingClientRect().x - 360,
                                  top: size === "small" ? 90 : 30,
                                }}
                                className="date-picker__dropdown-months"
                              >
                                {months.map((m, i) => (
                                  <p
                                    className={`${showDates.end.month === i + 1 ? "active" : ""}`}
                                    onClick={() => {
                                      changeMonth("end", i);
                                    }}
                                  >
                                    {m}
                                  </p>
                                ))}
                              </div>
                            </ClickAwayListener>
                          )}
                          &nbsp;
                          <p
                            className="pointer date-picker__range-month"
                            onClick={(e) => {
                              dropdownAnchor.current = e.currentTarget;
                              changeDropdownState("all");
                              changeDropdownState("end_year");
                            }}
                            style={{ margin: "0px auto" }}
                          >
                            {showDates.end.setLocale(lang).toFormat("yyyy")}
                          </p>
                          {dropdownsState.end_year && (
                            <ClickAwayListener
                              onClickAway={() => {
                                changeDropdownState("all");
                              }}
                            >
                              <div
                                style={{
                                  left: dropdownAnchor.current.getBoundingClientRect().x - 370,
                                  top: size === "small" ? 90 : 30,
                                }}
                                className="date-picker__dropdown-months"
                              >
                                {[
                                  DateTime.now().minus({ year: 1 }).year,
                                  DateTime.now().year,
                                  DateTime.now().plus({ year: 1 }).year,
                                  DateTime.now().plus({ year: 2 }).year,
                                ].map((year, i) => (
                                  <p
                                    className={`${showDates.end.startOf("year").year === year ? "active" : ""}`}
                                    onClick={() => {
                                      changeYear(year, "end");
                                    }}
                                  >
                                    {year}
                                  </p>
                                ))}
                              </div>
                            </ClickAwayListener>
                          )}
                        </div>
                      </>
                    )}
                  </div>

                  <TeambookIcon
                    size={16}
                    onClick={handleRightArrowClick}
                    name={icons.ARROW_DOWN}
                    theme={theme}
                    style={{ transform: "rotate(270deg)", right: 0 }}
                  />
                </div>
              </div>

              <Calendar
                selectedDates={selectedDates}
                setSelectedDates={setSelectedDates}
                position="end"
                displayedMonth={showDates.end}
                showDates={showDates}
                size={size}
                range={range}
                onDayClick={onDayClick}
                isRangePicker={isRangePicker}
                closeOnSelect={closeOnSelect}
                closeDatePicker={closeDatePicker}
                highlight={highlight}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      </ClickAwayListener>
    </Portal>
  );
}

export default TeambookDateSelector;

import { DAY_VIEW_HEIGHT, DAY_VIEW_WIDTH } from "../../../../lib/PlannerUtils";
import {
  useBookingsCreatingStore,
  useSelectedProjectStore,
} from "../../../../stores/planner";
import { shallow } from "zustand/shallow";

const DayViewCreation = ({ index, hour }) => {
  const { selectedProject } = useSelectedProjectStore();
  const [bookingsDuration] = useBookingsCreatingStore(
    (state) => [state.bookingsDuration],
    shallow
  );

  const calculateLeftOffset = () => {
    return hour * DAY_VIEW_WIDTH;
  };

  const calculateHeight = (bookingDuration) => {
    return DAY_VIEW_HEIGHT;
  };

  const calculateWidth = () => {
    return bookingsDuration * DAY_VIEW_WIDTH;
  };

  return (
    <div
      style={{
        width: `${calculateWidth()}px`,
        left: `${calculateLeftOffset()}px`,
        height: `${calculateHeight()}px`,
        top: `0px`,
        backgroundColor: selectedProject?.color,
        position: "absolute",
      }}
    ></div>
  );
};

export default DayViewCreation;

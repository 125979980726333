import React from "react";
import EntityNameCustomization from "./EntityNameCustomization";
import CustomProjectFields from "./CustomProjectFields";

const CustomizationComponent = ({}) => {
  return (
    <div className="account-schedule main-settings-component">
      <EntityNameCustomization />

      <CustomProjectFields />
    </div>
  );
};

export default CustomizationComponent;

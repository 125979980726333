import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { recalculateCapacityArray } from "../../../../../capacity_planning/lib/recalculateCapacityArray";
import { WhiteTooltip } from "../../../../../default_components/Tooltips";
import moment from "moment";

const ProjectsSubBlock = ({
  project,
  date,
  prReservations,
  reservations,
  selectedViewType,
  showDays = true,
  showTotal = false,
  showPercentage = false,
  usersWeeklyTotal,
}) => {
  const { t } = useTranslation();
  const [calculatedDurations, setCalculatedDurations] = useState([]);
  const [percentageDurations, setPercentageDurations] = useState([]);
  const [projectReservations, setProjectReservations] = useState(prReservations);

  useEffect(() => {
    setProjectReservations(prReservations);
    setPercentageDurations(calculatePercentageDuration());
  }, [prReservations]);

  useEffect(() => {
    updateCalculations();
  }, [projectReservations, date, reservations]);

  const updateCalculations = () => {
    setCalculatedDurations(recalculateCapacityArray(date, prReservations, selectedViewType));
  };

  const calculatePercentageDuration = () => {
    let newCapacityDurations = [];

    [...Array(selectedViewType)].forEach((x, i) => {
      const calculatedDate = date.plus({ months: i }).toISODate();

      const daysRserved = prReservations
        .filter((cr) => moment(cr.date).format("DD.MM.YY") === moment(calculatedDate).format("DD.MM.YY"))
        .map((cr) => cr.days_reserved)
        .reduce((ps, a) => ps + a, 0);

      const userMonthDuration = monthDuration(date.plus({ months: i }));

      if (userMonthDuration > 0) {
        newCapacityDurations.push(((daysRserved / userMonthDuration) * 100).toFixed(0));
      } else {
        newCapacityDurations.push(0);
      }
    });

    return newCapacityDurations;
  };

  const monthDuration = (newDate) => {
    let duration = 0;

    for (let i = 0; i < newDate.endOf("month").day; i += 1) {
      const newDateWeekday = newDate.plus({ days: i }).weekday - 1;

      duration += usersWeeklyTotal[newDateWeekday];
    }

    return duration;
  };

  const percentageDuration = (i) => {
    const value = percentageDurations[i];

    if (!value) {
      return "..";
    }

    return `${percentageDurations[i]}%`;
  };

  if (!project) {
    return <></>;
  }

  return (
    <div className="reporting-block">
      <div className="reporting__headline" style={{ backgroundColor: "var(--background-2)" }}>
        <div
          style={{
            borderRight: showTotal && "1px solid var(--stroke)",
            paddingRight: 0,
            paddingLeft: 30,
            overflow: "hidden",
            textOverflow: "ellipsis",
            backgroundColor: "var(--background-2)",
          }}
          className="reporting__headline__name"
        >
          <div className="named-row-project">
            <div style={{ background: project.color }} className="project-list__project-color" />

            <WhiteTooltip title={project?.name || ""}>
              <p>{project?.name || ""}</p>
            </WhiteTooltip>
          </div>

          {showDays && (
            <p className="duration">
              {calculatedDurations.reduce((ps, a) => ps + a, 0)} {t("planning.days")}
            </p>
          )}
        </div>

        {[...Array(selectedViewType)].map((x, i) => (
          <div className={`table-cell`}>
            <p>{(showPercentage ? percentageDuration(i) : calculatedDurations[i]) || "0"}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProjectsSubBlock;

import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { OffsetDays, getCellWidthForZoom, ToDateString } from "../../../lib/DateUtils";
import { PlannerContext } from "../context/PlannerContext";
import { DAY_VIEW_HEIGHT, GROUPED_PLANNER_USER_WIDTH, PLANNER_USER_WIDTH } from "../../../lib/PlannerUtils";
import lsKeys from "../../default_values/defaultKeys";
import { useZoomStore } from "../../../stores/planner";
import { shallow } from "zustand/shallow";
import { DateTime } from "luxon";

const formatMapping = {
  0: 5,
  1: 10,
  2: 15,
};

const UserHoursLeft = ({
  bookingsArray,
  schedule,
  days,
  date,
  weekendsHidden,
  topOffset,
  setBookingsDate,
  userIndex,
  bookingFormat,
  groupClients,
  hoursType,
  user,
  copyModeActive,
  projects,
  groupedView = false,
  weekendInfoRef,
  isLastWeek,
}) => {
  const [zoom] = useZoomStore((state) => [state.zoom], shallow);
  const { t } = useTranslation();

  let daysArray = Array.from(Array(days).keys());
  let scheduleOffset = (Math.max(...schedule) / 60) * formatMapping[bookingFormat];

  if (zoom === 1) {
    scheduleOffset = DAY_VIEW_HEIGHT;
  }

  const isGroupedPlanner = localStorage.getItem(lsKeys.LAST_PLANNER_TYPE) === "grouped_planners";

  const getHoursLeftTextStyle = (dateIndex, index) => {
    return {
      background: " var(--background-2)",
      width: `${getCellWidthForZoom(zoom)}px`,
      color: getFreeTime(OffsetDays(date, dateIndex)) < 0 || zoom === 90 ? "var(--error)" : "var(--text-2)",
      borderRight: (!weekendsHidden || OffsetDays(date, dateIndex).getDay() !== 5) && "1px solid var(--stroke)",
      borderBottom: "1px solid var(--stroke)",
      borderBottomRightRadius: isLastWeek && daysArray.length === index + 1 && 7,
    };
  };

  const dateIsWeekend = (date) => {
    return weekendsHidden && (date.getDay() === 0 || date.getDay() === 6);
  };

  const dateIsWeekendShow = (date) => {
    return date.getDay() === 0 || date.getDay() === 6;
  };

  const getFreeTime = (date) => {
    const timeOffIds = projects.filter((pr) => pr.kind === "time_off").map((pr) => pr.id);

    let sameDateBookings = bookingsArray
      .filter((booking) => booking.deleted_at === null)
      .filter((booking) => !timeOffIds.includes(booking.project_id) || !dateIsNotWeekend(date))
      .filter((booking) => booking.date === ToDateString(date));
    let freeTime = schedule[date.getDay()];

    sameDateBookings.forEach((booking) => {
      freeTime -= booking.duration;
    });

    return freeTime / 60;
  };

  const openWeekendInfo = (e, weekendDate, userId) => {
    weekendInfoRef.current.setPos({
      x: e.pageX,
      y: e.pageY,
    });

    weekendInfoRef.current.setOpen(true);

    weekendInfoRef.current.setUserIndex(userIndex);
    weekendInfoRef.current.setUserId(userId);

    weekendInfoRef.current.setDates([DateTime.fromISO(weekendDate).toISODate()]);
  };

  const dateIsNotWeekend = (date) => {
    const day = date.getDay();
    return day === 6 || day === 0;
  };

  const calculateLeftOffset = () => {
    if (hoursType === "grouped") {
      const plannerWidth = groupedView ? GROUPED_PLANNER_USER_WIDTH : PLANNER_USER_WIDTH;
      return groupClients ? plannerWidth + 50 : plannerWidth;
    } else {
      return "";
    }
  };

  return (
    <div
      style={{
        top: `${topOffset + scheduleOffset}px`,
        left: calculateLeftOffset(),
      }}
      className="hours-left-component"
    >
      {daysArray.map((dateIndex, i) =>
        dateIsWeekend(OffsetDays(date, dateIndex)) ? (
          <div
            key={`user-hours-left-${user.id}-${dateIndex}`}
            style={{
              width: 10,
              height: 17,
              background: "transparent",
              borderBottomRightRadius: isLastWeek && daysArray.length === i + 1 && 7,
            }}
          />
        ) : dateIsWeekendShow(OffsetDays(date, dateIndex)) ? (
          <div
            style={{
              ...getHoursLeftTextStyle(dateIndex, i),
              background: " var(--background-2)",
              borderRight:
                OffsetDays(date, dateIndex).getDay() === 0 ? "1px solid var(--stroke)" : "1px solid var(--stroke)",
              borderBottomRightRadius: isLastWeek && daysArray.length === i + 1 && 7,
            }}
            key={`user-hours-left-${user.id}-${dateIndex}`}
          >
            <p
              onClick={(e) => {
                if (!copyModeActive) {
                  openWeekendInfo(e, DateTime.fromJSDate(OffsetDays(date, dateIndex)).toISODate(), user.id);
                }
              }}
            >
              {((getFreeTime(OffsetDays(date, dateIndex)) < 0 && zoom === 90 && !isGroupedPlanner) || zoom !== 90) &&
                getFreeTime(OffsetDays(date, dateIndex)) + (zoom !== 90 && t("my_week.hrs"))}
            </p>
          </div>
        ) : (
          <div
            style={getHoursLeftTextStyle(dateIndex, i)}
            key={`${ToDateString(date)}-${dateIndex}`}
            onClick={(e) => {
              if (!copyModeActive) {
                openWeekendInfo(e, DateTime.fromJSDate(OffsetDays(date, dateIndex)).toISODate(), user.id);
              }
            }}
          >
            <p>
              {((getFreeTime(OffsetDays(date, dateIndex)) < 0 && zoom === 90 && !isGroupedPlanner) || zoom !== 90) &&
                getFreeTime(OffsetDays(date, dateIndex)) + (zoom !== 90 && t("my_week.hrs"))}
            </p>
          </div>
        )
      )}
    </div>
  );
};

export default UserHoursLeft;

import Api from "../../../Api";
import { updateValueWithId } from "../../../lib/PlannerUtils";

async function DeleteBookingsRequest(
  bookingsToDelete,
  date,
  zoom,
  setBookings,
  closeCreationWindow,
  allBookings,
  setEditWindowOpened,
  alsoRepetitions,
  closeEditWindow,
  teams,
  teamId,
  usersRef,
  filteredUsers,
  setProjects,
  setActiveProjects,
  projects,
  activeProjects
) {
  let userIds = [...new Set(bookingsToDelete.map((booking) => booking.user_id))];
  let bookingIds = bookingsToDelete.map((booking) => booking.id);

  let projectIds = bookingsToDelete.map((b) => b.project_id);

  await Api.Bookings.delete(bookingIds, allBookings, setBookings, setEditWindowOpened, alsoRepetitions).then(
    (response) => {
      let newBookings = allBookings;

      response.data.deleted_bookings.forEach((booking) => {
        newBookings = newBookings.filter((new_booking) => new_booking.id !== booking.id);
      });

      response.data.updated_repetition_bookings.forEach((booking) => {
        newBookings[newBookings.findIndex((b) => b.id === booking.id)] = booking;
      });

      setBookings(newBookings);

      userIds.map((userId) => {
        let user = filteredUsers.find((user) => user.id === parseInt(userId));
        let userIndexes = getAllIndexes(filteredUsers, user.id).flat();

        userIndexes.forEach((index) => {
          if (usersRef.current[index]) {
            usersRef.current[index].updateBookings(
              newBookings.filter((booking) => booking.user_id.toString() === userId.toString())
            );
          }

          // usersRef.current[index].updateBookings(
          //   newBookings.get(userId.toString())
          // );
        });
      });
    }
  );

  Api.Projects.in_range(projectIds).then((response) => {
    response.data.forEach((project) => {
      setProjects(updateValueWithId(project.id, projects, project));
      setActiveProjects(updateValueWithId(project.id, activeProjects, project));
    });
  });

  closeEditWindow();
}

const getAllIndexes = (arr, userId) => {
  let indexes = [];
  arr.forEach((user, i) => {
    if (user.id === userId) {
      indexes.push(i);
    }
  });

  return indexes;
};

export default DeleteBookingsRequest;

import { TeambookCheckbox } from "../../../default_components/TeambookCheckbox";
import React from "react";
import { useTranslation } from "react-i18next";

export const calculateStartTime = (account, createBookingsRef) => {
  if (!createBookingsRef || !createBookingsRef.current) return "09:00";

  const schedule = account?.schedule;
  const validRef = createBookingsRef.current.find((ref) => ref && ref.bookings && ref.bookings.length > 0);

  if (validRef) {
    const date = validRef.bookings[0]?.[1];
    if (date) {
      return parseIntegerToStringTime(schedule[date.getDay()][3]);
    }
  }

  return "09:00";
};

export const parseStringTimeIntoInteger = (stringTime) => {
  if (!stringTime) return 800;

  let date = new Date(`2000-01-01T${stringTime}:01`);
  if (stringTime.toString().length === 2) {
    return `00:0${stringTime}`;
  }

  if (date) {
    let hours = date.getHours() * 100;
    let minutes = date.getMinutes();
    return hours + minutes;
  } else {
    return 800;
  }
};

// INPUT FORMAT 540 = 09:00
export const parseIntegerToStringTime = (timeInteger) => {
  const hour = Math.floor(timeInteger / 60);
  const minutes = Math.round(timeInteger % 60);
  let minutesString = `${minutes}`;
  let hoursString = `${hour}`;

  if (minutes < 10) {
    minutesString = `0${minutes}`;
  }

  if (hour < 10) {
    hoursString = `0${hour}`;
  }

  return `${hoursString}:${minutesString}`;
};

export const parseStartTimeValue = (time, trailingZero = true) => {
  if (!time) return "08:00";

  const minutes = (time % 100).toString().length > 1 ? time % 100 : `0${time % 100}`;

  if (time.toString().length === 4) {
    return `${Math.floor(time / 100)}:${minutes}`;
  } else if (time.toString().length === 3) {
    return `${trailingZero ? "0" : ""}${Math.floor(time / 100)}:${minutes}`;
  } else {
    return `00:${minutes}`;
  }
};

export const bookingStartTimeText = (booking) => {
  let integerTime = Math.floor(booking.start_time / 100) * 60 + (booking.start_time % 100);

  return (
    parseStartTimeValue(booking.start_time, false) + "-" + parseIntegerToStringTime(integerTime + booking.duration)
  );
};

export const StartTimeBlock = ({
  startTime,
  setStartTime,
  startTimeWindowOpened,
  setStartTimeWindowOpened,
  account,
  createBookingsRef,
  bookingsDuration,
}) => {
  const { t } = useTranslation();

  const changeEndTime = (endTime) => {
    let integerTime =
      Math.floor(parseStringTimeIntoInteger(endTime) / 100) * 60 + (parseStringTimeIntoInteger(endTime) % 100);

    setStartTime(parseIntegerToStringTime(integerTime - bookingsDuration * 60));
  };

  const parseEndTime = () => {
    let integerTime =
      Math.floor(parseStringTimeIntoInteger(startTime) / 100) * 60 + (parseStringTimeIntoInteger(startTime) % 100);

    return parseIntegerToStringTime(integerTime + bookingsDuration * 60);
  };

  return (
    <div className="row checkbox">
      <div style={{ display: "flex", alignItems: "center" }}>
        <TeambookCheckbox
          checked={startTimeWindowOpened}
          onChange={(event) => {
            setStartTimeWindowOpened(!startTimeWindowOpened);
            setStartTime(calculateStartTime(account, createBookingsRef));
          }}
          color="primary"
        />

        <p
          className="booking-window__data-element-text"
          onClick={() => {
            setStartTimeWindowOpened(!startTimeWindowOpened);
            setStartTime(calculateStartTime(account, createBookingsRef));
          }}
        >
          {t("planning.start_end_time")}
        </p>
      </div>

      {startTimeWindowOpened && (
        <div className="start-time__block">
          <input
            className="start-time_picker"
            type="time"
            onChange={(e) => {
              setStartTime(e.target.value);
            }}
            value={startTime}
          />
          -
          <input
            className="start-time_picker"
            type="time"
            onChange={(e) => changeEndTime(e.target.value)}
            value={parseEndTime()}
          />
        </div>
      )}
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Api from "../../../Api";
import TeambookBlueButton from "../../default_components/TeambookBlueButton";
import ControlPanel from "../ControlPanel";
import { DateTime } from "luxon";

export default function UsersStatistics({ teambookStatistics }) {
  return (
    <table
      style={{
        width: "99.5%",
        margin: "70px 5px",
        textAlign: "left",
      }}
      border="1px solid black"
    >
      <tr style={{ textAlign: "center" }}>
        <th style={{ width: "25%", padding: 10 }}>Date</th>
        <th style={{ width: "25%", padding: 10 }}>Newly Created Users</th>
        <th style={{ width: "25%", padding: 10 }}># of Active Users</th>
        <th style={{ width: "25%", padding: 10 }}># of Logins</th>
      </tr>

      {teambookStatistics.map((statistics) => {
        return (
          <tr>
            <td style={{ width: "25%", padding: 10 }}>{DateTime.fromISO(statistics.date).toFormat("dd/MM/yyyy")}</td>
            <td style={{ width: "25%", padding: 10 }}>{statistics.users_created_count}</td>
            <td style={{ width: "25%", padding: 10 }}>{statistics.active_users_count}</td>
            <td style={{ width: "25%", padding: 10 }}>{statistics.visitors_today}</td>
          </tr>
        );
      })}
    </table>
  );
}

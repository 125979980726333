import lsKeys from "../components/default_values/defaultKeys";
import settings from "../components/organization_settings/Settings";

class UserSettings {
  constructor(settings, changeTheme) {
    this.settings = settings;
    this.changeTheme = changeTheme;
  }

  setLocalstorageValues() {
    if (this.settings === null) return;

    this.switchTheme(this.settings, this.changeTheme);
    this.switchLastUsedTeam(this.settings);
  }

  switchTheme(settings, changeTheme) {
    const newTheme = settings.dark_theme ? "dark" : "light";

    if (localStorage.getItem(lsKeys.THEME) === newTheme || changeTheme === false) return;

    localStorage.setItem(lsKeys.THEME, newTheme);

    changeTheme(newTheme);
  }

  switchLastUsedTeam(settings) {
    const lastThemeId = settings.last_planner_team_id;

    if (localStorage.getItem(lsKeys.LAST_TEAMID_KEY) !== null) return;

    localStorage.setItem(lsKeys.LAST_TEAMID_KEY, lastThemeId);
  }
}

export default UserSettings;

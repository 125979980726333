import React, { useEffect, useState } from "react";
import Api from "../../Api";
import TeambookTextForm from "../default_components/TeambookTextForm";
import TeambookBlueButton from "../default_components/TeambookBlueButton";

const LoginPage = ({ setAdminToken }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const submitLogin = () => {
    Api.Administrators.login({ email: email, password: password }).then(
      (response) => {
        localStorage.setItem("tb-admin-token", response.data.token);
        setAdminToken(response.data.token);
      }
    );
  };
  return (
    <div>
      <TeambookTextForm
        fieldName={"Email"}
        onChange={setEmail}
        fieldValue={email}
        type={"email"}
      />
      <TeambookTextForm
        fieldName={"Password"}
        onChange={setPassword}
        fieldValue={password}
        type={"password"}
      />
      <TeambookBlueButton
        onClick={submitLogin}
        variant="contained"
        text={"Login"}
        style={{
          margin: "10px 0 0 0",
        }}
      />
    </div>
  );
};

export default LoginPage;

import React from "react";
import { isEndOfMonth, OffsetDays, ToDateString } from "../../../lib/DateUtils";

const GenerateMonthRow = (zoom, firstPlannerDate, days, weekendsHidden, weekendStart, width, date, groupClients) => {
  let monthRow = [];

  monthRow.push(
    <div
      key={`filter-cell-month-${firstPlannerDate}`}
      className={"date-row__month-filter-cell"}
      style={{
        minWidth: width || 155,
        borderLeft: groupClients && "none",
      }}
    />
  );

  for (let i = 1; i < days + 1; i++) {
    let newDate = OffsetDays(firstPlannerDate, i - 1);

    if (weekendsHidden && (weekendStart === i % 7 || (weekendStart + 1) % 7 === i % 7)) {
      monthRow.push(
        <div
          style={{
            width: 10,
          }}
          key={`month-row-${ToDateString(newDate)}`}
          className="date-row__month-cell"
        />
      );
    } else {
      monthRow.push(
        <div className={`date-cell-header-${zoom} date-row__month-cell`} key={`month-row-${ToDateString(newDate)}`} />
      );
    }
  }

  return monthRow;
};

export default GenerateMonthRow;

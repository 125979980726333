import React, { useEffect, useState } from "react";
import WeekRow from "./WeekRow";
import DayRow from "./DayRow";
import DataRows from "./DataRows";
import BottomTotal from "./BottomTotal";
import Api from "../../../../../Api";
import { useAccountStore } from "../../../../../stores/accountStore";
import { shallow } from "zustand/shallow";

const ReportingTable = ({ stats, from, sortOrder, setSortOrder, setSlaveDataType, splitBy, projects = [], slaveDataType }) => {
  return (
    <div className="reporting__table-wrapper">
      <div className="reporting__table-content">
        <table className="main-reporting-table">
          <WeekRow
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
            setSlaveDataType={setSlaveDataType}
            splitBy={splitBy}
            slaveDataType={slaveDataType}
            stats={stats}
          />

          <DayRow stats={stats} />

          <DataRows stats={stats} splitBy={splitBy} projects={projects} slaveDataType={slaveDataType} />

          <BottomTotal stats={stats} />
        </table>
      </div>
    </div>
  );
};

export default ReportingTable;

import React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import { useTranslation } from "react-i18next";
import { CentsToDollarString } from "../../../../lib/DataFormat";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { icons } from "../../../default_images/IconsList";

const PRICE_MAPPING = {
  V2_S_Plan: 540 * 100,
  V2_M_Plan: 900 * 100,
  V2_L_Plan: 1080 * 100,
  V2_S_Plan_Yearly: 540 * 100,
  V2_M_Plan_Yearly: 900 * 100,
  V2_L_Plan_Yearly: 1080 * 100,
};

const SubscriptionKindDialog = ({
  finishSubscriptionKindSelect,
  closeWindow,
  plan,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={true}
      onClose={closeWindow}
      aria-labelledby="form-dialog-title"
      className="tb-default-dialog"
    >
      <DialogTitle
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid var(--background-2)",
        }}
      >
        <p style={{ margin: 0 }}>Select Type of Subscription</p>
        <TeambookIcon
          name={icons.CROSS}
          onClick={closeWindow}
          style={{ cursor: "pointer" }}
        />
      </DialogTitle>

      <DialogContent
        style={{ padding: "20px 40px", margin: "20px 0px", width: 450 }}
      >
        <div className="flex" style={{ justifyContent: "space-between" }}>
          <div
            aria-label="subscription annual box"
            className="subscription__annual-box"
            onClick={() => finishSubscriptionKindSelect("yearly")}
          >
            <p>Annual</p>
            <p>
              <b>{CentsToDollarString(PRICE_MAPPING[plan.plan_id])}</b>
            </p>
          </div>

          <div
            aria-label="subscription monthly box"
            className="subscription__annual-box"
            onClick={() => finishSubscriptionKindSelect("monthly")}
          >
            <p>Monthly</p>
            <p>
              <b>{CentsToDollarString(plan.price_cents)}</b>
            </p>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SubscriptionKindDialog;

import React, { useEffect, useState } from "react";
import LoadingComponent from "../../../default_components/LoadingComponent";
import DialogContent from "@mui/material/DialogContent";
import CustomerCreationDialog from "./CustomerCreationDialog";
import "./braintree.scss";
import CreditCardForm from "./CreditCardForm";
import HostedFieldsCreate from "./HostedFieldsCreate";
import { DialogTitle } from "@mui/material";
import clockImage from "../../../default_images/clock.svg";
import lsKeys from "../../../default_values/defaultKeys";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { icons } from "../../../default_images/IconsList";
import { useAccountStore } from "../../../../stores/accountStore";
import { shallow } from "zustand/shallow";

const SubscriptionModal = ({ subscribeRequest, loading, closeModal, selectedPlan, createManualSubscription }) => {
  const isDarkTheme = localStorage.getItem(lsKeys.THEME) === "dark";
  const [account] = useAccountStore((state) => [state.account], shallow);

  const [token, setToken] = useState();
  const [customerCreated, setCustomerCreated] = useState(!!account.braintree_customer_id);
  const [loadingText, setLoadingText] = useState();
  const [errorText, setErrorText] = useState();
  const [userData, setUserData] = useState({});

  useEffect(
    () =>
      HostedFieldsCreate(setToken, selectedPlan, subscribeRequest, setLoadingText, setErrorText, isDarkTheme, userData),
    [customerCreated]
  );

  useEffect(() => {
    setLoadingText(null);

    setTimeout(() => setErrorText(null), 4000);
  }, [errorText]);

  if (loading) {
    return <LoadingComponent />;
  }

  if (!customerCreated) {
    return (
      <CustomerCreationDialog
        closeModal={closeModal}
        setCustomerCreated={setCustomerCreated}
        setUserData={setUserData}
        createManualSubscription={createManualSubscription}
      />
    );
  }

  return (
    <>
      <DialogTitle
        style={{
          borderTopRightRadius: 20,
          borderTopLeftRadius: 20,
          borderBottom: "1px solid var(--background-2)",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        width={400}
      >
        <p style={{ margin: 0 }}>Add a new card</p>
        <TeambookIcon onClick={closeModal} style={{ cursor: "pointer", width: 24, height: 24 }} name={icons.CROSS} />
      </DialogTitle>
      <DialogContent>
        {token ? <CreditCardForm /> : <LoadingComponent />}

        {loadingText && (
          <p className="blink-text">
            {loadingText} <img src={clockImage} />
          </p>
        )}

        {errorText && <p className="error-text">{errorText}</p>}
      </DialogContent>
    </>
  );
};

export default SubscriptionModal;

import React, { useState } from "react";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import TeambookBlueButton from "../../default_components/TeambookBlueButton";
import XLSExport from "../../../lib/XLSExport";
import { CentsToDollarString } from "../../../lib/DataFormat";
import "./vat_report.scss";
import { DateTime } from "luxon";

const VatBottomControlBar = ({ customDate, setCustomDate, range, setRange, from, setFrom, accounts }) => {
  const [quarterPickerOpen, setQuarterPickerOpen] = useState(false);
  const [quarterPickerDate, setQuarterPickerDate] = useState(DateTime.now());

  const quarters = [
    {
      name: "Q1",
      time: DateTime.fromObject({ year: quarterPickerDate.year, month: 1, day: 1 }),
    },
    {
      name: "Q2",
      time: DateTime.fromObject({ year: quarterPickerDate.year, month: 4, day: 1 }),
    },
    {
      name: "Q3",
      time: DateTime.fromObject({ year: quarterPickerDate.year, month: 7, day: 1 }),
    },
    {
      name: "Q4",
      time: DateTime.fromObject({ year: quarterPickerDate.year, month: 10, day: 1 }),
    },
  ];

  return (
    <div className="vat-bottom-control-bar">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Checkbox color="primary" checked={customDate} onChange={(event) => setCustomDate(event.target.checked)} />
        <Select
          value={range}
          onChange={(event) => setRange(event.target.value)}
          menuPlacement="top"
          inputProps={{
            name: "Date Range",
            id: "age-native-simple",
          }}
          variant="outlined"
          className="select-field"
        >
          <MenuItem variant="filled" value={1} key={1}>
            {"Month"}
          </MenuItem>
          <MenuItem variant="filled" value={2} key={2}>
            {"Quarter"}
          </MenuItem>
          <MenuItem variant="filled" value={3} key={3}>
            {"Year"}
          </MenuItem>
        </Select>

        {parseInt(range) === 1 && (
          <DatePicker
            inputVariant="outlined"
            format="MMM yyyy"
            value={from}
            onChange={(date) => setFrom(date)}
            views={["year", "month"]}
            autoOk={true}
            className="dashboard-date-select mui-date-picker"
          />
        )}
        {parseInt(range) === 2 && (
          <div>
            <DatePicker
              inputVariant="outlined"
              format="yyyy, QQQ"
              value={from}
              onChange={(date) => setQuarterPickerDate(date)}
              views={["year"]}
              onYearChange={() => setQuarterPickerOpen(true)}
              autoOk={true}
              className="dashboard-date-select mui-date-picker"
            />
            <Dialog onClose={() => setQuarterPickerOpen(false)} open={quarterPickerOpen}>
              <DialogTitle className="Quarter-dialog-title">{from.year}</DialogTitle>
              <DialogContent className="Quarter-dialog-content">
                {quarters.map((quarter) => (
                  <div
                    key={quarter.name}
                    onClick={() => {
                      setQuarterPickerOpen(false);
                      setFrom(quarter.time);
                    }}
                    className="Quarter-dialog-quarterElement"
                  >
                    {quarter.name}
                  </div>
                ))}
              </DialogContent>
              <DialogActions>
                <TeambookBlueButton
                  onClick={() => {
                    setQuarterPickerOpen(false);
                    setFrom(DateTime.now());
                  }}
                  text={"Close"}
                />
              </DialogActions>
            </Dialog>
          </div>
        )}

        {parseInt(range) === 3 && (
          <DatePicker
            inputVariant="outlined"
            format="yyyy"
            value={from}
            onChange={(date) => setFrom(date)}
            views={["year"]}
            autoOk={true}
            className="dashboard-date-select"
          />
        )}

        <TeambookBlueButton
          style={{ marginLeft: "10px" }}
          onClick={() =>
            XLSExport.ExportVat([
              ...accounts.map((company) => ({
                ...company,
                amount_paid: company.amount_paid,
                vat_amount: company.vat_amount,
              })),
              {
                total_amount_paid: accounts.reduce((previous, current) => previous + current.amount_paid, 0),
                total_vat_amount: accounts.reduce((previous, current) => previous + current.vat_amount, 0),
              },
            ])
          }
          text={"Export Vat"}
        />
      </div>

      <div style={{ border: "1px solid black", padding: "10px" }}>
        <div style={{ display: "flex" }}>
          <p style={{ marginBottom: "0px" }}>
            <b>Total</b>
          </p>
          <div style={{ display: "flex", marginLeft: "30px" }}>
            <p style={{ marginBottom: "0px" }}>Amount paid:</p>
            <b>{CentsToDollarString(accounts.reduce((previous, current) => (previous += current.amount_paid), 0))}</b>
          </div>
          <div style={{ display: "flex", marginLeft: "30px" }}>
            <p style={{ marginBottom: "0px" }}>Vat amount:</p>
            <b>{CentsToDollarString(accounts.reduce((previous, current) => (previous += current.vat_amount), 0))}</b>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VatBottomControlBar;

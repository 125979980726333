import React from "react";
import "./projection.scss";

const ProjectionDayBookings = ({ bookings, isClientSharing, isLessToday }) => {
  return bookings.map((booking, i) => {
    const color = booking.project_color;
    const duration = booking.duration / 60;

    const bookingsStyles = {
      background: booking.tentative ? "var(--background-1)" : color,
      border: booking.tentative && `2px solid ${color}`,
      opacity: booking.tentative ? 1 : 0.7,
      color: booking.tentative ? color : "var(--background-1)",
    };

    return (
      <div
        className="projection__booking"
        style={{
          height: booking.duration / 6 - 1,
          borderTop: i > 0 && "1px solid #FFF",
          opacity: isLessToday && 0.7,
        }}
      >
        {!booking.tentative && (
          <div
            className="projection__booking-line"
            style={{ background: color }}
          />
        )}

        <div className="projection__booking-content" style={bookingsStyles}>
          <div className="projection__booking-title">
            {isClientSharing && duration > 3.5 && (
              <p className="projection__booking-code">{booking.project_code}</p>
            )}
            <p className="projection__booking-time">{duration}h</p>
          </div>
        </div>
      </div>
    );
  });
};

export default ProjectionDayBookings;

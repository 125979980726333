import React from "react";
import { DateTime } from "luxon";
import TeambookBlueButton from "../../default_components/TeambookBlueButton";
import TeambookDatePicker from "../../default_components/teambook_date_selector/DatePicker";

const ControlPanel = ({ date, changeDate, printPlanner }) => {
  return (
    <div className="planner-page__planner-control">
      <div className="planner-page__control-bar-first-line">
        <TeambookDatePicker
          from={date}
          formatStart={"MMM yyyy"}
          onDayClick={(date) => {
            changeDate(DateTime.fromJSDate(date.toDate()).startOf("week"));
          }}
          onArrowClicks={{
            left: () => {
              changeDate(date.minus({ days: 14 }).startOf("week"));
            },
            right: () => {
              changeDate(date.plus({ days: 14 }).startOf("week"));
            },
          }}
          range={1}
          closeOnSelect={true}
          setFrom={changeDate}
          type="default"
          theme="light"
          highlight="today"
          disabledRanges={["1_year"]}
        />

        <TeambookBlueButton
          className="mobile_hidden planner__print-pdf__button"
          onClick={printPlanner}
          variant="contained"
          style={{
            border: "1px solid #4C54A0 !important",
            backgroundColor: "#4C54A0",
          }}
          text={
            <div className="planner__print-pdf__button-text">
              <p>Print PDF</p>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default ControlPanel;

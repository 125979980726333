import { useRef, useCallback, useMemo, useEffect, useState } from "react";
import DateRow from "../default_components/DateRow";
import { useVirtualizer } from "@tanstack/react-virtual";
import { DAY_VIEW_HEIGHT, usersMaxScheduleTime } from "../../../lib/PlannerUtils";
import UserAvatarCell from "../default_components/UserAvatar";
import $ from "jquery";
import { _scrollPlannerToDate } from "../lib/plannerFunctions";
import { DragLayer } from "../default_components/DragLayer";
import { GetDaysInZoom } from "../../../lib/DateUtils";
import { shallow } from "zustand/shallow";
import { useZoomStore } from "../../../stores/planner";

const VirtualizedUsersPlanner = ({
  rows,
  allRowsCreation,
  weekendsHidden,
  weekendStart,
  userOrder,
  changeOrder,
  isSwappingUsers,
  users,
  cellSize,
  overscan,
  creation,
  bookingFormat,
  setUserSwapId,
  userSwapId,
  swapUsersRequest,
  setEditingUser,
  multipleSelect,
}) => {
  const ref = useRef();
  const [zoom] = useZoomStore((state) => [state.zoom], shallow);
  const [isMiddleMouseDown, setIsMiddleMouseDown] = useState(false);
  const startPosition = useRef({
    x: 0,
    y: 0,
  });

  useEffect(() => {
    scrollPlannerToDate();
  }, []);

  useEffect(() => {
    const handleMouseDown = (e) => {
      if (e.button === 1) {
        document.body.classList.add("grabbing-mode");
      }
    };

    const handleMouseUp = () => {
      document.body.classList.remove("grabbing-mode");
    };

    document.addEventListener("mousedown", handleMouseDown);
    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);

  const handleMiddleMouseDown = (e) => {
    if (e.button === 1) {
      setIsMiddleMouseDown(true);
      startPosition.current = {
        x: e.clientX,
        y: e.clientY,
      };
      e.preventDefault();
    }
  };

  const handleMouseMove = (e) => {
    if (isMiddleMouseDown) {
      const deltaX = e.clientX - startPosition.current.x;
      const deltaY = e.clientY - startPosition.current.y;

      ref.current.scrollBy(-deltaX, -deltaY);

      startPosition.current = {
        x: e.clientX,
        y: e.clientY,
      };
    }
  };

  const handleMiddleMouseUp = (e) => {
    if (e.button === 1) {
      setIsMiddleMouseDown(false);
    }
  };

  const scrollPlannerToDate = () => {
    if ($("#planner-scroll-list")?.children?.length) {
      _scrollPlannerToDate(zoom, weekendsHidden);
    }
  };

  const plannerWidth = () => {
    if (zoom === 90) {
      // 120px per week and 7 days per week
      return (GetDaysInZoom(zoom) * 120) / 7;
    }
    if (zoom === 28) {
      if (weekendsHidden) {
        return 4560;
      } else {
        return 6050;
      }
    }
    if (zoom === 14) {
      if (weekendsHidden) {
        return 6480;
      } else {
        return 8820;
      }
    }
  };

  const calculateCellHeight = useCallback((index) => {
    if (zoom === 1) {
      return DAY_VIEW_HEIGHT;
    } else {
      return (usersMaxScheduleTime(users[index]) / 60) * cellSize + 17;
    }
  });

  const avatars = useMemo(() => {
    return users.map((user, index) => (
      <UserAvatarCell
        setEditingUser={setEditingUser}
        isDraggable={true}
        user={users[index]}
        setUserSwapId={setUserSwapId}
        userSwapId={userSwapId}
        swapUsersRequest={swapUsersRequest}
        bookingFormat={bookingFormat}
        showButtons={true}
        setIsSwappingUsers={() => {}}
        key={`user-avatar-cell-${users[index].id}`}
        height={calculateCellHeight(index)}
        isLastUser={users.length - 1 === index}
      />
    ));
  }, [users]);

  const virtualizer = useVirtualizer({
    count: rows.length,
    paddingEnd: 150,
    overscan: overscan,
    getScrollElement: () => ref.current,
    estimateSize: calculateCellHeight,
  });

  return (
    <>
      <div
        className="planner-scroll-list"
        id="planner-scroll-list"
        ref={ref}
        style={{
          width: "100%",
          height: "100%",
          overflow: "auto",
          position: "relative",
          cursor: isMiddleMouseDown ? "grabbing " : "default ",
        }}
        onMouseDown={handleMiddleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMiddleMouseUp}
        onMouseLeave={() => setIsMiddleMouseDown(false)}
      >
        <DateRow
          allRowsCreation={allRowsCreation}
          weekendsHidden={weekendsHidden}
          weekendStart={weekendStart}
          userOrder={userOrder}
          changeOrder={changeOrder}
          isSwappingUsers={isSwappingUsers}
        />

        <div
          style={{
            height: `${virtualizer.getTotalSize()}px`,
            width: plannerWidth(),
            position: "relative",
          }}
          ref={startPosition}
          id="planner__user-row-list"
          className={(zoom === 1 || weekendsHidden) && "planner__user-row-list"}
        >
          <DragLayer calculateCellHeight={calculateCellHeight} bookingFormat={bookingFormat} cellSize={cellSize} />
          {multipleSelect}
          {creation}
          {avatars}
          {virtualizer.getVirtualItems().map(({ index, key }) => rows[index])}
        </div>
      </div>
    </>
  );
};

export default VirtualizedUsersPlanner;

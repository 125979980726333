import React, { useContext, useState, useEffect } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { useTranslation } from "react-i18next";
import TeambookBlueButton from "../../../default_components/TeambookBlueButton";
import CreateUserDialog from "../../modals/CreateUserDialog";
import Api from "../../../../Api";
import { PlannerContext } from "../../context/PlannerContext";
import { TeambookUsersPicker } from "../../../default_components/TeambookDefaultPickers";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { icons } from "../../../default_images/IconsList";
import WarningModal from "../../../default_components/WarningModal";
import { useAccountStore } from "../../../../stores/accountStore";
import { shallow } from "zustand/shallow";

const AddUserGroupedDialog = ({ addUsers, handleClose, project, teamId, teams, calculateFilteredUsers }) => {
  const { t } = useTranslation();

  const { team, bookings } = useContext(PlannerContext);

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [addUserWindowOpen, setAddUserWindowOpen] = useState(false);
  const [submitDisable, setSubmitDisable] = useState(false);
  const profileRole = localStorage.getItem("tb-role") || "regular";
  const [createdUsers, setCreatedUsers] = useState([]);
  const [teamsArray, setTeamsArray] = useState([]);
  const [creatingUserName, setCreatingUserName] = useState("");
  const [closeConfrimationWindowOpened, setCloseConfirmationWindowOpened] = useState(false);
  const [account] = useAccountStore((state) => [state.account], shallow);

  const allTeamUsers = team.team_users;

  //TODO: SAME ISSUE
  // const isChanged = useDataChange(
  //   {
  //     users: [],
  //   },
  //   { users: selectedUsers }
  // );

  const closeCreationWindow = () => {
    // if (isChanged) {
    //   openCloseConfirmationWindow();
    // } else {
    handleClose();
    setSelectedUsers([]);
    // }
  };

  useEffect(() => {
    if (teams) {
      setTeamsArray(Array.from(teams).map((team) => team[1]));
    }
  }, [teams]);

  const bookingsArray = [...bookings];
  let addedUserIds = bookingsArray
    .filter((booking) => booking.project_id === project.id)
    .map((booking) => booking.user_id)
    .filter((value, index, self) => self.indexOf(value) === index);

  let usersForOption = [...allTeamUsers, ...createdUsers].filter((user) => !addedUserIds.includes(user.id));

  async function addUsersToTeam(newUsers) {
    setSubmitDisable(true);

    let userIds = [...newUsers, ...createdUsers].map((user) => {
      return parseInt(user.id);
    });
    await Api.Teams.add_users(teamId, userIds).then((response) => {
      let updatedTeam = response.data;

      let oldTeams = teams;

      oldTeams.set(updatedTeam.id.toString(), updatedTeam);

      calculateFilteredUsers();

      setSelectedUsers([]);
      setSubmitDisable(false);
    });
  }

  const openCloseConfirmationWindow = () => {
    setCloseConfirmationWindowOpened(true);
  };

  const addUserToSelected = (users) => {
    setSelectedUsers(users);
  };

  return (
    <>
      <Dialog
        className="tb-default-dialog"
        open={true}
        onClose={closeCreationWindow}
        aria-labelledby="add-user-dialog"
        maxWidth={"sm"}
      >
        <DialogTitle className="planner-add-user-dialog__dialog-title" id="add-user-dialog">
          <p>{t("planning.add_user_group_text")}</p>
          <TeambookIcon name={icons.CROSS} onClick={closeCreationWindow} />
        </DialogTitle>

        <DialogContent
          className="add-user-dialog__select-container"
          style={{ height: "140px", width: 450, padding: "20px 40px" }}
        >
          {allTeamUsers && (
            <TeambookUsersPicker
              fieldName={t("planning.select_users")}
              isCreatable={true}
              filterSelectedOptions
              value={selectedUsers}
              onChange={(newValue) => {
                addUserToSelected(newValue);
              }}
              defaultValue={selectedUsers}
              onCreateOption={(option) => {
                setCreatingUserName(option);
                setAddUserWindowOpen(!addUserWindowOpen);
              }}
              users={usersForOption.map((user) => ({
                value: user.id,
                id: user.id,
                name: user.name,
              }))}
              type="multiple"
            />
          )}
        </DialogContent>
        <DialogActions>
          <TeambookBlueButton
            onClick={() => {
              addUsersToTeam(createdUsers);
              addUsers([
                ...selectedUsers,
                ...createdUsers.map(({ id, name }) => ({
                  value: id,
                  id: id,
                  name: name,
                })),
              ]);
            }}
            color="primary"
            text={t("save")}
            style={{
              border: "1px solid #4C54A0 !important",
              backgroundColor: "#4C54A0",
            }}
          />
        </DialogActions>
      </Dialog>
      {addUserWindowOpen && (
        <CreateUserDialog
          setCreatedUsers={setCreatedUsers}
          open={addUserWindowOpen}
          setAddUserWindowOpen={setAddUserWindowOpen}
          profileRole={profileRole}
          selectedUsers={selectedUsers}
          createdUsers={createdUsers}
          teams={teamsArray}
          currentTeam={teams.get(teamId)}
          creatingUserName={creatingUserName}
          setCreatingUserName={setCreatingUserName}
        />
      )}

      {closeConfrimationWindowOpened && (
        <WarningModal
          onNo={() => {
            handleClose();
            setSelectedUsers([]);
            setCloseConfirmationWindowOpened(false);
          }}
          onYes={() => {
            addUsersToTeam(createdUsers);
            addUsers([
              ...selectedUsers,
              ...createdUsers.map(({ id, name }) => ({
                value: id,
                id: id,
                name: name,
              })),
            ]);
            handleClose();
            setSelectedUsers([]);
            setCloseConfirmationWindowOpened(false);
          }}
          closeModal={() => setCloseConfirmationWindowOpened(false)}
        />
      )}
    </>
  );
};

export default AddUserGroupedDialog;

import React, { useEffect, useState } from "react";
import Api from "../../Api";
import "./sandbox.scss";
import loadingGif from "../default_images/loading.gif";
import { useParams } from "react-router-dom";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

const GenerateSandbox = () => {
  const { language } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    localStorage.setItem(localStorage.getItem("tb-lang"), language || "en");
    i18n.changeLanguage(language || "en");

    Api.Sandbox.generate(language || "en").then(() => {
      window.location.replace(`/planners`);
    });
  }, []);

  return (
    <div className="sandbox-generation">
      <div className="help-text">
        <img src={loadingGif} width={"100px"} />

        <p style={{ marginTop: "20px" }}>{t("settings.sandbox.creating")}</p>
        <p>({t("settings.sandbox.takes_up_to")})</p>
      </div>
    </div>
  );
};

export default GenerateSandbox;

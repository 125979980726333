import { WhiteTooltip } from "../../../default_components/Tooltips";
import IconButton from "@mui/material/IconButton";
import React from "react";
import AddBoxIcon from "@mui/icons-material/AddBox";
import UsersProfileImage from "../../../default_components/UsersProfileImage";
import { useTranslation } from "react-i18next";

const DeactivatedUserListBlock = (props) => {
  const { t } = useTranslation();

  return (
    <div
      onClick={() => props.showUser(props.user)}
      className={`users-page__user-element ${props.chosen_user === props.user ? t("users.invited") : ""}`}
    >
      <div className={"users-block__image-and-content1"}>
        <UsersProfileImage size={40} user={props.user} />
        <div className={"users-block__content"}>
          <p>{`${props.user.first_name} ${props.user.last_name}`}</p>
          <p className={`user-block__status`}>{`${props.user.invited === true ? t("users.invited") : ""}`}</p>
        </div>
      </div>

      <div className="users-block__tags">
        {props.user.tags.map((tag, key) => (
          <div key={tag.id} className="users-block__tags__name" style={{ backgroundColor: tag.color }}>
            {tag.name}
          </div>
        ))}
      </div>

      <div className="users-block__buttons">
        <WhiteTooltip title={t("users.reactivate")} placement="right">
          <IconButton id={"reactivateUser"} onClick={() => props.activateUserRequest(props.user.id)}>
            <AddBoxIcon />
          </IconButton>
        </WhiteTooltip>
      </div>
    </div>
  );
};

export default DeactivatedUserListBlock;

import React, { useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import { useTranslation } from "react-i18next";
import DialogActions from "@mui/material/DialogActions";
import TeambookBlueButton from "../../default_components/TeambookBlueButton";
import Api from "../../../Api";
import TeambookRadioButton from "../../default_components/TeambookRadioButton";
import { TeambookTeamsPicker, TeambookUsersPicker } from "../../default_components/TeambookDefaultPickers";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";
import MuiDatePicker from "../../default_components/MuiDatePicker";
import { DateTime } from "luxon";

const CopyPlannerDialog = ({ setDialogOpened, date, users, teams, updateReservations, user, dialogType = "total" }) => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(date.startOf("month"));
  const [endDate, setEndDate] = useState(date.plus({ month: 6 }).endOf("month"));
  const [copyDateSetting, setCopyDateSetting] = useState("year");
  const [kind, setKind] = useState("inject");
  const [copyType, setCopyType] = useState("users");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);

  const copyBookings = () => {
    Api.CapacityReservations.copy_planner({
      user_ids: dialogType === "individual" ? [user.id] : selectedUsers,
      team_ids: selectedTeams,
      start_date: copyDateSetting === "year" ? DateTime.now().startOf("month") : startDate,
      end_date: copyDateSetting === "year" ? DateTime.now().plus({ month: 11 }).endOf("month") : endDate,
      kind: kind,
    }).then(() => {
      updateReservations(users);
      setDialogOpened(false);
    });
  };

  const closeModal = () => {
    setDialogOpened(false);
  };

  return (
    <Dialog open={true} onClose={closeModal} aria-labelledby="form-dialog-title" className="tb-default-dialog">
      <DialogTitle>
        <p>{t("planning.copy_planner_bookings")}</p>
        <TeambookIcon name={icons.CROSS} onClick={closeModal} />
      </DialogTitle>

      <DialogContent style={{ padding: "20px 40px" }}>
        <div className="copy-planner-subblock">
          <p>Date Settings:</p>

          <div className="copy-planner__setting">
            <TeambookRadioButton checked={copyDateSetting === "year"} onCheck={() => setCopyDateSetting("year")} />
            <p onClick={() => setCopyDateSetting("year")}>Next 12 month</p>
          </div>

          <div className="copy-planner__setting">
            <TeambookRadioButton checked={copyDateSetting === "date_range"} onCheck={() => setCopyDateSetting("date_range")} />
            <p onClick={() => setCopyDateSetting("date_range")}>Custom date interval</p>
          </div>

          {copyDateSetting === "date_range" && (
            <div style={{ marginLeft: "30px" }}>
              <div style={{ display: "flex", gap: "10px" }}>
                <p className="date-selector">Start Month</p>

                <MuiDatePicker
                  variant="inline"
                  format={"MMM, yyyy"}
                  value={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                    setEndDate(date.plus({ month: 6 }).endOf("month"));
                  }}
                  sx={{ width: "130px", input: { padding: "0px" } }}
                  className="mui-date-picker"
                  views={["month", "year"]}
                />
              </div>

              <div style={{ display: "flex", gap: "10px" }}>
                <p className="date-selector">End Month</p>

                <MuiDatePicker
                  variant="inline"
                  format={"MMM, yyyy"}
                  value={endDate}
                  onChange={(date) => {
                    setEndDate(date.endOf("month"));
                  }}
                  sx={{ width: "130px", input: { padding: "0px" } }}
                  className="mui-date-picker"
                  views={["month", "year"]}
                />
              </div>
            </div>
          )}
        </div>

        {dialogType === "total" && (
          <div className="copy-planner-subblock">
            <p>{t("planning.select_data")}:</p>

            <div style={{ display: "flex", gap: "10px" }}>
              <TeambookRadioButton
                checked={copyType === "users"}
                onCheck={() => {
                  setCopyType("users");
                  setSelectedTeams([]);
                }}
              />

              <TeambookUsersPicker
                displayEmpty={true}
                type="multiple"
                className="copy-planner-select"
                onChange={(options, newValue) => {
                  setCopyType("users");
                  setSelectedTeams([]);
                  setSelectedUsers(options.map((o) => o.id));
                }}
                placeholder={t("planning.select_users")}
                maxMenuHeight={150}
                users={users}
                value={selectedUsers.map((id) => ({
                  name: users.filter((u) => u.id === id)[0].name,
                  id: id,
                }))}
                width={470}
              />
            </div>

            <div style={{ display: "flex", gap: "10px" }}>
              <TeambookRadioButton
                checked={copyType === "teams"}
                onCheck={() => {
                  setCopyType("teams");
                  setSelectedUsers([]);
                }}
              />

              <TeambookTeamsPicker
                placeholder={t("planning.select_teams")}
                maxMenuHeight={150}
                className="copy-planner-select"
                type="multiple"
                teams={teams}
                onChange={(options, newValue) => {
                  setCopyType("teams");
                  setSelectedUsers([]);
                  setSelectedTeams(options.map(({ id }) => id));
                }}
                value={selectedTeams.map((id) => ({
                  name: teams.filter((t) => t.id === id)[0].name,
                  id: id,
                }))}
                width={470}
              />
            </div>
          </div>
        )}

        <div className="copy-planner-subblock">
          <p style={{ fontSize: 14 }}>{t("planning.copy_settings")}:</p>

          <div className="copy-planner__setting">
            <TeambookRadioButton checked={kind === "inject"} onCheck={() => setKind("inject")} />
            <p onClick={() => setKind("inject")}> {t("planning.overwrite_by_planner")}</p>
          </div>

          <div className="copy-planner__setting">
            <TeambookRadioButton checked={kind === "add"} onCheck={() => setKind("add")} />
            <p onClick={() => setKind("add")}> {t("planning.capacity_data_top")}</p>
          </div>
        </div>
      </DialogContent>

      <DialogActions>
        <TeambookBlueButton text={"Save"} onClick={copyBookings} />
      </DialogActions>
    </Dialog>
  );
};

export default CopyPlannerDialog;

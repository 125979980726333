import React from "react";
import { DateTime } from "luxon";

export default function SignOffsStatistics({ stats }) {
  const WIDTH = "20%";

  return (
    <table
      style={{
        width: "99.5%",
        margin: "70px 5px",
        textAlign: "left",
      }}
      border="1px solid black"
    >
      <tr style={{ textAlign: "center" }}>
        <th style={{ width: WIDTH, padding: 10 }}>Date</th>
        <th style={{ width: WIDTH, padding: 10 }}>Account Id/Email</th>
        <th style={{ width: WIDTH, padding: 10 }}>First Name/Language</th>
        <th style={{ width: WIDTH, padding: 10 }}>Action</th>
        <th style={{ width: WIDTH, padding: 10 }}>Comment</th>
      </tr>

      {stats.map((stat) => {
        return (
          <tr>
            <td style={{ width: WIDTH, padding: 10 }}>{DateTime.fromISO(stat.date).toFormat("dd/MM/yyyy")}</td>
            <td style={{ width: WIDTH, padding: 10 }}>
              {stat.account_id} / {stat.user_email}
            </td>
            <td style={{ width: WIDTH, padding: 10 }}>
              {stat.first_name} / {stat.language}
            </td>
            <td style={{ width: WIDTH, padding: 10 }}>{stat.action_type}</td>
            <td style={{ width: WIDTH, padding: 10 }}>{stat.comment}</td>
          </tr>
        );
      })}
    </table>
  );
}

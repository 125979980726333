import React, { useEffect, useState } from "react";
import "moment/locale/de";
import "moment/locale/fr";
import "moment/locale/es";
import Api from "../../../Api";
import UserRow from "./UserRow";
import DateRow from "./DateRow";
import tbLogo from "../../default_images/logo.svg";

const PlannerToExport = ({ date, teamId }) => {
  const [bookings, setBookings] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (date && teamId) {
      Api.Teams.get_users(teamId).then((response) => {
        setUsers(response.data);

        Api.Bookings.get(
          response.data.map((user) => user.id),
          date.toISODate(),
          date.plus({ week: 4 }).toISODate()
        ).then((response) => {
          setBookings(response.data);
        });
      });
    }
  }, [date, teamId]);

  return (
    <div className="pdf-planner">
      <DateRow date={date} />

      {users.map((user, index) => (
        <UserRow
          user={user}
          bookings={bookings.filter((booking) => booking.user_id === user.id)}
          date={date}
          index={index}
        />
      ))}

      <img
        src={tbLogo}
        style={{ position: "absolute", bottom: -40, left: 15 }}
        alt="logo"
      />
    </div>
  );
};

export default PlannerToExport;

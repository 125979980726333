import React, { useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, Slider } from "@mui/material";
import { useTranslation } from "react-i18next";
import Switch from "@mui/material/Switch";
import DaysSelect from "../default_components/DaysSelect";
import lsKeys from "../../default_values/defaultKeys";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";

const BOOKING_FORMAT = "bookingFormat";

const SettingsDialog = ({
  open,
  closeWindow,
  weekendsHidden,
  changeWeekendsHidden,
  disableTooltip,
  changeTooltipDisabling,
  bookingFormat,
  setBookingFormat,
  teamId,
  hideOtherUsers,
  setHideOtherUsers,
  groupClients,
  setGroupClients,
  changeDate,
}) => {
  const { t } = useTranslation();

  const plannerType = localStorage.getItem(lsKeys.LAST_PLANNER_TYPE);
  const language = localStorage.getItem(lsKeys.LANGUAGE);

  useEffect(() => {
    const savedFormat = parseInt(localStorage.getItem(BOOKING_FORMAT) || 2);
    setBookingFormat(savedFormat);
  }, [setBookingFormat]);

  const marks = [
    {
      value: 0,
      label: t("planning.small"),
    },
    {
      value: 1,
      label: t("planning.limited"),
    },
    {
      value: 2,
      label: t("planning.normal"),
    },
  ];

  return (
    <Dialog className="tb-default-dialog" open={open || false} onClose={() => closeWindow()}>
      <DialogTitle>
        <p style={{ margin: "auto 0" }}>{t("planning.control_panel.visual_settings")}</p>

        <TeambookIcon name={icons.CROSS} onClick={() => closeWindow()} />
      </DialogTitle>
      <DialogContent language={language} className="planner-settings-dialog__content users_planner-settings">
        <div className="planner-settings-dialog__setting">
          <p className="planner-settings-dialog__setting-text">{t("planning.hide_weekends")}</p>
          <div className="planner-settings-dialog__setting-control">
            <Switch
              id={"hideWeekends"}
              checked={!weekendsHidden}
              onChange={(event) => {
                changeWeekendsHidden(!event.target.checked);
              }}
              color="primary"
              name="checkedB"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </div>
        </div>

        <div className="planner-settings-dialog__setting">
          <p className="planner-settings-dialog__setting-text">{t("planning.disable_tooltip")}</p>
          <div className="planner-settings-dialog__setting-control">
            <Switch
              id={"disableTooltip"}
              checked={disableTooltip}
              onChange={(event) => {
                changeTooltipDisabling(event.target.checked);
              }}
              color="primary"
              name="checkedB"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </div>
        </div>

        {plannerType === "planners" && (
          <div className="planner-settings-dialog__setting">
            <p className="planner-settings-dialog__setting-text">{t("planning.hide_others")}</p>
            <div className="planner-settings-dialog__setting-control">
              <Switch
                id={"hideOtherUsers"}
                checked={hideOtherUsers}
                onChange={(event) => {
                  if (event.target.checked) {
                    localStorage.setItem(lsKeys.PLANNER_HIDE_OTHERS, "true");
                  } else {
                    localStorage.setItem(lsKeys.PLANNER_HIDE_OTHERS, "false");
                  }

                  setHideOtherUsers(event.target.checked);
                }}
                color="primary"
                name="checkedB"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </div>
          </div>
        )}

        {plannerType === "grouped_planners" && (
          <div className="planner-settings-dialog__setting">
            <p className="planner-settings-dialog__setting-text">{t("planning.group_clients")}</p>
            <div className="planner-settings-dialog__setting-control">
              <Switch
                id="group-clients"
                checked={groupClients}
                onChange={(event) => {
                  if (event.target.checked) {
                    localStorage.setItem(lsKeys.GROUP_CLIENTS_PLANNER, "true");
                  } else {
                    localStorage.setItem(lsKeys.GROUP_CLIENTS_PLANNER, "false");
                  }

                  setGroupClients(event.target.checked);
                }}
                color="primary"
                name="checkedB"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </div>
          </div>
        )}

        <div className="planner-settings-dialog__setting">
          <p className="planner-settings-dialog__setting-text">{t("planning.cells_size")}</p>

          <div
            className="planner-settings-dialog__setting-control"
            style={{ width: "220px", alignItems: "center", margin: "25px 7px 0px 0px" }}
          >
            <Slider
              id={"cellsSize"}
              className={`planner-settings-dialog__slider ${language}`}
              aria-label="Restricted values"
              defaultValue={2}
              step={null}
              marks={marks}
              max={2}
              track={false}
              value={parseInt(bookingFormat)}
              onChange={(e, format) => {
                setBookingFormat(format);
                localStorage.setItem(BOOKING_FORMAT, format);
              }}
              valueLabelFormat={() => <div>{marks[bookingFormat].label}</div>}
              sx={{
                "& .MuiSlider-markLabel": {
                  opacity: 0.6,
                },
                [`& .MuiSlider-markLabel[data-index="${bookingFormat}"]`]: {
                  opacity: 1,
                },
              }}
            />
          </div>
        </div>

        <div className="planner-settings-dialog__setting">
          <p className="planner-settings-dialog__setting-text">{t("planning.planner_zoom")}</p>

          <div
            className="planner-settings-dialog__setting-control"
            style={{ width: "220px", alignItems: "center", margin: "25px 7px 0px 0px" }}
          >
            <DaysSelect plannerType={plannerType} teamId={teamId} changeDate={changeDate} />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SettingsDialog;

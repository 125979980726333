import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { EditorState, ContentState, convertFromHTML, RichUtils } from "draft-js";
import Editor from "@draft-js-plugins/editor";
import { convertToHTML } from "draft-convert";
import createLinkifyPlugin from "@draft-js-plugins/linkify";
import "./RichTextEditor.css";
import { WhiteTooltip } from "../Tooltips";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";

const linkifyPlugin = createLinkifyPlugin({
  component: (props) => {
    const { contentState, ...rest } = props;
    return (
      <a
        {...rest}
        style={{ cursor: "pointer" }}
        onClick={() => {
          window.open(rest.href, "_blank");
        }}
      />
    );
  },
});

const calculateState = (val) => {
  const blocksFromHTML = convertFromHTML(val);
  const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
  return state;
};

const RichTextEditor = forwardRef(({ value = "<p></p>", onChange = () => {}, editorHeight }, ref) => {
  const [editorReadOnly, setEditorReadOnly] = useState(true);
  const [editorState, setEditorState] = useState();

  useEffect(() => {
    setEditorState(EditorState.createWithContent(calculateState(value)));
  }, [value]);

  useEffect(() => {
    if (editorState) {
      onChange(convertToHTML(editorState.getCurrentContent()));
    }
  }, [editorState]);

  useImperativeHandle(ref, () => ({
    getComment: () => {
      return convertToHTML(editorState.getCurrentContent());
    },
  }));

  const _onBoldClick = (e) => {
    e.preventDefault();
    setEditorState(RichUtils.toggleInlineStyle(editorState, "BOLD"));
  };

  const _onUnderlineClick = (e) => {
    e.preventDefault();
    setEditorState(RichUtils.toggleInlineStyle(editorState, "UNDERLINE"));
  };

  const _onItalicClick = (e) => {
    e.preventDefault();
    setEditorState(RichUtils.toggleInlineStyle(editorState, "ITALIC"));
  };

  // useEffect(() => {
  //   const html = convertToHTML(editorState.getCurrentContent());
  //   onChange(html);
  // }, [editorState]);

  return (
    editorState && (
      <div className="rich-text__container">
        <div className="draft-js-editor__controls rich-text__controls">
          <WhiteTooltip placement="top" title={"Bold"} key={`tooltip-bold`}>
            <div
              className="rich-text__button"
              onMouseDown={_onBoldClick}
              style={{
                background: editorState.getCurrentInlineStyle().has("BOLD") ? "var(--stroke)" : "var(--background-2)",
              }}
            >
              <TeambookIcon color="onboarding_icon" name={icons.BOLD} />
            </div>
          </WhiteTooltip>
          <WhiteTooltip placement="top" title={"Underline"} key={`tooltip-underline`}>
            <div
              className="rich-text__button"
              onMouseDown={_onUnderlineClick}
              style={{
                background: editorState.getCurrentInlineStyle().has("UNDERLINE")
                  ? "var(--stroke)"
                  : "var(--background-2)",
              }}
            >
              <TeambookIcon color="onboarding_icon" name={icons.UNDERLINE} />
            </div>
          </WhiteTooltip>
          <WhiteTooltip placement="top" title={"Italic"} key={`tooltip-italic`}>
            <div
              className="rich-text__button"
              onMouseDown={_onItalicClick}
              style={{
                background: editorState.getCurrentInlineStyle().has("ITALIC") ? "var(--stroke)" : "var(--background-2)",
              }}
            >
              <i className="draft-js-editor__control">I</i>
            </div>
          </WhiteTooltip>
        </div>
        <div
          className="editor-section"
          onMouseDown={() => setEditorReadOnly(false)}
          style={{ height: editorHeight + "px" || "unset" }}
        >
          <Editor
            ref={ref}
            placeholder="Notes"
            readOnly={editorReadOnly}
            editorState={editorState}
            onChange={setEditorState}
            plugins={[linkifyPlugin]}
          />
        </div>
      </div>
    )
  );
});

export default RichTextEditor;

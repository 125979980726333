const SliceStringWithDots = (string, length) => {
  if (!string) {
    return string;
  }

  if (string.length > length) {
    return `${string.slice(0, length - 2)}...`;
  } else {
    return string;
  }
};

const ColorForBackground = (background) => {
  let blackColors = [
    "#FF9D54",
    "#FFCA42",
    "#FFE373",
    "#84D68A",
    "#86D7C6",
    "#52CBD9",
    "#81A4D5",
    "#4393EF",
    "#9B67CC",
    "#F77CDA",
    "#D96F6A",
    "#A38A83",
  ];

  return blackColors.includes(background) ? "black" : "white";
};

const compareStrings = (a, b) => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};

export { SliceStringWithDots, ColorForBackground, compareStrings };

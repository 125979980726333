import {
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import FormErrorMessage from "../../default_components/FormErrorMessage";
import TeambookBorderlessBlueButton from "../../default_components/TeambookBorderlessBlueButton";
import TeambookOutlinedButton from "../../default_components/TeambookOutlinedButton";
import TeambookTextForm from "../../default_components/TeambookTextForm";
import DangerPic from "../../default_images/danger_deletion.svg";

const DeleteTeamQuestionDialog = ({
  open,
  deleteTeam,
  deletingTeam,
  setOpen,
  teams,
}) => {
  const { t } = useTranslation();

  const [name, setName] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);

  const DeleteTeam = (team, name) => {
    if (team.name === name && teams.size !== 1) {
      closeErrorMessage();
      deleteTeam(team.id);
      closeDialog();
      closeConfirmDialog();
    } else {
      if (teams.size === 1) {
        setErrorMessage("You can't remove last team.");
      } else {
        setErrorMessage(t("planning.delete_team_error"));
      }
    }
  };

  const closeErrorMessage = () => {
    setErrorMessage(null);
  };

  const closeDialog = () => {
    setName("");
    closeErrorMessage();
    setOpen(false);
  };

  const closeConfirmDialog = () => {
    closeErrorMessage();
  };

  return (
    <>
      <Dialog className="tb-default-dialog" open={open} onClose={closeDialog}>
        <DialogTitle
          style={{
            border: "unset",
            justifyContent: "center",
            flexDirection: "column",
            gap: 15,
          }}
        >
          <img
            style={{ width: 50, height: 50 }}
            src={DangerPic}
            onClick={closeDialog}
          />
          <p style={{ textAlign: "center" }}>
            {t("planning.delete_team_1")}
            <span style={{ color: "#4c54a0" }}>{deletingTeam.name}</span>?
          </p>
        </DialogTitle>
        <DialogContent style={{ width: 600, margin: "15px 0px" }}>
          <p style={{ fontSize: 18 }}>{t("planning.delete_team_2")}</p>

          <p>
            {t("planning.delete_team_4")}
            <span style={{ color: "#4c54a0" }}>{deletingTeam.name}</span>.
          </p>

          <TeambookTextForm
            fieldName={t("planning.team_name")}
            fieldValue={name}
            type={"text"}
            width="280px"
            onChange={setName}
          />

          {errorMessage && (
            <FormErrorMessage
              text={errorMessage}
              closeErrorMessage={closeErrorMessage}
            />
          )}
        </DialogContent>
        <DialogActions style={{ gap: 30 }}>
          <TeambookBorderlessBlueButton
            color="var(--error)"
            text={t("planning.delete_team_3")}
            onClick={() => {
              DeleteTeam(deletingTeam, name);
            }}
          />
          <TeambookOutlinedButton
            text={t("cancel")}
            onClick={closeDialog}
            color="primary"
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteTeamQuestionDialog;

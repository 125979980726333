const CentsToDollarString = (cents) => {
  let result = cents / 100;

  if (result % 1 === 0) {
    return `$${result}.00`;
  } else {
    return `$${result.toFixed(2)}`;
  }
};

export { CentsToDollarString };

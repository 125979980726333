import React from "react";
import { useTranslation } from "react-i18next";
import { useBookingsCreatingStore, useBookingsEditingStore } from "../../../stores/planner";
import { shallow } from "zustand/shallow";
import TeambookBlueButton from "../../default_components/TeambookBlueButton.js";
import lsKeys from "../../default_values/defaultKeys";
import VideoComponent from "./VideoComponent";
import onboardingData from "./onboardingData";

const OnboardingComponent = ({ page, setOnboardingOpened, onboardingOpened, role }) => {
  const { t } = useTranslation();

  const [createWindowOpened] = useBookingsCreatingStore((state) => [state.createWindowOpened], shallow);

  const [editWindowOpened] = useBookingsEditingStore((state) => [state.editWindowOpened], shallow);

  const content = () => {
    let array = [];

    let hash = ["admin", "planner"].includes(role) ? onboardingData(t).admin[page] : onboardingData(t).regular[page];

    array.push(
      <VideoComponent
        header={t("planning.onboarding.video_title")}
        content={t("planning.onboarding.video_description")}
        videoUrl={`https://www.youtube.com/embed/${t("planning.onboarding.video_code")}?cc_load_policy=1`}
        type="general"
      />
    );

    if (page === "actuals") {
      const header = role === "admin" ? t("planning.onboarding.header_11") : t("planning.onboarding.header_16");
      const content = role === "admin" ? t("planning.onboarding.content_11") : t("planning.onboarding.content_16");

      array.push(
        <VideoComponent
          header={header}
          content={content}
          videoUrl={`https://www.youtube.com/embed/${t("planning.onboarding.actuals_video_code")}?cc_load_policy=1`}
          type="actuals"
        />
      );
    }

    if (page === "capacity") {
      const header = role === "admin" ? t("planning.onboarding.header_12") : t("planning.onboarding.header_15");
      const content = role === "admin" ? t("planning.onboarding.content_12") : t("planning.onboarding.content_15");

      array.push(
        <VideoComponent
          header={header}
          content={content}
          videoUrl={`https://www.youtube.com/embed/${t("planning.onboarding.capacity_video_code")}?cc_load_policy=1`}
          type="capacity"
        />
      );
    }

    hash.forEach((element, index) => {
      array.push(
        <div className="point" key={index}>
          <div style={{ marginLeft: "10px" }}>
            {element.url ? (
              <a className="point__header" href={element.url} target={element.new_tab ? "_blank" : ""}>
                {element.icon} {element.header}
              </a>
            ) : (
              <p className="point__header-without-link">
                {element.icon} {element.header}
              </p>
            )}

            <p className="point__text" style={{ display: "flex", flexDirection: "column" }}>
              {element.content}
            </p>
          </div>
        </div>
      );
    });

    return array;
  };
  const isCapacityPlanning = page === "capacity";

  if (onboardingOpened && !createWindowOpened && !editWindowOpened) {
    return (
      <div style={{ top: 120 }} className="onboarding">
        <p className="onboarding__header">
          {isCapacityPlanning ? t("planning.onboarding.header_capacity") : t("planning.onboarding.header")}
        </p>

        <div className="onboarding__content">{content()}</div>

        <div className="onboarding__footer">
          <TeambookBlueButton
            onClick={() => {
              setOnboardingOpened(false);

              switch (page) {
                case "capacity":
                  localStorage.setItem(lsKeys.CAPACITY_ONBOARDING_OPENED, "false");
                  return;
                case "actuals":
                  localStorage.setItem(lsKeys.ACTUALS_ONBOARDING_OPENED, "false");
                  return;
                default:
                  localStorage.setItem(lsKeys.ONBOARDING_OPENED, "false");
                  return;
              }
            }}
            variant="contained"
            style={{ marginRight: "15px" }}
            text={t("got_it")}
          />
        </div>
      </div>
    );
  } else {
    return <div />;
  }
};

export default OnboardingComponent;

import Api from "../../../Api";

async function GetActiveProjects(setActiveProjects) {
  await Api.Projects.active()
    .then((response) => {
      setActiveProjects(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
}

export default GetActiveProjects;

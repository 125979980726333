import React from "react";
import "./projection.scss";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { TeambookIcon } from "../default_images/TeambookIcon";
import { icons } from "../default_images/IconsList";
import TeambookDatePicker from "../default_components/teambook_date_selector/DatePicker";

const ProjectionControlRow = ({ currentDate, changeDate }) => {
  const { t } = useTranslation();

  const clickOnToday = () => {
    changeDate(DateTime.now().minus({ week: 5 }).startOf("week"));
  };

  return (
    <div className="projection__control-row">
      <TeambookDatePicker
        from={currentDate.plus({ week: 5 })}
        formatStart={"MMM yyyy"}
        onDayClick={(date) => {
          changeDate(date.startOf("week").minus({ week: 5 }));
        }}
        onArrowClicks={{
          left: () => {
            changeDate(currentDate.minus({ days: 14 }));
          },
          right: () => {
            changeDate(currentDate.plus({ days: 14 }));
          },
        }}
        range={1}
        closeOnSelect={true}
        setFrom={changeDate}
        type="default"
        highlight="today"
        disabledRanges={["1_year"]}
      />

      <div className="projection__today-button" onClick={clickOnToday}>
        <TeambookIcon name={icons.TODAY} color="alternative_default" tooltipTitle={t("today")} />
      </div>
    </div>
  );
};

export default ProjectionControlRow;

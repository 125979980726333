const USER_FILTERS = (t) => [t("planning.filter_teams"), t("planning.filter_users"), t("planning.filter_tags")];
const PROJECT_FILTERS = (t) => [
  t("planning.filter_projects"),
  t("planning.filter_clients"),
  t("projects.manager"),
  t("projects.status"),
  t("projects.business_unit"),
];
const TAG_FILTERS = (t) => [t("planning.filter_tags")];

export const calculateUsersFilters = (users, tags, filterValues, filterType, t) => {
  if (filterValues.length === 0) {
    return users;
  }

  const userArray = [];
  let getProjectsTag = null;
  const allowedFilters = filterValues.filter((filter) => USER_FILTERS(t).includes(filter.type));

  if (allowedFilters.length === 0) {
    return users;
  }

  if (filterType === "Or") {
    allowedFilters.map((values) => {
      if (values.type === t("planning.filter_users")) {
        const newUsers = users.filter((user) => user.id === values.id);

        userArray.push(newUsers);
      }

      if (values.type === t("planning.filter_teams")) {
        const newUsers = users.filter((user) => user.team_ids.includes(values.id));

        userArray.push(newUsers);
      }

      if (values.type === t("planning.filter_tags")) {
        const filterTags = tags.filter((item) => {
          return item.id === values.id;
        });

        getProjectsTag = filterTags.map((item) => item.name);
      }

      if (getProjectsTag !== null) {
        const filterTags = tags.filter((item) => {
          return item.name === getProjectsTag.toString();
        });

        const newUsers = users.filter(function (item) {
          const usersIds = filterTags.map((item) => item.tag_users);
          return usersIds.flat().includes(item.id);
        });

        userArray.push(newUsers);
      }
    });

    const finalUsers = userArray.flat().filter((thing, index, self) => {
      return index === self.findIndex((t) => t.id === thing.id);
    });

    return finalUsers;
  } else {
    let filterData = [];
    let getUsers = null;

    allowedFilters.map((values) => {
      if (values.type === t("planning.filter_users")) {
        const newUsers = users.filter(function (item) {
          return `${item.first_name} ${item.last_name}` === values.name;
        });

        if (filterData[0] === undefined) {
          filterData[0] = [];
        }

        filterData[0].push(...newUsers.flat());
      }

      if (values.type === t("planning.filter_teams")) {
        const newUsers = users.filter((user) => user.team_ids.includes(values.id));

        if (filterData[1] === undefined) {
          filterData[1] = [];
        }

        filterData[1].push(...newUsers);
      }

      if (values.type === t("planning.filter_tags")) {
        getUsers = null;
        const filterTags = tags.filter((item) => {
          return item.name === values.name;
        });

        getUsers = filterTags.map((tag) => tag.tag_users).flat();
      }

      if (getUsers !== null) {
        const newUsers = users.filter((user) => getUsers.includes(user.id));

        if (filterData[2] === undefined) {
          filterData[2] = newUsers.flat();
        } else {
          filterData[2] = filterData[2].filter((users) => newUsers.includes(users));
        }
      }
    });

    if (filterData.length > 0) {
      const finalUsers = filterData.filter((n) => n)[0].filter((user) => filterData.every((array) => array.includes(user)));

      return finalUsers;
    } else {
      return [];
    }
  }
};

export const calculateProjectsFilters = (projects, clients, filterValues, filterType, t) => {
  if (filterValues.length === 0) {
    return projects;
  }

  const projectArray = [];
  let filteredClients = null;
  const allowedFilters = filterValues.filter((filter) => PROJECT_FILTERS(t).includes(filter.type));

  if (allowedFilters.length === 0) {
    return projects;
  }

  if (filterType === "Or") {
    allowedFilters.map((values) => {
      if (values.type === t("planning.filter_projects")) {
        const newUsers = projects.filter((pr) => pr.id === values.id);

        projectArray.push(newUsers);
      }

      if (values.type === t("planning.filter_clients")) {
        filteredClients = clients.filter((item) => {
          return item.id === values.id;
        });
      }

      if (filteredClients !== null) {
        const newProjects = projects.filter((project) => {
          const clientIds = filteredClients.map((item) => item.id);

          return clientIds.flat().includes(project.client_id);
        });

        projectArray.push(newProjects);
      }

      if (values.type === t("projects.manager")) {
        const newUsers = projects.filter((pr) => pr.manager_id === values.id);

        projectArray.push(newUsers);
      }

      if (values.type === t("projects.status")) {
        const newUsers = projects.filter((pr) => pr.status === values.name);

        projectArray.push(newUsers);
      }

      if (values.type === t("projects.business_unit")) {
        const newUsers = projects.filter((pr) => pr.business_unit === values.name);

        projectArray.push(newUsers);
      }
    });

    const finalProjects = projectArray.flat().filter((thing, index, self) => {
      return index === self.findIndex((t) => t.id === thing.id);
    });

    return finalProjects;
  } else {
    let filterData = [];
    let getProjects = null;

    allowedFilters.map((values) => {
      if (values.type === t("planning.filter_projects")) {
        const newUsers = projects.filter((pr) => pr.id === values.id);

        if (filterData[0] === undefined) {
          filterData[0] = [];
        }

        filterData[0].push(...newUsers.flat());
      }

      if (values.type === t("planning.filter_clients")) {
        getProjects = null;
        const filterClients = clients.filter((item) => {
          return item.name === values.name;
        });

        getProjects = projects.filter((project) => {
          const clientIds = filterClients.map((item) => item.id);

          return clientIds.flat().includes(project.client_id);
        });
      }

      if (getProjects !== null) {
        if (filterData[2] === undefined) {
          filterData[2] = getProjects.flat();
        } else {
          filterData[2] = filterData[2].filter((project) => getProjects.includes(project));
        }
      }

      if (values.type === t("projects.manager")) {
        const newUsers = projects.filter((pr) => pr.manager_id === values.id);

        if (filterData[3] === undefined) {
          filterData[3] = [];
        }

        filterData[3].push(...newUsers.flat());
      }

      if (values.type === t("projects.status")) {
        const newUsers = projects.filter((pr) => pr.status === values.name);

        if (filterData[4] === undefined) {
          filterData[4] = [];
        }

        filterData[4].push(...newUsers.flat());
      }

      if (values.type === t("projects.business_unit")) {
        const newUsers = projects.filter((pr) => pr.business_unit === values.name);

        if (filterData[5] === undefined) {
          filterData[5] = [];
        }

        filterData[5].push(...newUsers.flat());
      }
    });

    if (filterData.length > 0) {
      const finalUsers = filterData.filter((n) => n)[0].filter((user) => filterData.every((array) => array.includes(user)));

      return finalUsers;
    } else {
      return [];
    }
  }
};

// Tags can only be filtered by tag itself
export const calculateTagsFilters = (tags, filterValues, filterType, t) => {
  if (filterValues.length === 0) {
    return tags;
  }

  const tagArray = [];
  const allowedFilters = filterValues.filter((filter) => TAG_FILTERS(t).includes(filter.type));

  if (allowedFilters.length === 0) {
    return tags;
  }

  if (filterType === "Or") {
    allowedFilters.map((values) => {
      if (values.type === t("planning.filter_tags")) {
        const newTags = tags.filter((tag) => tag.id === values.id);

        tagArray.push(newTags);
      }
    });

    const finalTags = tagArray.flat().filter((thing, index, self) => {
      return index === self.findIndex((t) => t.id === thing.id);
    });

    return finalTags;
  }
};

import { OffsetDays, GetDaysInZoom, changeDateTimeZoneToUTC } from "../../../lib/DateUtils";
import Api from "../../../Api";

async function GetBookingsRequest(setLoadingBookings, users, setProjects, date, zoom, setBookings) {
  const from = changeDateTimeZoneToUTC(date);

  const zoomDays = GetDaysInZoom(zoom);

  let offsetDays = zoomDays - 1;

  if (zoom === 1) {
    offsetDays = 1;
  }

  const to = OffsetDays(from, offsetDays);

  setLoadingBookings(true);

  Promise.all([
    Api.Bookings.get(
      users.map((user) => user.id),
      from,
      to
    ),
  ]).then((response) => {
    let new_bookings = response[0].data;

    let originalProjectIds = [...new Set(new_bookings.map((booking) => booking.project_id))];

    if (originalProjectIds.length > 0) {
      Promise.all([Api.Projects.in_range(originalProjectIds)]).then((response) => {
        setProjects(response[0].data);
        setBookings(new_bookings);
        setLoadingBookings(false);
      });
    } else {
      setProjects([]);
      setBookings(new_bookings);
      setLoadingBookings(false);
    }
  });
}

export default GetBookingsRequest;

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./locales/en.json";
import translationFR from "./locales/fr.json";
import translationDE from "./locales/de.json";
import translationES from "./locales/es.json";
import translationIT from "./locales/it.json";
import Backend from "i18next-http-backend";

const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
  de: {
    translation: translationDE,
  },
  es: {
    translation: translationES,
  },
  it: {
    translation: translationIT,
  },
};

const localesUrl = () => {
  if (process.env.REACT_APP_ENV === "production") {
    return "https://teambook-account-images-production.s3.amazonaws.com/i18n";
  } else {
    return "https://teambook-account-images-production.s3.amazonaws.com/i18n/development";
  }
};

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    backend: {
      // configure your backend options here
      loadPath: `${localesUrl()}/{{lng}}.json`,
    },
    lng: "en",
    fallbackLng: "en",
    debug: true,
  });

export default i18n;

import React, { useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import { useTranslation } from "react-i18next";
import DialogActions from "@mui/material/DialogActions";
import TeambookBlueButton from "../../default_components/TeambookBlueButton";
import { TeambookProjectsPicker, TeambookUsersPicker } from "../../default_components/TeambookDefaultPickers";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";

const AddUsersDialog = ({ setDialogOpened, users, addUsers }) => {
  const { t } = useTranslation();
  const [selectedUsers, setSelectedUsers] = useState([]);

  let usersOptions = users.sort((x, y) => (x.last_name > y.last_name ? 1 : -1));

  const closeModal = () => {
    setDialogOpened(false);
  };

  return (
    <Dialog open={true} onClose={closeModal} aria-labelledby="form-dialog-title" className="tb-default-dialog">
      <DialogTitle>
        <p>{t("planning.capacity.add_users")}</p>
        <TeambookIcon name={icons.CROSS} onClick={closeModal} />
      </DialogTitle>

      <DialogContent style={{ padding: "20px 40px", width: 400, height: 120 }}>
        <TeambookUsersPicker
          displayEmpty={true}
          users={usersOptions}
          defaultValue={[]}
          placeholder={t("planning.select_users")}
          maxMenuHeight={150}
          type="multiple"
          onChange={(options, newValue) => {
            setSelectedUsers(Array.isArray(options) ? options : []);
          }}
          isSearchable={true}
        />
      </DialogContent>

      <DialogActions>
        <TeambookBlueButton
          text={t("save")}
          onClick={() => {
            addUsers(selectedUsers);
            setDialogOpened(false);
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default AddUsersDialog;

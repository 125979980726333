import React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import { useTranslation } from "react-i18next";
import "./default_styles.scss";
import { WhiteTooltip } from "./Tooltips";
import { defaultProjectIcons } from "../default_values/DefaultProjectIcons";

const ProjectBudget = ({ project, className, actuals, value, daysFormat = false, type }) => {
  const { t } = useTranslation();
  const baseValue = value === undefined ? project.bookings_duration : value;

  const budgetRatio = (duration) => {
    if (duration > estimated_value) {
      return 100;
    } else {
      return (duration / estimated_value) * 100;
    }
  };

  const timeFormat = daysFormat ? "d" : "h";
  let estimated_value = project.estimated;

  if (estimated_value && daysFormat) {
    estimated_value = Math.round(estimated_value / 8);
  }

  const getTooltipTitle = () => {
    switch (type) {
      case "planned":
        return t("projects.selected_fields.planned_hours");
      case "logged":
        return t("projects.selected_fields.logged_hours");
      case "capacity":
        return t("projects.selected_fields.capacity_days");
    }
  };

  return (
    <div className={`project-budget ${className ? className : ""} ${estimated_value ? "" : "budget__not-estimated"}`}>
      <WhiteTooltip title={getTooltipTitle()}>
        <p className="project-budget__text">
          {baseValue}
          {timeFormat}
        </p>
      </WhiteTooltip>

      {project.actual_logs_duration > 0 && actuals && (
        <WhiteTooltip title={t("projects.actually_entered")}>
          <p className="project-budget__text flex">
            &nbsp;({defaultProjectIcons(-4, "var(--text-2)", 14)}
            {project.actual_logs_duration}
            {timeFormat})
          </p>
        </WhiteTooltip>
      )}

      {estimated_value > 0 && (
        <>
          <div className="project-budget__middle">
            <WhiteTooltip
              title={t("projects.actually_left", {
                actuals_available: estimated_value - project.actual_logs_duration,
              })}
            >
              <p className="project-budget__booked" data-active={baseValue > estimated_value}>
                {baseValue > estimated_value
                  ? `${baseValue - estimated_value}${timeFormat} ${t("projects.overbooked")}`
                  : `${estimated_value - baseValue}${timeFormat} ${t("projects.underbooked")}`}
              </p>
            </WhiteTooltip>

            <LinearProgress
              className="project-budget__bar"
              variant="determinate"
              color={budgetRatio(baseValue) === 100 ? "secondary" : "primary"}
              value={budgetRatio(baseValue)}
              height="10"
            />
          </div>

          <WhiteTooltip title={t("projects.estimated_time")}>
            <p className="project-budget__text">
              {estimated_value}
              {timeFormat}
            </p>
          </WhiteTooltip>
        </>
      )}
    </div>
  );
};

export default ProjectBudget;

import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { icons } from "../../../default_images/IconsList";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { TeambookCheckbox } from "../../../default_components/TeambookCheckbox";
import TeambookRadioButton from "../../../default_components/TeambookRadioButton";
import { CentsToDollarString } from "../../../../lib/DataFormat";
import TeambookOutlinedButton from "../../../default_components/TeambookOutlinedButton";
import { shallow } from "zustand/shallow";
import { useNotificationStore } from "../../../../stores/notificationStore";

const ANNUAL_PRICE_MAPPING = {
  V2_S_Plan: 540 * 100,
  V2_M_Plan: 900 * 100,
  V2_L_Plan: 1080 * 100,
  V2_S_Plan_Yearly: 540 * 100,
  V2_M_Plan_Yearly: 900 * 100,
  V2_L_Plan_Yearly: 1080 * 100,
};

const ACTUALS_PRICE_MAPPING = {
  V2_S_Plan: 49 * 100,
  V2_M_Plan: 79 * 100,
  V2_L_Plan: 90 * 100,
  V2_Starter_Plan: 16 * 100,
};

const ANNUAL_ACTUALS_PRICE_MAPPING = {
  V2_S_Plan: 540 * 100,
  V2_M_Plan: 900 * 100,
  V2_L_Plan: 1080 * 100,
};

export const SelectModuleModal = ({ close, plan, finishSubscriptionKindSelect }) => {
  const [isActualsSelected, setIsActualsSelected] = useState(false);
  const [setInAppNotification] = useNotificationStore((state) => [state.setInAppNotification], shallow);
  const { t } = useTranslation();

  const plans = [
    {
      name: t("settings.subscription.monthly"),
      value: "monthly",
      price: plan.price_cents,
      actuals_price: ACTUALS_PRICE_MAPPING[plan.plan_id],
    },
  ];

  if (Object.keys(ANNUAL_PRICE_MAPPING).includes(plan.plan_id)) {
    plans.push({
      name: t("settings.subscription.annual"),
      value: "yearly",
      price: ANNUAL_PRICE_MAPPING[plan.plan_id],
      actuals_price: ANNUAL_ACTUALS_PRICE_MAPPING[plan.plan_id],
    });
  }

  const showNotification = () => {
    setInAppNotification(t("settings.subscription.planning_notification"));
  };

  const switchActualsSelected = () => {
    setIsActualsSelected(!isActualsSelected);
  };

  return (
    <Dialog maxWidth={800} onClose={close} open className="tb-default-dialog">
      <DialogTitle>
        <p style={{ margin: "auto 0" }}>{t("settings.subscription.select_module")}</p>
        <TeambookIcon name={icons.CROSS} onClick={close} />
      </DialogTitle>
      <DialogContent style={{ width: 800 }}>
        <div className="select-module-dialog__modules">
          <div onClick={showNotification} className="select-module-dialog__module">
            <TeambookCheckbox checked={true} />
            <p className="pointer"> {t("header.planning")}</p>
          </div>
          <div className="select-module-dialog__module">
            <TeambookCheckbox checked={isActualsSelected} onChange={switchActualsSelected} />
            <p className="pointer" onClick={switchActualsSelected}>
              {t("header.actuals")}
            </p>
          </div>
        </div>

        <div className="select-module-dialog__plans">
          {plans.map((plan) => (
            <div className="select-module-dialog__plan">
              <div className="select-module-dialog__plan-info">
                <p className="col-md-4" style={{ padding: 0 }}>
                  {plan.name}
                </p>
                <p className="col-md-4" style={{ textAlign: "center" }}>
                  {CentsToDollarString(plan.price + (isActualsSelected ? plan.actuals_price : 0))}
                </p>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    padding: 0,
                  }}
                  className="col-md-4"
                  aria-label="subscription plan row"
                >
                  <TeambookOutlinedButton
                    id={"selectSubscription"}
                    onClick={() => finishSubscriptionKindSelect(plan.value, isActualsSelected)}
                    variant="contained"
                    style={{
                      height: 34,
                      minWidth: 75,
                      color: "#4C54A0 !important",
                    }}
                    text={t("select")}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
};

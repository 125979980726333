import UsersProfileImage from "../../../../default_components/UsersProfileImage";
import moment from "moment";
import { isWeekend, OffsetDays } from "../../../../../lib/DateUtils";
import { WhiteTooltip } from "../../../../default_components/Tooltips";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAccountStore } from "../../../../../stores/accountStore";
import { shallow } from "zustand/shallow";

const formatValues = [
  {
    label: "DD/MM/YYYY",
    value: 0,
  },
  {
    label: "MM/DD/YYYY",
    value: 1,
  },
  {
    label: "YYYY/MM/DD",
    value: 2,
  },
];

const EntityRow = ({ index, stats, startDate, additionalData, splitBy }) => {
  const { t } = useTranslation();
  const statsData = stats.data[index];
  const isProjects = Boolean(stats.data?.[0]?.kind);
  let projectHoursColumns = [];
  let currentDate = startDate;

  const [account] = useAccountStore((state) => [state.account], shallow);

  const firstColumnOfRow = () => {
    switch (splitBy) {
      case "comparison_user":
        return statsData.avatars ? (
          <UsersProfileImage size={24} user={statsData.color} userImage={statsData.avatars["100"]} />
        ) : (
          <span className="reporting__user-initials">{statsData.name[0][0] + statsData.name[1][0]}</span>
        );
      case "comparison_project":
        return (
          <div
            style={{
              width: 10,
              height: 24,
              background: statsData.color,
              borderRadius: 7,
            }}
          />
        );
    }
  };

  const colorOfActuals = (id, index) => {
    const actualHours = additionalData.data.find((entity) => entity.id === statsData.id)?.daily_total[index] || 0;
    const plannedHours = statsData.daily_total[index] || 0;

    if (actualHours > plannedHours) {
      return "#E3262F";
    } else if (actualHours < plannedHours) {
      return "#23A942";
    } else {
      return "var(--text-2)";
    }
  };

  projectHoursColumns.push(
    <td
      style={{
        height: 73,
        borderTop: "1px solid var(--stroke)",
        background: "var(--background-1)",
        borderLeft: "1px solid var(--stroke)",
      }}
      className="reporting__fixed-project-column"
    >
      <div className="fixed-column__content">
        {firstColumnOfRow()}
        <p className="reporting__column-text">{statsData.name}</p>
      </div>
    </td>
  );

  projectHoursColumns.push(
    <td
      className="table-column"
      style={{
        width: 90,
        minWidth: 90,
        maxWidth: 90,
      }}
    >
      <div style={{ padding: 0 }} className="compare__table-cell">
        <div style={{ justifyContent: "flex-start", paddingLeft: 10 }} className="compare__planning-data">
          {t("header.planning")}
        </div>

        <div style={{ justifyContent: "flex-start", paddingLeft: 10 }} className="compare__actual-data">
          {t("header.actuals")}
        </div>
      </div>
    </td>
  );

  for (let j = 0; j < stats.amount_of_days; j++) {
    const date = moment(currentDate).format(formatValues[account?.date_format]?.label);

    const isDayWeekend = isWeekend(currentDate);

    projectHoursColumns.push(
      <td className="table-column">
        <div className="compare__table-cell" style={{ borderRight: currentDate.getDay() === 0 ? "1px solid var(--stroke)" : "unset" }}>
          <div className="compare__planning-data">
            <p
              title={
                t(`dashboard.reporting_data.main_row.${isProjects ? "hours_for_project" : "hours_for_user"}`, {
                  projectName: statsData.name,
                  userName: statsData.name,
                }) + `${date}`
              }
              placement="bottom"
              className={`column-text ${isDayWeekend ? "weekend" : ""} fs-16`}
            >
              {statsData.daily_total[j]}
            </p>
          </div>

          <div className="compare__actual-data">
            <p
              title={
                t(`dashboard.reporting_data.main_row.${isProjects ? "actuals_hours_for_project" : "actuals_hours_for_user"}`, {
                  projectName: statsData.name,
                  userName: statsData.name,
                }) + `${date}`
              }
              placement="bottom"
              className={`column-text ${isDayWeekend ? "weekend" : ""} fs-16`}
              style={{ color: colorOfActuals(statsData.id, j) }}
            >
              {additionalData.data.find((entity) => entity.id === statsData.id)?.daily_total[j] || ""}
            </p>
          </div>
        </div>
      </td>
    );

    currentDate = OffsetDays(currentDate, 1);
  }

  projectHoursColumns.push(
    <td className="total_column" style={{ padding: 0 }}>
      <div className="compare__table-cell" style={{ borderTop: "1px solid var(--stroke)" }}>
        <div className="compare__planning-data">
          <p className="column-text fs-16">{statsData.daily_total.reduce((x, y) => x + y, 0)}</p>
        </div>
        <div style={{ border: "unset" }} className="compare__actual-data">
          <p className="column-text fs-16">
            {additionalData.data.find((entity) => entity.id === statsData.id)?.daily_total.reduce((x, y) => x + y, 0) || ""}
          </p>
        </div>
      </div>
    </td>
  );

  return <tr>{projectHoursColumns}</tr>;
};

export default EntityRow;

import {
  approvedThisMonth,
  loggedThisMonth,
  totalThisMonth,
} from "./calculateLogData";

export const sortEntityColumn = (
  splitBy,
  users,
  projects,
  sortMode,
  date,
  allUserLogs
) => {
  let usersWithHours = [];

  if (splitBy === "by_user") {
    usersWithHours = users.map((user) => ({
      ...user,
      logged_hours: loggedThisMonth(
        allUserLogs.filter(({ user_id, approved }) => user_id === user.id),
        date
      ),
      approved_hours: approvedThisMonth(
        allUserLogs.filter(
          ({ user_id, approved }) => user_id === user.id && approved
        ),
        date
      ),
      total_hours: totalThisMonth(user, date),
    }));
  } else {
    usersWithHours = projects.map((project) => ({
      ...project,
      logged_hours: loggedThisMonth(
        allUserLogs.filter(
          ({ project_id, approved }) => project_id === project.id
        ),
        date
      ),
      approved_hours: approvedThisMonth(
        allUserLogs.filter(
          ({ project_id, approved }) => project_id === project.id && approved
        ),
        date
      ),
    }));
  }

  const time_offs = [];
  const non_time_offs = [];
  projects.forEach((pr) => {
    if (pr.kind === "time_off") {
      time_offs.push(pr);
    } else {
      non_time_offs.push(pr);
    }
  });

  const isProject = Boolean(usersWithHours[0].kind);

  if (sortMode === "delta_hours_default") {
    if (isProject) {
      return [
        ...non_time_offs.sort((a, b) =>
          Math.abs(a.logged_hours - a.approved_hours) <
          Math.abs(b.logged_hours - b.approved_hours)
            ? 1
            : -1
        ),
        ...time_offs.sort((a, b) =>
          Math.abs(a.logged_hours - a.approved_hours) <
          Math.abs(b.logged_hours - b.approved_hours)
            ? 1
            : -1
        ),
      ];
    } else {
      return [...usersWithHours].sort((a, b) =>
        Math.abs(a.logged_hours - a.approved_hours) <
        Math.abs(b.logged_hours - b.approved_hours)
          ? 1
          : -1
      );
    }
  }
  if (sortMode === "delta_hours_reverse") {
    if (isProject) {
      return [
        ...non_time_offs.sort((a, b) =>
          Math.abs(a.logged_hours - a.approved_hours) <
          Math.abs(b.logged_hours - b.approved_hours)
            ? -1
            : 1
        ),
        ...time_offs.sort((a, b) =>
          Math.abs(a.logged_hours - a.approved_hours) <
          Math.abs(b.logged_hours - b.approved_hours)
            ? -1
            : 1
        ),
      ];
    } else {
      return [...usersWithHours].sort((a, b) =>
        Math.abs(a.logged_hours - a.approved_hours) <
        Math.abs(b.logged_hours - b.approved_hours)
          ? -1
          : 1
      );
    }
  } else if (sortMode === "default") {
    return [...usersWithHours].sort((a, b) =>
      a.total_hours - a.approved_hours < b.total_hours - b.approved_hours
        ? 1
        : -1
    );
  } else {
    if (splitBy === "by_user") {
      if (sortMode === "a") {
        return [...usersWithHours].sort((a, b) =>
          a.last_name.toLowerCase() < b.last_name.toLowerCase() ? -1 : 1
        );
      } else {
        return [...usersWithHours].sort((a, b) =>
          a.last_name.toLowerCase() < b.last_name.toLowerCase() ? 1 : -1
        );
      }
    } else {
      if (sortMode === "a") {
        return [
          ...non_time_offs.sort((a, b) =>
            a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
          ),
          ...time_offs.sort((a, b) =>
            a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
          ),
        ];
      } else {
        return [
          ...non_time_offs.sort((a, b) =>
            a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1
          ),
          ...time_offs.sort((a, b) =>
            a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1
          ),
        ];
      }
    }
  }
};

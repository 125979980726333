import React, { useEffect, useState } from "react";
import { useTeambookFilter } from "../../../../../stores/planner";
import { useTranslation } from "react-i18next";
import { OffsetDays } from "../../../../../lib/DateUtils";

const BottomTotal = ({ stats, additionalData }) => {
  const [totalColumns, setTotalColumns] = useState([]);
  const { t } = useTranslation();

  const { filterValues } = useTeambookFilter();

  useEffect(() => {
    let columns = [];
    let currentDate = new Date(stats.start_date);

    columns.push(
      <td className="compare__table-column">
        <div
          style={{
            justifyContent: "flex-start",
            paddingLeft: 10,
            color: "var(--text-2)",
          }}
          className="compare__planning-data"
        >
          {t("header.planning")}
        </div>
        <div style={{ justifyContent: "flex-start", paddingLeft: 10 }} className="compare__actual-data">
          {t("header.actuals")}
        </div>
      </td>
    );

    for (let i = 0; i < stats.amount_of_days; i++) {
      if (currentDate.getDay() === 5) {
        columns.push(
          <>
            <td className="compare__table-column">
              <p className="column-day-text" style={{ color: "#E3262F" }}>
                <div className="compare__planning-data">
                  <p className="column-text fs-16">{stats.hours_per_day[i] || ""}</p>
                </div>

                <div className="compare__actual-data">
                  <p className="column-text fs-16">{additionalData.hours_per_day[i] || ""}</p>
                </div>
              </p>
            </td>
          </>
        );
      } else if (currentDate.getDay() === 0) {
        columns.push(
          <td className="compare__table-column" style={{ borderRight: "1px solid var(--stroke)" }}>
            <p className="column-day-text" style={{ color: "#E3262F" }}>
              <div className="compare__planning-data">
                <p className="column-text fs-16">{stats.hours_per_day[i] || ""}</p>
              </div>

              <div className="compare__actual-data">
                <p className="column-text fs-16">{additionalData.hours_per_day[i] || ""}</p>
              </div>
            </p>
          </td>
        );
      } else {
        columns.push(
          <td className="compare__table-column">
            <p className="column-day-text">
              <div className="compare__planning-data">
                <p className="column-text fs-16">{stats.hours_per_day[i] || ""}</p>
              </div>

              <div className="compare__actual-data">
                <p className="column-text fs-16">{additionalData.hours_per_day[i] || ""}</p>
              </div>
            </p>
          </td>
        );
      }

      currentDate = OffsetDays(currentDate, 1);
    }

    columns.push(<td className="totals_div" />);

    setTotalColumns(columns);
  }, [stats]);

  if (filterValues.length > 0) {
    return;
  }

  return (
    <tr className="bottom-total-row">
      <td className="compare__fixed-column">
        <p>{t("dashboard.totals")}</p>
      </td>

      {totalColumns}
    </tr>
  );
};

export default BottomTotal;

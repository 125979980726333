import Api from "../../../Api";

async function GetAllMilestones(setMilestones) {
  await Api.Milestones.all()
    .then((response) => {
      setMilestones(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
}

export default GetAllMilestones;

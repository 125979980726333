import { DateTime } from "luxon";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function Calendar({
  displayedMonth,
  showDates,
  position,
  selectedDates,
  setSelectedDates,
  range,
  onDayClick,
  isRangePicker,
  closeOnSelect,
  closeDatePicker,
  highlight,
}) {
  const { t } = useTranslation();

  const weekDays = [
    t("planning.monday"),
    t("planning.tuesday"),
    t("planning.wednesday"),
    t("planning.thursday"),
    t("planning.friday"),
    t("planning.saturday"),
    t("planning.sunday"),
  ];

  const [isSelectingStarted, setIsSelectingStarted] = useState(false);

  const dayUntilNextWeek = displayedMonth.startOf("month").weekday - 1;

  const generateMonthDays = () => {
    let daysArray = [...new Array(displayedMonth.daysInMonth)];

    return daysArray.map((val, index) => {
      const dayOfMonth = displayedMonth.startOf("month").plus({ day: index });

      const isStartOrEndOfRange =
        dayOfMonth.startOf("day").ts === selectedDates.start.startOf("day").ts ||
        dayOfMonth.startOf("day").ts === selectedDates.end.startOf("day").ts;

      const isStartOfRange = dayOfMonth.startOf("day").ts === selectedDates.start.startOf("day").ts;

      const isEndOfRange = dayOfMonth.startOf("day").ts === selectedDates.end.startOf("day").ts;

      const isInRange = dayOfMonth.ts <= selectedDates.end.startOf("day").ts && dayOfMonth.ts >= selectedDates.start.startOf("day").ts;

      const isStartOfMonth = dayOfMonth.startOf("day").ts === dayOfMonth.startOf("month").startOf("day").ts;

      const calculateOffsetLeft = () => {
        let start = 0;

        if (isStartOfRange && (isRangePicker || highlight === "week")) {
          start = 8;
        }

        return start + dayUntilNextWeek * 30 + dayUntilNextWeek * 8;
      };

      const isToday = DateTime.now().startOf("day").toISODate() === dayOfMonth.startOf("day").toISODate();

      const calculateDayTextStyles = () => {
        if ((highlight === "today" && isToday) || ((highlight === "selected" || highlight === "week") && isStartOrEndOfRange)) {
          return "highlighted-date";
        } else {
          return "";
        }
      };

      const calculateDayStyles = () => {
        return (isRangePicker && highlight === "selected") || highlight === "week"
          ? {
              paddingRight: isStartOfRange && (isRangePicker || highlight === "week") && 8,
              marginLeft: isStartOfMonth ? calculateOffsetLeft() : isStartOfRange ? 8 : "unset",
              paddingLeft: isEndOfRange && 8,
              width: calculateWidth(),
              background: isInRange && "var(--stroke-transparent)",
              borderTopLeftRadius:
                isEndOfRange || (isInRange && index === 0 && !isStartOfRange)
                  ? "unset"
                  : (index === 0 && position !== "end") || isStartOfRange
                  ? 10
                  : "unset",
              borderBottomLeftRadius:
                isEndOfRange || (isInRange && index === 0 && !isStartOfRange)
                  ? "unset"
                  : (index === 0 && position !== "end") || isStartOfRange
                  ? 10
                  : "unset",
              borderTopRightRadius: isEndOfRange ? 10 : "unset",
              borderBottomRightRadius: isEndOfRange ? 10 : "unset",
            }
          : {
              marginLeft: isStartOfMonth && calculateOffsetLeft(),
              width: calculateWidth(),
            };
      };

      const calculateWidth = () => {
        if (isStartOrEndOfRange && (isRangePicker || highlight === "week")) {
          return 30;
        }

        return 38;
      };

      const selectCustomDates = () => {
        onDayClick(dayOfMonth);

        if (closeOnSelect) {
          closeDatePicker();
        }

        if (+range !== 4) {
          return;
        }

        const { years } = selectedDates.start.diff(dayOfMonth, ["years"]).toObject();

        if (Math.abs(years) > 1) {
          return;
        }

        if (!isSelectingStarted && selectedDates.start.ts < selectedDates.end.ts) {
          setSelectedDates((pr) => ({
            start: dayOfMonth.startOf("week"),
            end: dayOfMonth.endOf("week"),
          }));
          setIsSelectingStarted(true);
        } else {
          if (dayOfMonth.ts <= selectedDates.end.ts) {
            setSelectedDates((pr) => ({
              ...pr,
              start: dayOfMonth.startOf("week"),
            }));
          }
          if (dayOfMonth.ts >= selectedDates.end.ts) {
            setSelectedDates((pr) => ({
              ...pr,
              end: dayOfMonth.endOf("week"),
            }));
          }

          setIsSelectingStarted(false);
          // setIsSelectingStarted(false);
        }
      };

      return (
        <div className="calendar__week-day" style={{ ...calculateDayStyles() }} key={"day-" + index} onClick={selectCustomDates}>
          <p
            className={`calendar__day-number ${calculateDayTextStyles()} ${(isRangePicker || highlight === "week") && "range-picker__day"}`}
          >
            {dayOfMonth.day}
          </p>
        </div>
      );
    });
  };

  return (
    <div className="calendar">
      <div className="calendar__week-days">
        {weekDays.map((day, i) => (
          <p style={{ opacity: i === 5 || i == 6 ? 0.5 : 1 }} className="calendar__name-of-day">
            {day}
          </p>
        ))}
      </div>

      <div className="calendar__dates">{generateMonthDays()}</div>
    </div>
  );
}

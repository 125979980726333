import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import TeambookTextForm from "../../../default_components/TeambookTextForm";
import Api from "../../../../Api";
import Checkbox from "@mui/material/Checkbox";
import { DialogTitle } from "@mui/material";
import TeambookBlueButton from "../../../default_components/TeambookBlueButton";
import { TeambookReactSelect } from "../../../default_components/TeambookReactSelect";
import TeambookOutlinedButton from "../../../default_components/TeambookOutlinedButton";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { icons } from "../../../default_images/IconsList";
import { shallow } from "zustand/shallow";
import { useAccountStore } from "../../../../stores/accountStore";

const settings = (account) => {
  return JSON.parse(account?.hubspot_integration);
};

const DEAL_STATES = [
  { value: "any", label: "All" },
  { value: "closedwon", label: "Deal Won" },
  { value: "contractsent", label: "Contract Sent" },
];

const HubspotBlock = () => {
  const { t } = useTranslation();
  const [account, fetchAccount] = useAccountStore((state) => [state.account, state.fetchAccount], shallow);

  const [hubspotModalOpened, setHubspotModalOpened] = useState(false);
  const [hubspotEnteringKey, setHubspotEnteringKey] = useState("");
  const [hubspotClientSecret, setHubspotClientSecret] = useState("");
  const [dealEnabled, setDealEnabled] = useState(settings(account)?.deals);
  const [dealState, setDealState] = useState(settings(account)?.deal_state);

  useEffect(() => {
    setDealEnabled(settings(account)?.deals);
    setDealState(settings(account)?.deal_state);
  }, [account]);

  const submitApiKey = () => {
    if (hubspotEnteringKey.length > 0 && hubspotClientSecret.length > 0) {
      Api.Integrations.Hubspot.init(hubspotEnteringKey, hubspotClientSecret)
        .then(() => {
          fetchAccount();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const updateIntegration = () => {
    Api.Integrations.Hubspot.update({
      deals: dealEnabled,
      deal_state: dealState,
    })
      .then((response) => {
        fetchAccount();
        setHubspotModalOpened(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const hubspotConnected = () => {
    return account.hubspot_integration;
  };

  return (
    <>
      <div className="integration-block">
        <div className="integration-name">
          <TeambookIcon name={icons.HUBSPOT_INTEGRATION_ICON} alt="hubspot logo" />
        </div>

        <div className="integration-status">
          <p className={hubspotConnected() ? "active" : "not_active"}>{hubspotConnected() ? "Active" : "Not connected"}</p>
        </div>

        <TeambookOutlinedButton
          onClick={() => setHubspotModalOpened(true)}
          variant="contained"
          text={hubspotConnected() ? t("settings.integration.manage") : t("settings.integration.connect")}
          style={{
            margin: "13px 0 0 0",
            width: "171px",
          }}
        />
      </div>

      <Dialog
        fullWidth={true}
        open={hubspotModalOpened}
        onClose={() => setHubspotModalOpened(false)}
        aria-labelledby="max-width-dialog-title"
        className="tb-default-dialog"
      >
        <DialogTitle id="add-user-dialog">
          <p>{t("settings.integration.hubspot.header")}</p>
          <TeambookIcon name={icons.CROSS} onClick={() => setHubspotModalOpened(false)} />
        </DialogTitle>

        <DialogContent style={{ margin: "20px 0px" }}>
          {hubspotConnected() ? (
            <div style={{ height: "200px" }}>
              <p>Configure integration</p>

              <div className="settings__integration-hubspot__row">
                <Checkbox checked={dealEnabled} onChange={(event) => setDealEnabled(event.target.checked)} color="primary" />

                <p>Deals,</p>

                <p>with status:</p>

                <div style={{ width: "140px" }}>
                  <TeambookReactSelect
                    value={DEAL_STATES.filter((state) => state.value === dealState)}
                    label="Single select"
                    options={DEAL_STATES}
                    onChange={(newValue) => setDealState(newValue.value)}
                  />
                </div>
              </div>

              <p style={{ fontSize: "14px" }}>Keep in mind that data is fetched each 10 minutes.</p>

              <TeambookBlueButton
                onClick={updateIntegration}
                variant="contained"
                text={t("submit")}
                style={{
                  position: "absolute",
                  right: 40,
                }}
              />
            </div>
          ) : (
            <div style={{ marginBottom: 60 }}>
              <p>Please insert Private App data below to activate integration.</p>

              <TeambookTextForm
                fieldName={"Access Token"}
                onChange={setHubspotEnteringKey}
                fieldValue={hubspotEnteringKey}
                type={"text"}
                width={"400px"}
                placeholder={"pat-xxx-...."}
              />

              <TeambookTextForm
                fieldName={"Client Secret"}
                onChange={setHubspotClientSecret}
                fieldValue={hubspotClientSecret}
                type={"text"}
                width={"400px"}
                placeholder={"xxxxxxxx-yyyy-...."}
              />

              <TeambookBlueButton
                onClick={submitApiKey}
                variant="contained"
                text={t("submit")}
                style={{
                  position: "absolute",
                  right: 40,
                  bottom: 30,
                }}
              />
            </div>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default HubspotBlock;

import React, { useRef, useState, useEffect } from "react";
import { SliceStringWithDots } from "../../../../lib/StringFormat";
import "../../users.scss";
import "../../../default_styles/tb-colors.css";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import EditTag from "./EditTag";
import CreateTag from "./CreateTag";
import { useTranslation } from "react-i18next";
import DeleteDialog from "../../../default_components/DeleteDialog";
import Api from "../../../../Api";
import { Tooltip } from "./TagsTooltip";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { icons } from "../../../default_images/IconsList";
import { useNotificationStore } from "../../../../stores/notificationStore";
import { shallow } from "zustand/shallow";

function TagsDialogWindow({ closeTagsWindow, opened, tags, getAllTags, allUsers, getAllUsers }) {
  const [setInAppNotification] = useNotificationStore((state) => [state.setInAppNotification], shallow);

  const { t } = useTranslation();
  const [editOpened, setEditOpened] = useState(false);
  const [editingTag, setEditingTag] = useState(null);
  const [createOpened, setCreateOpened] = useState(false);
  const [deleteOpened, setDeleteOpened] = useState(false);
  const [deleteObject, setDeleteObject] = useState({});
  const [hoverableTag, setHoverableTag] = useState([]);
  const [sortOrder, setSortOrder] = useState("a");
  const [tagsArray, setTagsArray] = useState([...tags]);
  const [sortField, setSortField] = useState("");

  const [tagColor, setTagColor] = useState(editingTag?.color);
  const [tagName, setTagName] = useState(editingTag?.name || "New Tag");
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    sortTags("name", sortOrder);
  }, [tags]);

  useEffect(() => {
    setSortOrder("a");
    sortTags(sortField, "a");
  }, [sortField]);

  const sortTags = (sort_by = "name", order) => {
    setSortField(sort_by);

    // if (order) {
    //   setSortOrder(order);
    // } else {
    //   swapSortOrder();
    // }

    setTagsArray(
      [...tags].sort((a, b) => {
        if (order === "a") {
          if (sort_by === "name") {
            return a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1;
          }

          if (sort_by === "users") {
            return a.tag_users.length > b.tag_users.length ? 1 : -1;
          }

          if (sort_by === "color") {
            return a.color.toUpperCase() > b.color.toUpperCase() ? 1 : -1;
          }
        } else {
          if (sort_by === "name") {
            return a.name.toUpperCase() < b.name.toUpperCase() ? 1 : -1;
          }

          if (sort_by === "users") {
            return a.tag_users.length < b.tag_users.length ? 1 : -1;
          }

          if (sort_by === "color") {
            return a.color.toUpperCase() < b.color.toUpperCase() ? 1 : -1;
          }
        }
      })
    );
  };

  const tagTooltipRef = useRef(null);

  const getSortOrder = () => {
    setSortOrder(sortOrder === "a" ? "z" : "a");
    return sortOrder === "a" ? "z" : "a";
  };

  const closeErrorMessage = () => {
    setErrorMessage(null);
  };

  const updateTag = () => {
    Api.Tags.update(editingTag.id, tagName, tagColor)
      .then((response) => analyzeUpdateTag(response))
      .catch((error) => {
        setErrorMessage(error.response.data.error.message);
      });
  };

  const analyzeUpdateTag = (response) => {
    if (response.status === 200) {
      closeErrorMessage();
      handleCloseEditing();
      getAllTags("name");
      setInAppNotification(t("notifications.users.tags.edit"));
      getAllUsers();
    } else {
      console.log(response.status);
    }
  };

  const createTag = () => {
    setErrorMessage(null);
    Api.Tags.create(tagName, tagColor.toUpperCase())
      .then(analyzeCreateTagResponse)
      .catch((error) => {
        setErrorMessage(error.response.data.error.message);
      });
  };

  const analyzeCreateTagResponse = (response) => {
    getAllTags("name");
    setCreateOpened(false);
    setInAppNotification(t("notifications.users.tags.create"));
  };

  const ChangeTooltip = (newTooltip) => {
    tagTooltipRef.current.setPos({
      x: newTooltip.posX - 115,
      y: newTooltip.posY + 150 >= document.body.clientHeight ? newTooltip.posY - 135 : newTooltip.posY + 20,
    });

    tagTooltipRef.current.setOpen(newTooltip.open);
  };

  const handleCloseEditing = () => {
    setEditOpened(false);
    closeErrorMessage();
  };

  const openEditingWindow = (tag) => {
    closeErrorMessage();
    setEditingTag(tag);
    setTagName(tag.name);
    setTagColor(tag.color);
    setEditOpened(true);
    setCreateOpened(false);
  };

  const deleteTag = (tag) => {
    Api.Tags.delete(tag.id).then(onDeleteTagRequest);
  };

  const openCreationWindow = () => {
    setTagName("New Tag");
    setCreateOpened(true);
    setEditOpened(false);
  };

  const onDeleteTagRequest = (response) => {
    getAllUsers();
    getAllTags("name");
  };

  const keyDownHandler = ({ key }) => {
    switch (key) {
      case "Escape":
        if (createOpened || editOpened) {
          setCreateOpened(false);
          setEditOpened(false);
        } else {
          closeDialog();
        }
        break;

      case "Enter":
        {
          if (editOpened) {
            updateTag();
          }

          if (createOpened) {
            createTag();
          }
        }

        break;
    }
  };

  const closeDialog = () => {
    closeTagsWindow();
    setCreateOpened(false);
    setEditOpened(false);
    setHoverableTag([]);
  };

  let content;

  if (tagsArray !== undefined) {
    if (tagsArray.length === 0) {
      content = (
        <div className="tag-menu__items">
          {createOpened === true ? (
            <CreateTag
              tags={tagsArray}
              tagName={tagName}
              setTagName={setTagName}
              tagColor={tagColor}
              setTagColor={setTagColor}
              setCreateOpen={setCreateOpened}
              createTag={createTag}
              errorMessage={errorMessage}
              closeErrorMessage={closeErrorMessage}
            />
          ) : (
            <p style={{ margin: "0 20px" }}>{t("users.no_tags_yet")}</p>
          )}
        </div>
      );
    } else {
      content = (
        <div style={{ overflow: "overlay", paddingRight: 18 }} className="tag-menu__items">
          {createOpened === true && (
            <CreateTag
              tags={tagsArray}
              tagName={tagName}
              setTagName={setTagName}
              tagColor={tagColor}
              setTagColor={setTagColor}
              setCreateOpen={setCreateOpened}
              createTag={createTag}
              errorMessage={errorMessage}
              closeErrorMessage={closeErrorMessage}
            />
          )}

          {tagsArray.map((item, key) =>
            editOpened && editingTag.id === item.id ? (
              <EditTag
                tag={editingTag}
                closeErrorMessage={closeErrorMessage}
                updateTag={updateTag}
                tagName={tagName}
                setTagName={setTagName}
                tagColor={tagColor}
                setTagColor={setTagColor}
                errorMessage={errorMessage}
                handleCloseEditing={handleCloseEditing}
              />
            ) : (
              <div key={item.id} className="tags-menu__tag-element">
                <div className="tags-menu__tag-element__info row">
                  <div className="tags-menu__tag-element__color-box col-md-3">
                    <div className="tags-menu__tag-element__color-circle" style={{ backgroundColor: item.color }} />
                  </div>
                  <p className="col-md-3">{SliceStringWithDots(item.name, 20)}</p>

                  {item.tag_users !== undefined && (
                    <div style={{ padding: "0px", justifyContent: "center" }} className="col-md-3 row">
                      <p
                        onClick={(e) => {
                          if (item.tag_users.length) {
                            ChangeTooltip({
                              open: true,
                              posY: e.pageY,
                              posX: e.pageX,
                            });
                          }

                          setHoverableTag(item.tag_users);
                        }}
                        style={{
                          marginBottom: "0px",
                          userSelect: "none",
                          cursor: "pointer",
                        }}
                      >
                        {item.tag_users.filter((user) => allUsers.find(({ id }) => id === user)).length}
                      </p>
                    </div>
                  )}

                  <div className="tag-menu__tag-element__control col-md-3">
                    <TeambookIcon
                      id={"editTag"}
                      alt="edit"
                      name={icons.PENCIL}
                      color="alternative_default"
                      onClick={() => openEditingWindow(item)}
                      tooltipTitle={t("edit")}
                    />
                    <TeambookIcon
                      id={"deleteTag"}
                      alt="remove"
                      tooltipTitle={t("delete")}
                      name={icons.TRASH}
                      color="alternative_default"
                      onClick={() => {
                        setDeleteOpened(true);
                        setDeleteObject(item);
                      }}
                    />
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      );
    }
  } else {
    return <div />;
  }

  return (
    <Dialog
      onKeyDown={keyDownHandler}
      open={opened}
      onClose={() => {
        if (editOpened || createOpened) {
          setEditOpened(false);
          setCreateOpened(false);
          closeErrorMessage();
        } else {
          closeDialog();
        }
      }}
      aria-labelledby="form-dialog-title"
      className="tags-dialog__container tb-default-dialog"
    >
      <DialogTitle className="filter-menu__title">
        <p className="filter-menu__title-text">{t("users.tags")}</p>
        <TeambookIcon
          id={"addTag"}
          onClick={openCreationWindow}
          alt="add"
          className="filter-menu__title-add"
          name={icons.ADD}
          tooltipTitle={t("users.new_tag")}
        />

        <div className="filter-menu__title-close">
          <TeambookIcon
            name={icons.CROSS}
            onClick={() => {
              closeTagsWindow();
              setCreateOpened(false);
              setEditOpened(false);
              setHoverableTag([]);
            }}
          />
        </div>
      </DialogTitle>

      <DialogContent style={{ paddingRight: 22 }} className="tag-menu__tag-content">
        <div className="tag-menu__items__filter-buttons row">
          <p
            onClick={() => {
              sortTags("color", getSortOrder());
            }}
            className="tags-filter col-md-3"
          >
            {t("users.tag_color")}
            {sortField === "color" && (
              <TeambookIcon
                name={icons.LONG_ARROW}
                tooltipTitle={t("users.sort_by_color")}
                style={{
                  width: 16,
                  transform: sortOrder === "z" ? "rotate(180deg)" : "unset",
                }}
              />
            )}
          </p>

          <p
            onClick={() => {
              sortTags("name", getSortOrder());
            }}
            className="tags-filter col-md-3"
          >
            {t("users.tag_name")}
            {sortField === "name" ? (
              <TeambookIcon
                name={icons.LONG_ARROW}
                tooltipTitle={t("users.sort_by_name")}
                style={{
                  width: 16,
                  transform: sortOrder === "z" ? "rotate(180deg)" : "unset",
                }}
              />
            ) : (
              <div style={{ width: 16 }} />
            )}
          </p>
          <p
            onClick={() => {
              sortTags("users", getSortOrder());
            }}
            className="tags-filter col-md-3"
            style={{ justifyContent: "center" }}
          >
            {t("users.tag_users")}
            {sortField === "users" ? (
              <TeambookIcon
                name={icons.LONG_ARROW}
                tooltipTitle={t("users.sort_by_users")}
                style={{
                  width: 16,
                  transform: sortOrder === "z" ? "rotate(180deg)" : "unset",
                }}
              />
            ) : (
              <div style={{ width: 16 }} />
            )}
          </p>
        </div>

        <Tooltip tagsArray={hoverableTag} allUsers={allUsers} ref={tagTooltipRef} />

        {content}
      </DialogContent>
      <DeleteDialog
        open={deleteOpened}
        setOpen={setDeleteOpened}
        arrOfDeletionObjects={deleteObject}
        deleteRequest={deleteTag}
        contentText={
          <>
            <p>
              {t("users.delete_tag_confirmation_1")}
              <b>{deleteObject.name}</b>
              {t("users.delete_tag_confirmation_2")}
            </p>
            <p
              style={{
                marginTop: 10,
                wordWrap: "break-word",
                whiteSpace: "unset",
              }}
            >
              {t("users.delete_tag_warning")}
            </p>
          </>
        }
      />
    </Dialog>
  );
}

export default TagsDialogWindow;

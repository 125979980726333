import lsKeys from "../../default_values/defaultKeys";

export const Dropzone = ({ children, drop, style, isDraggable = true }) => {
  return isDraggable ? (
    <div style={style} ref={drop}>
      {children}
    </div>
  ) : (
    <>{children}</>
  );
};

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { recalculateCapacityArray } from "../../../../../capacity_planning/lib/recalculateCapacityArray";
import { SliceStringWithDots } from "../../../../../../lib/StringFormat";
import { icons } from "../../../../../default_images/IconsList";
import { TeambookIcon } from "../../../../../default_images/TeambookIcon";
import UsersSubBlock from "./UsersSubBlock";

const TagsBlock = ({ tag, users, date, reservations, showPercentage, projects, selectedViewType, percentageFormat }) => {
  const { t } = useTranslation();
  const [calculatedDurations, setCalculatedDurations] = useState([]);
  const [percentageDurations, setPercentageDurations] = useState([]);
  const [isListDisplaying, setIsListDisplaying] = useState(false);

  const percentageFormatMapping = {
    all: 0,
    billable: 1,
    non_billable: 2,
    time_off: 3,
  };

  useEffect(() => {
    updateCalculations(reservations);
  }, [reservations, date, showPercentage]);

  const updateCalculations = (reservations) => {
    setPercentageDurations(calculatePercentageDuration(reservations));
    setCalculatedDurations(recalculateCapacityArray(date, reservations, selectedViewType));
  };

  const sortUsers = (userIds) =>
    userIds
      .map((id) => {
        return users.find((user) => +user.id === +id);
      })
      .filter(Boolean)
      .sort((a, b) => (a.last_name > b.last_name ? 1 : -1))
      .map((user) => user.id);

  const groupedUserCapacities = () => {
    return reservations
      .filter((r) => tag.tag_users.includes(r.user_id))
      .reduce(function (r, a) {
        r[a.user_id] = r[a.user_id] || [];
        r[a.user_id].push(a);
        return r;
      }, Object.create(null));
  };

  const calculatePercentageDuration = () => {
    let newCapacityDurations = [];
    const tagUsersWorkingDays = usersWorkingDays();

    [...Array(selectedViewType)].forEach((x, i) => {
      const calculatedDate = date.plus({ months: i }).toISODate();

      const daysRserved = reservations
        .filter((cr) => cr.date === calculatedDate)
        .map((cr) => cr.days_reserved)
        .reduce((ps, a) => ps + a, 0);

      const billableDaysReserved = reservations
        .filter((cr) => cr.date === calculatedDate)
        .filter((cr) => projects.filter((p) => p.id === cr.project_id)[0]?.kind === "billable")
        .map((cr) => cr.days_reserved)
        .reduce((ps, a) => ps + a, 0);

      const nonBillableDaysReserved = reservations
        .filter((cr) => cr.date === calculatedDate)
        .filter((cr) => projects.filter((p) => p.id === cr.project_id)[0]?.kind === "non_billable")
        .map((cr) => cr.days_reserved)
        .reduce((ps, a) => ps + a, 0);

      const timeOffDaysReserved = reservations
        .filter((cr) => cr.date === calculatedDate)
        .filter((cr) => projects.filter((p) => p.id === cr.project_id)[0]?.kind === "time_off")
        .map((cr) => cr.days_reserved)
        .reduce((ps, a) => ps + a, 0);

      const tagsMonthDuration = monthDuration(date.plus({ months: i }), tagUsersWorkingDays);

      if (tagsMonthDuration > 0) {
        newCapacityDurations.push([
          ((daysRserved / tagsMonthDuration) * 100).toFixed(0),
          ((billableDaysReserved / tagsMonthDuration) * 100).toFixed(0),
          ((nonBillableDaysReserved / tagsMonthDuration) * 100).toFixed(0),
          ((timeOffDaysReserved / tagsMonthDuration) * 100).toFixed(0),
        ]);
      } else {
        newCapacityDurations.push([0, 0, 0, 0]);
      }
    });

    return newCapacityDurations;
  };

  const monthDuration = (newDate, tagUsersWorkingDays) => {
    let duration = 0;

    for (let i = 0; i < newDate.endOf("month").day; i += 1) {
      const newDateWeekday = newDate.plus({ days: i }).weekday - 1;

      duration += tagUsersWorkingDays[newDateWeekday];
    }

    return duration;
  };

  const usersWorkingDays = () => {
    let workingDaysArray = [0, 0, 0, 0, 0, 0, 0];

    tag.tag_users.forEach((userId) => {
      const user = users.filter((u) => u.id === userId)[0];

      if (user) {
        workingDaysArray.forEach((value, index) => {
          if (user.schedule[index][0] + user.schedule[index][2] > 0) {
            workingDaysArray[index] = workingDaysArray[index] + 1;
          }
        });
      }
    });

    return workingDaysArray;
  };

  const percentageDuration = (i) => {
    const value = percentageDurations[i]?.[percentageFormatMapping[percentageFormat]];

    if (!value) {
      return "..";
    }

    return `${percentageDurations[i]?.[percentageFormatMapping[percentageFormat]]}%`;
  };

  return (
    <div className="reporting-block">
      <div className="reporting__headline">
        <div
          style={{
            width: 400,
            minWidth: 400,
            maxHeight: 400,
            borderRight: "1px solid var(--stroke)",
            borderLeft: "1px solid var(--stroke)",
          }}
          className="reporting__headline__name"
        >
          <div className="flex named-row">
            <p
              onClick={() => {
                setIsListDisplaying(!isListDisplaying);
              }}
              style={{ cursor: "pointer" }}
            >
              <div
                style={{
                  width: 12,
                  height: 12,
                  background: tag.color,
                  marginRight: 10,
                  borderRadius: 7,
                }}
              />
              {SliceStringWithDots(tag.name, 14)}

              {reservations.length > 0 && (
                <TeambookIcon
                  name={isListDisplaying ? icons.ARROW_UP : icons.ARROW_DOWN}
                  alt={"Show All Entities"}
                  tooltipTitle={t(`planning.capacity.${isListDisplaying ? "hide" : "show"}_projects`)}
                />
              )}
            </p>
          </div>

          <p style={{ justifyContent: "center" }} className="duration">
            {calculatedDurations.reduce((x, y) => x + y, 0)} {t("planning.days")}
          </p>
        </div>

        {[...Array(selectedViewType)].map((x, i) => (
          <div className={`table-cell`}>
            <p>{(showPercentage ? percentageDuration(i) : calculatedDurations[i]) || "0"}</p>
          </div>
        ))}
      </div>

      {isListDisplaying &&
        sortUsers(Object.keys(groupedUserCapacities())).map((userId) => (
          <UsersSubBlock
            user={users.find((u) => u.id === parseInt(userId))}
            date={date}
            reservations={reservations.filter((cr) => cr.user_id === parseInt(userId) && tag.tag_users.includes(cr.user_id))}
            selectedViewType={selectedViewType}
          />
        ))}
    </div>
  );
};

export default TagsBlock;

import React from "react";
import { OffsetDays, GetDaysInZoom, GetStartDateForPlannerDate, ToDateString } from "../../../lib/DateUtils";
import { useTranslation } from "react-i18next";
import GenerateMonthRow from "../lib/GenerateMonthRow";
import monthName from "../lib/monthName";
import DateCell from "./DateCell";
import TeambookSortingTypes from "../../default_components/TeambookSortingTypes";
import { shallow } from "zustand/shallow";
import { useDateStore, useZoomStore } from "../../../stores/planner";
import { PLANNER_USER_WIDTH } from "../../../lib/PlannerUtils";
import { isWeekend } from "date-fns";

const DateRow = ({ allRowsCreation, weekendsHidden, weekendStart, userOrder, changeOrder, isSwappingUsers }) => {
  const { t } = useTranslation();

  const [zoom] = useZoomStore((state) => [state.zoom], shallow);
  const [date] = useDateStore((state) => [state.date], shallow);

  let days = GetDaysInZoom(zoom);

  const firstPlannerDate = GetStartDateForPlannerDate(date, zoom);
  let role = localStorage.getItem("tb-role");

  let rows = [];
  let monthRow = GenerateMonthRow(zoom, firstPlannerDate, days, weekendsHidden, weekendStart, 121, date);

  rows.push(
    <div className="date-row__filter-cell" style={{ width: PLANNER_USER_WIDTH }} key={`filter-cell-first-cell`}>
      <div className="filter-cell">
        {["admin", "planner", "regular"].includes(role) && !isSwappingUsers && (
          <TeambookSortingTypes
            filterOrder={userOrder !== "null" ? userOrder : "default"}
            filterOptions={["default", "alphabetical"]}
            setFilterOrder={changeOrder}
          />
        )}
      </div>
    </div>
  );

  if (zoom === 1) {
    for (let i = 7; i < 23; i++) {
      rows.push(
        <div
          className="date-row__hours"
          style={{ borderTopRightRadius: i === 22 ? 7 : 0 }}
          key={`date-cell-${ToDateString(date)}-${i}`}
        >
          <p>{i}:00</p>
        </div>
      );
    }
  } else {
    for (let i = 1; i < days + 1; i++) {
      let newDate = OffsetDays(firstPlannerDate, i - 1);

      const displayAsRedText = !weekendsHidden && (weekendStart === i % 7 || (weekendStart + 1) % 7 === i % 7);

      const isLastCell = i === days;
      const isSecondLastCell = i === days - 1;

      if (weekendsHidden && (weekendStart === i % 7 || (weekendStart + 1) % 7 === i % 7)) {
        if (isWeekend(newDate)) {
          rows.push(
            <div style={{ width: 10 }} className="date-row__weekend-cell__container">
              <div
                className="date-row__weekend-cell"
                style={{
                  borderTopRightRadius: i === days ? 7 : 0,
                  borderRight: i === days ? "1px solid var(--stroke)" : "unset",
                  borderLeft: newDate.getDay() === 0 && "unset",
                }}
                key={`date-cell-${ToDateString(newDate)}`}
              >
                {monthName(zoom, newDate, t, isLastCell || isSecondLastCell)}
              </div>
              {(weekendStart + 1) % 7 === i % 7 && i !== days && (
                <div className="date-row__weekend-cell__right-border" />
              )}
            </div>
          );
        }
      } else {
        rows.push(
          <DateCell
            key={`date-cell-${ToDateString(newDate)}`}
            date={newDate}
            allRowsCreation={allRowsCreation}
            displayAsRedText={displayAsRedText}
            weekendsHidden={weekendsHidden}
            isLastCell={i === days}
          />
        );
      }
    }
  }

  return (
    <>
      <div className={`date-row__month-row zoom-month-row-${zoom}`}>{monthRow}</div>
      <div className="date-row__date-row">{rows}</div>
    </>
  );
};

export default DateRow;

import Api from "../../../Api";

async function GetTeamUsers(setTeamUsers, teamId) {
  await Api.Teams.get_users(teamId)
    .then((response) => {
      setTeamUsers(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
}

export default GetTeamUsers;

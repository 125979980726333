import React from "react";
import { useTranslation } from "react-i18next";
import loadingGif from "../default_images/loading.gif";

const LoadingComponent = ({ left, top }) => {
  const { t } = useTranslation();

  const style = {
    "--left": left ? `${left}%` : undefined,
    "--top": top ? `${top}%` : undefined,
  };

  return (
    <div style={style} className="loading-component">
      {/*{t("planning.loading")}*/}
      <img src={loadingGif} width={"20%"} />
    </div>
  );
};

export default LoadingComponent;

export default function filterUsers(usersArray, filterType, filterValues, teams, tags, t) {
  let users = [...usersArray];

  let filteredUsers = [];

  if (filterValues.length === 0) {
    return usersArray;
  }

  filterValues.forEach((value) => {
    if (filterType === "Or") {
      switch (value.type) {
        case t("planning.filter_users"):
          users.forEach((user) => {
            if (user.id === value.id) {
              filteredUsers.push(user);
            }
          });

        case t("planning.filter_teams"):
          teams?.forEach((team) => {
            if (team.id === value.id) {
              filteredUsers.push(...team.team_users);
            }
          });

        case t("planning.filter_tags"):
          tags?.forEach((tag) => {
            if (tag.id === value.id) {
              filteredUsers.push(...tag.tag_users.map((userId) => users.find((user) => user.id === userId)));
            }
          });

        case t("planning.filter_authorization"):
          users.forEach((user) => {
            if (user.role === value.id) {
              filteredUsers.push(user);
            }
          });

        case t("planning.filter_billability"):
          users.forEach((user) => {
            if (user.billable === value.id) {
              filteredUsers.push(user);
            }
          });
      }
    } else {
      let _users = [...users];

      _users = [...users].filter((user) => {
        return filterValues.every((val) => {
          if (val.type === t("planning.filter_users")) {
            return user.id === val.id;
          }

          if (val.type === t("planning.filter_teams")) {
            return val.team_users.includes(user.id);
          }

          if (val.type === t("planning.filter_tags")) {
            return val.tag_users.includes(user.id);
          }

          if (val.type === t("planning.filter_authorization")) {
            return user.role === val.id;
          }

          if (val.type === t("planning.filter_billability")) {
            return user.billable === val.id;
          }
        });
      });

      filteredUsers = [..._users];
    }
  });

  if (filteredUsers.length) {
    return filteredUsers.filter((obj1, i, arr) => {
      return (
        arr.findIndex((obj2) =>
          ["id"].every((key) => {
            if (obj1 && obj2) {
              return obj2[key] === obj1[key];
            } else {
              return false;
            }
          })
        ) === i
      );
    });
  } else return [];
}

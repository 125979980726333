import { CalcWeeksInMonth } from "../../../lib/DateUtils";
import React, { memo, useEffect, useState } from "react";
import moment from "moment";
import ApprovalWeek from "./ApprovalWeek";
import { useAccountStore } from "../../../stores/accountStore";
import { shallow } from "zustand/shallow";

const ApprovalDays = ({
  date,
  projects,
  user,
  updateActuals,
  actualLogs,
  beginningDate,
  activities,
  occupations,
  createOccupation,
  createActivity,
  changeContextMenu,
  changeTooltip,
  splitBy,
  users,
}) => {
  const [weeks, setWeeks] = useState([]);
  const [account] = useAccountStore((state) => [state.account], shallow);

  useEffect(() => {
    let _weeks = [];
    const weeksCount = CalcWeeksInMonth(moment(date), account.first_week_day);

    for (let index = 0; index < weeksCount; index++) {
      _weeks.push(
        <ApprovalWeek
          changeContextMenu={changeContextMenu}
          changeTooltip={changeTooltip}
          projects={projects}
          date={date}
          beginningDate={beginningDate}
          index={index}
          actualLogs={actualLogs}
          user={user}
          updateActuals={updateActuals}
          activities={activities}
          occupations={occupations}
          createOccupation={createOccupation}
          createActivity={createActivity}
          splitBy={splitBy}
          users={users}
          weeksCount={weeksCount}
          isLastWeek={weeksCount - 1 === index}
        />
      );
    }

    setWeeks(_weeks);
  }, [date, beginningDate, actualLogs]);

  return <div className="actuals__calendar">{weeks}</div>;
};

export default memo(ApprovalDays);

import React from "react";

const BookingTooltip = React.forwardRef(({}, ref) => {
  return (
    <div className="mobile_hidden tooltip-component" id="tooltip">
      <div className="tooltip-component__block">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <p className="tooltip-component__project-name" id="tooltip-projectName" />
          <p
            className="tooltip-component__booking-duration"
            id="tooltip-bookingDuration"
            style={{ fontWeight: "800" }}
          />
        </div>

        <p className="tooltip-component__task-name" id="tooltip-taskName" />

        <p className="tooltip-component__client-name" id="tooltip-clientName" />

        {/* <p className="tooltip-component__client-name" id="tooltip-taskName" /> */}

        <p className="tooltip-component__comment" id="tooltip-comment" />

        <p className="tooltip-component__team" id="tooltip-team" />
      </div>
      <div className="tooltip-component__triangle" id="tooltip-triangle" />

      <div className="tooltip-component__triangle_bg" id="tooltip-triangle" />
      <div className="tooltip-component__stub" />
    </div>
  );
});

export default BookingTooltip;

import React, { useContext } from "react";
import UsersProfileImage from "../../default_components/UsersProfileImage";
import CloseIcon from "@mui/icons-material/Close";
import { getCellWidthForZoom, getNumberOfDays, OffsetDays } from "../../../lib/DateUtils";
import { useTranslation } from "react-i18next";
import { ClickAwayListener } from "@mui/base";
import { useZoomStore } from "../../../stores/planner";
import { shallow } from "zustand/shallow";
import { PLANNER_USER_WIDTH } from "../../../lib/PlannerUtils";

const DayInfo = ({
  bookings,
  user,
  closeDayInfo,
  editBookings,
  deleteBookingsRequest,
  OpenCreationMenu,
  addBookingToUpdatingHash,
  mouseUped,
  date,
  maxHeight,
  weekendsHidden,
  plannerDate,
}) => {
  const role = localStorage.getItem("tb-role") || "regular";
  const { t } = useTranslation();
  const [zoom] = useZoomStore((state) => [state.zoom], shallow);

  const OpenEdit = (booking) => {
    editBookings(booking);
    closeDayInfo();
  };

  const DeleteBooking = (booking) => {
    deleteBookingsRequest(booking);
    closeDayInfo();
  };

  const CreateBooking = (e) => {
    OpenCreationMenu();
    closeDayInfo();
  };

  const calculateLeftOffset = () => {
    let dateOffset = getNumberOfDays(plannerDate, date);
    let finalOffset = 35;

    for (let i = 0; i < dateOffset; i++) {
      let isWeekend =
        weekendsHidden && (OffsetDays(plannerDate, i).getDay() === 0 || OffsetDays(plannerDate, i).getDay() === 6);
      finalOffset += isWeekend ? 10 : getCellWidthForZoom(zoom);
    }

    return finalOffset;
  };

  return (
    <ClickAwayListener onClickAway={closeDayInfo}>
      <div
        className="day-info"
        style={{
          marginTop: `${maxHeight}px`,
          left: `${calculateLeftOffset()}px`,
        }}
      >
        <div className="day-info__cancel-button">
          <CloseIcon onClick={closeDayInfo} />
        </div>

        {bookings.map((booking) => (
          <div className="day-info__booking">
            <div style={{ display: "flex" }}>
              <UsersProfileImage
                size={40}
                user={user}
                userImage={user.avatars["40"]}
                style={{ marginLeft: 15, marginRight: 15 }}
              />

              <div style={{ backgroundColor: booking.project_color }} className="day-info__booking-color" />

              <div className="day-info__info-text">
                <p className="bold">{booking.project_code}</p>
                <p className="bold">{booking.project_name}</p>
                {/*<p className="client">{booking.client_name}</p>*/}
              </div>

              <p className="day-info__info-hours">{booking.duration / 60}h</p>
            </div>
            {["admin", "planner", "self_planner"].includes(role) && (
              <div style={{ display: "flex" }}>
                <p className="day-info__info-link" onClick={() => OpenEdit(booking)}>
                  {t("edit")}
                </p>
                <p className="day-info__info-link" onClick={() => DeleteBooking(booking)}>
                  {t("delete")}
                </p>
              </div>
            )}
          </div>
        ))}

        {["admin", "planner", "self_planner"].includes(role) && (
          <p className="day-info__info-link" onClick={(e) => CreateBooking(e)}>
            {t("planning.add_booking")}
          </p>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default DayInfo;

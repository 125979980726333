const orderedProjects = (projects) => {
  if (!projects) {
    return [];
  }

  return projects.sort(function (a, b) {
    var nameA = a.name.toUpperCase(); // ignore upper and lowercase
    var nameB = b.name.toUpperCase(); // ignore upper and lowercase

    if (b.kind === "time_off" && a.kind !== "time_off") {
      return -2;
    } else if (a.kind === "time_off" && b.kind !== "time_off") {
      return 2;
    }

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
};

export default orderedProjects;

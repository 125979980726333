import "../my_week.scss";
import Api from "../../../Api";
import { useTranslation } from "react-i18next";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";
import React, { useEffect, useState } from "react";
import TeambookWhiteButton from "../../default_components/TeambookWhiteButton";
import { useMsal } from "@azure/msal-react";
import GoogleScreen from "./googleScreen.png";
import TeambookTextForm from "../../default_components/TeambookTextForm";
import TeambookBlueButton from "../../default_components/TeambookBlueButton";

export default function CalendarInegrationDialog({ open, setOpen, user }) {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState("general");
  const [outlookConnected, setOutlookConnected] = useState();
  const [outlookSynced, setOutlookSynced] = useState();

  useEffect(() => {
    Api.Integrations.Outlook.getCalendar().then((res) => {
      setOutlookConnected(res.data.connected);
      setOutlookSynced(res.data.last_sync);
    });
  }, []);

  const fetchOutlookData = () => {
    Api.Integrations.Outlook.getCalendar().then((res) => {
      setOutlookConnected(res.data.connected);
      setOutlookSynced(res.data.last_sync);

      setTimeout(() => {
        fetchOutlookData();
      }, 3000);
    });
  };

  const CloseDialog = () => {
    setOpen(false);
  };

  const outlookURL = () => {
    let url;

    switch (process.env.REACT_APP_ENV) {
      case "production":
        url = "https://web.teambookapp.com/api/auth/azure_activedirectory_v2";
        break;
      case "staging":
        url = "https://web.teambooktest.com/api/auth/azure_activedirectory_v2";
        break;
      default:
        url = "http://localhost:3001/api/auth/azure_activedirectory_v2";
        break;
    }

    return url;
  };

  const getProjectUrl = (copyProject) => {
    let SERVER_ADDRESS;

    switch (process.env.REACT_APP_ENV) {
      case "production":
        SERVER_ADDRESS = "https://web.teambookapp.com";
        break;
      case "staging":
        SERVER_ADDRESS = "https://web.teambooktest.com";
        break;
      default:
        SERVER_ADDRESS = "http://localhost:3000";
        break;
    }

    return `${SERVER_ADDRESS}/projection/${copyProject.url}`;
  };

  const clickOnCopyButton = () => {
    navigator.clipboard.writeText(user.calendar_token ? Api.Calendar.link(user.calendar_token) : getProjectUrl(user));
  };

  const loginUsingAzure = (token) => {
    console.log(token);
    // Api.Auth.login_azure(token)
    //   .then(() => redirectToMainPage())
    //   .catch((error) => {
    //     setErrorMessage(error.response.data.error);
    //   });

    // if (accounts.length > 0) {
    //   const request = {
    //     ...calendarIntegrationRequest,
    //     account: accounts[0],
    //   };
    //
    //   const acquireToken = async () => {
    //     try {
    //       const response = await instance.acquireTokenSilent(request);
    //
    //       console.log(response);
    //     } catch (e) {
    //       console.error(e);
    //     }
    //   };
    //
    //   acquireToken();
    // }
  };

  return (
    <Dialog
      className="tb-default-dialog calendar-integration-modal"
      open={open}
      onClose={CloseDialog}
      aria-label="calendar integration modal"
    >
      <DialogTitle>
        <p>{t("my_week.calendar_integration")}</p>
        <TeambookIcon name={icons.CROSS} onClick={CloseDialog} />
      </DialogTitle>

      <DialogContent>
        <div style={{ width: "100%" }} className="flex users-data__tabs">
          <div
            className={`calendar-integration__tabs ${selectedTab === "general" && "active"}`}
            onClick={() => setSelectedTab("general")}
          >
            <TeambookIcon name={icons.LINK} />
            <p>Calendar URL</p>
          </div>

          <div
            className={`calendar-integration__tabs ${selectedTab === "outlook" && "active"}`}
            onClick={() => setSelectedTab("outlook")}
          >
            <TeambookIcon name={icons.OUTLOOK} />
            <p>Outlook Calendar</p>
          </div>
        </div>

        <div>
          {selectedTab === "general" && (
            <div className="calendar-integration__content">
              <div className="integration__firstStep">
                <p>1. {t("my_week.copy_link")}</p>

                <div className="integration__urlForm">
                  <TeambookTextForm
                    fieldValue={user.calendar_token ? Api.Calendar.link(user.calendar_token) : getProjectUrl(user)}
                    width={"320px"}
                  />

                  <TeambookBlueButton
                    className="integration__copyButton"
                    onClick={clickOnCopyButton}
                    text={t("my_week.copy")}
                  />
                </div>
              </div>

              <div className="integration__secondStep">
                <p>
                  2. {t("my_week.favourite_calendar").split("__")[0]}
                  <a target="_blank" href="https://calendar.google.com/calendar/">
                    {t("my_week.google_calendar")}
                  </a>
                  ,{" "}
                  <a target="_blank" href="https://outlook.live.com/calendar/0/view/month">
                    {t("my_week.outlook")}
                  </a>
                  {t("my_week.favourite_calendar").split("__")[1]}
                </p>
              </div>

              <div className="integration__thirdStep">
                <p>3. {t("my_week.create_calendar")}</p>
                <img src={GoogleScreen} />
              </div>
            </div>
          )}

          {selectedTab === "outlook" && (
            <div className="outlook-integration">
              {outlookConnected !== null && outlookConnected ? (
                <>
                  <p className="connected-text">Connected</p>
                  <TeambookWhiteButton
                    text={"Disconnect"}
                    onClick={() => {
                      Api.Integrations.Outlook.deleteCalendar().then(() => {
                        setOutlookConnected(false);
                        setOutlookSynced(null);
                      });
                    }}
                    className="disconnect-button"
                  />
                </>
              ) : (
                <TeambookBlueButton
                  text={"Connect to Outlook"}
                  onClick={() => {
                    fetchOutlookData();

                    window.open(outlookURL(), "_blank");
                  }}
                />
              )}
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}

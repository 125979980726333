import $ from "jquery";
import { SliceStringWithDots } from "../../../lib/StringFormat";

const TooltipChanger = (newTooltip) => {
  const removeHTMLTags = (str) => {
    return SliceStringWithDots(str.replace(/(<([^>]+)>)/gi, " "), 30);
  };

  const tooltipTopOffset = newTooltip.positionY - 250 < 98 ? 98 : newTooltip.positionY - 250;

  $("#tooltip").css({
    display: `${newTooltip.open ? "block" : "none"}`,
    top: `${tooltipTopOffset}px`,
    left: `${newTooltip.positionX}px`,
  });

  if (newTooltip.open) {
    $("#tooltip-projectName").text(SliceStringWithDots(newTooltip.projectName, 24));
    $("#tooltip-bookingDuration").text(`${newTooltip.bookingDuration / 60}h`);
    $("#tooltip-comment").text(newTooltip.comment && SliceStringWithDots(removeHTMLTags(newTooltip.comment), 25));

    $("#tooltip-team").text(SliceStringWithDots(newTooltip.team?.name || "", 25));

    let clientNameField = $("#tooltip-clientName");

    if (newTooltip.clientName) {
      clientNameField.css({ display: "block" });
      clientNameField.text(SliceStringWithDots(newTooltip.clientName, 25));
    } else {
      clientNameField.css({ display: "none" });
    }

    let taskName = "";

    if (newTooltip.task?.id) {
      taskName = newTooltip.task?.name;
    }

    $("#tooltip-taskName").text(SliceStringWithDots(taskName, 25));
    // $("#tooltip-triangle").css({ marginLeft: 110 });
  }
};

export default TooltipChanger;

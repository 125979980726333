import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Api from "../../../Api";
import TeambookBlueButton from "../../default_components/TeambookBlueButton";
import ControlPanel from "../ControlPanel";

export default function ClientsFeedback({ messages }) {
  return (
    <>
      <Link to="/administration">
        <TeambookBlueButton
          style={{
            zIndex: "1",
            position: "absolute",
            left: "5px",
            top: "5px",
          }}
          text={"To Administration"}
        />
      </Link>

      <table
        style={{
          width: "99.5%",
          margin: "70px 5px",
          textAlign: "left",
        }}
        border="1px solid black"
      >
        <tr style={{ textAlign: "center" }}>
          <th style={{ width: "25%", padding: 10 }}>From</th>
          <th style={{ width: "50%", padding: 10 }}>Message</th>
          <th style={{ width: "27.5%", padding: 10 }}>Send at</th>
        </tr>
        {messages.map((message) => {
          return (
            <tr>
              <td style={{ width: "25%", padding: 10 }}>
                <p style={{ margin: 0 }}> Account: {message.account?.name}</p>
                <p style={{ margin: 0 }}>{`User: ${message.user?.first_name} ${message.user?.last_name}`}</p>
                <p style={{ margin: 0 }}>Email: {message.user?.email}</p>
              </td>
              <td style={{ width: "50%", padding: 10 }}>{message.message}</td>
              <td style={{ width: "25%", padding: 10 }}>{message.created_at}</td>
            </tr>
          );
        })}
      </table>

      <ControlPanel />
    </>
  );
}

import React, { useEffect, useState } from "react";
import { useTeambookFilter } from "../../../../../stores/planner";
import { useTranslation } from "react-i18next";
import { OffsetDays } from "../../../../../lib/DateUtils";
import { DateTime } from "luxon";

const BottomTotal = ({ stats }) => {
  const [totalColumns, setTotalColumns] = useState([]);
  const { t } = useTranslation();

  const { filterValues } = useTeambookFilter();

  useEffect(() => {
    let columns = [];
    let currentDate = new Date(stats?.start_date);

    for (let i = 0; i < stats.amount_of_days; i++) {
      if (DateTime.fromJSDate(currentDate).weekday === 6) {
        columns.push(
          <td className="bottom-total__table-column">
            <p className="bottom-total__day-text" style={{ color: "var(--accent-light)" }}>
              {stats.hours_per_day[i]}
            </p>
          </td>
        );
      } else if (DateTime.fromJSDate(currentDate).weekday === 7) {
        columns.push(
          <td className="bottom-total__table-column" style={{ borderRight: "1px solid var(--stroke)" }}>
            <p className="bottom-total__day-text" style={{ color: "var(--accent-light)" }}>
              {stats.hours_per_day[i]}
            </p>
          </td>
        );
      } else {
        columns.push(
          <td className="bottom-total__table-column">
            <p className="bottom-total__day-text">{stats.hours_per_day[i]}</p>
          </td>
        );
      }

      currentDate = OffsetDays(currentDate, 1);
    }

    columns.push(<td className="bottom-total__totals-column" />);

    setTotalColumns(columns);
  }, [stats]);

  if (filterValues.length > 0) {
    return;
  }

  return (
    <tr className="bottom-total__row">
      <td className="bottom-total__fixed-column">
        <p>{t("dashboard.totals")}</p>
      </td>

      {totalColumns}
    </tr>
  );
};

export default BottomTotal;

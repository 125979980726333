import { useTranslation } from "react-i18next";
import { icons } from "../default_images/IconsList";
import { TeambookIcon } from "../default_images/TeambookIcon";
import { DatePicker } from "@mui/x-date-pickers";

export default function MuiDatePicker(props) {
  const { t } = useTranslation();
  const FilledCalendar = () => (
    <TeambookIcon style={{ width: 24, height: 24 }} name={icons.FILLED_CALENDAR} tooltipTitle={t("actuals.select_date")} />
  );

  return (
    <DatePicker
      {...props}
      slots={{
        openPickerIcon: FilledCalendar,
      }}
    />
  );
}

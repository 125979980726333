import React from "react";
import { useGoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { useTranslation } from "react-i18next";
import { TeambookIcon } from "../default_images/TeambookIcon";
import { icons } from "../default_images/IconsList";

const GoogleLoginButton = ({ successGoogle, responseGoogle, text, id = "google-login-button" }) => {
  const { t } = useTranslation();

  const loginGoogle = useGoogleLogin({
    onSuccess: async (codeResponse) => successGoogle(codeResponse),
    onError: responseGoogle,
    flow: "auth-code",
  });

  return (
    <div className="login-with-google-button" id={id} onClick={() => loginGoogle()}>
      <p id={id}>
        <TeambookIcon
          style={{
            marginRight: "10px",
          }}
          name={icons.GOOGLE}
          id={id}
        />
        {text}
      </p>
    </div>
  );
};

export default GoogleLoginButton;

import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Api from "../../../Api";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import XLSExport from "../../../lib/XLSExport";
import LoadingComponent from "../../default_components/LoadingComponent";
import TeambookBlueButton from "../../default_components/TeambookBlueButton";
import TeambookOutlinedButton from "../../default_components/TeambookOutlinedButton";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";

const UploadUsersDialog = ({ open, handleClose, getAllUsers }) => {
  const { t } = useTranslation();
  const [users, setUsers] = useState("");
  const [errors, setErrors] = useState();
  const [loading, setLoading] = useState(false);

  const uploadUsers = () => {
    setLoading(true);

    Api.Users.upload({
      users_string: users,
    }).then((response) => {
      setLoading(false);
      setErrors();

      if (response.data.errors.length > 0) {
        setUsers("");
        setErrors(response.data.errors);
      } else {
        setUsers("");
        getAllUsers();
        handleClose();
      }
    });
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
        setErrors();
      }}
      className="tb-default-dialog s"
    >
      <DialogTitle id="add-user-dialog">
        <p>{t("users.upload_users")}</p>
        <TeambookIcon
          name={icons.NEW_CROSS}
          onClick={() => {
            handleClose();
            setErrors();
          }}
        />
      </DialogTitle>
      <DialogContent style={{ margin: "20px 0", width: 490, scrollbarGutter: "unset" }}>
        <div className="users__import-defaul-text flex">
          <p>{t("users.integrations_users")}</p>
          &nbsp;
          <NavLink
            to={{
              pathname: "/settings",
              search: "?page=integration",
            }}
          >
            <p style={{ textDecoration: "underline", color: "var(--header-nav-color)" }}>{t("users.integrations_users_link")}</p>
          </NavLink>
        </div>

        <div className="users__import-or-line">
          <div className="line" />
          <p>{t("or").toLowerCase()}</p>
          <div className="line" />
        </div>

        <div className="users__import-inline-text">
          <p>{t("users.upload_first")}</p>
          &nbsp;
          <p className="users__import-link-text" onClick={XLSExport.ExportUploadingUsersFile}>
            {t("users.upload_first_file")}
          </p>
        </div>
        <div className="users__import-defaul-text">
          <p style={{ marginTop: 10 }}>{t("users.upload_second")}</p>
          <p style={{ margin: "10px 0 20px 0" }}>{t("users.upload_third")}</p>
        </div>

        <TextField
          id="outlined-multiline-static"
          className="text-field tb__bg__white full-width actuals-text-field"
          label={t("header.users")}
          placeholder={"Sylvester\tLeonard\tsylvester@teambook.com\nValentino\tMontalti\n"}
          multiline
          rows="4"
          variant="outlined"
          onChange={(event) => setUsers(event.target.value)}
          value={users}
        />

        {users.length > 0 && <p className="danger-text">CAUTION! If you add an email address, the user will receive an invitation email</p>}

        {errors && (
          <div className="danger-text">
            <p>Some records were not uploaded:</p>
            {errors.map((error) => (
              <p>{error}</p>
            ))}
          </div>
        )}
        {loading && <LoadingComponent />}
      </DialogContent>
      <DialogActions>
        <TeambookOutlinedButton
          onClick={() => {
            handleClose();
            setErrors();
          }}
          text={t("cancel")}
        />
        <TeambookBlueButton text={t("upload")} disabled={loading} onClick={uploadUsers} />
      </DialogActions>
    </Dialog>
  );
};

export default UploadUsersDialog;

import React from "react";
import "../capacity_planning.scss";
import { DateTime } from "luxon";
import { getWorkingDays } from "../../../lib/DateUtils";

const DatesRow = ({ date, selectedViewType, type = "planning" }) => {
  return (
    <>
      <div
        className="users-block__dates-row"
        style={{
          borderBottom: "none",
        }}
      >
        <div className="left-side"></div>

        <div style={{ paddingLeft: 30 }} className="flex right-side">
          {[...Array(selectedViewType)].map((x, i) => (
            <div
              className="table-cell"
              style={{
                height: 44,
                padding: 0,
                borderBottom: "none",
              }}
            >
              <div className={`table-cell__content`}>
                {(date.plus({ months: i }).month === 1 ||
                  date.plus({ months: i }).startOf("month").ts === DateTime.now().startOf("month").ts) && (
                  <p className="year">{date.plus({ months: i }).toFormat("yyyy")}</p>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      {type === "reporting" ? (
        <div
          className="users-block__dates-row"
          style={{
            top: "60px",
            paddingleft: 30,
            height: 31,
          }}
        >
          <div
            style={{
              borderTop: type === "reporting" && "1px solid #c8d2e6",
              height: type === "reporting" ? 31 : 60,
              background: "#fff",
            }}
            className="left-side"
          >
            <div />
          </div>
          <div
            style={{
              paddingLeft: 30,
              height: type === "reporting" ? 31 : 60,
            }}
            className="flex right-side"
          >
            {[...Array(selectedViewType)].map((x, i) => (
              <div
                style={{
                  height: 31,
                  background: "red",
                }}
                className="table-cell"
              >
                <p>{date.plus({ months: i }).toFormat("LLL").toUpperCase()}</p>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div
          className="users-block__dates-row is-capacity-months"
          style={{
            top: 44,
          }}
        >
          <div
            className="left-side second-row"
            style={{
              height: 36,
            }}
          >
            <div />
          </div>
          <div style={{ paddingLeft: 30, height: 36 }} className="flex right-side">
            {[...Array(selectedViewType)].map((x, i, arr) => (
              <div
                className={`table-cell ${
                  date.plus({ months: i }).startOf("month").ts === DateTime.now().startOf("month").ts && "current-month"
                }`}
                style={{
                  height: 36,
                  borderLeft: i === 0 && "1px solid var(--stroke)",
                  borderRight: "1px solid var(--stroke)",
                  borderTop: "1px solid var(--stroke)",
                  borderTopRightRadius: arr.length - 1 === i ? 7 : 0,
                }}
              >
                <div>
                  <p>{date.plus({ months: i }).toFormat("LLL").toUpperCase()}</p>
                  <p style={{ fontSize: 11 }}>
                    ({getWorkingDays(date.year, date.plus({ months: i }).month)}
                    d)
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default DatesRow;

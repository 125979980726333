import React, { useEffect, useState } from "react";
import ApprovalDay from "./ApprovalDay";
import ApprovalWeekTotal from "./ApprovalWeekTotal";
import { DateTime } from "luxon";

const ApprovalWeek = ({
  date,
  beginningDate,
  index,
  projects,
  actualLogs,
  updateActuals,
  activities,
  occupations,
  createOccupation,
  createActivity,
  changeContextMenu,
  changeTooltip,
  splitBy,
  users,
  weeksCount,
  isLastWeek,
}) => {
  const [weeklyLogs, setWeeklyLogs] = useState([]);
  const [days, setDays] = useState([]);
  const week = index % 7;

  useEffect(() => {
    let updatedWeeklyLogs = [];
    let localDays = [];

    for (let i = 0; i < 7; i++) {
      let dailyLogs = actualLogs.filter(
        (log) =>
          DateTime.fromISO(log.date).startOf("day").toISODate() ===
          beginningDate
            .plus({ days: i + week * 7 })
            .startOf("day")
            .toISODate()
      );

      localDays.push(
        <ApprovalDay
          changeTooltip={changeTooltip}
          changeContextMenu={changeContextMenu}
          projects={projects}
          beginningDate={date}
          date={beginningDate.plus({ day: i + week * 7 })}
          logs={dailyLogs}
          updateActuals={updateActuals}
          activities={activities}
          occupations={occupations}
          createOccupation={createOccupation}
          createActivity={createActivity}
          splitBy={splitBy}
          users={users}
          isLast={i === 6 && weeksCount - 1 === index}
          isLastWeek={isLastWeek}
        />
      );

      updatedWeeklyLogs.push(...dailyLogs);
    }

    setWeeklyLogs(updatedWeeklyLogs);
    setDays(localDays);
  }, [date, actualLogs, beginningDate]);

  return (
    <div key={"week-" + index} className="week-row">
      <ApprovalWeekTotal isLast={weeksCount - 1 === index} weeklyLogs={weeklyLogs} />
      <div className="week-row__week-days">{days}</div>
    </div>
  );
};

export default ApprovalWeek;

import React, { useState, useEffect } from "react";
import "../../../default_styles/tb-colors.css";
import Header from "../../../header/Header";
import DeactivatedFilterRow from "./DeactivatedFilterRow";
import ListSeparator from "../../../default_components/ListSeparator";
import DeactivatedUserListBlock from "./DeactivatedUserListBlock";
import UserForm from "../UserForm";
import Api from "../../../../Api";

function DeactivatedUsersPage() {
  const [users, setUsers] = useState(null);
  const [chosenUser, setChosenUser] = useState(null);

  useEffect(() => {
    requestDeactivatedUsers();
  }, []);

  const filterDeactivatedUsers = (string) => {
    Api.Users.deactivated(string).then(analyzeDeactivatedUsersResponse);
  };

  const analyzeDeactivatedUsersResponse = (response) => {
    if (response.data.length > 0) {
      setChosenUser(response.data[0]);
      setUsers(response.data);
    } else {
      console.log("NO DATA!");
    }
  };

  const requestDeactivatedUsers = () => {
    Api.Users.deactivated().then(analyzeDeactivatedUsers);
  };

  const analyzeDeactivatedUsers = (response) => {
    setUsers(response.data);
    setChosenUser(response.data[0]);
  };

  const activateUserRequest = (user_id) => {
    Api.Users.activate([user_id]).then(analyzeActivateUser);
  };

  const analyzeActivateUser = (response) => {
    if (response.status === 200) {
      window.location.reload();
    } else {
      console.log(response.status);
    }
  };

  const showUser = (user) => {
    setChosenUser(user);
  };

  if (users === null) {
    requestDeactivatedUsers();

    return <div />;
  }

  if (chosenUser === null || chosenUser === undefined) {
    return <div />;
  }

  let secondNameLetter = ".";

  return (
    <div>
      <Header page={"users"} />

      <DeactivatedFilterRow filterDeactivatedUsers={filterDeactivatedUsers} />

      <div className="users-page__users-content">
        <div className="users-page__user-list">
          {users.map((item, key) => {
            if (secondNameLetter !== item.last_name[0]) {
              secondNameLetter = item.last_name[0];
              return (
                <>
                  <ListSeparator text={item.last_name[0]} />

                  <DeactivatedUserListBlock
                    key={item.id}
                    user={item}
                    chosen_user={chosenUser}
                    showUser={showUser}
                    activateUserRequest={activateUserRequest}
                  />
                </>
              );
            }

            return (
              <DeactivatedUserListBlock
                key={item.id}
                user={item}
                chosen_user={chosenUser}
                showUser={showUser}
                activateUserRequest={activateUserRequest}
              />
            );
          })}
        </div>

        <UserForm user={chosenUser} page={"show"} />
      </div>
    </div>
  );
}

export default DeactivatedUsersPage;

import React, { useState, useEffect } from "react";
import { WhiteTooltip } from "../../../default_components/Tooltips";
import { useTranslation } from "react-i18next";
import Api from "../../../../Api";
import CreateTask from "./modals/CreateTask";
import EditTask from "./modals/EditTask";
import DeleteTask from "./modals/DeleteTask";
import { alphabeticalSort } from "../lib/alpha_sort";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { icons } from "../../../default_images/IconsList";

const Tasks = () => {
  const { t } = useTranslation();
  const [tasks, setTasks] = useState([]);
  const [openTasksCreation, setOpenTasksCreation] = useState(false);
  const [openTasksEditing, setOpenTasksEditing] = useState(false);
  const [openTasksDeletion, setOpenTasksDeletion] = useState(false);
  const [selectedTask, setSelectedTask] = useState({});

  useEffect(() => {
    getTasks();
  }, []);

  const getTasks = () => {
    Api.Tasks.get().then((response) => {
      setTasks(
        alphabeticalSort(
          response.data.filter((task) => task.project_id === null),
          "a"
        )
      );
    });
  };

  const openEditing = (project) => {
    setOpenTasksEditing(true);
    setSelectedTask(project);
  };

  const openDeleteQuestionDialog = (project) => {
    setOpenTasksDeletion(true);
    setSelectedTask(project);
  };

  return (
    <>
      <div className="time-off__control-row">
        <p className="tb__text__light-black">{t("settings.tasks.task_management")}</p>

        <WhiteTooltip title={t("settings.tasks.create_task")}>
          <div className="teambook-icon-frame" onClick={() => setOpenTasksCreation(true)}>
            <TeambookIcon name={icons.ADD} color="alternative_default" />
          </div>
        </WhiteTooltip>
      </div>

      <div className="time-off__scroll-component">
        {tasks.map((task) => (
          <div className="time-off__block" key={task.name} aria-label="block time off">
            <div className="time-off__description">
              <p>{task.name}</p>
            </div>

            <div className="time-off__block-content">
              <WhiteTooltip title={t("edit")}>
                <div className="teambook-icon-frame" onClick={() => openEditing(task)}>
                  <TeambookIcon name={icons.PENCIL} color="alternative_default" />
                </div>
              </WhiteTooltip>

              <WhiteTooltip title={t("delete")}>
                <div className="teambook-icon-frame" onClick={() => openDeleteQuestionDialog(task)}>
                  <TeambookIcon name={icons.TRASH} color="alternative_default" />
                </div>
              </WhiteTooltip>
            </div>
          </div>
        ))}
      </div>

      {openTasksCreation && (
        <CreateTask open={openTasksCreation} setOpen={setOpenTasksCreation} getProjects={getTasks} />
      )}

      {openTasksEditing && (
        <EditTask
          open={openTasksEditing}
          setOpen={setOpenTasksEditing}
          getProjects={getTasks}
          selectedTimeOff={selectedTask}
        />
      )}

      {openTasksDeletion && (
        <DeleteTask
          open={openTasksDeletion}
          setOpen={setOpenTasksDeletion}
          getTasks={getTasks}
          selectedTimeOff={selectedTask}
        />
      )}
    </>
  );
};

export default Tasks;

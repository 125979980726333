import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Dialog from "@mui/material/Dialog";
import TeambookTextForm from "../../default_components/TeambookTextForm";
import Api from "../../../Api";
import GetTeamsRequest from "../requests/GetTeamsRequest";
import FormErrorMessage from "../../default_components/FormErrorMessage";
import DeleteTeamQuestionDialog from "../modals/DeleteTeamQuestionDialog";
import { WhiteTooltip } from "../../default_components/Tooltips";
import lsKeys from "../../default_values/defaultKeys";
import { DialogTitle } from "@mui/material";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";
import { useTeambookFilter } from "../../../stores/planner";
import { useNotificationStore } from "../../../stores/notificationStore";
import { shallow } from "zustand/shallow";

const TeamSelect = ({
  teams,
  teamId,
  changeTeam = () => {},
  setTeams,
  dialogOpened,
  setDialogOpened,
  editable = true,
}) => {
  const [setInAppNotification] = useNotificationStore((state) => [state.setInAppNotification], shallow);

  const { t } = useTranslation();

  const [creationRowOpened, setCreationRowOpened] = useState(false);
  const [editingTeamId, setEditingTeamId] = useState();
  const [editingTeamName, setEditingTeamName] = useState();
  const [creationTeamName, setCreationTeamName] = useState();
  const [errorMessage, setErrorMessage] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deletingTeam, setDeletingTeam] = useState({});

  const { filterValues, setFilterValue } = useTeambookFilter();

  const role = localStorage.getItem("tb-role") || "regular";

  if (teamId === undefined || teams.size === 0) return null;

  const openEditTeam = (id, name) => {
    setEditingTeamId(id);
    setEditingTeamName(name);
    setCreationRowOpened(false);
  };

  const deleteTeam = (id) => {
    setEditingTeamId(null);

    Api.Teams.delete(id).then((response) => {
      GetTeamsRequest(setTeams, true);

      if (id.toString() === teamId.toString()) {
        window.location.replace(`/planners`);
      }
    });
  };

  const updateTeam = () => {
    if (editingTeamName === undefined || editingTeamName.length < 3 || editingTeamName.length > 30) {
      setErrorMessage("Team name must be 3 to 30 characters long.");
    } else {
      let teamUsers = teams.get(editingTeamId.toString()).team_users.map((user) => user.id);

      if (teams.get(`${editingTeamId}`).name === editingTeamName) {
        closeEditTeam();
      } else {
        Api.Teams.update(editingTeamId, editingTeamName, teamUsers)
          .then((response) => {
            GetTeamsRequest(setTeams, true);
            setEditingTeamId(null);
            setInAppNotification(t("notifications.planning.team.edit"));
          })
          .catch((error) => {
            setErrorMessage(error.response.data.error);
          });
      }
    }
  };

  const closeEditTeam = () => {
    setEditingTeamId(null);
    closeErrorMessage();
  };

  const closeErrorMessage = () => {
    setErrorMessage(null);
  };

  const changeTeamFunc = (id) => {
    if (!editable) {
      setFilterValue([]);

      localStorage.setItem(lsKeys.LAST_PLANNER_FILTERS, JSON.stringify([]));
      localStorage.setItem(lsKeys.LAST_TEAMID_KEY, id);

      window.location.replace(`/${localStorage.getItem(lsKeys.LAST_PLANNER_TYPE)}`);
    }
  };

  const openDeleteDialog = (selectedTeam) => {
    setDeleteDialogOpen(true);
    setDeletingTeam(selectedTeam);
  };

  const createTeam = () => {
    closeErrorMessage();

    if (creationTeamName === undefined || creationTeamName.length < 3 || creationTeamName.length > 30) {
      setErrorMessage("Team name must be 3 to 30 characters long.");
    } else {
      Api.Teams.create(creationTeamName)
        .then((response) => {
          GetTeamsRequest(setTeams, true);
          setCreationRowOpened(false);
          setCreationTeamName("");
          setInAppNotification(t("notifications.planning.team.create"));
        })
        .catch((error) => {
          setErrorMessage(error.response.data.error);
        });
    }
  };

  const keyDownHandler = ({ key }) => {
    if (creationRowOpened || editingTeamId !== null || editingTeamId !== undefined) {
      switch (key) {
        case "Escape":
          if (creationRowOpened) {
            setCreationTeamName("");
            setCreationRowOpened(false);
          }
          if (editingTeamId !== null) {
            closeEditTeam();
          }
          break;
        case "Enter":
          if (creationRowOpened) {
            createTeam();
          }
          if (editingTeamId !== null) {
            updateTeam();
          }
          break;
      }
    }
  };

  return (
    <Dialog
      onKeyDown={keyDownHandler}
      className="tb-default-dialog"
      open={dialogOpened}
      onClose={() => {
        if (!creationRowOpened && (editingTeamId === null || editingTeamId === undefined)) {
          setDialogOpened(false);
          setCreationRowOpened(false);
          closeEditTeam();
          setCreationTeamName("");
        } else {
          setCreationRowOpened(false);
          closeEditTeam();
        }
      }}
      aria-labelledby="deactivation-dialog"
      fullWidth={true}
      maxWidth={"sm"}
      hideBackdrop={deleteDialogOpen}
    >
      <DialogTitle>
        <div style={{ display: "flex", alignItems: "center", gap: 15 }}>
          <p>{t("planning.manage_teams")}</p>

          {editable && ["admin", "planner"].includes(role) && (
            <WhiteTooltip title={t("planning.new_team")}>
              <TeambookIcon
                id={"newTeam"}
                name={icons.ADD}
                onClick={() => {
                  closeEditTeam();
                  setCreationRowOpened(true);
                }}
                alt={"add"}
              />
            </WhiteTooltip>
          )}
        </div>

        <TeambookIcon
          onClick={() => {
            setDialogOpened(false);
            setCreationRowOpened(false);
            closeEditTeam();
          }}
          name={icons.CROSS}
          alt={"close"}
        />
      </DialogTitle>

      <DialogContent style={{ margin: "20px 0px", height: 300 }}>
        <DialogContentText style={{ color: "var(--text-2)", fontSize: "1.2rem" }}>
          {creationRowOpened && (
            <>
              <div
                className="manage-team-dialog__team-row creation"
                key={"creation"}
                style={{ cursor: editable ? "unset" : "pointer" }}
              >
                <TeambookTextForm
                  id={"teamName"}
                  onChange={(text) => setCreationTeamName(text)}
                  type={"text"}
                  fieldValue={creationTeamName}
                  width={"260px"}
                  style={{ margin: "0px" }}
                  placeholder={t("planning.team_name")}
                />
                {["admin", "planner"].includes(role) && (
                  <div
                    style={{
                      display: "flex",
                      gap: "36px",
                      alignItems: "center",
                    }}
                  >
                    <WhiteTooltip title={t("save")}>
                      <TeambookIcon
                        id={"createTeamButton"}
                        name={icons.CHECK}
                        alt={t("create")}
                        width
                        onClick={createTeam}
                        className={"manage-team-icon"}
                        color="alternative_default"
                      />
                    </WhiteTooltip>

                    <WhiteTooltip title={t("cancel")}>
                      <TeambookIcon
                        name={icons.CROSS}
                        alt={t("cancel")}
                        onClick={() => {
                          setCreationRowOpened(false);
                          setCreationTeamName("");
                          closeErrorMessage();
                        }}
                        className={"manage-team-icon"}
                        color="alternative_default"
                      />
                    </WhiteTooltip>
                  </div>
                )}
              </div>

              {errorMessage !== null ? (
                <FormErrorMessage text={errorMessage} closeErrorMessage={closeErrorMessage} />
              ) : null}
            </>
          )}

          {Array.from(teams, ([key, item]) =>
            !(editingTeamId === item.id) ? (
              <div className="manage-team-dialog__team-row" key={key}>
                <p
                  style={{
                    wordBreak: "break-all",
                    margin: "0px",
                    display: "flex",
                    alignItems: "center",
                    padding: "8px 0px",
                    width: "100%",
                    cursor: editable ? "unset" : "pointer",
                  }}
                  onClick={() => changeTeamFunc(item.id)}
                >
                  {item.name}
                  {` (${item.team_users.length} ${
                    item.team_users.length === 1
                      ? t("planning.filter.user").toLowerCase()
                      : t("header.users").toLowerCase()
                  })`}
                </p>

                <div className="manage-team-dialog__team-actions">
                  {editable && ["admin", "planner"].includes(role) && (
                    <div
                      style={{
                        display: "flex",
                        gap: "36px",
                        alignItems: "center",
                      }}
                    >
                      <WhiteTooltip title={t("edit")}>
                        <TeambookIcon
                          id={"editTeam"}
                          onClick={() => openEditTeam(item.id, item.name)}
                          name={icons.PENCIL}
                          color="alternative_default"
                          className={"manage-team-icon"}
                        />
                      </WhiteTooltip>

                      <WhiteTooltip title={t("delete")}>
                        <TeambookIcon
                          onClick={() => openDeleteDialog(item)}
                          name={icons.TRASH}
                          color="alternative_default"
                          className={"manage-team-icon"}
                        />
                      </WhiteTooltip>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <>
                <div className="manage-team-dialog__team-row editing" key={key}>
                  <TeambookTextForm
                    id={"editingTeamName"}
                    onChange={(text) => setEditingTeamName(text)}
                    type={"text"}
                    fieldValue={editingTeamName}
                    width={"260px"}
                    style={{ margin: "0px" }}
                  />

                  <div
                    style={{
                      display: "flex",
                      gap: "36px",
                      alignItems: "center",
                    }}
                  >
                    <WhiteTooltip title={t("save")}>
                      <TeambookIcon
                        id={"updateTeamButton"}
                        className={"manage-team-icon"}
                        name={icons.CHECK}
                        onClick={updateTeam}
                        alt={t("save")}
                        color="alternative_default"
                      />
                    </WhiteTooltip>

                    <WhiteTooltip title={t("cancel")}>
                      <TeambookIcon
                        className={"manage-team-icon"}
                        name={icons.CROSS}
                        onClick={closeEditTeam}
                        alt={t("cancel")}
                        color="alternative_default"
                      />
                    </WhiteTooltip>
                  </div>
                </div>

                {errorMessage !== null ? (
                  <FormErrorMessage text={errorMessage} closeErrorMessage={closeErrorMessage} />
                ) : null}
              </>
            )
          )}
        </DialogContentText>
      </DialogContent>
      <DeleteTeamQuestionDialog
        teams={teams}
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        deletingTeam={deletingTeam}
        deleteTeam={deleteTeam}
      />
    </Dialog>
  );
};

export default TeamSelect;

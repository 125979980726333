import React, { useContext } from "react";
import { MonthsOfTheYear } from "../../../lib/DateUtils";
import { PlannerContext } from "../context/PlannerContext";
import { DateTime } from "luxon";

const monthName = (zoom, date, t, hideMonthName) => {
  let monthsArray = MonthsOfTheYear(t);
  const { plannerDate } = useContext(PlannerContext);

  if (showMonthName(date, plannerDate, hideMonthName)) {
    return <p className="planner-month-name">{monthsArray[date.getMonth()]}</p>;
  }
};

const showMonthName = (date, plannerDate, hideMonthName) => {
  const firstMonthDay = date.getDate() === 1;

  const _plannerDate = DateTime.fromJSDate(plannerDate);
  const firstPlannerDate =
    _plannerDate.startOf("day").ts === DateTime.fromJSDate(date).startOf("day").ts &&
    _plannerDate.day < _plannerDate.endOf("month").day;

  return !hideMonthName && (firstMonthDay || firstPlannerDate);
};

export default monthName;

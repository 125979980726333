import React, { useState } from "react";
import TeambookOutlinedButton from "../../../default_components/TeambookOutlinedButton";
import { useTranslation } from "react-i18next";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { icons } from "../../../default_images/IconsList";
import { DialogTitle } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";

const QuickBooksBlock = ({ qbData }) => {
  const { t } = useTranslation();
  const [qbDataOpen, setQbDataOpen] = useState(false);
  const token = localStorage.getItem("Auth-Token");

  let link;

  switch (process.env.REACT_APP_ENV) {
    case "production":
      link = `https://web.teambookapp.com/api/qbwc/qwc?token=${token}`;
      break;
    default:
      link = `http://web.teambooktest.com/api/qbwc/qwc?token=${token}`;
      break;
  }

  const qbIntegrated = () => {
    return qbData?.synced_logs_count;
  };

  const openQBDataModal = () => {
    setQbDataOpen(true);
  };

  return (
    <div className="integration-block">
      <div className="integration-name">
        <TeambookIcon name={icons.QB_INTEGRATION_ICON} alt="qb logo" />
      </div>

      <div className="integration-status qb-status">
        <p className={qbIntegrated() ? "active" : "not_active"} title="Show QB Data" onClick={openQBDataModal}>
          {qbIntegrated() ? "Synced" : "Not connected"}
        </p>
      </div>

      <TeambookOutlinedButton
        onClick={() => window.open(link)}
        variant="contained"
        text={t("settings.integration.qwc_download")}
        style={{
          margin: "13px 0 0 0",
          width: "171px",
          lineHeight: "16px",
        }}
      />

      <Dialog
        fullWidth={true}
        open={qbDataOpen}
        onClose={() => setQbDataOpen(false)}
        aria-labelledby="max-width-dialog-title"
        className="tb-default-dialog"
      >
        <DialogTitle id="add-user-dialog">
          <p>QB Data</p>
          <TeambookIcon name={icons.CROSS} onClick={() => setQbDataOpen(false)} />
        </DialogTitle>

        <DialogContent style={{ margin: "20px 0px" }}>
          <div className="qb-data-block">
            <p className="qb-data-block__title">QB User Emails</p>

            <div className="qb-data-block__list">
              {qbData?.qb_employees?.map((employeeEmail) => (
                <p>{employeeEmail}</p>
              ))}
            </div>
          </div>
          <div className="qb-data-block">
            <p className="qb-data-block__title">QB Project Names</p>

            <div className="qb-data-block__list">
              {qbData?.qb_customers?.map((customerName) => (
                <p>{customerName}</p>
              ))}
            </div>
          </div>
          <div className="qb-data-block">
            <p className="qb-data-block__title">QB Task Names</p>

            <div className="qb-data-block__list">
              {qbData?.qb_item_services?.map((customerName) => (
                <p>{customerName}</p>
              ))}
            </div>
          </div>
          <div className="qb-data-block">
            <p className="qb-data-block__title">QB Payroll Item Names</p>

            <div className="qb-data-block__list">
              {qbData?.qb_payroll_item_wages?.map((customerName) => (
                <p>{customerName}</p>
              ))}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default QuickBooksBlock;

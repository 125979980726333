const BlockedSpace = () => {
  return (
    <div
      className="pdf-blocked"
      style={{
        position: "absolute",
        backgroundColor: "var(--background-2)",
        height: "100%",
        bottom: 0,
        zIndex: 0,
      }}
    />
  );
};

export default BlockedSpace;

import { create } from "zustand";
import Api from "../Api";

export const useProfileStore = create((set) => ({
  profile: null,
  fetchProfile: async (data) => {
    const response = await Api.Profile.get();
    set({ profile: await response.data });
  },

  changeTheme: () => {},
}));

import React, { useState, useEffect } from "react";
import Api from "../../../Api";
import TeambookBlueButton from "../../default_components/TeambookBlueButton";
import { Link } from "react-router-dom";
import accountSorting from "../accountSorting";
import { CentsToDollarString } from "../../../lib/DataFormat";
import { GetMonthBeginning, StatsOffset, GetQuarterBeginning, GetYearBeginning } from "../../../lib/DateUtils";
import VatBottomControlBar from "./VatBottomControlBar";
import ControlPanel from "../ControlPanel";
import { DateTime } from "luxon";

const VatReport = () => {
  const [range, setRange] = useState(1);
  const [from, setFrom] = useState(DateTime.now());
  const [accounts, setAccounts] = useState([]);
  const [customDate, setCustomDate] = useState(false);

  useEffect(() => {
    let token = localStorage.getItem("tb-admin-token");

    Api.Administrators.vat(token).then((response) => setAccounts(response.data));
  }, []);

  useEffect(() => {
    let token = localStorage.getItem("tb-admin-token");

    if (customDate) {
      if (range === 1) {
        let fromDate = GetMonthBeginning(from.toJSDate());
        let toDate = StatsOffset(fromDate, range);

        Api.Administrators.vat(token, fromDate, toDate).then((response) => setAccounts(response.data));
      } else if (range === 2) {
        let fromDate = GetQuarterBeginning(from.toJSDate());
        let toDate = StatsOffset(fromDate, range);

        Api.Administrators.vat(token, fromDate, toDate).then((response) => setAccounts(response.data));
      } else {
        let fromDate = GetYearBeginning(from.toJSDate());
        let toDate = StatsOffset(fromDate, range);

        Api.Administrators.vat(token, fromDate, toDate).then((response) => setAccounts(response.data));
      }
    } else {
      Api.Administrators.vat(token).then((response) => setAccounts(response.data));
    }
  }, [from, range, customDate]);

  return (
    <div style={{ position: "relative" }}>
      <div>
        <Link to="/administration">
          <TeambookBlueButton
            style={{
              zIndex: "1",
              position: "absolute",
              left: "5px",
              top: "5px",
            }}
            text={"To Administration"}
          />
        </Link>
      </div>

      <VatBottomControlBar
        customDate={customDate}
        setCustomDate={setCustomDate}
        range={range}
        setRange={setRange}
        from={from}
        setFrom={setFrom}
        accounts={accounts}
      />

      <table border="1px" className="table table-hover">
        <thead className="table-hover__head">
          <th className="table-hover__head-cell" onClick={() => accountSorting("customer_company_name", accounts, setAccounts)}>
            Customer name
          </th>
          <th className="table-hover__head-cell" onClick={() => accountSorting("issued_at", accounts, setAccounts)}>
            Transaction Date
          </th>
          <th className="table-hover__head-cell" onClick={() => accountSorting("amount_paid", accounts, setAccounts)}>
            Amount paid
          </th>
          <th className="table-hover__head-cell" onClick={() => accountSorting("vat_amount", accounts, setAccounts)}>
            Vat amount
          </th>
        </thead>
        <tbody>
          {accounts.map((account) => {
            return (
              <tr>
                <td>{account.customer_company_name}</td>
                <td>{account.issued_at}</td>
                <td>{CentsToDollarString(account.amount_paid)}</td>
                <td>{CentsToDollarString(account.vat_amount)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <ControlPanel />
    </div>
  );
};

export default VatReport;

import { useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { icons } from "../../../default_images/IconsList";

const HOLIDAY_INFO_OPEN_KEY = "holiday-info-open";

export default function HolidayInfo() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(localStorage.getItem(HOLIDAY_INFO_OPEN_KEY) === null);

  const CloseInfo = () => {
    setOpen(false);
    localStorage.setItem(HOLIDAY_INFO_OPEN_KEY, false);
  };

  return (
    open && (
      <div className="holiday-info">
        <NavLink
          to={{
            pathname: "/settings",
            search: "?page=time_offs",
          }}
        >
          {t("planning.automate_holiday")}
        </NavLink>
        <TeambookIcon name={icons.CROSS} onClick={CloseInfo} />
      </div>
    )
  );
}

import React from "react";
import { Link } from "react-router-dom";

const ControlPanel = () => {
  return (
    <>
      <div className="control-panel__container">
        <Link to={"/administration/statistics"}>
          <div>STATISTICS</div>
        </Link>

        <Link to={"/administration/vat_report"}>
          <div className="control-panel__option">VAT REPORT</div>
        </Link>

        <Link to={"/administration/whats_new"}>
          <div className="control-panel__option">WHATS NEW</div>
        </Link>

        <Link to={"/administration/locales"}>
          <div className="control-panel__option">LOCALES</div>
        </Link>
      </div>
    </>
  );
};

export default ControlPanel;

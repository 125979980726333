import logo_text from "../header/logo-text.svg";
import moment from "moment";
import { CentsToDollarString } from "../../lib/DataFormat";
import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import "./invoice.scss";

export const InvoiceWrapper = React.forwardRef(({ invoice }, ref) => {
  const CONTACT_US_EMAIL = "support@teambookapp.com";
  const invoiceRef = useRef();

  const printInvoice = useReactToPrint({
    content: () => invoiceRef.current,
  });

  React.useImperativeHandle(ref, () => {
    return {
      printInvoice: printInvoice,
    };
  });

  return (
    <div ref={invoiceRef} className="invoice-container">
      <style type="text/css" media="print">
        {
          "\
           @page { size: auto; }\
        "
        }
      </style>
      <div className="invoice-header">
        <div>
          <img className="header-text" src={logo_text} />
          <p className="left-side-text">Powered by Teambook SA</p>
        </div>

        <p className="right-side-text">Invoice receipt</p>
      </div>

      <p className="thanks-text">Thank You For Using Teambook!</p>

      <p className="billed-text">BILLED TO</p>

      <div className="billing-container">
        <div>
          <p className="billing-text">
            {invoice.customer_first_name} {invoice.customer_last_name}
          </p>
          <p className="billing-text">{invoice.customer_company_name}</p>
          <p className="billing-text">{invoice.billing_street_address}</p>
          <p className="billing-text">
            {invoice.billing_postal_code} {invoice.billing_locality}
          </p>
          <p className="billing-text">{invoice.billing_country_name}</p>
          <p className="billing-text">{invoice.vat_number}</p>
        </div>

        <div>
          <div style={{ display: "flex" }}>
            <p className="billing-blue-text">Invoice ID:&nbsp;</p>
            <p className="billing-text">{invoice.invoice_id}</p>
          </div>
          <div style={{ display: "flex" }}>
            <p className="billing-blue-text">Payment Date:&nbsp;</p>
            <p className="billing-text">{moment(invoice.issued_at).format("ddd D MMM YYYY")}</p>
          </div>
          <div style={{ display: "flex" }}>
            <p className="billing-blue-text">Payment Method:&nbsp;</p>
            <p className="billing-text">
              {invoice.cc_card_type} ending in: {invoice.cc_last_4}
            </p>
          </div>
        </div>
      </div>

      <div>
        <div className="service-header">
          <p className="billing-blue-text">Service:</p>

          <p className="billing-blue-text">Price (USD)</p>
        </div>

        <div className="service-body">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p className="billing-text">Teambook "{invoice.subscription_plan_name}" Plan</p>

            <p className="billing-text">{CentsToDollarString(invoice.subscription_plan_price_cents)}</p>
          </div>

          {invoice.tax_amount && (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p className="billing-text">Tax</p>

              <p className="billing-text">{CentsToDollarString(invoice.tax_amount)}</p>
            </div>
          )}
        </div>

        <div className="service-header">
          <div />

          <p className="billing-blue-text">
            Total amount payed: {CentsToDollarString(invoice.subscription_plan_price_cents + invoice.tax_amount || 0)}
          </p>
        </div>

        <div className="invoice-footer">
          <div style={{ display: "flex" }}>
            <p className="billing-blue-text">Questions?&nbsp;</p>
            <p className="billing-text">Contact us - {CONTACT_US_EMAIL}</p>
          </div>

          <p className="billing-blue-text">Teambook, your team scheduling app developed by Teambook SA</p>
          <p className="footer-text">Route de Divonne 48, CH-1260 Nyon. Switzerland. VAT Number: CH-480.331.836 TVA</p>
        </div>
      </div>
    </div>
  );
});

import { useTranslation } from "react-i18next";
import { TeambookReactSelect } from "./TeambookReactSelect";

const PickerStyles = {
  menuPortal: (base) => ({
    ...base,
    zIndex: "1301 !important",
  }),
  multiValue: (base) => ({
    ...base,
    borderRadius: "20px !important",
    padding: "1px 6px !important",
    margin: "1px",
    background: "var(--background-2)",
  }),
  multiValueLabel: (base) => ({
    ...base,
    fontFamily: "Montserrat",
    color: "var(--text-2)",
  }),
  multiValueRemove: (base) => ({
    ...base,
    cursor: "pointer",
    borderRadius: "20px",
    margin: "2px 0px",
    "&:hover": {
      background: "var(--stroke)",
    },
  }),
  valueContainer: (base) => ({
    ...base,
    height: "100%",
  }),

  input: (base) => ({
    ...base,
    color: "var(--text-2)",
  }),
};

export const TeambookUsersPicker = ({
  nameStyles,
  fieldName,
  id,
  type,
  users,
  classNamePrefix,
  className,
  placeholder,
  onChange,
  closeMenuOnSelect,
  onCreateOption,
  maxMenuHeight,
  defaultValue,
  displayEmpty,
  value,
  isCreatable,
  height,
  width,
  isSearchable,
  styles,
}) => {
  const { t } = useTranslation();
  return (
    <TeambookReactSelect
      nameStyles={nameStyles}
      fieldName={fieldName}
      id={id}
      options={users}
      type={type}
      classNamePrefix={classNamePrefix}
      className={className}
      menuPortalTarget={document.body}
      placeholder={placeholder}
      onChange={onChange}
      closeMenuOnSelect={closeMenuOnSelect}
      onCreateOption={onCreateOption}
      maxMenuHeight={maxMenuHeight}
      styles={{ ...PickerStyles, ...styles }}
      defaultValue={defaultValue}
      displayEmpty={displayEmpty}
      value={value}
      createOptionPosition={"first"}
      formatCreateLabel={() => t("planning.create_user")}
      isValidNewOption={() => isCreatable}
      height={height}
      width={width}
      isSearchable={true}
    />
  );
};

export const TeambookTeamsPicker = ({
  nameStyles,
  fieldName,
  id,
  type,
  teams,
  classNamePrefix,
  className,
  placeholder,
  onChange,
  closeMenuOnSelect,
  maxMenuHeight,
  defaultValue,
  displayEmpty,
  value,
  isSearchable = true,
  components,
  placement,
  height,
  width,
  onBlur = () => {},
  grayPlaceholder,
  disabled,
}) => (
  <TeambookReactSelect
    nameStyles={nameStyles}
    fieldName={fieldName}
    id={id}
    options={teams}
    type={type}
    classNamePrefix={classNamePrefix}
    className={className}
    menuPortalTarget={document.body}
    placeholder={placeholder}
    onChange={onChange}
    closeMenuOnSelect={closeMenuOnSelect}
    maxMenuHeight={maxMenuHeight}
    styles={PickerStyles}
    defaultValue={defaultValue}
    displayEmpty={displayEmpty}
    value={value}
    isSearchable={isSearchable}
    new_components={components}
    menuPlacement={placement}
    height={height}
    width={width}
    onBlur={onBlur}
    grayPlaceholder={grayPlaceholder}
    isDisabled={disabled}
  />
);

export const TeambookProjectsPicker = ({
  nameStyles,
  fieldName,
  id,
  type,
  projects,
  classNamePrefix,
  className,
  placeholder,
  onChange,
  closeMenuOnSelect,
  maxMenuHeight,
  defaultValue,
  displayEmpty,
  value,
  isSearchable,
  components,
  grayPlaceholder,
}) => (
  <TeambookReactSelect
    nameStyles={nameStyles}
    fieldName={fieldName}
    id={id}
    options={projects}
    type={type}
    classNamePrefix={classNamePrefix}
    className={className}
    menuPortalTarget={document.body}
    placeholder={placeholder}
    onChange={onChange}
    closeMenuOnSelect={closeMenuOnSelect}
    maxMenuHeight={maxMenuHeight}
    styles={PickerStyles}
    defaultValue={defaultValue}
    displayEmpty={displayEmpty}
    value={value}
    isSearchable={true}
    new_components={components}
    grayPlaceholder={grayPlaceholder}
  />
);

import React from "react";
import { useTranslation } from "react-i18next";

const GraphRangeSwitch = ({ graphRange, setGraphRange, type }) => {
  const { t } = useTranslation();

  const regularSwitchOptions = [
    { value: "week_days", text: t("planning.week_days") },
    { value: "daily", text: t("planning.daily") },
    { value: "weekly", text: t("planning.weekly") },
  ];

  const capacitySwitchOptions = [
    { value: "man_days", text: t("dashboard.man_days") },
    { value: "percentage", text: "%" },
  ];

  const switchOptions = () => {
    return type === "capacity" ? capacitySwitchOptions : regularSwitchOptions;
  };

  return (
    <>
      {switchOptions().map((option) => (
        <p
          style={{
            color: graphRange === option.value ? "var(--text-1)" : "var(--text-3)",
          }}
          data-active={graphRange === option.value}
          onClick={() => setGraphRange(option.value)}
        >
          {option.text}
        </p>
      ))}
    </>
  );
};

export default GraphRangeSwitch;

import Api from "../../../Api";

async function DeleteUserFromTeamRequest(teamId, id, reload = true) {
  return Api.Teams.delete_users(teamId, id).then((res) => {
    const teamsMap = new Map();
    const data = [];
    data.push(res.data);
    data.forEach((i) => {
      teamsMap.set(i.id.toString(), i);
    });

    //TODO: REMOVE RELOAD
    if (reload) {
      window.location.reload();
    }
  });
}

export default DeleteUserFromTeamRequest;

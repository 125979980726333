import { statusOptions } from "./statusOptions";

export default function filterProjects(projectsArray, filterType, filterValues, t) {
  let projects = [...projectsArray];

  let filteredProjects = [];

  if (filterValues.length === 0) {
    return projectsArray;
  }

  filterValues.forEach((value) => {
    if (filterType === "Or") {
      switch (value.type) {
        case t("planning.filter_projects"):
          projects.forEach((project) => {
            if (project?.id === value?.id) {
              filteredProjects.push(project);
            }
          });

        case t("planning.filter_clients"):
          projects.forEach((project) => {
            if (project?.client_id === value?.id) {
              filteredProjects.push(project);
            }
          });

        case t(t("projects.manager")):
          projects.forEach((project) => {
            if (project?.manager_id === value?.id) {
              filteredProjects.push(project);
            }
          });

        case t(t("projects.status")):
          projects.forEach((project) => {
            if (project.status && value.name) {
              if (project.status.toLowerCase() === value.name.toLowerCase()) {
                filteredProjects.push(project);
              }
            }
          });

        case t("projects.business_unit"):
          projects.forEach((project) => {
            if (project?.business_unit === value?.id) {
              filteredProjects.push(project);
            }
          });
      }
    } else {
      let _projects = [...projects];

      _projects = [...projects].filter((project) => {
        return filterValues.every((val) => {
          if (val.type === t("planning.filter_projects")) {
            return project.id === val.id;
          }

          if (val.type === t("planning.filter_clients")) {
            return project.client_id === val.id;
          }

          if (val.type === t("projects.manager")) {
            return project?.manager_id === val?.id;
          }

          if (val.type === t(t("projects.status"))) {
            return project?.status?.toLowerCase() === val?.name?.toLowerCase();
          }

          if (val.type === t("projects.business_unit")) {
            return project.business_unit === val.id;
          }
        });
      });

      filteredProjects = [..._projects];
    }
  });

  if (filteredProjects.length) {
    return filteredProjects.filter(
      (obj1, i, arr) => arr.findIndex((obj2) => ["id"].every((key) => obj2[key] === obj1[key])) === i
    );
  } else return [];
}

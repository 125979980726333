import { useTranslation } from "react-i18next";
import { icons } from "../default_images/IconsList";
import { TeambookIcon } from "../default_images/TeambookIcon";

export const InfoTooltip = ({ content, style }) => {
  const { t } = useTranslation();
  return (
    <TeambookIcon
      name={icons.INFO}
      color="default"
      placement="right"
      tooltipTitle={content}
      style={style}
    />
  );
};

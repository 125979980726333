import { useState, memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { shallow } from "zustand/shallow";
import { TeambookBanner } from "./TeambookBanner";
import { useAccountStore } from "../../stores/accountStore";
import TeambookWhiteButton from "./TeambookWhiteButton";

export const SwitchToPaidPlanBanner = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  const [account] = useAccountStore((state) => [state.account], shallow);

  useEffect(() => {
    if (accountIsFree()) {
      setShow(true);
    }
  }, [account]);

  const accountIsFree = () => {
    return account?.subscription?.subscription_id === "Free" && account?.subscription?.projects_available === 10;
  };

  const goToSubscription = () => {
    window.location.replace("/settings?page=subscription");
  };

  return (
    <TeambookBanner
      bannerContent={
        <>
          <div
            style={{
              color: "#fff",
              fontSize: 18,
              margin: "0 auto",
              alignItems: "center",
              gap: 40,
              display: "flex",
            }}
          >
            <p style={{ margin: 0 }}>{t("auth.switch_to_paid_plan")}</p>

            <TeambookWhiteButton text={t("auth.upgrade")} onClick={goToSubscription} />
          </div>
        </>
      }
      bannerOpened={show}
    />
  );
};

import React, { useEffect, useState } from "react";

const MilestoneHook = ({
  side,
  zoomWidth,
  row,
  startMilestoneDragging,
  draggingMilestone,
  endMilestoneDragging,
  calculateWidth,
  calculateLeftOffset,
  milestone,
  weekendsHidden,
}) => {
  const [width, setWidth] = useState(0);
  const [left, setLeft] = useState(0);

  const getSizes = (zoomWidth) => {
    switch (zoomWidth) {
      case 140: {
        return {
          width: 25,
          correctRightPadding: 22,
        };
      }
      case 72: {
        return {
          width: 15,
          correctRightPadding: 12,
        };
      }
      case 20: {
        return {
          width: 6,
          correctRightPadding: 4,
        };
      }
      default:
        break;
    }
  };

  useEffect(() => {
    setWidth(getSizes(zoomWidth).width);
    setLeft(
      side === "leftHook"
        ? calculateLeftOffset() + 2
        : calculateLeftOffset() +
            calculateWidth() -
            getSizes(zoomWidth).correctRightPadding
    );
  }, [zoomWidth, milestone, weekendsHidden]);

  return (
    <div
      className="milestone__hook"
      style={{
        marginTop: `${row * 20 + 5}px`,
        left,
        width,
      }}
      draggable={true}
      onDragStart={(e) => startMilestoneDragging(e, side)}
      onDrag={(e) => draggingMilestone(e, side)}
      onDragEnd={(e) => endMilestoneDragging(e, side)}
    />
  );
};

export default MilestoneHook;

import React from "react";
import Tooltip from "@mui/material/Tooltip";

export const WhiteTooltip = (props) => {
  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: "var(--background-1)",
            border: "1px solid var(--stroke)",
            boxShadow: "5px 10px 30px var(--box-shadow)",
            color: "var(--text-2)",
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: "400",
            maxWidth: props.maxWidth || 300,
            ...props.style,
            // boxShadow: theme.shadows[1],
            fontSize: 12,
          },
        },
      }}
      {...props}
    >
      {props.children}
    </Tooltip>
  );
};

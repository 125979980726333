import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import { useTranslation } from "react-i18next";
import Cross from "../../default_images/cross.svg";
import { shallow } from "zustand/shallow";
import { useAccountStore } from "../../../stores/accountStore";

const ExceedProjectsDialog = ({ open, handleClose }) => {
  const { t } = useTranslation();
  const [account] = useAccountStore((state) => [state.account], shallow);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="upload-projects-dialog"
      fullWidth={true}
      maxWidth={"sm"}
      className="tb-default-dialog"
    >
      <DialogTitle id="add-projects-dialog">
        <p> {t("projects.projects_exceeded_title")}</p>
        <img src={Cross} onClick={handleClose} />
      </DialogTitle>
      <DialogContent style={{ padding: "20px 40px" }}>
        <p className="exceeded-text">
          {t("projects.projects_exceeded_text_1")} {account?.subscription?.projects_available} {t("projects.projects_exceeded_text_2")}
        </p>

        <p>{t("projects.projects_exceeded_text_3")}</p>

        <p>
          {t("projects.projects_exceeded_text_4")} <a href="/settings">{t("projects.projects_exceeded_text_7")}</a>
        </p>

        <p>{t("projects.projects_exceeded_text_5")}</p>

        <p>
          {t("projects.projects_exceeded_text_6")}
          <a href="mailto:support@teambookapp.com">{t("header.support_email")}</a>
        </p>
      </DialogContent>
    </Dialog>
  );
};

export default ExceedProjectsDialog;

import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import UsersProfileImage from "../../default_components/UsersProfileImage";
import DropPhoto from "./DropPhoto";
import { useTranslation } from "react-i18next";

// const useStyles = makeStyles((theme) => ({
//   modal: {
//     display: "flex",
//     alignItems: "flex-start",
//     justifyContent: "center",
//     marginTop: "30px",
//   },
//   paper: {
//     outline: "none",
//     width: "900px",
//     borderRadius: 20,
//     backgroundColor: "#fff",
//     padding: "5px",
//     boxShadow: "0 5px 15px rgba(0,0,0,0.5)",
//   },
// }));

const ChangeProfilePhoto = ({ user, showEdit = true, bgStyle }) => {
  // const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [userImage, setUserImage] = useState(user.avatars["100"] || null);
  const { t } = useTranslation();

  const handleOpen = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="change-photo-cmps">
      <div className="image-block">
        <div className="image upload-action">
          <div className="user-image-change">
            <UsersProfileImage size={100} user={user} userImage={user.avatars["100"]} />
          </div>

          {showEdit && (
            <a className={"modal-trigger bg-image-modal"} style={bgStyle} onClick={(e) => handleOpen(e)}>
              <div className="edit-image">{t("edit")}</div>
            </a>
          )}
        </div>
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        style={{ display: "flex", alignItems: "flex-start", justifyContent: "center", marginTop: "30px" }}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ style: { display: "none" } }}
      >
        <Fade in={open}>
          <div
            className="paper"
            style={{
              maxHeight: "90vh",
              overflowY: "auto",
              outline: "none",
              width: "900px",
              borderRadius: 20,
              backgroundColor: "#fff",
              padding: "5px",
              boxShadow: "0 5px 15px rgba(0,0,0,0.5)",
            }}
          >
            <h2
              style={{
                margin: "30px 40px 0px 40px",
                borderBottom: "1px solid #f5f5f5",
                paddingBottom: "10px",
              }}
              id="transition-modal-title"
            >
              {t("profile.general_info.upload_your_picture")}
            </h2>
            <DropPhoto user={user} setOpen={setOpen} setUserImage={setUserImage} />
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default ChangeProfilePhoto;

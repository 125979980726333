import React from "react";
import "../header.scss";
import Menu from "@mui/material/Menu";
import { useTranslation } from "react-i18next";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";
import lsKeys from "../../default_values/defaultKeys";

const REOPEN_ONBOARDING_PAGES = ["actuals", "planning"];

const HelpMenu = ({
  helpAnchor,
  helpMenuOpened,
  setHelpMenuOpened,
  setOnboardingOpened,
  page,
  didYouKnowRef,
  openFeedbackModal,
}) => {
  const { t } = useTranslation();

  const ReopenOnboarding = () => {
    localStorage.setItem(lsKeys.ONBOARDING_OPENED, "true");
    localStorage.setItem(lsKeys.CAPACITY_ONBOARDING_OPENED, "true");
    localStorage.setItem(lsKeys.ACTUALS_ONBOARDING_OPENED, "true");
    setOnboardingOpened(true);
  };

  return (
    <Menu
      id="header-help-menu"
      className="header-menu help-menu"
      anchorEl={helpAnchor}
      keepMounted
      open={helpMenuOpened}
      onClose={() => setHelpMenuOpened(false)}
      style={{ marginLeft: -45, marginTop: 12 }}
    >
      <a href={t("header.kb_link")} target="_blank" rel="noopener noreferrer">
        <div className="header-menu__item-content">
          <TeambookIcon name={icons.KNOWLEDGE_BASE} />
          {t("header.kb")}
        </div>
      </a>

      <a href="#" rel="noopener noreferrer" onClick={openFeedbackModal}>
        <div className="header-menu__item-content">
          <TeambookIcon name={icons.IDEA} />
          {t("header.suggest_feature")}
        </div>
      </a>

      <a href="mailto:support@teambookapp.com" target="_blank" rel="noopener noreferrer">
        <div className="header-menu__item-content">
          <TeambookIcon name={icons.CONTACT_US} />
          {t("header.contact_us")}
        </div>
      </a>

      {REOPEN_ONBOARDING_PAGES.includes(page) && (
        <a
          style={{ cursor: "pointer", whiteSpace: "nowrap" }}
          onClick={() => {
            ReopenOnboarding();
            setHelpMenuOpened(false);
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="header-menu__item-content">
            <TeambookIcon name={icons.ONBOARDING} />
            {t("planning.onboarding.reopen_onboarding")}
          </div>
        </a>
      )}

      {page === "planning" && (
        <a
          style={{ cursor: "pointer" }}
          rel="noopener noreferrer"
          onClick={() => {
            didYouKnowRef.current.ReopenDidYouKnow();
            setHelpMenuOpened(false);
          }}
        >
          <div className="header-menu__item-content">
            <TeambookIcon name={icons.QUESTION_SYMBOL} />
            {t("reopen_did_you_know")}
          </div>
        </a>
      )}
    </Menu>
  );
};

export default HelpMenu;

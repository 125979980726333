import React, { useEffect, useState } from "react";
import Api from "../../../Api";
import { WhiteTooltip } from "../../default_components/Tooltips";
import Check from "../../default_images/check.svg";
import { TeambookCheckbox } from "../../default_components/TeambookCheckbox";
import $ from "jquery";
import UsersProfileImage from "../../default_components/UsersProfileImage";

const UserLog = ({
  log,
  index,
  updateActuals,
  projectFor,
  date,
  type = "regular",
  openEditLoggingWindow,
  changeContextMenu,
  changeTooltip,
  occupations,
  activities,
  user,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isApproved, setIsApproved] = useState(log.approved);

  useEffect(() => {
    setIsApproved(log.approved);
  }, [log]);

  const Approve = (e) => {
    e.stopPropagation();
    e.preventDefault();

    Api.ActualLogs.approve_log([log.id], !isApproved).then(() => {
      updateActuals();
      setIsApproved(!isApproved);
    });
  };

  return (
    <div
      key={`day-${date.day}-project-${index}`}
      className="actuals__log"
      onMouseEnter={(e) => {
        e.preventDefault();
        setIsHovered(true);
        let elemOffset = $(e.target);
        changeTooltip({
          open: true,
          bookingDuration: log.duration,
          projectName: user?.name || "",
          occupation:
            occupations.find((occupation) => occupation.id === log.task_id)
              ?.name || "",
          activity:
            activities.find((activity) => activity.id === log.payroll_item_id)
              ?.name || "",
          positionX: elemOffset.offset().left + elemOffset.width() / 2,
          positionY: elemOffset.offset().top,
        });
      }}
      onMouseLeave={() => {
        setIsHovered(false);
        changeTooltip({ open: false });
      }}
      onClick={() => {
        openEditLoggingWindow(log);
      }}
      onContextMenu={(e) => {
        e.preventDefault();
        changeContextMenu({
          open: true,
          pos: { x: e.pageX, y: e.pageY },
          log: log,
        });
      }}
      style={{
        borderColor: projectFor(log)?.color,
        color: log.approved ? "var(--background-1)" : "unset",
        background: isApproved ? projectFor(log)?.color : "unset",
      }}
    >
      <p style={{ marginLeft: 5 }} className="actuals__log-user-name">
        {user?.name}
      </p>

      {type === "approve" && isHovered ? (
        <WhiteTooltip title={`Approve`}>
          <TeambookCheckbox
            type="checkbox"
            className="actuals__button-approve"
            onChange={Approve}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            checked={isApproved}
            value={isApproved}
            style={{ width: 16, marginLeft: "auto" }}
          />
        </WhiteTooltip>
      ) : (
        isApproved && (
          <TeambookCheckbox
            checked={true}
            style={{ width: 16, marginLeft: "auto" }}
            className="actuals__log-check"
            src={Check}
          />
        )
      )}

      <p className="actuals__log-duration" style={{ width: 30 }}>
        {log.duration / 60}h
      </p>
    </div>
  );
};

export default UserLog;

import React from "react";
import TeambookOutlinedButton from "../../../default_components/TeambookOutlinedButton";
import { useTranslation } from "react-i18next";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { icons } from "../../../default_images/IconsList";

const SlackBlock = (props) => {
  const { t } = useTranslation();

  return (
    <div className="integration-block">
      <div className="integration-name">
        <TeambookIcon name={icons.ZAPIER_INTEGRATION_ICON} alt="zapier logo" />
      </div>

      <div className="integration-status">
        <p className={"not_active"}>{"Not connected"}</p>
      </div>

      <TeambookOutlinedButton
        onClick={() => window.open("https://zapier.com/developer/public-invite/159073/50f7ca87fe908b34842220549e9457ba/", "_blank")}
        variant="contained"
        text={t("settings.integration.connect")}
        style={{
          margin: "13px 0 0 0",
          width: "171px",
        }}
      />
    </div>
  );
};

export default SlackBlock;

import React, { useState } from "react";
import Api from "../../../../Api";
import { useTranslation } from "react-i18next";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Dialog, DialogTitle } from "@mui/material";
import TeambookBlueButton from "../../../default_components/TeambookBlueButton";
import FormErrorMessage from "../../../default_components/FormErrorMessage";
import TeambookTextForm from "../../../default_components/TeambookTextForm";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { icons } from "../../../default_images/IconsList";
import { useAccountStore } from "../../../../stores/accountStore";
import { shallow } from "zustand/shallow";
import { useNotificationStore } from "../../../../stores/notificationStore";

const CouponApplicationModal = ({ closeModal }) => {
  const { t } = useTranslation();
  const [coupon, setCoupon] = useState();
  const [errorMessage, setErrorMessage] = useState(null);
  const [fetchAccount] = useAccountStore((state) => [state.account], shallow);
  const [setInAppNotification] = useNotificationStore((state) => [state.setInAppNotification], shallow);
  const applyCoupon = () => {
    Api.Coupons.submit(coupon)
      .then((response) => {
        fetchAccount();
        setInAppNotification(t("notify.settings.subscription.coupon_apply"));
        closeModal();
      })
      .catch((error) => setErrorMessage(error.response.data.error));
  };

  return (
    <Dialog className="tb-default-dialog" onClose={closeModal} open={true} maxWidth={"sm"}>
      <DialogTitle id="add-user-dialog">
        <p>{t("settings.subscription.coupon_apply")}</p>

        <TeambookIcon name={icons.CROSS} onClick={closeModal} />
      </DialogTitle>

      <DialogContent style={{ margin: "20px 0px" }}>
        <TeambookTextForm
          fieldName={"Coupon"}
          onChange={setCoupon}
          fieldValue={coupon}
          type={"text"}
          width={"400px"}
          placeholder={"PLANX-hfSnf-...."}
        />
        {errorMessage !== null && (
          <FormErrorMessage text={errorMessage} closeErrorMessage={() => setErrorMessage(null)} />
        )}
      </DialogContent>

      <DialogActions>
        <TeambookBlueButton text={t("submit")} onClick={applyCoupon} className="user__submit" />
      </DialogActions>
    </Dialog>
  );
};

export default CouponApplicationModal;

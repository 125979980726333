import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const logoutFromTeambook = () => {
  localStorage.removeItem("Auth-Token");
};

const MobileMenuComponent = ({ setPagesMenuOpened, role }) => {
  const { t } = useTranslation();

  return (
    <div className="header-mobile-menu">
      <div className="header-mobile-menu__container">
        <Link className="header-mobile-menu__link" to="/planners" onClick={() => setPagesMenuOpened(false)}>
          {t("header.planning")}
        </Link>

        {["admin", "planner"].includes(role) && (
          <Link className="header-mobile-menu__link" to="/dashboard" onClick={() => setPagesMenuOpened(false)}>
            {t("header.dashboard")}
          </Link>
        )}

        {["admin", "planner"].includes(role) && (
          <Link className="header-mobile-menu__link" to="/users" onClick={() => setPagesMenuOpened(false)}>
            {t("header.users")}
          </Link>
        )}

        {["admin", "planner"].includes(role) && (
          <Link className="header-mobile-menu__link" to="/projects" onClick={() => setPagesMenuOpened(false)}>
            {t("header.projects")}
          </Link>
        )}

        <div className="header-mobile-menu-logout__container" />

        <Link
          className="header-mobile-menu__link"
          to={"/login"}
          onClick={() => {
            logoutFromTeambook();
          }}
        >
          {t("header.logout")}
        </Link>
      </div>
    </div>
  );
};

export default MobileMenuComponent;

import React, { useContext } from "react";
import { PlannerContext } from "../context/PlannerContext";
import { useZoomStore } from "../../../stores/planner";
import { shallow } from "zustand/shallow";
import { PLANNER_USER_WIDTH } from "../../../lib/PlannerUtils";

const TagInfo = ({ bookingFormat, user, isHovered, showButtons, height }) => {
  const [zoom] = useZoomStore((state) => [state.zoom], shallow);

  const calculateHeight = () => {
    if (height) {
      return height;
    } else {
      switch (bookingFormat) {
        case 2:
          return 137;
        case 1:
          return 97;
        default:
          return 57;
      }
    }
  };

  const tagArrayLength = () => {
    if (zoom === 1) {
      return 3;
    }
    switch (bookingFormat) {
      case 2:
        return 6;
      case 1:
        return 4;
      default:
        return 2;
    }
  };

  const tagsAreShown = () => {
    return user.tags.length !== 0 && isHovered && showButtons;
  };

  return (
    tagsAreShown() && (
      <div style={{ left: PLANNER_USER_WIDTH }} className="tag-section">
        <div
          className="tag-section__tags"
          style={{
            top: `-${calculateHeight()}px`,
            height: `${calculateHeight()}px`,
          }}
        >
          {user.tags.slice(0, tagArrayLength()).map((tag) => (
            <div
              className="tag-section__tag"
              style={{ backgroundColor: tag.color + "66", border: "2px solid " + tag.color }}
              key={`tag-${tag.id}`}
            >
              <p>{tag.name}</p>
            </div>
          ))}
        </div>
      </div>
    )
  );
};

export default TagInfo;

import { useTranslation } from "react-i18next";
import React from "react";
import Slider from "@mui/material/Slider";
import lsKeys from "../../default_values/defaultKeys";
import { useZoomStore } from "../../../stores/planner";
import { shallow } from "zustand/shallow";
import { GetWeekMonday } from "../../../lib/DateUtils";

const DaysSelect = ({ teamId, plannerType, changeDate }) => {
  const { t } = useTranslation();
  const [zoom, setZoom] = useZoomStore((state) => [state.zoom, state.setZoom], shallow);
  const lang = localStorage.getItem(lsKeys.LANGUAGE);

  const getZoomOptions = () => {
    if (plannerType === "grouped_planners") {
      return [
        {
          value: 0,
          label: t("planning.2_weeks"),
          zoomDates: 14,
        },
        {
          value: 1,
          label: t("planning.4_weeks"),
          zoomDates: 28,
        },
        {
          value: 2,
          label: t("planning.3_months"),
          zoomDates: 90,
        },
      ];
    }

    if (plannerType === "planners") {
      return [
        {
          value: 0,
          label: t("planning.day"),
          zoomDates: 1,
        },
        {
          value: 1,
          label: t("planning.2_weeks"),
          zoomDates: 14,
        },
        {
          value: 2,
          label: t("planning.4_weeks"),
          zoomDates: 28,
        },
        {
          value: 3,
          label: t("planning.3_months"),
          zoomDates: 90,
        },
      ];
    }
  };

  const handleChange = (value) => {
    let zoomSettings = new Map(JSON.parse(localStorage.getItem(lsKeys.LAST_TEAMS_ZOOM_KEY)));

    zoomSettings.set(teamId.toString(), parseInt(getZoomOptions()[value].zoomDates));

    localStorage.setItem(lsKeys.LAST_TEAMS_ZOOM_KEY, JSON.stringify(Array.from(zoomSettings)));

    setZoom(getZoomOptions()[value].zoomDates);

    if (getZoomOptions()[value].zoomDates === 1) {
      changeDate(new Date());
    } else {
      changeDate(GetWeekMonday(new Date()));
    }

    //TODO: REMOVE RELOAD
  };

  return (
    <Slider
      id={"cellsSize"}
      className={`planner-settings-dialog__zoom-slider ${lang} ${plannerType}`}
      defaultValue={
        +(
          getZoomOptions()
            .find((obj) => obj.zoomDates === +zoom)
            ?.value.toString() || 1
        )
      }
      max={getZoomOptions().length - 1}
      aria-labelledby="discrete-slider-restrict"
      step={null}
      track={false}
      onChangeCommitted={(event, val) => {
        localStorage.removeItem("default_zoom");
        handleChange(val);
      }}
      marks={getZoomOptions()}
      sx={{
        "& .MuiSlider-markLabel": {
          opacity: 0.6,

          [`&[data-index='${getZoomOptions().findIndex((opt) => opt.zoomDates === +zoom)}']`]: {
            opacity: 1,
          },
        },
      }}
    />
  );
};

export default DaysSelect;

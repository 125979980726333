import { Dialog, DialogTitle, DialogContent, Slider } from "@mui/material/";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import TeambookBlueButton from "../../default_components/TeambookBlueButton";
import { DialogActions } from "@mui/material";
import Api from "../../../Api";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTime } from "luxon";
import TeambookRadioButton from "../../default_components/TeambookRadioButton";
import lsKeys from "../../default_values/defaultKeys";
import { TeambookTeamsPicker, TeambookUsersPicker } from "../../default_components/TeambookDefaultPickers";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";
import MuiDatePicker from "../../default_components/MuiDatePicker";

const CopyPlannerWindow = ({ setCopyPlannerOpened, date, updateActuals, users, teams, approvalMode }) => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(date.startOf("month"));
  const [endDate, setEndDate] = useState(date.endOf("month"));
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [approveType, setApproveType] = useState("users");
  const [kind, setKind] = useState("inject");

  const lang = localStorage.getItem(lsKeys.LANGUAGE);

  const closeWindow = () => {
    setCopyPlannerOpened(false);
  };

  const copy = () => {
    Api.ActualLogs.copy_planner({
      from: startDate.toISODate(),
      to: endDate.toISODate(),
      teams: selectedTeams,
      users: selectedUsers,
      kind: kind,
    }).then((response) => {
      updateActuals();
      setCopyPlannerOpened(false);
    });
  };

  return (
    <Dialog className="tb-default-dialog" open={true} onClose={() => closeWindow()} id="copyPlannerWindow">
      <DialogTitle>
        <p style={{ margin: "auto 0" }}>{t("actuals.copy_planner")}</p>
        <TeambookIcon name={icons.CROSS} onClick={() => closeWindow()} />
      </DialogTitle>

      <DialogContent style={{ height: "fit-content", margin: "20px 0px" }} className="planner-settings-dialog__content">
        <div className="copy-planner-dialog__date-row">
          <p language={lang}>{`${t("projects.start_date")}`}</p>
          <div className="copy-planner-dialog__date-picker-container">
            <MuiDatePicker
              variant="inline"
              format={"d MMM, yyyy"}
              value={startDate}
              onChange={(date) => setStartDate(date)}
              sx={{ width: "150px", input: { padding: "0px" } }}
              className="mui-date-picker"
            />
          </div>
        </div>

        <div className="copy-planner-dialog__date-row">
          <p language={lang}>{`${t("projects.end_date")}`}</p>
          <div className="copy-planner-dialog__date-picker-container">
            <MuiDatePicker
              variant="inline"
              format={"d MMM, yyyy"}
              value={endDate}
              onChange={(date) => setEndDate(date)}
              sx={{ width: "150px", input: { padding: "0px" } }}
              className="mui-date-picker"
            />
          </div>
        </div>
        <>
          {["admin", "planner"].includes(localStorage.getItem(lsKeys.ROLE)) && approvalMode && (
            <div className="copy-planner-subblock">
              <p className="copy-planner-subblock__title">{t("planning.select_data")}</p>
              <div style={{ display: "flex", width: "100%" }}>
                <TeambookRadioButton
                  checked={approveType === "users"}
                  onCheck={() => {
                    setApproveType("users");
                    setSelectedTeams([]);
                  }}
                />

                <TeambookUsersPicker
                  displayEmpty
                  type="multiple"
                  onChange={(options, newValue) => {
                    setApproveType("users");
                    setSelectedTeams([]);
                    setSelectedUsers(options.map((o) => o.id));
                  }}
                  value={selectedUsers.map((id) => ({
                    name: users.filter((u) => u.id === id)[0].name,
                    id: id,
                  }))}
                  users={users}
                  placeholder={t("planning.select_users")}
                  maxMenuHeight={150}
                  width={400}
                />
              </div>
              <div style={{ display: "flex" }}>
                <TeambookRadioButton
                  checked={approveType === "teams"}
                  onCheck={() => {
                    setApproveType("teams");
                    setSelectedUsers([]);
                  }}
                />

                <TeambookTeamsPicker
                  displayEmpty
                  type="multiple"
                  onChange={(options, newValue) => {
                    setApproveType("teams");
                    setSelectedUsers([]);
                    setSelectedTeams(options.map((o) => o.id));
                  }}
                  value={selectedTeams.map((id) => ({
                    name: teams.filter((t) => t.id === id)[0].name,
                    id: id,
                  }))}
                  teams={teams}
                  placeholder={t("planning.select_teams")}
                  maxMenuHeight={150}
                  width={400}
                />
              </div>
            </div>
          )}
          <p className="copy-planner-subblock__title">{t("planning.copy_settings")}:</p>

          <div className="copy-planner__checkboxes">
            <TeambookRadioButton checked={kind === "inject"} onCheck={() => setKind("inject")} />

            <p style={{ fontSize: 14, margin: 0 }} onClick={() => setKind("inject")}>
              {t("planning.overwrite_capacity")}
            </p>
          </div>

          <div className="copy-planner__checkboxes">
            <TeambookRadioButton checked={kind === "add"} onCheck={() => setKind("add")} />

            <p style={{ fontSize: 14, margin: 0 }} onClick={() => setKind("add")}>
              {t("planning.logs_on_top")}
            </p>
          </div>
        </>
      </DialogContent>

      <DialogActions style={{ gap: 15 }}>
        <div>
          <p style={{ fontSize: 14, margin: 0, color: "red" }}>{t("planning.causting_text_copy")}</p>
        </div>
        <TeambookBlueButton
          style={{ margin: "0px 0px 0px 60px" }}
          text={t("submit")}
          onClick={copy}
          id="submitCopyPlanner"
        />
      </DialogActions>
    </Dialog>
  );
};

export default CopyPlannerWindow;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Api from "../../../Api";
import TeambookBlueButton from "../../default_components/TeambookBlueButton";
import ControlPanel from "../ControlPanel";
import { DateTime } from "luxon";

export default function InAppStatistics({ teambookStatistics }) {
  return (
    <table
      style={{
        width: "99.5%",
        margin: "70px 5px",
        textAlign: "left",
      }}
      border="1px solid black"
    >
      <tr style={{ textAlign: "center" }}>
        <th style={{ width: "20%", padding: 10 }}>Date</th>
        <th style={{ width: "20%", padding: 10 }}>Dark Theme Users</th>
        {/*<th style={{ width: "20%", padding: 10 }}># of deactivated Projects</th>*/}
        {/*<th style={{ width: "20%", padding: 10 }}>AVG # projects per customer</th>*/}
        {/*<th style={{ width: "20%", padding: 10 }}>AVG # projects per FREE acc</th>*/}
      </tr>

      {teambookStatistics.map((statistics) => {
        return (
          <tr>
            <td style={{ width: "20%", padding: 10 }}>{DateTime.fromISO(statistics.date).toFormat("dd/MM/yyyy")}</td>
            <td style={{ width: "20%", padding: 10 }}>{statistics.dark_theme}</td>
            {/*<td style={{ width: "20%", padding: 10 }}>{statistics.projects_deactivated_count}</td>*/}
            {/*<td style={{ width: "20%", padding: 10 }}>{statistics.average_projects_subscribers}</td>*/}
            {/*<td style={{ width: "20%", padding: 10 }}>{statistics.average_projects_free}</td>*/}
          </tr>
        );
      })}
    </table>
  );
}

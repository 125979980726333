import React, { useEffect, useState } from "react";
import ProjectsBlock from "./ProjectsBlock";
import SeparatorRow from "./SeparatorRow";
import { useTranslation } from "react-i18next";
import ExportImg from "../../../../default_images/export_blue.svg";
import { WhiteTooltip } from "../../../../default_components/Tooltips";
import { useTeambookFilter } from "../../../../../stores/planner";
import { calculateProjectsFilters, calculateUsersFilters } from "../../../../capacity_planning/lib/calculateFilters";
import XLSExport from "../../../../../lib/XLSExport";
import { recalculateCapacityArray } from "../../../../capacity_planning/lib/recalculateCapacityArray";
import DatesRow from "../DatesRow";
import Api from "../../../../../Api";
import { DateTime } from "luxon";
import { useAccountStore } from "../../../../../stores/accountStore";
import { shallow } from "zustand/shallow";

const ReportingProjects = ({ date, users, tags, projects, selectedViewType, clients }) => {
  const { filterValues, filterType } = useTeambookFilter();
  const { t } = useTranslation();

  const [nonTimeOffProjects, setNonTimeOffProjects] = useState([]);
  const [timeOffs, setTimeOffs] = useState([]);
  const [capacityReservations, setCapacityReservations] = useState([]);
  const [projectReservations, setProjectReservations] = useState([]);
  const [showedUsers, setShowedUsers] = useState(users);
  const [showedProjects, setShowedProjects] = useState(projects);
  const [dropdownEntity, setDropdownEntity] = useState();
  const [projectShowedId, setProjectShowedId] = useState();
  const [isTimeOffsOpened, setIsTimeOffsOpened] = useState(false);
  const [sortOrder, setSortOrder] = useState("z");
  const [sortedDate, setSortedDate] = useState(DateTime.now());
  const [sortType, setSortType] = useState("alphabetical"); //alphabetical || by_month
  const [areAllProjectsExpanded, setAreAllProjectsExpanded] = useState(false);

  const [account] = useAccountStore((state) => [state.account], shallow);

  useEffect(() => {
    Api.CapacityReservations.get({
      user_ids: showedUsers.map((u) => u.id),
      start_date: date.toISODate(),
      end_date: date.plus({ years: 2 }).toISODate(),
    }).then((res) => {
      setCapacityReservations(res.data);
    });

    Api.CapacityReservations.get_project_reservations({
      project_ids: projects.filter((p) => p.active).map((p) => p.id),
      start_date: date.toISODate(),
      end_date: date.plus({ years: 2 }).toISODate(),
    }).then((res) => {
      setProjectReservations(res.data);
    });
  }, [date, showedUsers, projects]);

  useEffect(() => {
    setShowedUsers(
      showedUsers.filter((user) => user.team_ids.length > 0).sort((a, b) => (a.last_name > b.last_name ? 1 : -1))
    );
  }, [users]);

  useEffect(() => {
    setShowedUsers(calculateUsersFilters(users, tags, filterValues, filterType, t));

    setShowedProjects(calculateProjectsFilters(projects, clients, filterValues, filterType, t));
  }, [users, projects, filterValues, filterType, tags]);

  const getProjectReservations = (id) => {
    return projectReservations
      .filter((pr) => {
        const iso_date = DateTime.fromISO(pr.date);
        return pr.project_id === id && iso_date.month === sortedDate.month && iso_date.year === sortedDate.year;
      })
      .reduce((prev, cur) => prev + cur.days_reserved, 0);
  };

  useEffect(() => {
    let projs = showedProjects
      .filter((p) => p.kind !== "time_off")
      .filter((p) => p.active && p.capacity_counted)
      .sort((x, y) => (x.name > y.name ? 1 : -1));

    let timeoffProjects = showedProjects
      .filter((p) => p.kind === "time_off")
      .filter((p) => p.active && p.capacity_counted)
      .sort((x, y) => (x.name > y.name ? 1 : -1));

    let sortedProjects = [];
    let sortedTimeoffs = [];

    const alphaSort = (arr) => {
      return [...arr].sort((a, b) => {
        if (sortOrder === "a") {
          return a.name > b.name ? 1 : -1;
        }

        if (sortOrder === "z") {
          return a.name > b.name ? -1 : 1;
        }
      });
    };

    const sortByMonth = (arr) => {
      return [...arr].sort((a, b) => {
        if (sortOrder === "a") {
          return getProjectReservations(a.id) > getProjectReservations(b.id) ? 1 : -1;
        }

        if (sortOrder === "z") {
          return getProjectReservations(a.id) > getProjectReservations(b.id) ? -1 : 1;
        }
      });
    };

    if (sortType === "alphabetical") {
      sortedProjects = alphaSort(projs);
      sortedTimeoffs = alphaSort(timeoffProjects);
    }

    if (sortType === "by_month") {
      sortedProjects = sortByMonth(projs);
      sortedTimeoffs = sortByMonth(timeoffProjects);
    }

    setNonTimeOffProjects(sortedProjects);
    setTimeOffs(sortedTimeoffs);
  }, [showedProjects, sortOrder, sortType, sortedDate]);

  const exportProjectsData = (type) => {
    let exportArray = [
      ["", ...[...Array(selectedViewType)].map((x, i) => date.plus({ months: i }).toFormat("LLL yy"))],
    ];

    const projectsToExport = showedProjects.filter((project) => {
      if (type === "default") {
        return project.kind !== "time_off";
      } else if (type === "time_off") {
        return project.kind === "time_off";
      }
    });

    const reservstionsToExport = projectReservations.filter((reservation) => {
      const projectById = (id) => {
        return showedProjects.find((pr) => pr.id === id);
      };

      if (type === "default") {
        return projectById(reservation.project_id).kind !== "time_off";
      } else if (type === "time_off") {
        return projectById(reservation.project_id).kind === "time_off";
      }
    });

    exportArray.push(...getExportData("Projects", projectsToExport, reservstionsToExport));

    XLSExport.exportCapacityReporting("Projects", exportArray);
  };

  const getExportData = (type, objects, reservations) => {
    let exportArray = [[type]];

    objects.forEach((object) => {
      exportArray.push([
        object.name,
        ...summarizeCapacities(
          reservations.filter((cr) => cr[type === "Users" ? "user_id" : "project_id"] === object.id)
        ),
      ]);
    });

    return exportArray;
  };

  const summarizeCapacities = (capacity) => {
    return recalculateCapacityArray(date, capacity, selectedViewType);
  };

  const toggleAllProjects = () => {
    setAreAllProjectsExpanded((prev) => !prev);
  };

  return (
    <div className="reporting-capacity__component overflow-scrolled">
      <DatesRow
        date={date}
        exportData={exportProjectsData}
        selectedViewType={selectedViewType}
        type="reporting"
        setSortOrder={setSortOrder}
        sortOrder={sortOrder}
        sortedDate={sortedDate}
        setSortedDate={setSortedDate}
        setSortType={setSortType}
        sortType={sortType}
      />

      <SeparatorRow
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        setSortType={setSortType}
        sortData={true}
        name={""}
        subtext={t("planning.total_days")}
        reservations={projectReservations.filter((pr) => projects.map((p) => p.id).includes(pr.project_id))}
        date={date}
        dropdownEntity={dropdownEntity}
        exportData={() => exportProjectsData("default")}
        selectedViewType={selectedViewType}
        areAllProjectsExpanded={areAllProjectsExpanded}
        toggleAllProjects={toggleAllProjects}
        isExpand
        style={{ position: "sticky", top: "96px", zIndex: "2" }}
      />

      {nonTimeOffProjects.map((p) => (
        <ProjectsBlock
          project={p}
          date={date}
          prReservations={projectReservations.filter((cr) => cr.project_id === p.id)}
          reservations={capacityReservations.filter((cr) => cr.project_id === p.id)}
          projectShowedId={projectShowedId}
          setProjectShowedId={setProjectShowedId}
          users={showedUsers}
          selectedViewType={selectedViewType}
          toggleAllProjects={toggleAllProjects}
          areAllProjectsExpanded={areAllProjectsExpanded}
        />
      ))}

      <SeparatorRow
        showArrow
        name={t("dashboard.time_offs")}
        subtext={t("planning.total_days")}
        reservations={projectReservations.filter((pr) => timeOffs.map((p) => p.id).includes(pr.project_id))}
        date={date}
        selectedViewType={selectedViewType}
        separationKind={!isTimeOffsOpened}
        setDropdownEntity={setIsTimeOffsOpened}
        exportData={() => exportProjectsData("time_off")}
      />

      {isTimeOffsOpened &&
        timeOffs.map((p) => (
          <ProjectsBlock
            project={p}
            date={date}
            prReservations={projectReservations.filter((cr) => cr.project_id === p.id)}
            reservations={capacityReservations.filter((cr) => cr.project_id === p.id)}
            projectShowedId={projectShowedId}
            setProjectShowedId={setProjectShowedId}
            users={showedUsers}
            selectedViewType={selectedViewType}
          />
        ))}
    </div>
  );
};

export default ReportingProjects;

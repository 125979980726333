import React from "react";
import { useTranslation } from "react-i18next";

const PlanningBlocked = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="row error_component">
        <div className="col-md-4 offset-md-4 col-xs-12">
          <div className="not_found">
            <h2>{t("planning_was_blocked")}</h2>
            <div className="text">{t("too_many_active_projects")}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlanningBlocked;

export const StickyTotal = ({ value, style }) => {
  return (
    <div
      style={{
        width: 90,
        maxWidth: 90,
        minWidth: 90,
        height: 56,
        position: "sticky",
        background: "var(--background-1)",
        right: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginLeft: "auto",

        boxShadow: "-1px 0px 0px 0px var(--stroke)",
        borderRight: "1px solid var(--stroke)",
        fontWeight: 600,
        ...style,
      }}
      className="aaaaaaaaa"
    >
      <p style={{ margin: 0, color: "var(--text-2)" }}>{value}</p>
    </div>
  );
};

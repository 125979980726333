import React from "react";
import { getNumberOfDays, getCellWidthForZoom, OffsetDays } from "../../../../lib/DateUtils";
import { GROUPED_PLANNER_USER_WIDTH, getPlannerOffsetX } from "../../../../lib/PlannerUtils";
import { useDateStore, useMilestonesStore, useZoomStore } from "../../../../stores/planner";
import { shallow } from "zustand/shallow";

const GroupedCreationMilestone = ({
  dateStart,
  dateEnd,
  weekendsHidden,
  milestoneProject,
  projectMouseMoved,
  projectMouseUped,
  groupClients,
}) => {
  const milestoneOrder = useMilestonesStore.getState().creationOrder;

  const userRole = localStorage.getItem("tb-role") || "regular";
  const [zoom] = useZoomStore((state) => [state.zoom], shallow);
  const [date] = useDateStore((state) => [state.date], shallow);

  const calculateLeftOffset = () => {
    let dateOffset = getNumberOfDays(date, dateStart.toJSDate());
    let finalOffset = GROUPED_PLANNER_USER_WIDTH;

    if (groupClients) {
      finalOffset += 50;
    }

    for (let i = 0; i < dateOffset; i++) {
      let calculatedDate = OffsetDays(date, i).getDay();
      let isWeekend = weekendsHidden && (calculatedDate === 0 || calculatedDate === 6);
      finalOffset += isWeekend ? 10 : getCellWidthForZoom(zoom);
    }

    return finalOffset;
  };

  const calculateWidth = () => {
    let width = 0;

    for (let i = 0; i <= getNumberOfDays(dateStart.toJSDate(), dateEnd.toJSDate()); i++) {
      let calculatedDate = OffsetDays(dateStart.toJSDate(), i).getDay();
      let isWeekend = weekendsHidden && (calculatedDate === 0 || calculatedDate === 6);
      width += isWeekend ? 10 : getCellWidthForZoom(zoom);
    }

    return width;
  };

  const calculateOffsetTop = () => {
    const constantTopOffset = milestoneOrder === 0 ? 0 : 20;
    return constantTopOffset + 5;
  };

  return (
    <div
      className="milestone"
      id="milestone-creation"
      style={{
        width: `${calculateWidth()}px`,
        left: `${calculateLeftOffset()}px`,
        top: `${calculateOffsetTop()}px`,
        backgroundColor: milestoneProject.color,
      }}
      onMouseMove={(e) => {
        if (["contractor", "regular"].includes(userRole)) return;

        projectMouseMoved(getPlannerOffsetX(e), milestoneProject.id);
      }}
      onMouseUp={(e) => {
        if (e.button === 1) return;
        projectMouseUped();
      }}
    />
  );
};

export default GroupedCreationMilestone;

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ApiIntegrationBlock from "./ApiIntegrationBlock";
import SlackBlock from "./SlackBlock";
import HubspotBlock from "./HubspotBlock";
import ZapierBlock from "./ZapierBlock";
import QuickBooksBlock from "./QuickBooksBlock";
import PersonioBlock from "./PersonioBlock";
import Api from "../../../../Api";

const IntegrationComponent = () => {
  const { t } = useTranslation();
  const [qbData, setQbData] = useState();

  let greetingMessage = t("settings.integration.greeting_text").split("__");

  useEffect(() => {
    Api.Integrations.QuickBooks.get().then((res) => {
      setQbData(res.data);
    });
  }, []);

  return (
    <div className="account-schedule main-settings-component">
      <p className="greeting">
        {greetingMessage[0]}
        <br />
        {greetingMessage[1]}
      </p>

      <div className="integrations__integration-list">
        <ApiIntegrationBlock />

        <SlackBlock />

        <HubspotBlock />

        <ZapierBlock />

        <QuickBooksBlock qbData={qbData} />

        <PersonioBlock />
      </div>
    </div>
  );
};

export default IntegrationComponent;

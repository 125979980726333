import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { useTranslation } from "react-i18next";
import DangerPic from "../../default_images/danger_deletion.svg";
import TeambookOutlinedButton from "../../default_components/TeambookOutlinedButton";

const EmptyExportWarning = ({ open, setOpen, type = "bookings" }) => {
  const { t } = useTranslation();
  return (
    <Dialog className="tb-default-dialog" open={open} onClose={() => setOpen(false)}>
      <DialogContent
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img style={{ margin: "20px 0px", width: 60, justifySelf: "center" }} alt={"danger"} src={DangerPic} />
        <p>{type === "bookings" ? t("dashboard.empty_export_data") : t("dashboard.empty_export_data_logs")}</p>
      </DialogContent>
      <DialogActions>
        <TeambookOutlinedButton onClick={() => setOpen(false)} text={t("close")} />
      </DialogActions>
    </Dialog>
  );
};

export default EmptyExportWarning;

import { createContext } from "react";
export const PlannerContext = createContext({
  team: undefined,
  filteredUsers: [],
  projects: [],
  clients: [],
  users: undefined,
  tags: [],
  bookings: [],
  plannerDate: undefined,
});

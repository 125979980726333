import lsKeys from "./defaultKeys";
import { DateTime } from "luxon";

export const isoCodes = (format = 0) => {
  const currentLanguage = localStorage.getItem(lsKeys.LANGUAGE);

  if (currentLanguage !== "en") {
    return currentLanguage;
  } else {
    return ["en-GB", "en-US", "zh-CN"][format];
  }
};

export const dateFormat = (format = 0) => {
  return ["dd/MM/yyyy", "MM/dd/yyyy", "yyyy/dd/MM"][format];
};

export const shortDateFormat = (format = 0) => {
  return ["d/MM/yy", "MM/d/yyyy", "yyyy/d/MM"][format];
};

export const displayShortDate = (isoDate, format = 0) => {
  return DateTime.fromISO(isoDate).setLocale(isoCodes(format)).toFormat(shortDateFormat(format));
};

export const displayDate = (isoDate, format = 0) => {
  return DateTime.fromISO(isoDate).setLocale(isoCodes(format)).toFormat("d LLL, y");
};

export const displayDateTime = (isoDate, format = 0) => {
  return DateTime.fromISO(isoDate).setLocale(isoCodes(format)).toFormat("T - d LLL, y");
};

import React, { useState, useRef } from "react";

export const TeambookBanner = ({ bannerContent, bannerOpened, className }) => {
  return (
    <>
      {bannerOpened && (
        <div className={`tb-banner mobile_hidden ${className}`}>
          <div className="tb-banner__container">{bannerContent}</div>
        </div>
      )}
    </>
  );
};

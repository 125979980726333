import React from "react";
const TeambookBorderlessBlueButton = ({
  text,
  onClick,
  style,
  color,
  underlineText = true,
}) => {
  return (
    <button
      onClick={onClick}
      style={{
        margin: 0,
        minWidth: "50px",
        cursor: "pointer",
        color: "#7FA6CE",
        fontSize: "14px",
        lineHeight: "24px",
        background: "var(--background-1)",
        border: "unset",
        ...style,
      }}
    >
      <p
        style={{
          margin: 0,
          color,
          textDecoration: underlineText && "underline",
        }}
      >
        {text}
      </p>
    </button>
  );
};

export default TeambookBorderlessBlueButton;

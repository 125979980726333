import React from "react";
import "./my_week.scss";
import BookingIcons from "./BookingIcons";

const MyWeekColumn = ({
  columnDate,
  bookings,
  projects,
  columnClassName,
  tasks,
}) => {
  const columnBookings = bookings.filter(
    (booking) => booking.date === columnDate.toISODate()
  );
  const amountHours =
    columnBookings.reduce((acc, booking) => acc + booking.duration, 0) / 60;

  const Booking = ({ booking, tasks }) => {
    const color = booking.project_color;
    const duration = booking.duration / 60;

    const bookingsStyles = {
      background: booking.tentative ? "var(--background-1)" : color,
      border: booking.tentative && `2px solid ${color}`,
      opacity: booking.tentative ? 1 : 0.7,
      color: booking.tentative ? color : "var(--background-1)",
      height: (booking.duration / 60) * 62,
    };

    return (
      <div className="column__booking-frame" style={bookingsStyles}>
        <div className="booking-info">
          <p>{booking.project_name}</p>
          <p>{duration}h</p>
        </div>

        {booking.task_id && (
          <div className="task-description">
            [{tasks.filter((t) => t.id === booking.task_id)?.[0].name}]
          </div>
        )}

        <BookingIcons
          booking={booking}
          color={bookingsStyles.color}
          project={
            projects.filter((project) => project.id === booking.project_id)[0]
          }
        />
      </div>
    );
  };

  return (
    <div
      className={columnClassName(columnDate, "my-week__column", columnBookings)}
    >
      <div className="column__content">
        <div className="column__day-info">
          <p className="day-number">{columnDate.day}</p>
          <p className="amount-hours">{amountHours}h</p>
        </div>

        {columnBookings.map((booking) => (
          <Booking booking={booking} tasks={tasks} />
        ))}
      </div>
    </div>
  );
};

export default MyWeekColumn;

import React from "react";
import UsersProfileImage from "../../default_components/UsersProfileImage";
import UserBooking from "./UserBooking";
import lsKeys from "../../default_values/defaultKeys";
import BlockedSpace from "./BlockedSpace";

const UserRow = ({ user, bookings, date, index }) => {
  const rows = [];

  const weekendsHidden =
    localStorage.getItem(lsKeys.WEEKENDS_HIDDEN_KEY) === "true";

  for (let i = 0; i < 28; i++) {
    let newDate = date.plus({ day: i });

    if ((5 === i % 7 || (5 + 1) % 7 === i % 7) && weekendsHidden) {
      rows.push(
        <div className="user-row__weekend" key={`user-cell-${newDate}`} />
      );
    } else {
      rows.push(
        <div style={{ position: "relative" }} className="user-row__workday">
          {bookings
            .filter((booking) => booking.date === newDate.toISODate())
            .sort((a, b) => {
              return a.order - b.order;
            })
            .map((booking) => (
              <UserBooking booking={booking} />
            ))}
          {(5 === i % 7 || (5 + 1) % 7 === i % 7) && !weekendsHidden && (
            <BlockedSpace schedule={user.schedule} />
          )}
        </div>
      );
    }
  }

  const calculateTopOffset = () => {
    let baseOffset = 110;

    for (let i = 0; i < index; i++) {
      if ((baseOffset + 137) % 1200 < 137) {
        baseOffset = Math.round((baseOffset + 137) / 1200) * 1200 + 137;
      } else {
        baseOffset += 137;
      }
    }

    if ((baseOffset + 137) % 1200 < 137) {
      return 137 - ((baseOffset + 137) % 1200);
    }

    return 0;
  };

  return (
    <div
      className="user-row"
      style={{
        marginTop: calculateTopOffset(),
      }}
    >
      <div className="user-block">
        <UsersProfileImage
          size={50}
          user={user}
          userImage={user.avatars["50"]}
          style={{
            marginBottom: 10,
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "25px",
          }}
        />

        <p style={{ fontSize: "13px", textAlign: "center" }}>{user.name}</p>
      </div>

      {rows}
    </div>
  );
};

export default UserRow;

import React from "react";
import { useTranslation } from "react-i18next";
import TeambookScheduleComponent from "../../../default_components/schedule/TeambookScheduleComponent";
import { TeambookReactSelect } from "../../../default_components/TeambookReactSelect";

const ScheduleTabInfo = ({
  schedule,
  setSchedule,
  kind,
  holidays,
  selectedHoliday,
  setSelectedHoliday,
}) => {
  const { t } = useTranslation();

  const selectOptions =
    holidays?.map((holiday) => ({
      value: holiday.id,
      label: holiday.name,
    })) || [];

  selectOptions.push({ value: null, label: t("projects.none") });

  return (
    <div className="users__schedule-tab">
      <div className="holidays">
        <p>{t("dashboard.holidays")}:</p>

        {kind === "show" ? (
          <p>{selectedHoliday?.name || t("projects.none")}</p>
        ) : (
          <TeambookReactSelect
            height={44}
            value={
              selectOptions.filter((opt) => opt.value === selectedHoliday)[0]
            }
            options={selectOptions}
            onChange={(value) => {
              setSelectedHoliday(value.value);
            }}
            width="220px"
            grayPlaceholder
          />
        )}
      </div>

      <TeambookScheduleComponent
        schedule={schedule}
        setSchedule={setSchedule}
        kind={kind}
      />
    </div>
  );
};

export default ScheduleTabInfo;

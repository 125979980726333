import UsersProfileImage from "../../../../default_components/UsersProfileImage";
import moment from "moment";
import { WhiteTooltip } from "../../../../default_components/Tooltips";
import { OffsetDays } from "../../../../../lib/DateUtils";
import React from "react";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { useAccountStore } from "../../../../../stores/accountStore";
import { shallow } from "zustand/shallow";

const formatValues = [
  {
    label: "DD/MM/YYYY",
    value: 0,
  },
  {
    label: "MM/DD/YYYY",
    value: 1,
  },
  {
    label: "YYYY/MM/DD",
    value: 2,
  },
];

const UsersRows = ({ index, stats, startDate, isProjects, projects, slaveDataType }) => {
  const { t } = useTranslation();
  let userRows = [];
  const indexedStat = stats.data[index];

  const [account] = useAccountStore((state) => [state.account], shallow);

  const getProjectColor = (id) => projects.find((project) => project.id === id)?.color || "transparent";

  const getClassName = (j) => {
    if (j === 6) {
      return "saturday";
    } else if (j === 7) {
      return "sunday";
    } else {
      return "";
    }
  };

  for (let j = 0; j < indexedStat.daily_entities.length; j++) {
    let userColumns = [];
    let currentDate = startDate;

    userColumns.push(
      <td style={{ borderLeft: "1px solid var(--stroke)" }} className={`fixed-column ${isProjects ? "padded" : ""}`}>
        <div className="flex">
          {isProjects && slaveDataType === "default" ? (
            <UsersProfileImage
              size={24}
              user={indexedStat.daily_entities[j]}
              userImage={indexedStat.daily_entities[j].avatar}
              style={{ backgroundPosition: "center", backgroundSize: "cover" }}
              className="user-form__avatar"
            />
          ) : (
            <>
              <td
                style={{
                  paddingLeft: "0px !important",
                  gap: 5,
                  background: "unset",
                }}
                className="reporting__fixed-project-column fixed-column__content"
              >
                <div
                  style={{
                    width: 10,
                    height: 24,
                    background: indexedStat.daily_entities[j].color || getProjectColor(indexedStat.daily_entities[j].id),
                    borderRadius: 7,
                  }}
                />
                <p className="reporting__column-text">{indexedStat.daily_entities[j].name}</p>
              </td>
            </>
          )}
          <p className="column-text reporting__fixed-user-row">{indexedStat.daily_entities[j].name}</p>
        </div>
      </td>
    );

    for (let k = 0; k < stats.amount_of_days; k++) {
      const date = moment(currentDate).format(formatValues[account?.date_format]?.label);

      let columnValue = indexedStat.daily_entities[j].daily_hours[k];

      if (columnValue === 0) {
        columnValue = "";
      }

      const localeProps = {
        projectName: !isProjects ? indexedStat.daily_entities[j].name : indexedStat.name,
        userName: !isProjects ? indexedStat.name : indexedStat.daily_entities[j].name,
      };

      userColumns.push(
        <td className={`main-table__users-row ${getClassName(DateTime.fromJSDate(currentDate).weekday)}`}>
          <p
            title={
              t(`dashboard.reporting_data.row.${!isProjects ? "hours_for_project" : "hours_for_user"}`, {
                ...localeProps,
              }) + `${date}`
            }
            className="column-text fs-16"
          >
            {columnValue}
          </p>
        </td>
      );

      currentDate = OffsetDays(currentDate, 1);
    }

    userColumns.push(
      <td className="fixed-total-color">
        <p className="column-text">{indexedStat.daily_entities[j].daily_hours.reduce((x, y) => x + y, 0)}</p>
      </td>
    );

    userRows.push(<tr className="main-row">{userColumns}</tr>);
  }

  return <>{userRows}</>;
};

export default UsersRows;

import React, { useState, useEffect } from "react";
import Api from "../../../Api";
import { useParams } from "react-router-dom";
import { DateTime } from "luxon";
import ProjectionHeader from "../ProjectionHeader";
import ProjectionControlRow from "../ProjectionControlRow";
import ProjectionTable from "../ProjectionTable";

const Projection = () => {
  const { url_token } = useParams();

  const [users, setUsers] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [project, setProject] = useState(null);
  const [date, setDate] = useState(DateTime.now().minus({ week: 5 }).startOf("week"));

  useEffect(() => {
    Api.Projects.projection(url_token, date.toISODate()).then((response) => {
      setUsers(response.data.users);
      setBookings(response.data.bookings);
      setProject(response.data.project);
    });
  }, [date]);

  return (
    <>
      {project && (
        <>
          <ProjectionHeader item={project} isProject />

          <ProjectionControlRow currentDate={date} changeDate={(value) => setDate(value)} />

          <ProjectionTable date={date} project={{ project, bookings, users }} />
        </>
      )}
    </>
  );
};

export default Projection;

import React, { useContext, useRef, useState } from "react";
import { getNumberOfDays, getCellWidthForZoom, OffsetDays, GetDaysInZoom } from "../../../../lib/DateUtils";
import { defaultProjectIcons } from "../../../default_values/DefaultProjectIcons";
import BlockIcon from "@mui/icons-material/Block";
import moment from "moment";
import { SliceStringWithDots } from "../../../../lib/StringFormat";
import {
  useBookingsEditingStore,
  useShiftPressedStore,
  useFilteredUsersStore,
  useZoomStore,
  useCopyBookingsStore,
} from "../../../../stores/planner";
import { PlannerContext } from "../../context/PlannerContext";
import lsKeys from "../../../default_values/defaultKeys";
import { DraggableBooking } from "../../default_components/DraggableBooking";
import { GROUPED_PLANNER_USER_WIDTH, getPlannerOffsetX, getPlannerOffsetY } from "../../../../lib/PlannerUtils";
import { useProfileStore } from "../../../../stores/profileStore";
import { shallow } from "zustand/shallow";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { icons } from "../../../default_images/IconsList";
import { DateTime } from "luxon";
import { bookingStartTimeText } from "../../default_components/planner_right_side_modal/StartTimeBlock";

const formatMapping = {
  0: 5,
  1: 10,
  2: 15,
};

const GroupedUserBookings = ({
  bookingsArray,
  date,
  topOffset,
  weekendsHidden,
  maxHeight,
  selectBooking,
  activeProjects,
  changeTooltip,
  bookingFormat,
  teams,
  project,
  changeContextMenu,
  updateBookingsRequest,
  user,
  mouseUped,
  groupClients,
  mouseMoved,
  mouseDowned,
  tasks,
  weekendInfoRef,
  index,
}) => {
  const { team, projects } = useContext(PlannerContext);

  const bookingIds = useBookingsEditingStore(({ bookingsEditing }) => bookingsEditing.map((b) => b.id));
  const [zoom] = useZoomStore((state) => [state.zoom], shallow);

  const [currentUser] = useProfileStore((state) => [state.profile], shallow);

  const [initialSize, setInitialSize] = useState(null);
  const [initialPos, setInitialPos] = useState(null);
  const [isDragging, setIsDragging] = useState(false);

  const role = localStorage.getItem(lsKeys.ROLE);

  const openWeekendInfo = (e, weekendDate, userId) => {
    weekendInfoRef.current.setPos({
      x: e.pageX,
      y: e.pageY,
    });

    weekendInfoRef.current.setOpen(true);

    // weekendInfoRef.current.setBookings(bookings);
    weekendInfoRef.current.setUserIndex(index);
    weekendInfoRef.current.setUserId(userId);

    if (new Date(weekendDate).getDay() === 0) {
      weekendInfoRef.current.setDates([
        DateTime.fromISO(weekendDate).minus({ day: 1 }).toISODate(),
        DateTime.fromISO(weekendDate).toISODate(),
      ]);
    } else {
      weekendInfoRef.current.setDates([
        DateTime.fromISO(weekendDate).toISODate(),
        DateTime.fromISO(weekendDate).plus({ day: 1 }).toISODate(),
      ]);
    }
  };

  const checkSelect = (booking) => bookingIds.includes(booking.id);

  const numRound = (num, precision) => {
    return Math.round(num / precision) * precision;
  };

  const startBookingResizing = (e, booking) => {
    e.stopPropagation();
    setIsDragging(true);
    let resizable = document.getElementById(`booking-${booking.id}-${project.id}`);
    setInitialPos(e.clientY);
    setInitialSize(resizable.offsetHeight);
  };

  const resizingBooking = (e, booking) => {
    e.stopPropagation();
    let resizable = document.getElementById(`booking-${booking.id}-${project.id}`);

    let newHeight =
      parseFloat(initialSize) + numRound(parseFloat(e.clientY - initialPos), formatMapping[bookingFormat] / 2);

    resizable.style.zIndex = 2;

    if (newHeight >= formatMapping[bookingFormat] / 2) {
      resizable.style.height = `${numRound(
        calculateHeight(((newHeight + 1) / formatMapping[bookingFormat]) * 60, booking.id, booking.date),
        formatMapping[bookingFormat] / 2
      )}px`;

      if (resizable.querySelector(".booking__project-duration")) {
        resizable.querySelector(".booking__project-duration").innerHTML =
          parseFloat(document.getElementById(`booking-${booking.id}-${project.id}`).style.height) /
            formatMapping[bookingFormat] +
          "h";
      }
    }
  };

  const endBookingResizing = (e, booking) => {
    e.stopPropagation();
    let resizable = document.getElementById(`booking-${booking.id}-${project.id}`);
    let newDuration =
      numRound(parseFloat(resizable.style.height), formatMapping[bookingFormat] / 2) / formatMapping[bookingFormat];

    resizable.style.zIndex = 1;
    updateBookingsRequest(
      newDuration,
      booking.tentative,
      booking.location,
      booking.start_time,
      booking.comment,
      booking.using_budget,
      [booking],
      getProject(booking.project_id)
    );

    setIsDragging(false);
  };

  const bookingRef = useRef([]);

  const calculateLeftOffset = (bookingDate) => {
    let dateOffset = getNumberOfDays(date, bookingDate);
    let finalOffset = GROUPED_PLANNER_USER_WIDTH;
    const initialWeekDay = date.getDay();

    if (groupClients) {
      finalOffset += 50;
    }

    for (let i = 0; i < dateOffset; i++) {
      const weekday = (initialWeekDay + i) % 7;
      const isWeekend = weekendsHidden && (weekday === 0 || weekday === 6);

      if (i + 1 === dateOffset && weekendsHidden && weekday === 6) break;

      finalOffset += isWeekend ? 10 : getCellWidthForZoom(zoom);
    }

    return finalOffset;
  };

  const calculateHeight = (bookingDuration, bookingId, bookingDate) => {
    let sameDateBookings = getSameDateBookings(bookingDate);

    let index = sameDateBookings.findIndex((booking) => booking.id === bookingId);
    let emptySpace = maxHeight;

    for (let i = 0; i < index; i++) {
      emptySpace -= (sameDateBookings[i].duration / 60) * formatMapping[bookingFormat];
    }

    emptySpace = emptySpace > 0 ? emptySpace : 0;

    let calculatedHeight = (bookingDuration / 60) * formatMapping[bookingFormat];

    return calculatedHeight <= emptySpace ? calculatedHeight : emptySpace;
  };

  const calculateNewTooltipX = (booking, e, i) => {
    return bookingRef.current[i].getBoundingClientRect().left + getCellWidthForZoom(zoom) / 2 + 7;
  };

  const calculateNewTooltipY = (booking, e, i) => {
    return bookingRef.current[i].getBoundingClientRect().top + 140;
  };

  const calculateOffsetTop = (id, date, order) => {
    let offset = 0;
    let sameDateBookings = getSameDateBookings(date);

    let index = sameDateBookings.findIndex((booking) => booking.id === id);

    for (let i = 0; i < index; i++) {
      offset += calculateHeight(sameDateBookings[i].duration);
    }

    return topOffset + offset;
  };

  const getSameDateBookings = (date) => {
    let datedBookings = bookingsArray
      .filter((booking) => booking.date === date)
      .filter((booking) => booking.deleted_at === null);

    let startTimeBookings = datedBookings
      .filter((booking) => booking.start_time !== null)
      .sort((a, b) => {
        return a.start_time - b.start_time;
      });
    let otherBookings = datedBookings
      .filter((booking) => booking.start_time === null)
      .sort((a, b) => {
        return a.order - b.order;
      });

    return [...startTimeBookings, ...otherBookings];
  };

  const calculateWidth = (date) => {
    if (DateTime.fromISO(date).weekday === 5) {
      return getCellWidthForZoom(zoom);
    } else {
      return getCellWidthForZoom(zoom) - 1;
    }
  };

  const project_code = (booking) => {
    if (project.id === booking.project_id) {
      return getProject(booking.project_id).code.slice(0, 5);
    } else {
      return <BlockIcon style={{ fontSize: "16px", fill: "#FFFFFF", stroke: "#FFFFFF" || getColor(booking) }} />;
    }
  };

  const getBackground = (booking) => {
    if (project.id === booking.project_id) {
      return `${booking.tentative ? "var(--background-1)" : booking.project_color}`;
    } else {
      return "#9D9D9D";
    }
  };

  const getProject = (id) => {
    if (activeProjects.find((pr) => pr.id === id) === undefined) {
      return projects.find((pr) => pr.id === id);
    }

    return activeProjects.find((pr) => pr.id === id);
  };

  const getColor = (booking) => {
    const isDarkTheme = localStorage.getItem(lsKeys.THEME) === "dark";

    if (team.id === booking.team_id) {
      if (booking.tentative && project.id === booking.project_id) return `${booking.project_color}`;

      // if (isDarkTheme) return "rgba(255, 255, 255, 0.75)";
      //
      // return "#474747";

      return "#FFFFFF";
    } else {
      // if (isDarkTheme) return "rgba(255, 255, 255, 0.75)";

      // return "#474747";
      return "#FFFFFF";
    }
  };

  const getLineColor = (booking) => {
    if (project.id === booking.project_id) {
      return `${booking.project_color}`;
    } else {
      return "#9D9D9D";
    }
  };

  const getBorder = (booking) => {
    if (project.id === booking.project_id) {
      return booking.tentative ? `2px solid ${booking.project_color}` : "";
    } else {
      return "none";
    }
  };

  const getOpacity = (bookingDate) => {
    return new Date() - 86400000 > new Date(bookingDate) ? "0.7" : "1";
  };

  const dateIsNotWeekend = (date) => {
    if (!weekendsHidden || !date) {
      return true;
    }

    return moment(date).day() !== 0 && moment(date).day() !== 6;
  };

  const bookingObjectStyles = (booking) => {
    return {
      width: `${calculateWidth(booking.date)}px`,
      height: `${calculateHeight(booking.duration, booking.id, booking.date) - 1}px`,
      left: 0,
      top: 0,
      backgroundColor: `${getBackground(booking)}B3`,
      border: getBorder(booking),
      color: getColor(booking),
      fontWeight: "700",
      display:
        calculateHeight(booking.duration, booking.id, booking.date) === 0 || booking.deleted_at !== null
          ? "none"
          : "block",
      opacity: getOpacity(booking.date),
      padding: calculateBookingPadding(booking),
    };
  };

  const calculateBookingPadding = (booking) => {
    if (zoom >= 90) {
      return "1px 0px 0px 0px";
    } else {
      if (booking.tentative) {
        return "2px 2px 2px 6px";
      } else {
        return checkSelect(booking) ? "0px 0px 0px 4px" : "2px 2px 2px 6px";
      }
    }
  };

  if (bookingsArray.length === 0) {
    return null;
  }

  const isResizable = (booking) => {
    if (!bookingIds.length) {
      return true;
    } else if (bookingIds.length === 1 && bookingIds[0] === booking.id) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      {bookingsArray
        .filter(
          (b) =>
            DateTime.fromISO(b.date).startOf("day") <
            DateTime.fromJSDate(date)
              .plus({ day: GetDaysInZoom(zoom) })
              .startOf("day")
        )
        .map((booking, i) =>
          dateIsNotWeekend(booking.date) ? (
            <DraggableBooking
              isDragging={
                role === "regular" || (role === "self_planner" && booking.user_id !== currentUser.id)
                  ? true
                  : isDragging
              }
              key={"drag" + booking.id + "-" + project.id}
              booking={booking}
            >
              <div
                key={`booking-${booking.id}`}
                id={`booking-${booking.id}-${project.id}`}
                className={`proj-${booking.project_id} booking-${booking.id}-${booking.project_id} booking`}
                style={{
                  ...bookingObjectStyles(booking),
                  transform: `translate3d(${calculateLeftOffset(booking.date)}px, ${calculateOffsetTop(
                    booking.id,
                    booking.date,
                    booking.order
                  )}px, 0)`,
                }}
                data-active={bookingIds.includes(booking.id)}
                onClick={() => selectBooking(booking, project.id)}
                onMouseLeave={() => {
                  changeTooltip({ open: false });
                }}
                onMouseDown={(e) => {
                  if (
                    useShiftPressedStore.getState().shiftPressed ||
                    (useCopyBookingsStore.getState().copyModeActive &&
                      useCopyBookingsStore.getState().copyBookingIds.length > 0)
                  ) {
                    e.preventDefault();
                    mouseDowned(
                      getPlannerOffsetX(e),
                      getPlannerOffsetY(e, zoom),
                      useFilteredUsersStore.getState().filteredUsers
                    );
                  }
                }}
                onMouseUp={(e) => {
                  if (e.button === 1) return;
                  if (
                    useShiftPressedStore.getState().shiftPressed ||
                    (useCopyBookingsStore.getState().copyModeActive &&
                      useCopyBookingsStore.getState().copyBookingIds.length > 0)
                  ) {
                    mouseUped(
                      getPlannerOffsetX(e),
                      getPlannerOffsetY(e),
                      useFilteredUsersStore.getState().filteredUsers,
                      e
                    );
                  }
                }}
                onMouseMove={(e) => {
                  if (useShiftPressedStore.getState().shiftPressed) {
                    mouseMoved(
                      getPlannerOffsetX(e),
                      getPlannerOffsetY(e),
                      useFilteredUsersStore.getState().filteredUsers,
                      e
                    );
                  }

                  changeTooltip({
                    open: true,
                    projectCode: getProject(booking.project_id)?.code,
                    projectName: getProject(booking.project_id)?.name,
                    clientName: getProject(booking.project_id)?.client_name,
                    bookingDuration: booking.duration,
                    comment: booking.comment,
                    positionX: calculateNewTooltipX(booking, e, i),
                    positionY: calculateNewTooltipY(booking, e, i),
                    bookingId: booking.id,
                    team: teams.get(booking.team_id.toString()),
                    task: tasks.filter((t) => t.id === booking.task_id)?.[0],
                  });
                }}
                onContextMenu={(e) => {
                  e.preventDefault();
                  if (
                    !(["self_planner"].includes(role) && currentUser.id !== booking.user_id) &&
                    !["regular", "contractor"].includes(role)
                  ) {
                    changeTooltip({
                      open: false,
                    });
                    changeContextMenu({
                      open: true,
                      posY: e.pageY,
                      posX: e.pageX,
                      booking: booking,
                    });
                  }

                  e.stopPropagation();
                }}
                ref={(el) => (bookingRef.current[i] = el)}
              >
                {!booking.tentative && (
                  <div
                    className="booking-line"
                    style={{
                      backgroundColor: getLineColor(booking),
                      width:
                        zoom === 90 ? (checkSelect(booking) ? "1px" : "2px") : checkSelect(booking) ? "2px" : "4px",
                    }}
                  />
                )}

                {booking.duration > 60 &&
                  !(role === "self_planner" && user.id !== currentUser.id) &&
                  !["regular", "contractor"].includes(role) && (
                    <div
                      id={`resizer-${booking.id}-${project.id}`}
                      style={{
                        position: "absolute",
                        width: "100%",
                        bottom: -5,
                        height: 15,
                        zIndex: "10",
                        background: "transparent",
                        cursor: "row-resize",
                        display: isResizable(booking) ? "block" : "none",
                      }}
                      draggable={true}
                      onDragStart={(e) => startBookingResizing(e, booking)}
                      onDrag={(e) => resizingBooking(e, booking)}
                      onDragEnd={(e) => endBookingResizing(e, booking)}
                    />
                  )}

                {zoom < 90 && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        height: "17px",
                      }}
                    >
                      {zoom === 14 ? (
                        <p className="booking__project-name">
                          {team.id === booking.team_id && project.id === booking.project_id ? (
                            getProject(booking.project_id)?.name
                          ) : (
                            <BlockIcon
                              style={{ fontSize: "16px", fill: "#FFFFFF", stroke: "#FFFFFF" || getColor(booking) }}
                            />
                          )}
                        </p>
                      ) : (
                        <p className="booking__project-code">{project_code(booking)}</p>
                      )}

                      {/*<p className="booking__project-duration">{booking.duration / 60}h</p>*/}
                    </div>

                    {booking.task_id && (
                      <div
                        style={{
                          height: "17px",
                        }}
                      >
                        <p className="booking__task-name">{tasks.filter((t) => t.id === booking.task_id)?.[0]?.name}</p>
                      </div>
                    )}
                  </>
                )}

                <div
                  className="booking__booking-icon"
                  style={{
                    gap: zoom >= 90 && "2px",
                    flexDirection: zoom >= 90 ? "column" : "row",
                  }}
                >
                  {booking.repetition && (
                    <p
                      style={{
                        width: "12px",
                        margin: 0,
                        fontWeight: 500,
                        fontSize: zoom === 90 ? 12 : 16,
                        color:
                          project.id === booking.project_id
                            ? booking.tentative
                              ? getProject(booking.project_id).color
                              : "#FFFFFF"
                            : "#FFFFFF",
                      }}
                    >
                      R
                    </p>
                  )}

                  {getProject(booking.project_id)?.kind === "time_off" &&
                    booking.tentative &&
                    defaultProjectIcons(getProject(booking.project_id).icon_id, getColor(booking), zoom)}

                  {getProject(booking.project_id)?.kind === "time_off" &&
                    !booking.tentative &&
                    defaultProjectIcons(getProject(booking.project_id).icon_id, getColor(booking), zoom)}

                  {booking.location === 1 && !booking.tentative && defaultProjectIcons(-2, getColor(booking), zoom)}

                  {booking.location === 1 &&
                    booking.tentative &&
                    defaultProjectIcons(
                      -2,
                      project.id === booking.project_id ? getProject(booking.project_id).color : "#FFFFFF",
                      zoom
                    )}
                  {booking.comment.length > 0 && !booking.tentative && defaultProjectIcons(-3, getColor(booking), zoom)}

                  {booking.comment.length > 0 &&
                    booking.tentative &&
                    defaultProjectIcons(
                      -3,
                      project.id === booking.project_id ? getProject(booking.project_id).color : "#FFFFFF",
                      zoom
                    )}

                  {booking.location === 2 && !booking.tentative && defaultProjectIcons(3, getColor(booking), zoom)}

                  {booking.location === 2 &&
                    booking.tentative &&
                    defaultProjectIcons(
                      3,
                      project.id === booking.project_id ? getProject(booking.project_id).color : "#FFFFFF",
                      zoom
                    )}

                  {booking.using_budget === false &&
                    !booking.tentative &&
                    defaultProjectIcons(10, getColor(booking), zoom)}

                  {booking.using_budget === false &&
                    booking.tentative &&
                    defaultProjectIcons(
                      10,
                      project.id === booking.project_id ? getProject(booking.project_id).color : "#FFFFFF",
                      zoom
                    )}
                </div>

                {booking.start_time?.toString() && (
                  <div
                    className="booking__start-time-block"
                    style={{
                      display: "flex",
                      justifyContent: zoom >= 90 && "center",
                    }}
                  >
                    {/*{project.id !== booking.project_id*/}
                    {/*  ? defaultProjectIcons(-4, "#fff", zoom)*/}
                    {/*  : booking.tentative*/}
                    {/*    ? defaultProjectIcons(-4, getProject(booking.project_id).color, zoom)*/}
                    {/*    : defaultProjectIcons(-4, "#fff", zoom)}*/}

                    {zoom < 90 && (
                      <p
                        className="booking__start-time-text"
                        style={{
                          color: getColor(booking),
                        }}
                        tentative={booking.tentative}
                      >
                        {bookingStartTimeText(booking)}
                      </p>
                    )}
                  </div>
                )}
              </div>
            </DraggableBooking>
          ) : (
            <TeambookIcon
              name={icons.WARNING}
              color="red"
              className="booking__weekend-booking-sign pointer"
              style={{
                left: `${calculateLeftOffset(booking.date) + 3}px`,
              }}
              key={`bookings-weekend-${booking.id}`}
              onClick={(e) => {
                openWeekendInfo(e, booking.date, booking.user_id);
              }}
            />
          )
        )}
    </>
  );
};

export default GroupedUserBookings;

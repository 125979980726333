import { Route } from "react-router-dom";
import NoMatch from "../components/default_components/NoMatch";
import React, { useState } from "react";
import { BrowserRouter as Router, Routes } from "react-router-dom";
import routes from "./routesMapping";

const TeambookRoutes = ({ role }) => {
  const [isAdminRole, setIsAdminRole] = useState(false);

  return (
    <Router basename="/">
      <Routes>
        {routes(role, isAdminRole, setIsAdminRole).map((route) => (
          <Route path={route.path} element={route.element} key={`path-${route.path}`} />
        ))}

        <Route path="*" element={<NoMatch />} />
      </Routes>
    </Router>
  );
};

export default TeambookRoutes;

import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import FormErrorMessage from "../../../../default_components/FormErrorMessage";
import Api from "../../../../../Api";
import { projectIconsQuantity, defaultProjectIcons } from "../../../../default_values/DefaultProjectIcons";
import TeambookTextForm from "../../../../default_components/TeambookTextForm";
import TeambookBlueButton from "../../../../default_components/TeambookBlueButton";
import { TeambookIcon } from "../../../../default_images/TeambookIcon";
import { icons } from "../../../../default_images/IconsList";
import { shallow } from "zustand/shallow";
import { useNotificationStore } from "../../../../../stores/notificationStore";

export default function EditTask({ getProjects, open, setOpen, selectedTimeOff }) {
  const { t } = useTranslation();
  const [setInAppNotification] = useNotificationStore((state) => [state.setInAppNotification], shallow);
  const [name, setName] = useState(selectedTimeOff.name);
  const [code, setCode] = useState(selectedTimeOff.code);
  const [icon, setIcon] = useState(selectedTimeOff.icon_id);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorField, setErrorField] = useState(null);

  const handleSubmit = () => {
    Api.Tasks.update(selectedTimeOff.id, name)
      .then((response) => {
        handleClose();
        setErrorMessage(null);
        setInAppNotification(t("notifications.settings.timeoff_holidays.time_off.edit"));
      })
      .catch((error) => analyzeCreationError(error));
  };

  const handleClose = () => {
    getProjects();

    setOpen(false);
    setIcon(null);
    setName("");
    setCode("");
    setErrorMessage(null);
  };

  let allIcons = [];

  for (let i = 1; i < projectIconsQuantity; i++) {
    allIcons.push(
      <div
        className="payroll-icon"
        onClick={() => setIcon(i)}
        style={{ border: i === icon ? "1px solid #333333" : "unset" }}
      >
        {defaultProjectIcons(i, "var(--text-2)")}
      </div>
    );
  }

  const analyzeCreationError = (error) => {
    setErrorMessage(error.response.data.error.message);
    setErrorField(error.response.data.error.field);
  };

  const closeErrorMessage = () => {
    setErrorMessage(null);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth={"xs"}
      className="tb-default-dialog"
    >
      <DialogTitle id="form-dialog-title">
        <p> {t("settings.tasks.edit_task")}</p>
        <TeambookIcon name={icons.CROSS} onClick={handleClose} />
      </DialogTitle>
      <DialogContent>
        <div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <TeambookTextForm
              id={"nameTimeOff"}
              fieldName={t("settings.time_off.to_name")}
              onChange={(text) => setName(text.length > 0 ? text[0].toUpperCase() + text.slice(1) : "")}
              type={"text"}
              fieldValue={name}
              width={"100%"}
            />
          </div>

          <div>
            {errorMessage !== null && errorField === "name" ? (
              <FormErrorMessage text={errorMessage} closeErrorMessage={closeErrorMessage} />
            ) : null}
          </div>
        </div>

        {errorMessage !== null && errorField === "icon" ? (
          <FormErrorMessage text={errorMessage} closeErrorMessage={closeErrorMessage} />
        ) : null}
      </DialogContent>
      <DialogActions>
        <TeambookBlueButton text={t("save")} onClick={handleSubmit} id="saveEditTimeOffButton" />
      </DialogActions>
    </Dialog>
  );
}

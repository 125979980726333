import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Api from "../../../Api";
import TeambookBlueButton from "../../default_components/TeambookBlueButton";
import ControlPanel from "../ControlPanel";
import { DateTime } from "luxon";

export default function FrontendErrorsStatistics({ errors }) {
  const WIDTH = "20%";

  return (
    <table
      style={{
        width: "99.5%",
        margin: "70px 5px",
        textAlign: "left",
      }}
      border="1px solid black"
    >
      <tr style={{ textAlign: "center" }}>
        <th style={{ width: WIDTH, padding: 10 }}>Error ID</th>
        <th style={{ width: WIDTH, padding: 10 }}>Account ID</th>
        <th style={{ width: WIDTH, padding: 10 }}>User ID</th>
        <th style={{ width: WIDTH, padding: 10 }}>Error</th>
        <th style={{ width: WIDTH, padding: 10 }}>URL</th>
        <th style={{ width: WIDTH, padding: 10 }}>Date Time</th>
      </tr>

      {errors.map((error) => {
        return (
          <tr>
            <td style={{ width: WIDTH, padding: 10 }}>{error.id}</td>
            <td style={{ width: WIDTH, padding: 10 }}>{error.account_id}</td>
            <td style={{ width: WIDTH, padding: 10 }}>{error.user_id}</td>
            <td style={{ width: WIDTH, padding: 10 }}>{error.error_text.slice(0, 250)}</td>
            <td style={{ width: WIDTH, padding: 10 }}>{error.url}</td>
            <td style={{ width: WIDTH, padding: 10 }}>{error.created_at}</td>
          </tr>
        );
      })}
    </table>
  );
}

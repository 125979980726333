import { TeambookCheckbox } from "./TeambookCheckbox";
import TeambookRadioButton from "./TeambookRadioButton";
import { InputNumber } from "./InputNumber";
import React from "react";
import { useTranslation } from "react-i18next";

const TeambookNotificationComponent = ({
  notificationSetting,
  setNotificationSetting,
  blockEditing,
}) => {
  const { t } = useTranslation();

  return (
    <div className="settings__general-info__form__fields">
      <div
        style={{
          opacity: notificationSetting.operational_setting !== "off" ? 1 : 0.6,
        }}
        className="notifications-group"
      >
        <div className="notifications-select-row primary-row">
          <TeambookCheckbox
            checked={notificationSetting.operational_setting !== "off"}
            onChange={(event) => {
              if (blockEditing) {
                return;
              }

              if (notificationSetting.operational_setting !== "off") {
                setNotificationSetting({
                  ...notificationSetting,
                  operational_setting: "off",
                });
              } else {
                setNotificationSetting({
                  ...notificationSetting,
                  operational_setting: "daily",
                });
              }
            }}
            color="primary"
            style={{
              marginRight: "15px",
              height: "fit-content",
            }}
          />

          <p
            className="pointer notifications-header"
            onClick={() => {
              if (blockEditing) {
                return;
              }

              if (notificationSetting.operational_setting !== "off") {
                setNotificationSetting({
                  ...notificationSetting,
                  operational_setting: "off",
                });
              } else {
                setNotificationSetting({
                  ...notificationSetting,
                  operational_setting: "daily",
                });
              }
            }}
          >
            {t("profile.notifications.operational_planning")}
          </p>
        </div>

        <div
          className="notifications-block"
          style={{ marginLeft: 39, marginTop: 6 }}
        >
          <div className="notifications-select-row">
            <TeambookRadioButton
              checked={notificationSetting.operational_setting === "daily"}
              onCheck={() => {
                if (blockEditing) {
                  return;
                }

                setNotificationSetting({
                  ...notificationSetting,
                  operational_setting: "daily",
                });
              }}
            />

            <p
              className="notifications-secondary-header pointer"
              onClick={() => {
                if (blockEditing) {
                  return;
                }

                setNotificationSetting({
                  ...notificationSetting,
                  operational_setting: "daily",
                });
              }}
            >
              {t("profile.notifications.daily_notifications")}
            </p>
          </div>

          <p
            onClick={() => {
              if (blockEditing) {
                return;
              }

              setNotificationSetting({
                ...notificationSetting,
                operational_setting: "daily",
              });
            }}
            className="notifications-description pointer"
          >
            {t("profile.notifications.daily_notifications_description")}
          </p>
        </div>

        <div className="notifications-block" style={{ marginLeft: 39 }}>
          <div className="notifications-select-row">
            <TeambookRadioButton
              checked={notificationSetting.operational_setting === "immediate"}
              onCheck={() => {
                if (blockEditing) {
                  return;
                }

                setNotificationSetting({
                  ...notificationSetting,
                  operational_setting: "immediate",
                });
              }}
            />

            <p
              className="notifications-secondary-header pointer"
              onClick={() => {
                if (blockEditing) {
                  return;
                }

                setNotificationSetting({
                  ...notificationSetting,
                  operational_setting: "immediate",
                });
              }}
            >
              {t("profile.notifications.immediate_notifications")}
            </p>
          </div>

          <p
            onClick={() => {
              if (blockEditing) {
                return;
              }

              setNotificationSetting({
                ...notificationSetting,
                operational_setting: "immediate",
              });
            }}
            className="notifications-description pointer"
          >
            {t("profile.notifications.immediate_notifications_description")}
          </p>
        </div>
      </div>

      <div
        style={{
          opacity: notificationSetting.capacity_setting !== "off" ? 1 : 0.6,
        }}
        className="notifications-group"
      >
        <div className="notifications-select-row primary-row">
          <TeambookCheckbox
            checked={notificationSetting.capacity_setting !== "off"}
            onChange={(event) => {
              if (blockEditing) {
                return;
              }

              if (notificationSetting.capacity_setting !== "off") {
                setNotificationSetting({
                  ...notificationSetting,
                  capacity_setting: "off",
                });
              } else {
                setNotificationSetting({
                  ...notificationSetting,
                  capacity_setting: "on",
                });
              }
            }}
            color="primary"
            style={{
              marginRight: "15px",
              height: "fit-content",
            }}
          />

          <p
            className="notifications-header pointer"
            onClick={() => {
              if (blockEditing) {
                return;
              }

              if (notificationSetting.capacity_setting !== "off") {
                setNotificationSetting({
                  ...notificationSetting,
                  capacity_setting: "off",
                });
              } else {
                setNotificationSetting({
                  ...notificationSetting,
                  capacity_setting: "on",
                });
              }
            }}
          >
            {t("profile.notifications.capacity_planning")}
          </p>
        </div>

        <div
          className="notifications-block secondary-block"
          style={{ alignItems: "flex-end" }}
        >
          <div className="notifications-select-row">
            <p
              className="notifications-secondary-header"
              style={{
                marginTop: 33,
              }}
            >
              {t("profile.notifications.capacity_description", {
                day: notificationSetting.capacity_day,
              })}
            </p>
          </div>

          {notificationSetting.capacity_setting !== "off" && (
            <InputNumber
              max={28}
              min={1}
              step={1}
              setValue={(value) => {
                if (blockEditing) {
                  return;
                }

                setNotificationSetting({
                  ...notificationSetting,
                  capacity_day: value,
                });
              }}
              value={notificationSetting.capacity_day}
            />
          )}
        </div>
      </div>

      <div
        style={{
          opacity: notificationSetting.actual_setting !== "off" ? 1 : 0.6,
        }}
        className="notifications-group"
      >
        <div className="notifications-select-row primary-row">
          <TeambookCheckbox
            checked={notificationSetting.actual_setting !== "off"}
            onChange={(event) => {
              if (blockEditing) {
                return;
              }

              if (notificationSetting.actual_setting !== "off") {
                setNotificationSetting({
                  ...notificationSetting,
                  actual_setting: "off",
                });
              } else {
                setNotificationSetting({
                  ...notificationSetting,
                  actual_setting: "on",
                });
              }
            }}
            color="primary"
            style={{
              marginRight: "15px",
              height: "fit-content",
            }}
          />

          <p
            className="notifications-header pointer"
            onClick={() => {
              if (blockEditing) {
                return;
              }

              if (notificationSetting.actual_setting !== "off") {
                setNotificationSetting({
                  ...notificationSetting,
                  actual_setting: "off",
                });
              } else {
                setNotificationSetting({
                  ...notificationSetting,
                  actual_setting: "on",
                });
              }
            }}
          >
            {t("profile.notifications.actual_time_tracking")}
          </p>
        </div>

        <div className="notifications-block secondary-block">
          <div className="notifications-select-row">
            <p
              style={{
                marginTop: 33,
              }}
              className="notifications-secondary-header"
            >
              {t("profile.notifications.actual_time_description", {
                day: notificationSetting.actual_day,
              })}
            </p>
          </div>

          {notificationSetting.actual_setting !== "off" && (
            <InputNumber
              max={28}
              min={1}
              step={1}
              setValue={(value) => {
                if (blockEditing) {
                  return;
                }

                setNotificationSetting({
                  ...notificationSetting,
                  actual_day: value,
                });
              }}
              value={notificationSetting.actual_day}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TeambookNotificationComponent;

import { components } from "react-select";
import ReactSelect from "react-select/creatable";
import { TeambookIcon } from "../default_images/TeambookIcon";
import { icons } from "../default_images/IconsList";

export const ReactSelectStyles = (height, width, grayPlaceholder) => {
  return {
    option: (base, data) => {
      return {
        ...base,
        background: data.isSelected
          ? "var(--stroke) !important"
          : data.isFocused
          ? "var(--background-2) !important"
          : "#transparent",
        color: data.isSelected ? "var(--selected-option-color)" : "var(--text-2)",
        fontSize: "14px !important",
        fontFamily: "Montserrat",
      };
    },

    menu: (base) => ({
      ...base,
      background: "var(--background-1)",
    }),

    input: (base) => ({
      ...base,
      color: "var(--text-2)",
    }),

    menuList: (base) => ({
      ...base,
      background: "var(--background-1)",
      border: "none",
      boxShadow: "var(--box-shadow)",
    }),

    control: (base, data) => {
      return {
        ...base,
        height: height || data.height,
        maxHeight: height || data.height,
        width: width || data.width,
        minWidth: width || data.width,
        maxWidth: "unset",
        fontFamily: "Montserrat",
        background: "var(--background-1) !important",
        border: data.isDisabled ? "unset !important" : "1px solid var(--stroke) !important",
      };
    },

    multiValue: (base) => ({
      ...base,
      background: "#F5F5F5 !important",
      fontFamily: "Montserrat",
    }),

    singleValue: (base) => ({
      ...base,
      color: grayPlaceholder && "var(--text-2) !important",
    }),
  };
};

export const TeambookReactSelect = ({
  nameStyles,
  fieldName,
  classNamePrefix = "tb-react-select",
  isSearchable = false,
  menuIsOpen,
  options,
  value,
  defaultValue,
  menuPlacement,
  onChange,
  id,
  height,
  width,
  type,
  new_components,
  closeMenuOnSelect,
  isDisabled,
  placeholder,
  createOptionPosition = "",
  maxMenuHeight,
  formatCreateLabel = () => {},
  isValidNewOption = () => {},
  onCreateOption = () => {},
  styles,
  onBlur = () => {},
  menuPortalTarget = document.body,
  dropdownArrow,
  filterOption,
  grayPlaceholder,
}) => {
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <TeambookIcon
          name={icons.ARROW_DOWN}
          style={{
            cursor: "pointer",
            transform: props.selectProps.menuIsOpen ? "rotate(180deg)" : "unset",
          }}
        />
      </components.DropdownIndicator>
    );
  };

  const MultiValueRemove = (props) => {
    return (
      <components.MultiValueRemove {...props}>
        <TeambookIcon name={icons.CROSS} style={{ width: 14, height: 14 }} />
      </components.MultiValueRemove>
    );
  };

  return (
    <>
      {fieldName && (
        <p className="tb-react-select__field-name" style={{ fontSize: 14, marginBottom: 5, ...nameStyles }}>
          {fieldName}
        </p>
      )}
      <ReactSelect
        components={{
          DropdownIndicator,
          MultiValueRemove: MultiValueRemove,
          ...new_components,
        }}
        menuPortalTarget={menuPortalTarget}
        classNamePrefix={type === "multiple" ? `${classNamePrefix}-multi` : `${classNamePrefix}`}
        isSearchable={isSearchable}
        menuIsOpen={menuIsOpen}
        options={options}
        value={value}
        styles={{
          ...ReactSelectStyles(height, width, grayPlaceholder),
          ...styles,
        }}
        getOptionValue={({ id, value }) => id || value}
        getOptionLabel={(object) =>
          object.label !== undefined ? object.label : object.name !== undefined ? object.name : object.title
        }
        maxMenuHeight={maxMenuHeight}
        defaultValue={defaultValue}
        menuPlacement={menuPlacement}
        onChange={onChange}
        onBlur={onBlur}
        id={id}
        isMulti={type === "multiple"}
        closeMenuOnSelect={closeMenuOnSelect}
        isDisabled={isDisabled}
        placeholder={placeholder}
        createOptionPosition={createOptionPosition}
        formatCreateLabel={formatCreateLabel}
        isValidNewOption={isValidNewOption}
        onCreateOption={onCreateOption}
        filterOption={filterOption}
      />
    </>
  );
};

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import TeambookFilterComponent from "../default_components/teambook_filter/TeambookFilterComponent";
import lsKeys from "../default_values/defaultKeys";
import { DateTime } from "luxon";
import { TeambookIcon } from "../default_images/TeambookIcon";
import { icons } from "../default_images/IconsList";
import CapacitySplitSelector from "./lib/CapacitySplitSelector";
import { WhiteTooltip } from "../default_components/Tooltips";
import XLSExport from "../../lib/XLSExport";
import { TeambookMonthPicker } from "../default_components/TeambookMonthPicker";
import { useCapacityStore } from "../../stores/capacity";
import { shallow } from "zustand/shallow";
import CapacityVisualSettings from "./CapacityVisualSettings";
import { TeambookTeamsPicker } from "../default_components/TeambookDefaultPickers";

const clientWidth = document.body.clientWidth;

const ControlBar = ({
  selectedDate,
  setSelectedDate,
  setSelectedType,
  selectedType,
  users,
  teams,
  projects,
  tags,
  clients,
  businessUnits,
  managers,
  setSelectedViewType,
  selectedViewType,
  copyPlanner,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [visualSettingsOpened, setVisualSettingsOpened] = useState(false);

  const [showTimeOffs, setShowTimeOffs] = useCapacityStore(
    (state) => [state.showTimeOffs, state.setShowTimeOffs],
    shallow
  );

  const version = clientWidth < 640 ? "mobile" : (clientWidth >= 640 && clientWidth) < 1100 ? "tablet" : "desktop";

  const openVisualSettings = () => {
    setVisualSettingsOpened(true);
  };

  const closeVisualSettings = () => {
    setVisualSettingsOpened(false);
  };

  const exportCapacity = () => {
    XLSExport.ExportCapacity({
      from: selectedDate.startOf("month"),
      to: selectedDate.startOf("month").plus({ year: 2 }),
    });
  };

  const capacityFilterPage = {
    user: "userCapacity",
    project: "projectCapacity",
  };

  return (
    <div className="capacity__control-bar">
      <div className="capacity__control-bar__splitter" style={{ flex: 1 }}>
        <WhiteTooltip title={t("today")}>
          <div className="dashboard__tb-button" onClick={() => setSelectedDate(DateTime.now().startOf("month"))}>
            <TeambookIcon style={{ width: 20, height: 20 }} name={icons.TODAY} color="darkblue" />
          </div>
        </WhiteTooltip>

        <TeambookMonthPicker width={140} currentDate={selectedDate} setCurrentDate={setSelectedDate} />

        <div style={{ marginLeft: 3 }} className="planner-control__filter-text-field capacity mobile_hidden">
          <TeambookFilterComponent
            users={users}
            clients={clients}
            projects={projects}
            tags={tags}
            teams={teams.map((team) => ({
              ...team,
              team_users: users.filter((user) => team.team_users.includes(user.id)),
            }))}
            managers={managers}
            businessUnits={businessUnits}
            filterPage={capacityFilterPage[selectedType]}
          />
        </div>

        <div className="control-bar__left-side">
          {teams.length > 1 && (
            <div className="disabled">
              <TeambookTeamsPicker
                disabled
                classNamePrefix={"tb-react-planner-selector"}
                teams={teams}
                value={teams[0]}
                onChange={() => {}}
                width={128}
                height={40}
                isSearchable={false}
              />
            </div>
          )}
          <div className="planner-control-bar__action-buttons">
            <div className="dashboard__tb-button disabled" onClick={() => {}}>
              <TeambookIcon name={icons.REVERT} color="darkblue" />
            </div>

            {copyPlanner && selectedType === "user" && (
              <div className="dashboard__tb-button" onClick={copyPlanner}>
                <TeambookIcon name={icons.COPY} tooltipTitle={t("actuals.copy_planner")} color="darkblue" />
              </div>
            )}

            <div className="dashboard__tb-button disabled" onClick={exportCapacity}>
              <TeambookIcon name={icons.PRINT} color="darkblue" />
            </div>
          </div>

          <div className="dashboard__tb-button" onClick={openVisualSettings}>
            <TeambookIcon
              name={icons.EYE_OPENED}
              tooltipTitle={t("planning.control_panel.visual_settings")}
              color="darkblue"
            />
          </div>

          <CapacitySplitSelector
            className="mobile_hidden"
            pageName={t("planning.select_by_capacity")}
            onSelect={setSelectedType}
            selectedType={selectedType}
          />
        </div>

        <CapacityVisualSettings
          open={visualSettingsOpened}
          showTimeOff={showTimeOffs}
          setShowTimeOff={setShowTimeOffs}
          selectedViewType={selectedViewType}
          setSelectedViewType={setSelectedViewType}
          closeWindow={closeVisualSettings}
        />
      </div>
    </div>
  );
};

export default ControlBar;

import TeambookMenuComponent from "../../TeambookMenuComponent";
import React, { useState } from "react";
import SaveFilterModal from "./SaveFilterModal";
import { useTeambookFilter } from "../../../../stores/planner";
import ApplyFilterModal from "./ApplyFilterModal";
import { useTranslation } from "react-i18next";
import Api from "../../../../Api";

const SavedFilterMenu = ({ storageOptionsAnchor, storageOptionsOpened, setStorageOptionsOpened, filterPage }) => {
  const { t } = useTranslation();
  const [saveFilterModalOpen, setSaveFilterModalOpen] = useState(false);
  const [applyFilterModalOpen, setApplyFilterModalOpen] = useState(false);

  const openApplyFilterModal = () => {
    setApplyFilterModalOpen(true);
    setStorageOptionsOpened(false);
  };

  const openSaveFilterModal = () => {
    setStorageOptionsOpened(false);

    if (useTeambookFilter.getState().filterValues.length > 0) {
      Api.Users.get_saved_filters(filterPage).then((res) => {
        if (res.data.length > 2) {
          alert(t("planning.more_than_three"));
        } else {
          setSaveFilterModalOpen(true);
        }
      });
    } else {
      alert(t("planning.empty_filter"));
    }
  };

  return (
    <>
      <TeambookMenuComponent
        anchorEl={storageOptionsAnchor}
        isOpen={storageOptionsOpened}
        closeMenu={() => {
          setStorageOptionsOpened(false);
        }}
        options={[
          { text: t("planning.save_filter"), onClick: openSaveFilterModal, style: { borderRadius: "10px 10px 0 0" } },
          {
            text: t("planning.apply_saved_filter"),
            onClick: openApplyFilterModal,
            style: { borderRadius: "0 0 10px 10px" },
          },
        ]}
      />

      {saveFilterModalOpen && (
        <SaveFilterModal setSaveFilterModalOpen={setSaveFilterModalOpen} filterPage={filterPage} />
      )}

      {applyFilterModalOpen && (
        <ApplyFilterModal setApplyFilterModalOpen={setApplyFilterModalOpen} filterPage={filterPage} />
      )}
    </>
  );
};

export default SavedFilterMenu;

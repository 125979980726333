import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import "../dashboard.scss";
import { useTranslation } from "react-i18next";
import { colorsPerType } from "../line_chart/colorsPerType";
import { WhiteTooltip } from "../../default_components/Tooltips";

const colors = ["var(--accent-dark)", "var(--accent-light)"];

const DashboardBars = ({ stats, type }) => {
  const { t } = useTranslation();
  const barsColor = colorsPerType(type).baseColor;

  let barsData = stats
    .map((stat, i) => {
      return [
        {
          name: t("dashboard.billable"),
          value: stat.time_bar.billable,
          color: barsColor,
          barDescription: t("dashboard.billable_bar"),
          group: 1,
          typeIndex: i,
        },
        {
          name: t("dashboard.non_billable"),
          value: stat.time_bar.non_billable,
          color: barsColor,
          barDescription: t("dashboard.non_billable_bar"),
          group: 2,
          typeIndex: i,
        },
        {
          name: t("dashboard.time_off"),
          value: stat.time_bar.time_off,
          color: barsColor,
          barDescription: t("dashboard.time_off_bar"),
          group: 3,
          typeIndex: i,
        },
        {
          name: t("dashboard.unscheduled"),
          value: stat.time_bar.unscheduled,
          color: barsColor,
          barDescription: t("dashboard.available_bar"),
          group: 4,
          typeIndex: i,
        },
      ];
    })
    .flat()
    .sort((a, b) => (a.group + a.typeIndex > b.group + b.typeIndex ? 1 : -1));

  const maxHeight = Math.max(
    ...stats.map((stat) =>
      Math.max(...[stat.time_bar.billable, stat.time_bar.unscheduled, stat.time_bar.non_billable, stat.time_bar.time_off])
    )
  );
  const hours = () => {
    return [5, 4, 3, 2, 1].map((value) => (findNextHigherRoundNumber(Math.round(maxHeight)) / 5) * value);
  };

  const findNextHigherRoundNumber = (inputNumber) => {
    // Convert the input number to a string
    var inputString = inputNumber.toString();

    // Find the position of the leftmost non-zero digit
    var nonZeroIndex = inputString.indexOf(inputString.match(/[1-9]/));

    // Construct the result string based on the leftmost non-zero digit
    var resultString;
    if (nonZeroIndex === 0) {
      // For numbers starting with a non-zero digit, increment that digit by 1
      resultString = (parseInt(inputString.charAt(nonZeroIndex), 10) + 1).toString() + "0".repeat(inputString.length - nonZeroIndex - 1);
    } else {
      // For other numbers, increment the first digit by 1 and append zeros
      resultString = (parseInt(inputString.charAt(0), 10) + 1).toString() + "0".repeat(inputString.length - 1);
    }

    // Convert the constructed string to a number and return
    return parseInt(resultString, 10);
  };

  const calculateHeight = (value) => {
    const height = Math.max((value / findNextHigherRoundNumber(Math.round(maxHeight))) * 298, value > 0 ? 10 : 0);

    return height;
  };

  return (
    <div className="dashboard__bars">
      <div className="dashboard-bars__title" style={{ position: "absolute", left: 20, top: 20 }}>
        {type === "capacity" ? "Daily Split" : t("dashboard.hourly_split")}
      </div>

      <div className="dashboard-bars__grid">
        <div className="dashboard-grid__hours">
          {hours().map((h) => {
            return (
              <div style={{ display: "flex", position: "relative" }}>
                <p className="dashboard-grid__hour">
                  {h.toFixed(1)}
                  {type === "capacity" ? "d" : "h"}
                </p>
                <div className="grid-row" />
              </div>
            );
          })}
        </div>

        <div
          style={{
            justifyContent: type === "compare" ? "unset" : "space-around",
          }}
          className="dashboard-bars__bars-wrapper"
        >
          {barsData.map((data, i) => {
            const value = data.value;
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <WhiteTooltip title={value + " " + (type === "capacity" ? "d" : "h")}>
                  <div
                    className="dashboard-bars__bar"
                    style={{
                      marginLeft: type === "compare" && i % 2 === 0 && i !== 0 ? 55 : i === 0 && type === "compare" ? 30 : 1,
                      height: calculateHeight(value),
                      maxHeight: 310,
                      borderRadius: 6,
                      background: type === "compare" ? colors[i % 2] : colorsPerType(type).baseColor,
                      width: type === "compare" ? 20 : 30,
                    }}
                  />
                </WhiteTooltip>

                {((type === "compare" && i % 2 === 0) || type !== "compare") && (
                  <div
                    style={{
                      left: type === "compare" && i !== 0 ? 60 : type === "compare" && i === 0 ? 35 : 1,
                      width: 30,
                      position: "relative",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      className="dashboard-bars__bar-name"
                      style={{
                        position: "absolute",
                        bottom: -35,
                        whiteSpace: "nowrap",
                      }}
                    >
                      <WhiteTooltip title={value + "h"}>{data.name}</WhiteTooltip>
                    </p>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default DashboardBars;

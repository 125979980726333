export const projectColors = [
  "#E04848",
  "#E37142",
  "#FF9D54",
  "#FFCA42",
  "#FFE373",
  "#84D68A",
  "#56B268",
  "#2B664A",
  "#4BAE88",
  "#86D7C6",
  "#52CBD9",
  "#81A4D5",
  "#4393EF",
  "#554FFF",
  "#9B67CC",
  "#F77CDA",
  "#D96F6A",
  "#A38A83",
];

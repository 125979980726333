import { useTranslation } from "react-i18next";
import moment from "moment";
import { TeambookReactSelect } from "../../default_components/TeambookReactSelect";
import { TeambookCheckbox } from "../../default_components/TeambookCheckbox";
import { InputNumber } from "../../default_components/InputNumber";
import { useEffect } from "react";
import TeambookDatePicker from "../../default_components/teambook_date_selector/DatePicker";
import { DateTime } from "luxon";

const BookingRepetition = ({
  repetitionType,
  setRepetitionType,
  repetitionEvery,
  creationBookingsArray,
  setRepetitionEvery,
  repetitionEndsAfter,
  setRepetitionEndsAfter,
  repetitionEndsAfterNumber,
  setRepetitionEndsAt,
  repetitionEndsAt,
  setRepetitionEndsAfterNumber,
  repetitionEndsAtDate,
  setRepetitionEndsAtDate,
  repetitionIgnoreWeekends,
  setRepetitionIgnoreWeekends,
}) => {
  const { t } = useTranslation();

  const repetitionEveryHash = () => {
    //1, 2, 3, ..., 30, 31
    let repetitions = Array.from({ length: 31 }, (_, i) => i + 1);

    if (repetitionType === "weekly") {
      repetitions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
    } else if (repetitionType === "monthly") {
      repetitions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    } else if (repetitionType === "yearly") {
      repetitions = [1, 2, 3, 4, 5];
    } else if (repetitionType === "custom") {
      repetitions = [1, 2, 3, 4];
    }

    return repetitions.map(function (el) {
      return {
        value: el,
        label: el,
        name: el,
      };
    });
  };

  const repetitionOptions = () => {
    const values = [
      {
        value: "weekly",
        label: t("planning.weekly"),
      },
      {
        value: "monthly",
        label: t("planning.monthly"),
      },
      {
        value: "yearly",
        label: t("planning.yearly"),
      },
      {
        value: "custom",
        label: t("planning.custom"),
      },
    ];

    if ([...new Set(creationBookingsArray.map((data) => data[0]))].length === creationBookingsArray.length) {
      values.unshift({
        value: "daily",
        label: t("planning.daily"),
      });
    }

    return values;
  };

  useEffect(() => {
    if (
      [...new Set(creationBookingsArray.map((data) => data[0]))].length !== creationBookingsArray.length &&
      repetitionType === "daily"
    ) {
      setRepetitionType("weekly");
    }
  }, [creationBookingsArray]);

  return (
    <>
      <div style={{ margin: "10px 0px" }}>
        <TeambookReactSelect
          fieldName={""}
          value={repetitionOptions().find((option) => option.value === repetitionType)}
          onChange={(value) => {
            setRepetitionType(value.value);
          }}
          menuPlacement={"top"}
          isSearchable={false}
          options={repetitionOptions()}
          width={"100%"}
          height={44}
        />
      </div>

      {repetitionType !== null && (
        <>
          <div
            style={{
              justifyContent: repetitionType === "custom" ? "space-between" : "unset",
            }}
            className="repetition-every booking-repetition"
          >
            <p style={{ marginRight: "auto" }}>{t("planning.every")}</p>

            {repetitionType === "custom" ? (
              <p>{Math.ceil(creationBookingsArray[0][1].getDate() / 7)}</p>
            ) : (
              <TeambookReactSelect
                classNamePrefix="repetition-select"
                menuPortalTarget={null}
                value={repetitionEvery}
                defaultValue={repetitionEvery}
                onChange={(value) => setRepetitionEvery(value)}
                options={repetitionEveryHash()}
                placeholder={""}
                getOptionLabel={(obj) => obj.label}
                width={80}
                isSearchable
                height={44}
              />
            )}

            {repetitionType === "custom" ? (
              <p>{moment(creationBookingsArray[0][1]).format("dddd")} of Month</p>
            ) : (
              <p>
                {
                  {
                    daily: t("planning.days"),
                    weekly: t("planning.weeks"),
                    monthly: t("planning.months"),
                    yearly: t("planning.years"),
                  }[repetitionType]
                }
              </p>
            )}
          </div>

          <div className="booking-repetition">
            <p style={{ marginTop: "10px" }}>{t("planning.will_end")}</p>
          </div>

          <div className="repetition-after booking-repetition">
            <TeambookCheckbox
              checked={repetitionEndsAfter}
              onChange={(event) => {
                setRepetitionEndsAfter(!repetitionEndsAfter);
                setRepetitionEndsAt(repetitionEndsAfter);
              }}
              color="primary"
            />
            <p
              style={{ marginRight: "auto", cursor: "pointer" }}
              onClick={() => {
                setRepetitionEndsAfter(true), setRepetitionEndsAt(false);
              }}
            >
              {t("planning.repetition_after")}
            </p>
            <InputNumber
              fieldName={""}
              setValue={(number) => {
                if (typeof number === "string") {
                  setRepetitionEndsAfterNumber("");
                }

                if (number > 0 && number < 100) {
                  setRepetitionEndsAfterNumber(number);
                } else if (number === "") {
                  setRepetitionEndsAfterNumber("");
                }
              }}
              step={1}
              min={0}
              max={100}
              value={repetitionEndsAfterNumber}
            />
            <p>{t("planning.times")}</p>
          </div>

          <div className="booking-repetition">
            <TeambookCheckbox
              checked={repetitionEndsAt}
              onChange={(event) => {
                setRepetitionEndsAt(!repetitionEndsAt);
                setRepetitionEndsAfter(repetitionEndsAt);
              }}
              value={repetitionEndsAt}
              color="primary"
            />

            <p
              style={{ marginRight: "auto", cursor: "pointer" }}
              onClick={() => {
                setRepetitionEndsAt(true), setRepetitionEndsAfter(false);
              }}
            >
              {t("planning.repetition_on")}
            </p>

            <TeambookDatePicker
              style={{ marginRight: 80 }}
              variant="inline"
              formatStart="d/MM/y"
              from={DateTime.fromJSDate(repetitionEndsAtDate)}
              onDayClick={(date) => setRepetitionEndsAtDate(date.toJSDate())}
              type="default"
              highlight="today"
              closeOnSelect={true}
              range={1}
              disabledRanges={["1_year"]}
            />
          </div>

          {repetitionType !== "custom" && (
            <div className="booking-repetition">
              <TeambookCheckbox
                checked={repetitionIgnoreWeekends}
                onChange={(event) => setRepetitionIgnoreWeekends(!repetitionIgnoreWeekends)}
                value={repetitionIgnoreWeekends}
                color="primary"
              />
              <p className="ignore-weekends" onClick={() => setRepetitionIgnoreWeekends(!repetitionIgnoreWeekends)}>
                {t("planning.ignore_weekends")}
              </p>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default BookingRepetition;

export const filterProjects = (filteredOptions, clients, bookings, activeProjects, setFilteredProjects, t) => {
  let _filteredProjects = [];

  filteredOptions.forEach((filteredOption) => {
    if (filteredOption.type === t("planning.filter_projects")) {
      _filteredProjects.push(filteredOption);
    } else if (filteredOption.type === t("planning.filter_clients")) {
      let client = clients.filter((client) => filteredOption.name === client.name)[0];

      if (client) {
        _filteredProjects.push(...activeProjects.filter((project) => project.client_id === client.id));
      }
    } else if (filteredOption.type === t("projects.business_unit")) {
      _filteredProjects.push(...activeProjects.filter((project) => project.business_unit === filteredOption.name));
    } else if (filteredOption.type === t("planning.filter_advanced")) {
      const getBooking = [...bookings];

      const filterBookings = (project, field, value) => {
        let filteredProjects = [];
        const filteredBookings = getBooking.filter((booking) => booking[field] === value);

        if (filteredBookings.length) {
          filteredProjects = Array.from(new Set(filteredBookings.map(({ project_id }) => project_id)));
        }

        filteredProjects.forEach(() => {
          if (filteredProjects.includes(project.id)) {
            _filteredProjects.push(project);
          }
        });
      };

      activeProjects.forEach((project) => {
        switch (filteredOption.name) {
          case "Billable project": {
            if (project.kind === "billable") {
              _filteredProjects.push(project);
            }
            break;
          }

          case "Non billable project": {
            if (project.kind === "non_billable") {
              _filteredProjects.push(project);
            }
            break;
          }

          case "On client site": {
            filterBookings(project, "location", 1);
            break;
          }

          case "At office": {
            filterBookings(project, "location", 0);
            break;
          }

          case "Home office": {
            filterBookings(project, "location", 2);
            break;
          }

          case "Tentative booking": {
            filterBookings(project, "tentative", true);
            break;
          }
        }
      });
    }
  });

  setFilteredProjects(_filteredProjects);
};

import React, { useEffect, useState } from "react";
import { useTeambookFilter } from "../../../../../stores/planner";
import EntityRow from "./EntityRow";
import { useTranslation } from "react-i18next";

const DataRows = ({ stats, splitBy, account, additionalData }) => {
  const { t } = useTranslation();
  const [rows, setRows] = useState([]);

  const startDate = new Date(stats.start_date);

  const { filterValues } = useTeambookFilter();

  useEffect(() => {
    let rowsArray = [];
    let usedEntityIds = [];

    if (stats.data) {
      for (let i = 0; i < stats.data.length; i++) {
        usedEntityIds.push(stats.data[i].id);

        if (filterValues.length === 0 || entityIsFiltered(i, stats)) {
          rowsArray.push(<EntityRow index={i} stats={stats} startDate={startDate} additionalData={additionalData} splitBy={splitBy} />);
        }
      }

      for (let i = 0; i < additionalData.data.length; i++) {
        if (usedEntityIds.includes(additionalData.data[i].id)) continue;

        if (filterValues.length === 0 || entityIsFiltered(i, additionalData)) {
          const projectStats = JSON.parse(JSON.stringify(additionalData));
          projectStats.data[i].daily_total = [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          ];

          rowsArray.push(
            <EntityRow index={i} stats={projectStats} startDate={startDate} additionalData={additionalData} splitBy={splitBy} />
          );
        }
      }
    }

    setRows(rowsArray);
  }, [stats, filterValues, account]);

  const entityIsFiltered = (i, stats) => {
    if (splitBy === "comparison_project") {
      const filteredProjects = filterValues.filter((value) => value.type === t("planning.filter_projects"));

      if (filteredProjects.length > 0) {
        return filteredProjects.map((r) => r.id).includes(stats.data[i].id);
      } else {
        return true;
      }
    } else if (splitBy === "comparison_user") {
      const filteredUsers = filterValues.filter((value) => value.type === t("planning.filter_users"));

      if (filteredUsers.length > 0) {
        return filteredUsers.map((r) => r.id).includes(stats.data[i].id);
      } else {
        return true;
      }
    } else if (splitBy === "comparison_task") {
      const filteredUsers = filterValues.filter((value) => value.type === "tasks");

      if (filteredUsers.length > 0) {
        return filteredUsers.map((r) => r.id).includes(stats.data[i].id);
      } else {
        return true;
      }
    }
  };

  return <>{rows}</>;
};

export default DataRows;

import React from "react";
import TeambookOutlinedButton from "../../../default_components/TeambookOutlinedButton";
import { useTranslation } from "react-i18next";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { icons } from "../../../default_images/IconsList";
import { shallow } from "zustand/shallow";
import { useAccountStore } from "../../../../stores/accountStore";

const SlackBlock = () => {
  const { t } = useTranslation();
  const [account] = useAccountStore((state) => [state.account], shallow);

  return (
    <div className="integration-block">
      <div className="integration-name">
        <TeambookIcon name={icons.SLACK_INTEGRATION_ICON} alt="slack logo" />
      </div>

      <div className="integration-status">
        <p className={account.slack_integration ? "active" : "not_active"}>{account.slack_integration ? "Active" : "Not connected"}</p>
      </div>

      <TeambookOutlinedButton
        onClick={() =>
          window.open(
            "https://slack.com/oauth/v2/authorize?client_id=406371365653." +
              "2878781469877&scope=app_mentions:read,channels:history,channels:join,channels:manage,channels:read," +
              "chat:write,chat:write.customize,chat:write.public,files:read,files:write,groups:history,groups:read," +
              "groups:write,im:history,im:read,im:write,links:read,links:write,mpim:history,mpim:read,mpim:write," +
              "pins:read,pins:write,reactions:read,reactions:write,reminders:read,reminders:write,team:read," +
              "usergroups:read,usergroups:write,users.profile:read,users:read,users:write,incoming-webhook," +
              "commands&user_scope=",
            "_blank"
          )
        }
        variant="contained"
        text={t("settings.integration.connect")}
        style={{
          margin: "13px 0 0 0",
          width: "171px",
        }}
      />
    </div>
  );
};

export default SlackBlock;

import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReportingContext } from "../../lib/ReportingContext";
import { recalculateCapacityArray } from "../../../../capacity_planning/lib/recalculateCapacityArray";
import { StickyTotal } from "./reporting_tags/StickyTotal";

const CompanyUtilization = ({ usersCount, date, reservations, selectedViewType }) => {
  const { t } = useTranslation();
  const [calculatedDurations, setCalculatedDurations] = useState([]);

  useEffect(() => {
    updateCalculations(reservations);
  }, [reservations, date]);

  const updateCalculations = (reservations) => {
    setCalculatedDurations(recalculateCapacityArray(date, reservations, selectedViewType));
  };

  return (
    <div className="reporting-block">
      <div className="reporting__headline">
        <div className="reporting__headline__name">
          <p>Staffing Ratio</p>
        </div>

        {[...Array(selectedViewType)].map((x, i) => (
          <div className="table-cell">
            <p>{((calculatedDurations[i] / (usersCount * 22)) * 100).toFixed(0)}%</p>
          </div>
        ))}

        <StickyTotal
          style={{
            width: 1,
            maxWidth: 1,
            minWidth: 1,
            borderRight: "unset",
            marginLeft: "auto",
          }}
        />
      </div>
    </div>
  );
};

export default CompanyUtilization;

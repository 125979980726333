import Api from "../../../Api";

async function GetActiveUsersRequest(setAllUsers) {
  await Api.Users.active()
    .then((response) => {
      setAllUsers(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
}

export default GetActiveUsersRequest;

import React, { useState, useEffect } from "react";
import Api from "../../../Api";
import TeambookBlueButton from "../../default_components/TeambookBlueButton";
import WhatsNewOptions from "./WhatsNewOptions";

const LanguageBlock = ({ language }) => {
  const [whatsNew, setWhatsNew] = useState([]);
  const [isShowMoreOptions, setIsShowMoreOptions] = useState(false);
  let adminToken = localStorage.getItem("tb-admin-token");

  useEffect(() => {
    Api.Administrators.get_whats_new(language).then((response) => {
      setWhatsNew(response.data);
    });
  }, []);

  const updateWhatsNew = (id) => {
    const title = whatsNew.find((wn) => wn.id === id).title;
    const description = whatsNew.find((wn) => wn.id === id).description;

    Api.Administrators.update_whats_new(adminToken, id, title, description);
  };

  const deleteWhatsNew = (id) => {
    Api.Administrators.delete_whats_new(adminToken, id).then(() => {
      Api.Administrators.get_whats_new(language).then((response) => {
        setWhatsNew(response.data);
      });
    });
  };

  const updatedValue = (id, title, description) => {
    let array = [...whatsNew];
    const index = array.findIndex((val) => val.id === id);

    array[index].title = title;
    array[index].description = description;

    setWhatsNew(array);
  };

  if (whatsNew.length === 0) {
    return null;
  }

  return (
    <>
      <WhatsNewOptions
        whatsNew={whatsNew.slice(0, 3)}
        updatedValue={updatedValue}
        updateWhatsNew={updateWhatsNew}
        deleteWhatsNew={deleteWhatsNew}
      />

      {isShowMoreOptions && (
        <WhatsNewOptions
          whatsNew={whatsNew.slice(3)}
          updatedValue={updatedValue}
          updateWhatsNew={updateWhatsNew}
          deleteWhatsNew={deleteWhatsNew}
        />
      )}

      {whatsNew.length > 3 && (
        <tr>
          <td style={{ height: "fit-content", padding: 0 }}>
            <TeambookBlueButton
              text={isShowMoreOptions ? "Show less" : "Show more"}
              onClick={() => setIsShowMoreOptions(!isShowMoreOptions)}
              color="primary"
            />
          </td>
          <td />
        </tr>
      )}
    </>
  );
};

export default LanguageBlock;

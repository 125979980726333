import GetClientsRequest from "../requests/GetClientsRequest";
import GetTagsRequest from "../requests/GetTagsRequest";
import GetAllUsersRequest from "../requests/GetAllUsersRequest";
import GetAllMilestones from "../requests/GetAllMilestones";

const TeamChangedActions = (setClients, setTags, setOnboardingOpened, setAllUsers, setMilestones) => {
  GetClientsRequest(setClients);
  GetTagsRequest(setTags);
  GetAllUsersRequest(setAllUsers);

  if (setMilestones) {
    GetAllMilestones(setMilestones);
  }

  setOnboardingOpened(localStorage.getItem("tb-onboarding") === "true");
};

export default TeamChangedActions;

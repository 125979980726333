import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";
import $ from "jquery";

const VideoComponent = ({ header, content, videoUrl, type }) => {
  const [videoModalOpened, setVideoModalOpened] = useState(false);
  const [videoHeight, setVideoHeight] = useState(200);
  const [videoWidth, setVideoWidth] = useState(400);

  useEffect(() => {
    setTimeout(function () {
      const elements = $(".onboarding-video-component .MuiPaper-root");
      if (elements.length !== 0) {
        setVideoHeight(elements[0].clientHeight - 100);
        setVideoWidth(elements[0].clientWidth - 100);
      }
    }, 300);
  }, [videoModalOpened]);

  const containerClass = `point onboarding-video ${type}-video`;

  return (
    <div className={containerClass}>
      <div onClick={() => setVideoModalOpened(true)}>
        <p className="point__header">
          <TeambookIcon name={icons.PLAY_BUTTON} /> {header}
        </p>
        <p className="point__text" style={{ display: "flex", flexDirection: "column", paddingBottom: 10 }}>
          {content}
        </p>
      </div>
      <Dialog
        PaperProps={{ sx: { maxHeight: 667, minHeight: 667 } }}
        maxWidth={"xl"}
        open={videoModalOpened}
        onClose={() => setVideoModalOpened(false)}
        aria-labelledby="form-dialog-title"
        className="tb-default-dialog onboarding-video-component"
      >
        <DialogTitle>
          <p className="tutorial-title">{header}</p>
          <TeambookIcon name={icons.CROSS} onClick={() => setVideoModalOpened(false)} />
        </DialogTitle>
        <DialogContent style={{ width: 980, padding: "0px 40px" }} className="register-pop-up__content">
          <iframe
            width={900}
            height={520}
            src={videoUrl}
            title="Teambook Video Player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default VideoComponent;

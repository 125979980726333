import React from "react";
import Header from "../header/Header";
import NotEnoughData from "../default_images/need_more_data.svg";
import { useTranslation } from "react-i18next";

const NoTeams = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header />

      <div className="no-users-team">
        <img src={NotEnoughData} alt={"No Users"} />
        <p>{t("planning.user_no_team")}</p>
      </div>
    </>
  );
};

export default NoTeams;

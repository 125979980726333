import { useTeambookFilter } from "../../../stores/planner";

const filterUsers = (
  fO,
  listAllUsers,
  setEmptyFiltered,
  clients,
  bookings,
  tags,
  projects,
  filterType,
  t
) => {
  const userArray = [];
  let getProjects = null;
  let getProjectsTag = null;
  const { filterValues: filteredOptions } = useTeambookFilter.getState();

  if (filterType === "Or") {
    if (filteredOptions.length > 0) {
      filteredOptions.map((values) => {
        if (values.type === t("planning.filter_users")) {
          const newUsers = listAllUsers.filter(function (item) {
            return `${item.first_name} ${item.last_name}` === values.name;
          });

          if (newUsers.length === 0) {
            setEmptyFiltered(true);
          }

          userArray.push(newUsers);
        }

        if (values.type === t("planning.filter_clients")) {
          const filterClients = clients.filter((item) => {
            return item.name === values.name;
          });

          getProjects = [];

          filterClients.forEach((client) => {
            getProjects.push(
              ...projects
                .filter((project) => project.client_id === client.id)
                .map((p) => p.name)
            );
          });
        }

        if (getProjects !== null) {
          const getBooking = [...bookings];
          const filterBooking = getBooking.filter((item) => {
            if (item) {
              return getProjects.includes(item.project_name);
            }
          });
          const newUsers = listAllUsers.filter(function (item) {
            const usersId = filterBooking.map((item) => item.user_id);
            return usersId.includes(item.id);
          });

          if (newUsers.length === 0) {
            setEmptyFiltered(true);
          }
          userArray.push(newUsers);
        }
        if (values.type === t("planning.filter_tags")) {
          const filterTags = tags.filter((item) => {
            return item.name === values.name;
          });

          getProjectsTag = filterTags.map((item) => item.name);
        }

        if (getProjectsTag !== null) {
          const filterTags = tags.filter((item) => {
            return item.name === getProjectsTag.toString();
          });

          const newUsers = listAllUsers.filter(function (item) {
            const usersIds = filterTags.map((item) => item.tag_users);
            return usersIds.flat().includes(item.id);
          });

          if (newUsers.length === 0) {
            setEmptyFiltered(true);
          }
          userArray.push(newUsers);
        }

        if (values.type === t("planning.filter_projects")) {
          const bookingsArray = [...bookings];

          const filterBooking = bookingsArray.filter((item) => {
            if (item !== undefined) {
              if (item.project_name) {
                return item.project_name === values.name;
              } else {
                return null;
              }
            }
          });
          const newUsers = listAllUsers.filter(function (item) {
            const usersId = filterBooking.map((item) => item.user_id);

            return usersId.includes(item.id);
          });
          if (newUsers.length === 0) {
            setEmptyFiltered(true);
          }
          userArray.push(newUsers);
        }

        if (values.type === t("planning.filter_advanced")) {
          let newUsers = [];
          if (values.name === t("planning.filter.advanced_options.confirmed")) {
            const getBooking = [...bookings];
            const filterBooking = getBooking.filter((item) => {
              if (item !== undefined) {
                return item.tentative === false;
              }
            });
            newUsers = listAllUsers.filter(function (item) {
              const usersId = filterBooking.map((item) => item.user_id);
              return usersId.includes(item.id);
            });
          } else if (
            values.name === t("planning.filter.advanced_options.not_confirmed")
          ) {
            const getBooking = [...bookings];
            const filterBooking = getBooking.filter((item) => {
              if (item !== undefined) {
                return item.tentative === false;
              }
            });
            newUsers = listAllUsers.filter(function (item) {
              const usersId = filterBooking.map((item) => item.user_id);
              return !usersId.includes(item.id);
            });
          } else if (
            values.name === t("planning.filter.advanced_options.on_client") ||
            values.name === t("planning.at_office") ||
            values.name === t("planning.home_office")
          ) {
            const getBooking = [...bookings];

            const filterBooking = getBooking.filter((item) => {
              if (item !== undefined) {
                if (
                  values.name ===
                  t("planning.filter.advanced_options.on_client")
                ) {
                  return item.location === 1;
                } else if (values.name === t("planning.at_office")) {
                  return item.location === 0;
                } else if (values.name === t("planning.home_office")) {
                  return item.location === 2;
                }
              }
            });
            newUsers = listAllUsers.filter(function (item) {
              const usersId = filterBooking.map((item) => item.user_id);
              return usersId.includes(item.id);
            });
          } else if (
            values.name === t("planning.filter.advanced_options.tentative")
          ) {
            const getBooking = [...bookings];

            const filterBooking = getBooking.filter((item) => {
              if (item !== undefined) {
                return item.tentative === true;
              }
            });

            newUsers = listAllUsers.filter(function (item) {
              const usersId = filterBooking.map((item) => item.user_id);
              return usersId.includes(item.id);
            });
          } else if (
            values.name === t("planning.filter.advanced_options.billable") ||
            values.name === t("planning.filter.advanced_options.time_off") ||
            values.name === t("planning.filter.advanced_options.non_billable")
          ) {
            if (
              values.name === t("planning.filter.advanced_options.time_off")
            ) {
              getProjects = projects.map((item) =>
                item.kind === "time_off" ? item.name : null
              );
            } else if (
              values.name === t("planning.filter.advanced_options.billable")
            ) {
              getProjects = projects.map((item) =>
                item.kind === "billable" ? item.name : null
              );
            } else if (
              values.name === t("planning.filter.advanced_options.non_billable")
            ) {
              getProjects = projects.map((item) => {
                return item.kind === "non_billable" ? item.name : null;
              });
            }

            if (getProjects !== null) {
              const getBooking = [...bookings];

              const filterBooking = getProjects.map((item) => {
                return getBooking.filter((book) => {
                  if (book) {
                    return book.project_name === item;
                  }
                });
              });

              newUsers = listAllUsers.filter(function (item) {
                const usersId = filterBooking
                  .flat()
                  .map((item) => item.user_id);
                return usersId.includes(item.id);
              });
            }
          }

          if (newUsers.length === 0) {
            setEmptyFiltered(true);
          }

          userArray.push(newUsers);
        }
      });
    } else {
      setEmptyFiltered(false);
    }

    const users = userArray.flat().filter((thing, index, self) => {
      return index === self.findIndex((t) => t.id === thing.id);
    });

    return users;
  } else {
    let filterData = [];

    if (filteredOptions.length > 0) {
      let getUsers = null;

      filteredOptions.map((values) => {
        if (values.type === t("planning.filter_users")) {
          const newUsers = listAllUsers.filter(function (item) {
            return `${item.first_name} ${item.last_name}` === values.name;
          });

          if (newUsers.length === 0) {
            setEmptyFiltered(true);
          }

          if (filterData[0] === undefined) {
            filterData[0] = [];
          }

          filterData[0].push(...newUsers.flat());
        }

        if (values.type === t("planning.filter_clients")) {
          const filterClients = clients.filter((item) => {
            return item.name === values.name;
          });

          getProjects = [];

          let filteredProjectsIds = filterClients.map((client) =>
            projects
              .filter((project) => project.client_id === client.id)
              .map((p) => p.id)
          );

          if (filteredProjectsIds.length > 0) {
            filteredProjectsIds[0].filter((pr) =>
              filteredProjectsIds.every((array) => array.includes(pr))
            );

            getProjects = filteredProjectsIds[0];
          }
        }

        if (getProjects !== null) {
          const getBooking = [...bookings];

          const filterBooking = getBooking.filter((item) => {
            if (item) {
              return getProjects.includes(item.project_id);
            }
          });
          const newUsers = listAllUsers.filter(function (item) {
            const usersId = filterBooking.map((item) => item.user_id);
            return usersId.includes(item.id);
          });

          if (newUsers.length === 0) {
            setEmptyFiltered(true);
          }

          if (filterData[1] === undefined) {
            filterData[1] = [];
          }

          filterData[1].push(...newUsers.flat());
        }

        if (values.type === t("planning.filter_tags")) {
          getUsers = null;
          const filterTags = tags.filter((item) => {
            return item.name === values.name;
          });

          getUsers = filterTags.map((tag) => tag.tag_users).flat();
        }

        if (getUsers !== null) {
          const newUsers = listAllUsers.filter((user) =>
            getUsers.includes(user.id)
          );

          if (newUsers.length === 0) {
            setEmptyFiltered(true);
          }

          if (filterData[2] === undefined) {
            filterData[2] = newUsers.flat();
          } else {
            filterData[2] = filterData[2].filter((users) =>
              newUsers.includes(users)
            );
          }
        }

        if (values.type === t("planning.filter_projects")) {
          const bookingsArray = [...bookings];
          const filterBooking = bookingsArray.filter((item) => {
            if (item !== undefined) {
              if (item.project_name) {
                return item.project_name === values.name;
              } else {
                return null;
              }
            }
          });

          const newUsers = listAllUsers.filter(function (item) {
            const usersId = filterBooking.map((item) => item.user_id);
            return usersId.includes(item.id);
          });

          if (newUsers.length === 0) {
            setEmptyFiltered(true);
          }

          if (filterData[3] === undefined) {
            filterData[3] = newUsers.flat();
          } else {
            filterData[3] = filterData[3].filter((users) =>
              newUsers.includes(users)
            );
          }
        }

        if (values.type === t("planning.filter_advanced")) {
          let newUsers = [];
          if (values.name === t("planning.filter.advanced_options.confirmed")) {
            const getBooking = [...bookings];
            const filterBooking = getBooking.filter((item) => {
              if (item !== undefined) {
                return item.tentative === false;
              }
            });
            newUsers = listAllUsers.filter(function (item) {
              const usersId = filterBooking.map((item) => item.user_id);
              return usersId.includes(item.id);
            });
          } else if (
            values.name === t("planning.filter.advanced_options.not_confirmed")
          ) {
            const getBooking = [...bookings];
            const filterBooking = getBooking.filter((item) => {
              if (item !== undefined) {
                return item.tentative === false;
              }
            });
            newUsers = listAllUsers.filter(function (item) {
              const usersId = filterBooking.map((item) => item.user_id);
              return !usersId.includes(item.id);
            });
          } else if (
            values.name === t("planning.filter.advanced_options.on_client") ||
            values.name === t("planning.at_office") ||
            values.name === t("planning.home_office")
          ) {
            const getBooking = [...bookings];

            const filterBooking = getBooking.filter((item) => {
              if (item !== undefined) {
                if (
                  values.name ===
                  t("planning.filter.advanced_options.on_client")
                ) {
                  return item.location === 1;
                } else if (values.name === t("planning.at_office")) {
                  return item.location === 0;
                } else if (values.name === t("planning.home_office")) {
                  return item.location === 2;
                }
              }
            });
            newUsers = listAllUsers.filter(function (item) {
              const usersId = filterBooking.map((item) => item.user_id);
              return usersId.includes(item.id);
            });
          } else if (
            values.name === t("planning.filter.advanced_options.tentative")
          ) {
            const getBooking = [...bookings];
            const filterBooking = getBooking.filter((item) => {
              if (item !== undefined) {
                return item.tentative === true;
              }
            });
            newUsers = listAllUsers.filter(function (item) {
              const usersId = filterBooking.map((item) => item.user_id);
              return usersId.includes(item.id);
            });
          } else if (
            values.name === t("planning.filter.advanced_options.billable") ||
            values.name === t("planning.filter.advanced_options.time_off") ||
            values.name === t("planning.filter.advanced_options.non_billable")
          ) {
            if (
              values.name === t("planning.filter.advanced_options.time_off")
            ) {
              getProjects = projects.map((item) =>
                item.kind === "time_off" ? item.name : null
              );
            } else if (
              values.name === t("planning.filter.advanced_options.billable")
            ) {
              getProjects = projects.map((item) =>
                item.kind === "billable" ? item.name : null
              );
            } else if (
              values.name === t("planning.filter.advanced_options.non_billable")
            ) {
              getProjects = projects.map((item) => {
                return item.kind === "non_billable" ? item.name : null;
              });
            }

            if (getProjects !== null) {
              const getBooking = [...bookings];

              const filterBooking = getProjects.map((item) => {
                return getBooking.filter((book) => {
                  if (book) {
                    return book.project_name === item;
                  }
                });
              });
              newUsers = listAllUsers.filter(function (item) {
                const usersId = filterBooking
                  .flat()
                  .map((item) => item.user_id);
                return usersId.includes(item.id);
              });
            }
          }

          if (newUsers.length === 0) {
            setEmptyFiltered(true);
          }

          if (filterData[4] === undefined) {
            filterData[4] = [];
          }

          filterData[4].push(...newUsers.flat());
        }
      });
    } else {
      setEmptyFiltered(false);
    }

    filterData = filterData.filter((n) => n);

    if (filterData.length > 0) {
      if (filterData[0]) {
        filterData = filterData[0].filter((user) =>
          filterData.every((array) => array.includes(user))
        );
      }

      return filterData.filter((thing, index, self) => {
        return index === self.findIndex((t) => t.id === thing.id);
      });
    } else {
      return [];
    }
  }
};

export default filterUsers;

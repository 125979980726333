import { Dialog, DialogTitle, DialogContent } from "@mui/material/";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import moment from "moment";
import TeambookBlueButton from "../../default_components/TeambookBlueButton";
import Api from "../../../Api";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import XLSExport from "../../../lib/XLSExport";
import { ToDateString } from "../../../lib/DateUtils";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";
import { DateTime } from "luxon";
import MuiDatePicker from "../../default_components/MuiDatePicker";

const DownloadReportingWindow = ({ splitBy, setDownloadWindowOpen, date, teams, type, slaveDataType }) => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(DateTime.now().startOf("month"));
  const [endDate, setEndDate] = useState(DateTime.now().endOf("month"));
  const [satriunEmailDate, setSatriunEmailDate] = useState(0);
  const [satriunEmailSent, setSatriunEmailSent] = useState(false);

  useEffect(() => {
    if (splitBy === "sat_overview") {
      Api.Integrations.Satriun.get_email_day().then((res) => setSatriunEmailDate(res.data.day));
    }
  }, []);

  const closeWindow = () => {
    setDownloadWindowOpen(false);
  };

  const download = () => {
    Api.Dashboard.download_reporting({
      from: startDate,
      to: endDate,
      split_by: splitBy,
      type: type,
      slave_entity_type: slaveDataType,
      teams: teams.map((t) => t.id),
    }).then((response) => {
      XLSExport.exportReporting(splitBy, startDate, endDate, response.data);
      setDownloadWindowOpen(false);
    });
  };

  const updateSatriunMonthlyDate = (day) => {
    Api.Integrations.Satriun.set_email_day(parseInt(day)).then((res) => {
      setSatriunEmailDate(day);
    });
  };

  const triggerSatriunEmail = () => {
    if (satriunEmailSent === false) {
      Api.Integrations.Satriun.trigger_email().then(() => {
        setSatriunEmailSent(true);
      });
    }
  };

  return (
    <Dialog className="tb-default-dialog" open={true} onClose={() => closeWindow()}>
      <DialogTitle>
        <p style={{ margin: "auto 0" }}>{t("download")}</p>
        <TeambookIcon name={icons.CROSS} onClick={() => closeWindow()} />
      </DialogTitle>

      <DialogContent style={{ margin: "20px 0px" }}>
        <div
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            height: 50,
          }}
        >
          <p style={{ margin: "auto 0" }}>{`${t("projects.start_date")}:`}</p>
          <MuiDatePicker
            variant="inline"
            format={"d MMM, yyyy"}
            value={startDate}
            onChange={(date) => setStartDate(date)}
            sx={{ width: "150px", input: { padding: "0px" } }}
            className="mui-date-picker"
          />
        </div>

        <div
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            height: 50,
          }}
        >
          <p style={{ margin: "auto 0" }}>{`${t("projects.end_date")}:`}</p>
          <MuiDatePicker
            variant="inline"
            format={"d MMM, yyyy"}
            value={endDate}
            onChange={(date) => setEndDate(date)}
            sx={{ width: "150px", input: { padding: "0px" } }}
            className="mui-date-picker"
          />
        </div>

        <div className="download-button">
          <TeambookBlueButton text={t("download")} onClick={download} />
        </div>

        {splitBy === "sat_overview" && (
          <div className="sat-export-block">
            <p className="title">Monthly Export</p>

            <div className="flex">
              <p>Monthly Report by Email on:</p>

              <input
                onChange={(event) => {
                  if (event.target.value >= 0 && event.target.value <= 28) {
                    updateSatriunMonthlyDate(event.target.value);
                  }
                }}
                onWheel={(e) => e.target.blur()}
                value={parseInt(satriunEmailDate)}
                type={"number"}
              />
            </div>

            <div className="download-button">
              <TeambookBlueButton text={"Send email"} onClick={triggerSatriunEmail} />
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default DownloadReportingWindow;

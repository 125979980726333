import React, { useEffect, useState } from "react";
import { recalculateCapacityArray } from "../../../../capacity_planning/lib/recalculateCapacityArray";
import { TeambookIcon } from "../../../../default_images/TeambookIcon";
import { icons } from "../../../../default_images/IconsList";
import TeambookSortingTypes from "../../../../default_components/TeambookSortingTypes";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { calculateDaysInMonth } from "../../../../capacity_planning/lib/calculateHeatStyles";
import { useAccountStore } from "../../../../../stores/accountStore";
import { shallow } from "zustand/shallow";

const SeparatorRow = ({
  name,
  subtext,
  reservations,
  date,
  separationKind,
  setDropdownEntity,
  selectedViewType,
  showArrow = false,
  sortData = false,
  sortOrder,
  setSortOrder,
  setSortType,
  areAllUsersExpanded,
  toggleAllUsers,
  isExpand = false,
  style = {},
}) => {
  const { t } = useTranslation();
  const [calculatedDurations, setCalculatedDurations] = useState([]);

  useEffect(() => {
    if (reservations) {
      updateCalculations(reservations);
    }
  }, [reservations]);

  const updateCalculations = (reservations) => {
    setCalculatedDurations(recalculateCapacityArray(date, reservations, selectedViewType));
  };

  return (
    <div className="users-block" style={{ width: "fit-content", minWidth: "100%", ...style }}>
      <div className="users-block__headline">
        <div className="users-block__headline__user-name">
          {sortData && (
            <TeambookSortingTypes
              setFilterOrder={setSortOrder}
              filterOrder={sortOrder}
              filterOptions={["alphabetical"]}
              style={{ justifyContent: "flex-start" }}
              onClick={() => setSortType("alphabetical")}
            />
          )}

          {isExpand && (
            <div className="toggle_users-icon" onClick={toggleAllUsers}>
              <TeambookIcon
                name={icons.DOUBLE_ARROWS}
                color="alternative_default"
                alt={areAllUsersExpanded ? "Collapse" : "Expand"}
                title={
                  areAllUsersExpanded
                    ? t("planning.capacity.collapse_all_users")
                    : t("planning.capacity.expand_all_users")
                }
                className={areAllUsersExpanded ? "collapsed_arrows" : "expanded_arrows"}
              />
            </div>
          )}

          {name ? (
            <p
              onClick={() => {
                setDropdownEntity(separationKind);
              }}
              style={{ cursor: "pointer" }}
            >
              {name}

              {showArrow && (
                <TeambookIcon name={!separationKind ? icons.ARROW_UP : icons.ARROW_DOWN} alt={"Show All Entities"} />
              )}
            </p>
          ) : (
            <div />
          )}

          {subtext && <p style={{ textAlign: "center" }}>{subtext}</p>}
        </div>

        {[...Array(selectedViewType)].map((x, i) => (
          <div className="table-cell">
            <p>{calculatedDurations[i] || ""}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SeparatorRow;

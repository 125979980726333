import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { TeambookCheckbox } from "../../default_components/TeambookCheckbox";
import DialogActions from "@mui/material/DialogActions";
import TeambookBlueButton from "../../default_components/TeambookBlueButton";
import React, { useEffect, useState } from "react";
import Api from "../../../Api";
import lsKeys from "../../default_values/defaultKeys";
import { useTranslation } from "react-i18next";

const LeaveTeambookModal = ({ logOut, logOutModalOpen }) => {
  const { t } = useTranslation();

  const [leaveReasons, setLeaveReasons] = useState([]);
  const [leaveReasonComment, setLeaveReasonComment] = useState("");
  const [canLogOut, setCanLogOut] = useState(false);

  const reasonMapping = {
    need_help_and_demo: (
      <p style={{ margin: "unset" }}>
        {t("settings.logout.need_help_and_demo")}

        <a href={t("settings.logout.calendly_link")} target="_blank">
          {t("settings.logout.need_help_and_demo_2")}
        </a>
      </p>
    ),
    doesnt_fulfill: t("settings.delete_account.doesnt_fulfill"),
    like_what_i_see: t("settings.logout.like_what_i_see"),
    trouble_using: t("settings.delete_account.trouble_using"),
    interface_issue: t("settings.delete_account.interface_issue"),
    technical_issue: t("settings.delete_account.technical_issue"),
    other: t("settings.delete_account.other"),
  };

  useEffect(() => {
    if (leaveReasons.includes("other")) {
      if (leaveReasonComment.length > 0) {
        setCanLogOut(true);
      } else {
        setCanLogOut(false);
      }
    } else if (leaveReasons.length > 0) {
      setCanLogOut(true);
    } else {
      setCanLogOut(false);
    }
  }, [leaveReasons, leaveReasonComment]);

  const switchLeaveReason = (reason) => {
    if (leaveReasons.includes(reason)) {
      setLeaveReasons(leaveReasons.filter((r) => r !== reason));
    } else {
      setLeaveReasons([...leaveReasons, reason]);
    }
  };

  const leaveTeambook = () => {
    Api.Administrators.create_sign_offs("log_out", `${leaveReasons.join(", ")} | ${leaveReasonComment}`).then(() => {
      localStorage.setItem(lsKeys.LOGGED_OUT, "true");
      logOut();
    });
  };

  return (
    <Dialog
      open={logOutModalOpen}
      aria-labelledby="form-dialog-title"
      className="tb-default-dialog leave-modal__dialog"
    >
      <DialogTitle id="form-dialog-title">
        <p>{t("settings.logout.appreciate_feedback")}</p>
      </DialogTitle>

      <DialogContent style={{ margin: "20px 0px" }}>
        <p>{t("settings.logout.thank_you_for_creating")}</p>

        <p>{t("settings.logout.we_hope_you_liked")}</p>

        <p>{t("settings.logout.would_you_be_kind")}</p>

        <p>{t("settings.logout.thank_you")}!</p>

        {Object.keys(reasonMapping).map((reason) => (
          <div className="leave-modal__option">
            <TeambookCheckbox onChange={() => switchLeaveReason(reason)} checked={leaveReasons.includes(reason)} />

            <p onClick={() => switchLeaveReason(reason)}>{reasonMapping[reason]}</p>
          </div>
        ))}

        {leaveReasons.includes("other") && (
          <textarea
            value={leaveReasonComment}
            onChange={(e) => setLeaveReasonComment(e.target.value)}
            className="leave-modal__textarea"
            placeholder={t("settings.delete_account.thoughts")}
          />
        )}
      </DialogContent>

      <DialogActions>
        <TeambookBlueButton onClick={leaveTeambook} text={t("header.logout")} disabled={!canLogOut} />
      </DialogActions>
    </Dialog>
  );
};

export default LeaveTeambookModal;

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTeambookFilter } from "../../../../../stores/planner";
import UsersProfileImage from "../../../../default_components/UsersProfileImage";
import { sortDataBySurname } from "../../lib/sortDataBySurname";
import { TeambookIcon } from "../../../../default_images/TeambookIcon";
import { icons } from "../../../../default_images/IconsList";

const ActualsOverviewPage = ({ stats, sortOrder, setSortOrder }) => {
  const { t } = useTranslation();
  const [userRows, setUserRows] = useState([]);
  const { filterValues } = useTeambookFilter();

  useEffect(() => {
    let newRows =
      stats.users?.length > 0 &&
      sortDataBySurname(stats.users, sortOrder)
        ?.filter((user) => filterValues.length === 0 || userIsFiltered(user))
        .map((user) => (
          <div className="overview-table__data">
            <div className="overview-table__entity-name">
              <UsersProfileImage userImage={user?.avatars ? user.avatars["100"] : null} size={24} user={user} />
              {user.name}
            </div>
            <div className="overview-table__entity-capacity" style={{ borderRight: "unset" }}>
              {user.capacity}
            </div>
            <div
              className="overview-table__entity-billable"
              style={{ width: "20%", borderLeft: "1px solid var(--stroke)" }}
            >
              {user.billable}
            </div>
            <div className="overview-table__entity-non-billable" style={{ width: "20%" }}>
              {user.non_billable}
            </div>
            <div className="overview-table__entity-time-off" style={{ width: "20%" }}>
              {user.time_off}
            </div>
            <div className="overview-table__entity-availability">{user.availability}</div>
          </div>
        ));

    setUserRows(newRows);
  }, [stats, filterValues, sortOrder]);

  const userIsFiltered = (user) => {
    const filteredUsers = filterValues.filter((value) => value.type === t("planning.filter_users"));

    if (filteredUsers.length > 0) {
      return filteredUsers.map((r) => r.id).includes(user.id);
    } else {
      return true;
    }
  };

  return (
    <div className="reporting__overview-page">
      <table className="overview-table" style={{ width: "100%" }}>
        <tr>
          <th
            rowSpan="2"
            style={{
              width: "15%",
              paddingLeft: 10,
              borderLeft: "1px solid var(--stroke)",
            }}
          >
            {sortOrder === "a" ? (
              <div className="reporting__sort-switch" onClick={() => setSortOrder("z")}>
                <TeambookIcon color="alternative_default" name={icons.SORT_A} tooltipTitle={t("dashboard.a_z")} />
              </div>
            ) : (
              <div className="reporting__sort-switch" onClick={() => setSortOrder("a")}>
                <TeambookIcon color="alternative_default" name={icons.SORT_Z} tooltipTitle={t("dashboard.z_a")} />
              </div>
            )}
          </th>
          <th rowspan="2" style={{ width: "10%", verticalAlign: "bottom" }}>
            <p style={{ marginBottom: 4 }}>{t("planning.select_by_capacity")}</p>
          </th>
          <th className="overview-table__header" colspan="2" style={{ width: "40%", height: 44 }}>
            <p>{t("header.projects")}</p>
          </th>
          <th className="overview-table__header" style={{ width: "20%", height: 44 }}>
            <p>{t("dashboard.time_offs")}</p>
          </th>
          <th rowSpan="2" style={{ width: "15%", verticalAlign: "bottom" }}>
            <p style={{ marginBottom: 4 }}>{t("dashboard.availability")}</p>
          </th>
        </tr>
        <tr>
          <td className="overview-table__subheader" style={{ width: "20%" }}>
            <div className="overview-table__subheader-wrapper">
              <p style={{ width: "100%" }}>{t("dashboard.billable")}</p>
            </div>
          </td>
          <td className="overview-table__subheader" style={{ width: "20%" }}>
            <div className="overview-table__subheader-wrapper">
              <p style={{ width: "100%" }}>{t("dashboard.non_billable")}</p>
            </div>
          </td>
          <td className="overview-table__subheader" style={{ width: "20%" }}>
            <div className="overview-table__subheader-wrapper">
              <p style={{ width: "100%" }}>{t("dashboard.real")}</p>
            </div>
          </td>
        </tr>
      </table>
      {userRows}
    </div>
  );
};

export default ActualsOverviewPage;

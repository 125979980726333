import { createContext } from "react";
import lsKeys from "../../../default_values/defaultKeys";

export const ReportingContext = createContext({
  cellWidth: () => {
    const capacityReportingRange =
      parseInt(localStorage.getItem(lsKeys.LAST_CAPACITY_ZOOM)) || 12;

    if (capacityReportingRange === 6) {
      return 180;
    } else {
      return 60;
    }
  },
});

const timeZonesMain = [
  [
    "pacific/midway",
    {
      olson: "Pacific/Midway",
      text: "(GMT-11:00) Midway Island, Samoa",
    },
  ],
  [
    "etc/gmt+10",
    {
      olson: "Etc/GMT+10",
      text: "(GMT-10:00) Hawaii",
    },
  ],
  [
    "pacific/marquesas",
    {
      olson: "Pacific/Marquesas",
      text: "(GMT-09:30) Marquesas Islands",
    },
  ],
  [
    "america/anchorage",
    {
      olson: "America/Anchorage",
      text: "(GMT-09:00) Alaska",
    },
  ],
  [
    "america/los_angeles",
    {
      olson: "America/Los_Angeles",
      text: "(GMT-08:00) Pacific Time (US & Canada)",
    },
  ],
  [
    "america/denver",
    {
      olson: "America/Denver",
      text: "(GMT-07:00) Mountain Time (US & Canada)",
    },
  ],
  [
    "america/chicago",
    {
      olson: "America/Chicago",
      text: "(GMT-06:00) Central Time (US & Canada)",
    },
  ],
  [
    "america/new_york",
    {
      olson: "America/New_York",
      text: "(GMT-05:00) Eastern Time (US & Canada)",
    },
  ],
  [
    "america/havana",
    {
      olson: "America/Havana",
      text: "(GMT-05:00) Cuba",
    },
  ],
  [
    "america/caracas",
    {
      olson: "America/Caracas",
      text: "(GMT-04:30) Caracas",
    },
  ],
  [
    "america/santiago",
    {
      olson: "America/Santiago",
      text: "(GMT-04:00) Santiago",
    },
  ],
  [
    "america/campo_grande",
    {
      olson: "America/Campo_Grande",
      text: "(GMT-04:00) Brazil",
    },
  ],
  [
    "america/glace_bay",
    {
      olson: "America/Glace_Bay",
      text: "(GMT-04:00) Atlantic Time (Canada)",
    },
  ],
  [
    "america/st_johns",
    {
      olson: "America/St_Johns",
      text: "(GMT-03:30) Newfoundland",
    },
  ],
  [
    "america/godthab",
    {
      olson: "America/Godthab",
      text: "(GMT-03:00) Greenland",
    },
  ],
  [
    "america/argentina/buenos_aires",
    {
      olson: "America/Argentina/Buenos_Aires",
      text: "(GMT-03:00) Buenos Aires",
    },
  ],
  [
    "america/sao_paulo",
    {
      olson: "America/Sao_Paulo",
      text: "(GMT-03:00) Brasilia",
    },
  ],
  [
    "america/noronha",
    {
      olson: "America/Noronha",
      text: "(GMT-02:00) Mid-Atlantic",
    },
  ],
  [
    "atlantic/cape_verde",
    {
      olson: "Atlantic/Cape_Verde",
      text: "(GMT-01:00) Cape Verde Is.",
    },
  ],
  [
    "europe/belfast",
    {
      olson: "Europe/Belfast",
      text: "(GMT) Belfast",
    },
  ],
  [
    "europe/dublin",
    {
      olson: "Europe/Dublin",
      text: "(GMT) Dublin",
    },
  ],
  [
    "europe/lisbon",
    {
      olson: "Europe/Lisbon",
      text: "(GMT) Lisbon",
    },
  ],
  [
    "europe/london",
    {
      olson: "Europe/London",
      text: "(GMT) London",
    },
  ],
  [
    "africa/abidjan",
    {
      olson: "Africa/Abidjan",
      text: "(GMT) Monrovia, Reykjavik",
    },
  ],
  [
    "utc",
    {
      olson: "UTC",
      text: "(GMT) UTC",
    },
  ],
  [
    "europe/amsterdam",
    {
      olson: "Europe/Amsterdam",
      text: "(GMT+01:00) Amsterdam, Stockholm, Vienna",
    },
  ],
  [
    "europe/stockholm",
    {
      olson: "Europe/Stockholm",
      text: "(GMT+01:00) Stockholm",
    },
  ],
  [
    "europe/oslo",
    {
      olson: "Europe/Oslo",
      text: "(GMT+01:00) Oslo",
    },
  ],
  [
    "europe/vienna",
    {
      olson: "Europe/Vienna",
      text: "(GMT+01:00) Vienna",
    },
  ],
  [
    "europe/berlin",
    {
      olson: "Europe/Berlin",
      text: "(GMT+01:00) Berlin",
    },
  ],
  [
    "europe/rome",
    {
      olson: "Europe/Rome",
      text: "(GMT+01:00) Rome",
    },
  ],
  [
    "europe/zurich",
    {
      olson: "Europe/Zurich",
      text: "(GMT+01:00) Geneva, Bern, Zurich",
    },
  ],
  [
    "europe/belgrade",
    {
      olson: "Europe/Belgrade",
      text: "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
    },
  ],
  [
    "europe/brussels",
    {
      olson: "Europe/Brussels",
      text: "(GMT+01:00) Brussels, Copenhagen, Madrid",
    },
  ],
  [
    "europe/copenhagen",
    {
      olson: "Europe/Copenhagen",
      text: "(GMT+01:00) Copenhagen",
    },
  ],
  [
    "europe/warsaw",
    {
      olson: "Europe/Warsaw",
      text: "(GMT+01:00) Warsaw",
    },
  ],
  [
    "europe/madrid",
    {
      olson: "Europe/Madrid",
      text: "(GMT+01:00) Madrid",
    },
  ],
  [
    "europe/paris",
    {
      olson: "Europe/Paris",
      text: "(GMT+01:00) Paris",
    },
  ],
  [
    "africa/algiers",
    {
      olson: "Africa/Algiers",
      text: "(GMT+01:00) West Central Africa",
    },
  ],
  [
    "africa/windhoek",
    {
      olson: "Africa/Windhoek",
      text: "(GMT+01:00) Windhoek",
    },
  ],
  [
    "africa/cairo",
    {
      olson: "Africa/Cairo",
      text: "(GMT+02:00) Cairo",
    },
  ],
  [
    "asia/jerusalem",
    {
      olson: "Asia/Jerusalem",
      text: "(GMT+02:00) Jerusalem",
    },
  ],
  [
    "eet",
    {
      olson: "EET",
      text: "(GMT+03:00) Eastern European Time",
    },
  ],
  [
    "europe/moscow",
    {
      olson: "Europe/Moscow",
      text: "(GMT+03:00) Moscow",
    },
  ],
  [
    "africa/addis_ababa",
    {
      olson: "Africa/Addis_Ababa",
      text: "(GMT+03:00) Nairobi",
    },
  ],
  [
    "asia/tehran",
    {
      olson: "Asia/Tehran",
      text: "(GMT+03:30) Tehran",
    },
  ],
  [
    "asia/dubai",
    {
      olson: "Asia/Dubai",
      text: "(GMT+04:00) Abu Dhabi, Muscat",
    },
  ],
  [
    "asia/tbilisi",
    {
      olson: "Asia/Tbilisi",
      text: "(GMT+04:00) Tbilisi",
    },
  ],
  [
    "asia/yerevan",
    {
      olson: "Asia/Yerevan",
      text: "(GMT+04:00) Yerevan",
    },
  ],
  [
    "asia/kabul",
    {
      olson: "Asia/Kabul",
      text: "(GMT+04:30) Kabul",
    },
  ],
  [
    "asia/yekaterinburg",
    {
      olson: "Asia/Yekaterinburg",
      text: "(GMT+05:00) Ekaterinburg",
    },
  ],
  [
    "asia/tashkent",
    {
      olson: "Asia/Tashkent",
      text: "(GMT+05:00) Tashkent",
    },
  ],
  [
    "asia/kolkata",
    {
      olson: "Asia/Kolkata",
      text: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi",
    },
  ],
  [
    "asia/katmandu",
    {
      olson: "Asia/Katmandu",
      text: "(GMT+05:45) Kathmandu",
    },
  ],
  [
    "asia/dhaka",
    {
      olson: "Asia/Dhaka",
      text: "(GMT+06:00) Astana, Dhaka",
    },
  ],
  [
    "asia/rangoon",
    {
      olson: "Asia/Rangoon",
      text: "(GMT+06:30) Yangon (Rangoon)",
    },
  ],
  [
    "asia/bangkok",
    {
      olson: "Asia/Bangkok",
      text: "(GMT+07:00) Bangkok, Hanoi, Jakarta",
    },
  ],
  [
    "asia/hong_kong",
    {
      olson: "Asia/Hong_Kong",
      text: "(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
    },
  ],
  [
    "australia/perth",
    {
      olson: "Australia/Perth",
      text: "(GMT+08:00) Perth",
    },
  ],
  [
    "asia/tokyo",
    {
      olson: "Asia/Tokyo",
      text: "(GMT+09:00) Osaka, Sapporo, Tokyo",
    },
  ],
  [
    "australia/darwin",
    {
      olson: "Australia/Darwin",
      text: "(GMT+09:30) Darwin",
    },
  ],
  [
    "australia/brisbane",
    {
      olson: "Australia/Brisbane",
      text: "(GMT+10:00) Brisbane",
    },
  ],
  [
    "australia/hobart",
    {
      olson: "Australia/Hobart",
      text: "(GMT+10:00) Hobart",
    },
  ],
  [
    "australia/lord_howe",
    {
      olson: "Australia/Lord_Howe",
      text: "(GMT+10:30) Lord Howe Island",
    },
  ],
  [
    "etc/gmt-11",
    {
      olson: "Etc/GMT-11",
      text: "(GMT+11:00) Solomon Is., New Caledonia",
    },
  ],
  [
    "pacific/norfolk",
    {
      olson: "Pacific/Norfolk",
      text: "(GMT+11:30) Norfolk Island",
    },
  ],
  [
    "pacific/auckland",
    {
      olson: "Pacific/Auckland",
      text: "(GMT+12:00) Auckland, Wellington",
    },
  ],
  [
    "etc/gmt-12",
    {
      olson: "Etc/GMT-12",
      text: "(GMT+12:00) Fiji, Kamchatka, Marshall Is.",
    },
  ],
];

const timeZonesMap = new Map(timeZonesMain);

const timeZonesArray = timeZonesMain.map((timeZoneValue) => {
  return { title: timeZoneValue[1].text, value: timeZoneValue[1].olson };
});

export default {
  timeZonesMap: timeZonesMap,
  timeZonesArray: timeZonesArray,
};

import { DateTime } from "luxon";

export const loggedThisMonth = (allUserLogs, date) => {
  const userLogs = allUserLogs.filter((log) => {
    const logDate = DateTime.fromISO(log.date).startOf("day");
    const loggedThisMonth = logDate >= date.startOf("month") && logDate <= date.endOf("month");

    return loggedThisMonth;
  });

  return userLogs.reduce((ps, a) => ps + a.duration, 0) / 60;
};

export const approvedThisMonth = (allUserLogs, date) => {
  const userLogs = allUserLogs.filter((log) => {
    const logDate = DateTime.fromISO(log.date).startOf("day");
    const loggedThisMonth = logDate >= date.startOf("month") && logDate <= date.endOf("month");

    return loggedThisMonth && log.approved;
  });

  return userLogs.reduce((ps, a) => ps + a.duration, 0) / 60;
};

export const totalThisMonth = (user, date) => {
  const firstDateOfMonth = date.startOf("month");
  let workingHoursInMonth = 0;

  for (let i = 0; i < date.endOf("month").day; i++) {
    const daySchedule = user.schedule[firstDateOfMonth.plus({ day: i }).weekday - 1];

    workingHoursInMonth += daySchedule[0] + daySchedule[2];
  }

  return workingHoursInMonth / 60;
};

import React, { useEffect, useState } from "react";
import "./items_row.scss";
import { WhiteTooltip } from "../../../../default_components/Tooltips";
import { SliceStringWithDots } from "../../../../../lib/StringFormat";

const WhiteTooltipStyles = {
  tooltip: {
    sx: {
      padding: "5px 5px 3px",
      borderRadius: "7px",
      backgroundColor: "var(--background-1)",
      border: "1px solid var(--stroke)",
    },
  },
  arrow: {
    sx: {
      "::before": {
        backgroundColor: "var(--background-1)",
        border: "1px solid var(--stroke)",
      },
    },
  },
};

const ItemsRow = React.memo(({ items, isTeams }) => {
  const [displayedItems, setDisplayedItems] = useState([]);
  const [remainingItems, setRemainingItems] = useState([]);

  const calculateDisplayedItemCount = (items, widthColumn) => {
    let totalWidth = 0;

    const displayedItemCount = items.reduce((count, item) => {
      const itemWidth = calculateItemWidth(item);
      totalWidth += itemWidth + 10;

      return totalWidth >= widthColumn - 40 ? count : count + 1;
    }, 0);

    return displayedItemCount;
  };

  const calculateItemWidth = (item) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = "12px Montserrat";
    const text = SliceStringWithDots(item?.name, 15);
    const width = context.measureText(text).width;
    return Math.ceil(width) + 16;
  };

  const updateDisplayedItems = () => {
    const widthColumn = document.getElementsByClassName("column-width-4")[0]?.clientWidth;

    const displayedItemCount = calculateDisplayedItemCount(items, widthColumn);
    const displayedItems = items.slice(0, displayedItemCount);
    const remainingItems = items.slice(displayedItemCount);

    setDisplayedItems(displayedItems);
    setRemainingItems(remainingItems);
  };

  useEffect(() => {
    window.addEventListener("resize", updateDisplayedItems);

    return () => {
      window.removeEventListener("resize", updateDisplayedItems);
    };
  }, []);

  useEffect(() => {
    updateDisplayedItems();
  }, [items]);

  return (
    <div className="users-block__items mobile_hidden column-width-4">
      {displayedItems.length > 0 ? (
        displayedItems.map((item) => (
          <div
            key={item.id}
            className={`users-block__item ${isTeams && "is-teams"}`}
            style={{ backgroundColor: item.color + "66", border: "2px solid " + item.color || "var(--background-2)" }}
          >
            <WhiteTooltip title={item.name} placement="top">
              <p>{SliceStringWithDots(item.name, 15)}</p>
            </WhiteTooltip>
          </div>
        ))
      ) : (
        <p>-</p>
      )}

      {remainingItems.length > 0 && (
        <WhiteTooltip
          arrow
          placement="top"
          componentsProps={WhiteTooltipStyles}
          title={remainingItems.map((item) => (
            <div
              className={`users-block__item remaining ${isTeams && "is-teams"}`}
              style={{ backgroundColor: item.color + "66", border: "2px solid " + item.color || "var(--background-2)" }}
            >
              <p>{SliceStringWithDots(item.name, 15)}</p>
            </div>
          ))}
        >
          <p className="user-block__tags-overflow-indicator">+{remainingItems.length}</p>
        </WhiteTooltip>
      )}
    </div>
  );
});

export default ItemsRow;

import React, { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const WAIT_INTERVAL = 1000;
const ENTER_KEY = 13;

const DeactivatedFilterRow = (props) => {
  const { t } = useTranslation();
  const timeoutRef = useRef(null);
  const [filterValue, setFilterValue] = useState("");

  const handleChange = (event) => {
    setFilterValue(event.target.value);
  };

  useEffect(() => {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      timeoutRef.current = null;
      filterUsers();
    }, WAIT_INTERVAL);
  }, [filterValue]);

  const handleKeyDown = (e) => {
    if (e.keyCode === ENTER_KEY) {
      timeoutRef.current = null;
      filterUsers();
    }
  };

  const filterUsers = () => {
    props.filterDeactivatedUsers(filterValue);
  };

  return (
    <div className={"filter-row"}>
      <SearchIcon color={"disabled"} className={"filter-row__search-icon"} />

      <InputBase
        placeholder={"Search by Name or Email"}
        className={"filter-row__filter-input"}
        inputProps={{ "aria-label": "naked" }}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />

      <div className={"filter-row__buttons"}>
        <div>
          <Link
            component={Button}
            to="/users"
            className="filter-row__button"
            variant="contained"
          >
            {t("users.active_users")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DeactivatedFilterRow;

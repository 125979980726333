import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Api from "../../../Api";
import TeambookBlueButton from "../../default_components/TeambookBlueButton";
import TeambookScheduleComponent from "../../default_components/schedule/TeambookScheduleComponent";
import { useProfileStore } from "../../../stores/profileStore";
import { shallow } from "zustand/shallow";
import { useNotificationStore } from "../../../stores/notificationStore";

const ScheduleSettings = () => {
  const { t } = useTranslation();
  const [setInAppNotification] = useNotificationStore((state) => [state.setInAppNotification], shallow);
  const [user, fetchProfile] = useProfileStore((state) => [state.profile, state.fetchProfile], shallow);
  const [schedule, setSchedule] = useState(user.schedule || []);

  const submitSchedule = () => {
    Api.Users.update(user.id, {
      settings: {
        schedule: schedule,
      },
    })
      .then((response) => {
        fetchProfile();
        setInAppNotification(t("notifications.profile.schedule.schedule_update"));
      })
      .catch((error) => {
        console.error("Failed to save schedule:", error.response.data);
      });
  };

  return (
    <div className="account-schedule">
      <TeambookScheduleComponent schedule={schedule} setSchedule={setSchedule} kind={"edit"} />

      <TeambookBlueButton
        onClick={submitSchedule}
        className="settings__submit"
        variant="contained"
        style={{ position: "absolute", right: "60px", bottom: "30px" }}
        text={t("save")}
      >
        {t("save")}
      </TeambookBlueButton>
    </div>
  );
};

export default ScheduleSettings;

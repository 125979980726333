import React from "react";
import { useTranslation } from "react-i18next";
import { useBookingsEditingStore } from "../../../stores/planner";
import { shallow } from "zustand/shallow";

const MultipleSelectHelp = () => {
  const { t } = useTranslation();

  const [editWindowOpened] = useBookingsEditingStore(
    (state) => [state.editWindowOpened],
    shallow
  );

  if (!editWindowOpened) {
    return <></>;
  }

  return (
    <div className="mobile_hidden planner-multiple-select-help">
      {t("planning.shift_help")}
    </div>
  );
};

export default MultipleSelectHelp;

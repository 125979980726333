import Api from "../../../Api";

async function GetTagsRequest(setTags) {
  await Api.Tags.all("name")
    .then((response) => {
      setTags(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
}

export default GetTagsRequest;

import React, { useState, useEffect } from "react";
import Api from "../../../Api";
import { DateTime } from "luxon";
import { useParams } from "react-router-dom";
import ProjectionHeader from "../ProjectionHeader";
import ProjectionControlRow from "../ProjectionControlRow";
import ProjectionTable from "../ProjectionTable";

const ClientProjection = () => {
  const { id } = useParams();

  const [projects, setProjects] = useState([]);
  const [clientProjects, setClientProjects] = useState([]);
  const [date, setDate] = useState(DateTime.now().minus({ week: 5 }).startOf("week"));

  useEffect(() => {
    Api.Clients.projects(id)
      .then((response) => {
        setProjects(response.data);
      })
      .catch(() => alert("Something went wrong. Please try again later."));
  }, [date]);

  useEffect(() => {
    getClientProjects();
  }, [projects, date]);

  const getClientProjects = async () => {
    let array = [];
    const results = await Promise.all(
      projects.map((project) => {
        return Api.Projects.projection(project.url, date.toJSDate());
      })
    );

    results.forEach((result) => {
      array.push(result.data);
    });

    setClientProjects(array);
  };

  return (
    <>
      <ProjectionHeader item={projects[0]} isClient />

      <ProjectionControlRow currentDate={date} changeDate={(value) => setDate(value)} />

      <ProjectionTable date={date} clientProjects={clientProjects} />
    </>
  );
};

export default ClientProjection;

import React from "react";
import { WhiteTooltip } from "./Tooltips";
import { useTranslation } from "react-i18next";

const TeambookSortingTypes = ({ filterOrder, filterOptions, setFilterOrder, style, onClick }) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-evenly",
        width: "100%",
        ...style,
      }}
      onClick={onClick}
    >
      {filterOptions.includes("alphabetical") && (
        <WhiteTooltip title={t("planning.alphabetical_order")}>
          {filterOrder === "a" ? (
            <div className="date-row__sort-icon" style={{ color: "var(--text-1)" }}>
              <svg
                id={"alphabeticalOrder"}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setFilterOrder("z")}
                alt="AlphabeticalOrder"
              >
                <path
                  d="M7.4082 3.01367L3.17773 11H2.41602L7.01562 2.47461H7.54883L7.4082 3.01367ZM8.63867 11L7.14453 2.94922L7.19141 2.46875H7.68945L9.34766 11H8.63867ZM8.63867 7.98828L8.5332 8.60938H4.13281L4.23242 7.98828H8.63867ZM8.99023 20.3848L8.88477 21H3.17773L3.2832 20.3848H8.99023ZM10.0859 13.0195L3.39453 21H2.92578L3.01953 20.4199L9.69922 12.4746H10.1797L10.0859 13.0195ZM9.875 12.4688L9.76953 13.084H4.44922L4.54883 12.4688H9.875Z"
                  fill="currentColor"
                />
                <path
                  d="M16.5 5V20M16.5 20L19 16.7391M16.5 20L14 16.7391"
                  stroke="currentColor"
                  strokeWidth="1"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          ) : (
            <div
              className="date-row__sort-icon"
              onClick={() => setFilterOrder("a")}
              style={{ color: filterOrder === "z" ? "var(--text-1)" : "var(--text-15)" }}
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8.99023 10.3848L8.88477 11H3.17773L3.2832 10.3848H8.99023ZM10.0859 3.01953L3.39453 11H2.92578L3.01953 10.4199L9.69922 2.47461H10.1797L10.0859 3.01953ZM9.875 2.46875L9.76953 3.08398H4.44922L4.54883 2.46875H9.875ZM7.4082 13.0137L3.17773 21H2.41602L7.01562 12.4746H7.54883L7.4082 13.0137ZM8.63867 21L7.14453 12.9492L7.19141 12.4688H7.68945L9.34766 21H8.63867ZM8.63867 17.9883L8.5332 18.6094H4.13281L4.23242 17.9883H8.63867Z"
                  fill="currentColor"
                />
                <path
                  d="M16.5 5V20M16.5 20L19 16.7391M16.5 20L14 16.7391"
                  stroke="currentColor"
                  strokeWidth="1"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          )}
        </WhiteTooltip>
      )}
      {filterOptions.includes("default") && (
        <WhiteTooltip title={t("planning.my_order")} placement={"bottom"}>
          <div
            className="date-row__sort-icon"
            onClick={() => setFilterOrder("default")}
            style={{ color: filterOrder === "default" ? "var(--text-1)" : "var(--text-15)" }}
            id="myOrder"
          >
            <svg
              alt="MyOrder"
              id="myOrder"
              width="22"
              height="16"
              viewBox="0 0 22 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 3.23913H1M13 8.23913H1M13 13.2391H1M18.5 1V15.5M18.5 15.5L21 12M18.5 15.5L16 12"
                stroke="currentColor"
                fill="none"
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </WhiteTooltip>
      )}
    </div>
  );
};

export default TeambookSortingTypes;

import React, { useEffect, useState } from "react";
import Api from "../../../Api";
import DatesRow from "../users_planning/DatesRow";
import ProjectsBlock from "./ProjectsBlock";
import { useTeambookFilter } from "../../../stores/planner";
import { useTranslation } from "react-i18next";
import { calculateProjectsFilters } from "../lib/calculateFilters";
import { compareStrings } from "../../../lib/StringFormat";
import { CellMeasurerCache } from "react-virtualized";
import { useCapacityStore } from "../../../stores/capacity";
import { shallow } from "zustand/shallow";
import NoBookingsFiltered from "../../planner/default_components/NoBookingsFiltered";

const minimumWidthForView = (viewType) => {
  return 400 + viewType * 80;
};

const ProjectPlanning = ({ date, allProjects, users, clients, jumpToUser, selectedProject, selectedViewType }) => {
  const { filterValues, filterType } = useTeambookFilter();
  const { t } = useTranslation();

  const [projects, setProjects] = useState([]);
  const [capacityReservations, setCapacityReservations] = useState([]);
  const [projectReservations, setProjectReservations] = useState([]);
  const [shownUsers, setShownUsers] = useState([]);
  const [windowWidth, setWindowWidth] = useState(
    Math.max(minimumWidthForView(selectedViewType), window.innerWidth - 30)
  );

  const [showTimeOffs] = useCapacityStore((state) => [state.showTimeOffs], shallow);
  const [emptyFiltered, setEmptyFiltered] = useState(projects.length === 0);

  useEffect(() => {
    setEmptyFiltered(projects.length === 0);
  }, [projects]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(Math.max(minimumWidthForView(selectedViewType), window.innerWidth - 30));
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    setWindowWidth(Math.max(minimumWidthForView(selectedViewType), window.innerWidth - 30));
  }, [selectedViewType]);

  const cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 217,
    minHeight: 217,
  });

  useEffect(() => {
    setShownUsers(users.filter((user) => user.team_ids.length > 0));

    if (users.length > 0) {
      Api.CapacityReservations.get({
        user_ids: users.filter((user) => user.team_ids.length > 0).map((u) => u.id),
        start_date: date.toISODate(),
        end_date: date.plus({ years: 2 }).toISODate(),
      }).then((res) => {
        setCapacityReservations(res.data);
      });
    }

    if (allProjects.length > 0) {
      let projs = allProjects
        .filter((p) => p.kind !== "time_off")
        .filter((p) => p.active && p.capacity_counted)
        .sort((x, y) => compareStrings(x.name, y.name));
      let timeOffs = allProjects
        .filter((p) => p.kind === "time_off")
        .filter((p) => p.active && p.capacity_counted)
        .sort((x, y) => compareStrings(x.name, y.name));

      setProjects([...projs, ...timeOffs]);

      Api.CapacityReservations.get_project_reservations({
        project_ids: [...projs, ...timeOffs].map((p) => p.id),
        start_date: date.toISODate(),
        end_date: date.plus({ years: 2 }).toISODate(),
      }).then((res) => {
        setProjectReservations(res.data);
      });
    }
  }, [date, users, allProjects]);

  useEffect(() => {
    let projs = allProjects.filter((p) => p.kind !== "time_off").sort((x, y) => compareStrings(x.name, y.name));
    let timeOffs = allProjects.filter((p) => p.kind === "time_off").sort((x, y) => compareStrings(x.name, y.name));

    const filteredProjects = calculateProjectsFilters([...projs, ...timeOffs], clients, filterValues, filterType, t);

    if (selectedProject && filteredProjects.find((project) => project.id === selectedProject)) {
      setProjects([
        [...filteredProjects].filter((project) => project.id === selectedProject)[0],
        ...[...filteredProjects].filter((project) => project.id !== selectedProject),
      ]);
    } else {
      setProjects(filteredProjects);
    }
  }, [allProjects, filterValues, filterType, selectedProject]);

  return (
    <div style={{ position: emptyFiltered ? "unset" : "fixed" }} className="capacity__component projects-planning">
      {emptyFiltered && filterValues.length > 0 ? (
        <NoBookingsFiltered />
      ) : (
        <>
          <DatesRow date={date} selectedViewType={selectedViewType} />

          <div>
            {projects
              .filter((pr) => pr.kind !== "time_off" || showTimeOffs)
              .map((project, i) => (
                <div>
                  <ProjectsBlock
                    project={project}
                    date={date}
                    capacityReservations={capacityReservations}
                    setCapacityReservations={setCapacityReservations}
                    prReservations={projectReservations}
                    users={shownUsers}
                    jumpToUser={jumpToUser}
                    setPrReservations={setProjectReservations}
                    selectedViewType={selectedViewType}
                    key={`project-${project.id}`}
                    projects={projects.filter((pr) => pr.kind !== "time_off" || showTimeOffs)}
                  />
                </div>
              ))}
          </div>
        </>
      )}
    </div>
  );
};

export default ProjectPlanning;

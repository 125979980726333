import {
  GetStartDateForPlannerDate,
  OffsetDays,
  getNumberOfDays,
  GetDaysInZoom,
  isWeekend,
} from "../../../lib/DateUtils";
import Api from "../../../Api";
import { useFilteredUsersStore } from "../../../stores/planner";

async function MoveBookingRequest(
  date,
  zoom,
  bookings,
  setBookings,
  bookingsEditing,
  teamUsers,
  closeEditWindow,
  teams,
  teamId,
  usersRef,
  filteredUsers,
  hash
) {
  const zoomDays = GetDaysInZoom(zoom) + 14;
  const from = GetStartDateForPlannerDate(date, zoom);
  const to = OffsetDays(from, zoomDays);

  const bookingMovingHash = hash;
  const { booking } = hash;

  const weekendsHidden = !(localStorage.getItem("weekendsHidden") === "false");

  let fromUserIndex = 0;
  let toUserIndex = 0;
  let userIds = [];
  let userIdsFrom = [];
  let indexIsOut = false;
  let bookingEditingHash = {};

  if (bookingsEditing.length === 0) {
    bookingsEditing = [booking];
  }

  for (let booking of bookingsEditing) {
    if (bookingEditingHash[booking.user_id]) {
      bookingEditingHash[booking.user_id].push(booking);
    } else {
      bookingEditingHash[booking.user_id] = [booking];
    }
  }

  teamUsers.forEach((user, i) => {
    if (user && user.id && bookingMovingHash.to_user) {
      if (user.id.toString() === bookingMovingHash.from_user.toString()) {
        fromUserIndex = i;
      }

      if (user.id.toString() === bookingMovingHash.to_user.toString()) {
        toUserIndex = i;
      }
    }
  });

  let userIndexOffset = toUserIndex - fromUserIndex;
  let userDateOffset = getNumberOfDays(
    bookingMovingHash.from_date,
    bookingMovingHash.to_date
  );

  Object.keys(bookingEditingHash).forEach((userId) => {
    teamUsers.forEach((user, i) => {
      if (userId.toString() === user.id.toString()) {
        if (
          teamUsers.length <= i + userIndexOffset ||
          0 > i + userIndexOffset
        ) {
          indexIsOut = true;
        } else {
          for (let j = 0; j < bookingEditingHash[userId].length; j++) {
            userIds.push(teamUsers[i + userIndexOffset].id);
            userIdsFrom.push(teamUsers[i].id);
          }
        }
      }
    });
  });

  let currentBookingId = 0;
  let movingArray = [];

  for (let userId of Object.keys(bookingEditingHash)) {
    for (let booking of bookingEditingHash[userId]) {
      if (
        isWeekend(OffsetDays(new Date(booking.date), userDateOffset)) &&
        weekendsHidden &&
        zoom !== 1
      ) {
        continue;
      }

      await Api.Bookings.update([booking.id], {
        project_id: booking.project_id,
        user_id: userIds[currentBookingId],
        tentative: booking.tentative,
        location: booking.location,
        duration: booking.duration,
        start_time: booking.start_time,
        comment: booking.comment,
        date: OffsetDays(new Date(booking.date), userDateOffset),
        also_repetitions: false,
        task_id: booking.task_id,
      });

      movingArray.push([
        booking,
        {
          user_id: userIds[currentBookingId],
          date: OffsetDays(new Date(booking.date), userDateOffset),
        },
      ]);

      currentBookingId++;
    }
  }

  let new_bookings = bookings || [];

  let userIdsToUpdateRow = [];

  movingArray.forEach((arrayOption) => {
    userIdsToUpdateRow.push(arrayOption[0].user_id);
    userIdsToUpdateRow.push(arrayOption[1].user_id);
  });

  await Promise.all([
    Api.Bookings.get([...userIds, ...userIdsFrom], from, to),
  ]).then((response) => {
    response[0].data.forEach((i) => {
      new_bookings[new_bookings.findIndex((b) => b.id === i.id)] = i;
    });
  });

  setBookings(new_bookings);
  hash.updateBookings();
  closeEditWindow();

  return userIdsToUpdateRow;
}

export default MoveBookingRequest;

import i18next from "i18next";

class CustomTranslationEngine {
  constructor(account) {
    this.account = account;
  }

  updateTranslationValues() {
    this.account.custom_translations.forEach((customTranslation) => {
      if (customTranslation.key === "projects") {
        this.updateProjectsValues(customTranslation);
      }
      if (customTranslation.key === "project") {
        this.updateProjectValues(customTranslation);
      }
      if (customTranslation.key === "users") {
        this.updateUsersValues(customTranslation);
      }
      if (customTranslation.key === "user") {
        this.updateUserValues(customTranslation);
      }
    });
  }

  //PROJECTS
  updateProjectsValues(customTranslation) {
    if (customTranslation.language === "en") {
      i18next.addResources(customTranslation.language, "translation", {
        "header.projects": customTranslation.value,
        "projects.upload_projects": `Import ${customTranslation.value}`,
        "projects.selected_projects_count": `${customTranslation.value} Selected`,
        "planning.filter_projects": customTranslation.value,
        "planning.capacity.by_reporting_projects": `Reporting: ${customTranslation.value}`,
        "dashboard.by_projects": `By ${customTranslation.value}`,
        "planning.capacity_by_projects": `Capacity: by ${customTranslation.value}`,
        "dashboard.productivity_hint": `Productivity is the Time Spent Working on ${customTranslation.value} (Billable & Non-billable).`,
        "dashboard.utilization_hint": `Utilization is the Time Spent Working on Billable ${customTranslation.value}.`,
        "planning.capacity.expand_all_projects": `Expand All ${customTranslation.value}`,
        "dashboard.divide_by_projects": `Divide by ${customTranslation.value}`,
        "planning.capacity.show_projects": `Show ${customTranslation.value}`,
        "planning.capacity.hide_projects": `Hide ${customTranslation.value}`,
        "settings.subscription.available_projects": `Available ${customTranslation.value}:`,
      });
    }
    if (customTranslation.language === "fr") {
      i18next.addResources(customTranslation.language, "translation", {
        "header.projects": customTranslation.value,
        "projects.upload_projects": `Importer ${customTranslation.value}`,
        "projects.selected_projects_count": `${customTranslation.value} Sélectionnés`,
        "planning.filter_projects": customTranslation.value,
        "planning.capacity.by_reporting_projects": `Rapports: ${customTranslation.value}`,
        "dashboard.by_projects": `Par ${customTranslation.value}`,
        "planning.capacity_by_projects": `Capacité: par ${customTranslation.value}`,
        "dashboard.productivity_hint": `La productivité est le temps passé à travailler sur des ${customTranslation.value} (facturables et non facturables).`,
        "dashboard.utilization_hint": `L'utilisation est le temps passé à travailler sur des ${customTranslation.value} facturables.`,
        "planning.capacity.expand_all_projects": `Développer tous les ${customTranslation.value}`,
        "dashboard.divide_by_projects": `Diviser par ${customTranslation.value}`,
        "planning.capacity.show_projects": `Afficher les ${customTranslation.value}`,
        "planning.capacity.hide_projects": `Cacher les ${customTranslation.value}`,
        "settings.subscription.available_projects": `${customTranslation.value} disponibles:`,
      });
    }
  }

  //PROJECT
  updateProjectValues(customTranslation) {
    if (customTranslation.language === "en") {
      i18next.addResources(customTranslation.language, "translation", {
        "projects.create_project": `Create ${customTranslation.value}`,
        "planning.project": customTranslation.value,
        "projects.selected_project_count": `${customTranslation.value} Selected`,
        "dashboard.project": customTranslation.value,
        "planning.capacity.by_project": `By ${customTranslation.value}`,
        "projects.project_info": `${customTranslation.value}'s Info`,
        "projects.edit_project": `Edit ${customTranslation.value}`,
        "planning.capacity.add_project": `Add ${customTranslation.value}`,
        "planning.specify_projects": customTranslation.value,
        "actuals.approve_by_project": `Approve by ${customTranslation.value}`,
        "projects.copy_calendar": `Share ${customTranslation.value} Calendar`,
        "projects.deactivate_confirmation_1": `Are you sure you want to archive the ${customTranslation.value} `,
        "projects.delete_confirmation_1": `Are you sure you want delete the ${customTranslation.value} `,
        "projects.reactivate_project_1": `Are you sure you want to re-activate the ${customTranslation.value} `,
        "projects.activate_project": `Activate ${customTranslation.value}`,
        "projects.updated_successfully": `${customTranslation.value} was updated successfully`,
        "projects.created_successfully": `${customTranslation.value} {{name}} [{{code}}] was created successfully`,
        "projects.copied_project_url": `Copied ${customTranslation.value} URL`,
      });
    } else if (customTranslation.language === "fr") {
      i18next.addResources(customTranslation.language, "translation", {
        "projects.create_project": `Créer un ${customTranslation.value}`,
        "planning.project": customTranslation.value,
        "projects.selected_project_count": `${customTranslation.value} Sélectionnés`,
        "dashboard.project": customTranslation.value,
        "planning.capacity.by_project": `Par ${customTranslation.value}`,
        "projects.project_info": `Details ${customTranslation.value}`,
        "projects.edit_project": `Editer ${customTranslation.value}`,
        "planning.capacity.add_project": `Ajouter ${customTranslation.value}`,
        "planning.specify_projects": customTranslation.value,
        "actuals.approve_by_project": `Approuver by ${customTranslation.value}`,
        "projects.copy_calendar": `Partager le Calendrier du ${customTranslation.value}`,
        "projects.deactivate_confirmation_1": `Voulez-vous vraiment archiver le ${customTranslation.value} `,
        "projects.delete_confirmation_1": `Êtes-vous sûr de vouloir supprimer le ${customTranslation.value} `,
        "projects.reactivate_project_1": `Êtes-vous sûr de vouloir réactiver le ${customTranslation.value} `,
        "projects.activate_project": `Activer ${customTranslation.value}`,
        "projects.updated_successfully": `${customTranslation.value} a été mis à jour avec succès`,
        "projects.created_successfully": `${customTranslation.value} {{name}} [{{code}}] a été créé avec succès`,
        "projects.copied_project_url": `URL du ${customTranslation.value} copié`,
      });
    }
  }

  //USERS
  updateUsersValues(customTranslation) {
    if (customTranslation.language === "en") {
      i18next.addResources(customTranslation.language, "translation", {
        "header.users": customTranslation.value,
        "users.upload_users": `Upload ${customTranslation.value}`,
        "users.selected_users_count": `${customTranslation.value} Selected`,
        "planning.filter_users": customTranslation.value,
        "users.tag_users": customTranslation.value,
        "planning.add_user_text": `Add ${customTranslation.value} to the Team`,
        "planning.add_user_group_text": `Add ${customTranslation.value} to the Group`,
        "planning.select_users": `Select ${customTranslation.value}`,
        "planning.capacity.by_reporting_users": `Reporting: ${customTranslation.value}`,
        "dashboard.by_users": `By ${customTranslation.value}`,
        "planning.capacity_by_users": `Capacity: by ${customTranslation.value}`,
        "planning.hide_other_users": `Hide other ${customTranslation.value}`,
        "planning.select_by_users": `${customTranslation.value} View`,
        "planning.capacity.expand_all_users": `Expand All ${customTranslation.value}`,
        "dashboard.divide_by_users": `Divide by ${customTranslation.value}`,
        "planning.capacity.show_users": `Show ${customTranslation.value}`,
        "planning.capacity.hide_users": `Hide ${customTranslation.value}`,
        "users.all_users": `All ${customTranslation.value}`,
        "settings.schedule.important": `IMPORTANT! Changes will apply to all ${customTranslation.value} in your organization.`,
        "notifications.planning.add_users_to_team": `You've successfully added ${customTranslation.value} to team`,
      });
    } else if (customTranslation.language === "fr") {
      i18next.addResources(customTranslation.language, "translation", {
        "header.users": customTranslation.value,
        "users.upload_users": `Importer ${customTranslation.value}`,
        "users.selected_users_count": `${customTranslation.value} Sélectionnés`,
        "planning.filter_users": customTranslation.value,
        "users.tag_users": customTranslation.value,
        "planning.add_user_text": `Ajouter un ${customTranslation.value} à l'équipe`,
        "planning.add_user_group_text": `Ajouter ${customTranslation.value} au groupe`,
        "planning.select_users": `Sélectionner ${customTranslation.value}`,
        "planning.capacity.by_reporting_users": `Rapports: ${customTranslation.value}`,
        "dashboard.by_users": `Par ${customTranslation.value}`,
        "planning.capacity_by_users": `Capacité: par ${customTranslation.value}`,
        "planning.hide_other_users": `Masquer les autres ${customTranslation.value}`,
        "planning.select_by_users": `Vue des ${customTranslation.value}`,
        "planning.capacity.expand_all_users": `Développer tous les ${customTranslation.value}`,
        "dashboard.divide_by_users": `Diviser par ${customTranslation.value}`,
        "planning.capacity.show_users": `Afficher ${customTranslation.value}`,
        "planning.capacity.hide_users": `Cacher ${customTranslation.value}`,
        "users.all_users": `Tous Les ${customTranslation.value}`,
        "settings.schedule.important": `IMPORTANT! Les modifications s'appliqueront à tous les ${customTranslation.value} de votre organisation.`,
        "notifications.planning.add_users_to_team": `Vous avez ajouté des ${customTranslation.value} à l'équipe avec succès`,
      });
    }
  }

  //USER
  updateUserValues(customTranslation) {
    if (customTranslation.language === "en") {
      i18next.addResources(customTranslation.language, "translation", {
        "users.create_user": `Create ${customTranslation.value}`,
        "users.selected_user_count": `${customTranslation.value} Selected`,
        "planning.add_user": `Add ${customTranslation.value}`,
        "planning.capacity.by_user": `By ${customTranslation.value}`,
        "planning.capacity.add_user": `Add ${customTranslation.value}`,
        "planning.create_user": `Create New ${customTranslation.value}`,
        "planning.specify_users": customTranslation.value,
        "actuals.approve_by_user": `Approve by ${customTranslation.value}`,
        "users.new_user": `New ${customTranslation.value}`,
        "users.user_info": `${customTranslation.value}'s info`,
        "users.edit_user": `Edit ${customTranslation.value}`,
        "users.deactivate_confirmation_1": `Are you sure you want to de-activate the ${customTranslation.value} `,
        "users.delete_confirmation_1": `Are you sure you want delete the ${customTranslation.value} `,
        "users.reactivate": `Reactivate ${customTranslation.value}`,
        "users.activate_confirmation_1": `Are you sure you want to re-activate the ${customTranslation.value} `,
        "users.updated_successfully": `${customTranslation.value} was updated successfully`,
        "users.created_successfully": `${customTranslation.value} {{name}} was created successfully`,
      });
    } else if (customTranslation.language === "fr") {
      i18next.addResources(customTranslation.language, "translation", {
        "users.create_user": `Créer ${customTranslation.value}`,
        "users.selected_user_count": `${customTranslation.value} Sélectionnés`,
        "planning.add_user": `Ajouter ${customTranslation.value}`,
        "planning.capacity.by_user": `Par ${customTranslation.value}`,
        "planning.capacity.add_user": `Ajouter ${customTranslation.value}`,
        "planning.create_user": `Créer un nouvel ${customTranslation.value}`,
        "planning.specify_users": customTranslation.value,
        "actuals.approve_by_user": `Approuver par ${customTranslation.value}`,
        "users.new_user": `Nouvel ${customTranslation.value}`,
        "users.user_info": `Informations sur ${customTranslation.value}`,
        "users.edit_user": `Editer ${customTranslation.value}`,
        "users.deactivate_confirmation_1": `Etes-vous sûr de vouloir archiver ${customTranslation.value} `,
        "users.delete_confirmation_1": `Etes-vous sûr de vouloir supprimer ${customTranslation.value} `,
        "users.reactivate": `Re-activer ${customTranslation.value}`,
        "users.activate_confirmation_1": `Êtes-vous sûr de vouloir réactiver ${customTranslation.value} `,
        "users.updated_successfully": `${customTranslation.value} mis à jour avec succès`,
        "users.created_successfully": `${customTranslation.value} {{name}} a été créé avec succès`,
      });
    }
  }
}

export default CustomTranslationEngine;

import React from "react";
import { useTranslation } from "react-i18next";
import lsKeys from "../../default_values/defaultKeys";
import { WhiteTooltip } from "../../default_components/Tooltips";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";

const CapacitySplitSelector = ({ className, pageName, onSelect, selectedType }) => {
  const { t } = useTranslation();
  const role = localStorage.getItem(lsKeys.ROLE);

  const selectOptions = [
    {
      value: "user",
      label: t("planning.capacity.by_user"),
    },
    {
      value: "project",
      label: t("planning.capacity.by_project"),
    },
  ];

  const isActive = (type) => type === selectedType;
  return (
    role !== "contractor" && (
      <div className={"planner-type-select__shown-component " + className}>
        <div
          style={{
            background: isActive(selectOptions[0].value) ? "var(--planner-select-view-button)" : "unset",
            borderTopLeftRadius: 6,
            borderBottomLeftRadius: 6,
            cursor: "pointer",
          }}
          onClick={() => onSelect(selectOptions[0].value)}
          className="planner-type-select__dropdown-option"
        >
          <TeambookIcon
            name={icons.USERS}
            color="darkblue"
            style={{ width: 22, height: 22 }}
            tooltipTitle={t("planning.capacity_by_users")}
          />
        </div>

        <div
          style={{
            background: isActive(selectOptions[1].value) ? "var(--planner-select-view-button)" : "unset",
            borderLeft: "1px solid var(--stroke)",
            cursor: "pointer",
          }}
          className="planner-type-select__dropdown-option"
          onClick={() => onSelect(selectOptions[1].value)}
        >
          <TeambookIcon
            tooltipTitle={t("planning.capacity_by_projects")}
            name={icons.PROJECT}
            style={{ width: 22, height: 22 }}
            color="darkblue"
          />
        </div>
      </div>
    )
  );
};

export default CapacitySplitSelector;

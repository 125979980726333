import React, { useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import { useTranslation } from "react-i18next";
import DialogActions from "@mui/material/DialogActions";
import TeambookBlueButton from "../../default_components/TeambookBlueButton";
import { TeambookProjectsPicker } from "../../default_components/TeambookDefaultPickers";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";
import { useProfileStore } from "../../../stores/profileStore";
import { shallow } from "zustand/shallow";

const AddProjectsDialog = ({ setDialogOpened, projects, addProjects }) => {
  const { t } = useTranslation();
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [profile] = useProfileStore((state) => [state.profile], shallow);

  const closeModal = () => {
    setDialogOpened(false);
    setSelectedProjects([]);
  };

  const projectOptions = () => {
    if (profile.role === "self_planner") {
      return [...projects.filter((pr) => pr.kind === "time_off" && pr.capacity_counted).sort((x, y) => (x.name > y.name ? 1 : -1))];
    } else {
      return [
        ...projects.filter((pr) => pr.kind !== "time_off" && pr.capacity_counted).sort((x, y) => (x.name > y.name ? 1 : -1)),
        ...projects.filter((pr) => pr.kind === "time_off" && pr.capacity_counted).sort((x, y) => (x.name > y.name ? 1 : -1)),
      ];
    }
  };

  return (
    <Dialog open={true} onClose={closeModal} aria-labelledby="form-dialog-title" className="tb-default-dialog">
      <DialogTitle>
        <p>{t("planning.add_projects")}</p>
        <TeambookIcon name={icons.CROSS} onClick={closeModal} />
      </DialogTitle>

      <DialogContent style={{ padding: "20px 40px", width: 400, height: 120 }}>
        <TeambookProjectsPicker
          displayEmpty={true}
          projects={projectOptions()}
          defaultValue={[]}
          placeholder={"Select projects"}
          maxMenuHeight={150}
          type="multiple"
          onChange={(options, newValue) => {
            setSelectedProjects(Array.isArray(options) ? options : []);
          }}
          isSearchable={true}
        />
      </DialogContent>

      <DialogActions>
        <TeambookBlueButton
          text={t("save")}
          onClick={() => {
            addProjects(selectedProjects);
            setSelectedProjects([]);
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default AddProjectsDialog;

import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReportingContext } from "../../../lib/ReportingContext";
import { WhiteTooltip } from "../../../../../default_components/Tooltips";
import { getCorrectTextLocale } from "../../../../../capacity_planning";
import UsersProfileImage from "../../../../../default_components/UsersProfileImage";
import { recalculateCapacityArray } from "../../../../../capacity_planning/lib/recalculateCapacityArray";
import { calculateHeatStyles } from "../../../../../capacity_planning/lib/calculateHeatStyles";
import { shallow } from "zustand/shallow";
import { useAccountStore } from "../../../../../../stores/accountStore";

const UsersSubBlock = ({ user, date, reservations, selectedViewType }) => {
  const { t } = useTranslation();
  const [calculatedDurations, setCalculatedDurations] = useState([]);

  const [account] = useAccountStore((state) => [state.account], shallow);

  useEffect(() => {
    updateCalculations(reservations);
  }, [reservations, date]);

  const updateCalculations = (reservations) => {
    setCalculatedDurations(recalculateCapacityArray(date, reservations, selectedViewType));
  };

  return (
    <div className="reporting-block">
      <div className="reporting__headline" style={{ backgroundColor: "var(--background-2)" }}>
        <div className="reporting__headline__name" style={{ backgroundColor: "var(--background-2)" }}>
          <div className="flex named-row">
            <UsersProfileImage
              size={40}
              user={user}
              userImage={user.avatars["50"]}
              style={{
                marginLeft: "30px",
              }}
            />

            <p style={{ fontStyle: "italic" }}>{user.name}</p>
          </div>

          <p className="duration">{calculatedDurations.reduce((ps, a) => ps + a, 0)} days</p>
        </div>

        {[...Array(selectedViewType)].map((x, i) => {
          const styleBg = calculateHeatStyles(account, user, date.plus({ months: i }), calculatedDurations[i] || 0);
          const toolTipMessage = getCorrectTextLocale(styleBg, t);

          return (
            <div className={`table-cell ${styleBg}`} title={toolTipMessage}>
              <p>{calculatedDurations[i] || "0"}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default UsersSubBlock;

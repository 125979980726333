import React from "react";
import "./projection.scss";
import teambookAd from "../default_images/teambookAd.svg";
import TeambookBlueButton from "../default_components/TeambookBlueButton";
import { useTranslation } from "react-i18next";

const ProjectionTeambookAd = () => {
  const { t } = useTranslation();

  const moveToTeambook = () => {
    window.open("https://teambookapp.com", "_blank");
  };

  return (
    <div className="projection__teambook-ad">
      <div className="projection__teambook-ad-content">
        <p className="blue-text">{t("projects.teambook_ad.title")}</p>
        <p>{t("projects.teambook_ad.text")}</p>

        <TeambookBlueButton
          className="projection__teambook-ad-button"
          onClick={moveToTeambook}
          borderRadius="7px !important"
          text={t("projects.teambook_ad.try_now")}
        />
      </div>

      <img src={teambookAd} alt="teambook-ad" />
    </div>
  );
};

export default ProjectionTeambookAd;

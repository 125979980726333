import Api from "../../../../Api";
import client from "braintree-web/client";
import hostedFields from "braintree-web/hosted-fields";
import $ from "jquery";
import threeDSecure from "braintree-web/three-d-secure";
import { useProfileStore } from "../../../../stores/profileStore";
import { shallow } from "zustand/shallow";
import axios from "axios";

const fieldsStyles = (isDarkTheme) => {
  return {
    input: {
      "font-size": "16px",
      "font-family": "roboto, verdana, sans-serif",
      "font-weight": "400",
      color: isDarkTheme ? "#FFFFFFBF" : "#474747",
    },
    "::placeholder": {
      color: isDarkTheme ? "#FFFFFFBF" : "#474747",
      "font-weight": "lighter",
      opacity: "0.5",
    },
    ":focus": {
      color: isDarkTheme ? "#FFFFFFBF" : "#474747",
    },
    ".valid": {
      color: isDarkTheme ? "#FFFFFFBF" : "#474747",
    },
    ".invalid": {
      color: isDarkTheme ? "#FFFFFFBF" : "#474747",
    },
  };
};

const emptySrc = "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E";

const verifyCard = async (response, clientInstance, payload, changePaymentRequest, profile) => {
  const ipLookUp = await axios.get("https://api.ipify.org/?format=json");
  const ip = ipLookUp.data.ip;

  threeDSecure.create(
    {
      client: clientInstance,
      version: "2",
    },
    function (createError, threeDSecure) {
      threeDSecure.on("lookup-complete", function (data, next) {
        next();
      });

      threeDSecure.verifyCard(
        {
          nonce: response.data.nonce,
          bin: payload.details.bin,
          amount: "0.00",
          collectDeviceData: true,
          challengeRequested: true,
          email: profile.email,
          billingAddress: {
            givenName: profile.first_name,
            surname: profile.last_name,
          },
          additionalInformation: {
            ipAddress: ip || "192.168.1.1",
          },
        },
        function (verifyError, payload) {
          changePaymentRequest(payload.nonce);
        }
      );
    }
  );
};

const HostedFieldsUpdate = (setToken, changePaymentRequest, setErrorText, setLoadingText, isDarkTheme, profile) => {
  Api.Billing.client_token().then((response) => {
    setToken(response.data.token);

    client.create(
      {
        authorization: response.data.token,
      },
      function (err, clientInstance) {
        if (err) {
          console.error(err);
          return;
        }

        hostedFields.create(
          {
            client: clientInstance,
            styles: fieldsStyles(isDarkTheme),
            fields: {
              number: {
                selector: "#card-number",
                placeholder: "XXXX XXXX XXXX XXXX",
              },
              cvv: {
                selector: "#cvv",
                placeholder: "XXX",
              },
              expirationDate: {
                selector: "#expiration-date",
                placeholder: "MM/YY",
              },
            },
          },
          function (err, hostedFieldsInstance) {
            if (err) {
              console.error(err);
              return;
            }

            function findLabel(field) {
              return $('.hosted-field--label[for="' + field.container.id + '"]');
            }

            hostedFieldsInstance.on("focus", function (event) {
              var field = event.fields[event.emittedBy];

              findLabel(field).addClass("label-float").removeClass("filled");
            });

            // Emulates floating label pattern
            hostedFieldsInstance.on("blur", function (event) {
              var field = event.fields[event.emittedBy];

              var label = findLabel(field);

              if (field.isEmpty) {
                label.removeClass("label-float");
              } else if (field.isValid) {
                label.addClass("filled");
              } else {
                label.addClass("invalid");
              }
            });

            hostedFieldsInstance.on("empty", function (event) {
              var field = event.fields[event.emittedBy];

              findLabel(field).removeClass("filled").removeClass("invalid");
              $("#card-logo").attr("src", emptySrc);
            });

            hostedFieldsInstance.on("validityChange", function (event) {
              var field = event.fields[event.emittedBy];
              var label = findLabel(field);

              if (field.isPotentiallyValid) {
                label.removeClass("invalid");
              } else {
                label.addClass("invalid");
              }
            });

            $("#cardForm").submit(function (event) {
              event.preventDefault();

              $(".pay-button").prop("disabled", true);

              hostedFieldsInstance.tokenize(function (err, payload) {
                if (err) {
                  setErrorText(err.message);
                  $(".pay-button").prop("disabled", false);
                  return;
                }

                setLoadingText("Verifying credit card");

                Api.Billing.vault_credit_card(payload.nonce)
                  .then((response) => verifyCard(response, clientInstance, payload, changePaymentRequest, profile))
                  .catch((error) => setErrorText(error.response.data.error));
              });
            });
          }
        );
      }
    );
  });
};

export default HostedFieldsUpdate;

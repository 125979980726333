import React, { useState } from "react";
import TeambookBlueButton from "../../default_components/TeambookBlueButton";
import { Link } from "react-router-dom";
import LanguageBlock from "./LanguageBlock";
import CreateWhatsNew from "./CreateWhatsNew";
import ControlPanel from "../ControlPanel";

const languages = ["en", "fr", "de", "es", "it"];
const languageName = ["English", "French", "Deutsch", "Spanish", "Italian"];

const WhatsNew = () => {
  const [addWhatsNewOpen, setAddWhatsNewOpen] = useState(false);

  return (
    <div style={{ position: "relative" }}>
      <div>
        <Link to="/administration">
          <TeambookBlueButton
            style={{
              zIndex: "1",
              position: "absolute",
              left: "5px",
              top: "5px",
            }}
            text={"To Administration"}
          />
        </Link>
      </div>
      <table border="1px" className="table table-hover">
        <thead className="table-hover__head">
          <th>Header</th>
          <th>Text</th>
          <th>
            <TeambookBlueButton text={"Add New WHATS NEW"} onClick={() => setAddWhatsNewOpen(true)} />
          </th>
        </thead>

        <tbody>
          {languages.map((language, index) => (
            <>
              <p style={{ margin: "20px 0 0", padding: "5px 0" }}>{languageName[index]}</p>

              <LanguageBlock language={language} />
            </>
          ))}
        </tbody>
      </table>

      {addWhatsNewOpen && <CreateWhatsNew />}

      <ControlPanel />
    </div>
  );
};

export default WhatsNew;

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Api from "../../../../Api";
import FormErrorMessage from "../../../default_components/FormErrorMessage";
import TeambookBlueButton from "../../../default_components/TeambookBlueButton";
import TeambookTextForm from "../../../default_components/TeambookTextForm";
import Cross from "../../../default_images/cross.svg";

const phoneRegex = /^[+0-9][0-9]*$/;

export const ClientCreationDialog = ({
  open,
  setOpen,
  setClient,
  setAllClients,
  clients,
}) => {
  const { t } = useTranslation();

  const [clientName, setClientName] = useState(null);
  const [clientEmail, setClientEmail] = useState(null);
  const [clientPhone, setClientPhone] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorField, setErrorField] = useState(null);

  const closeErrorMessage = () => {
    setErrorMessage(null);
  };

  const closeDialog = () => {
    setOpen(false);
    setErrorField(null);
    setErrorMessage(null);
    setClientName(null);
    setClientPhone("");
    setClientEmail(null);
  };

  const createClient = () => {
    Api.Clients.create({
      name: clientName,
      active: true,
      phone_number: clientPhone,
      email: clientEmail,
      notes: "",
    })
      .then((response) => {
        setAllClients([...clients, response.data]);
        setClient(response.data);
        closeDialog();
      })
      .catch((error) => {
        setErrorMessage(error.response.data.error.message);
        setErrorField(error.response.data.error.field);
      });
  };

  return (
    <Dialog className="tb-default-dialog" open={open} onClose={closeDialog}>
      <DialogTitle>
        <p>{t("projects.new_client")}</p>
        <img className="form_close" src={Cross} onClick={closeDialog} />
      </DialogTitle>
      <DialogContent style={{ width: 400, padding: "20px 40px" }}>
        <div className="client-creation-dialog__value-row">
          <p className="client-creation-dialog__value-name">
            {t("projects.client_name")}
          </p>
          <TeambookTextForm
            style={{ margin: 0 }}
            value={clientName}
            onChange={setClientName}
          />
          {errorMessage !== null && errorField === "name" ? (
            <FormErrorMessage
              style={{ marginTop: "10px" }}
              text={errorMessage}
              closeErrorMessage={closeErrorMessage}
            />
          ) : null}
        </div>
        <div className="client-creation-dialog__value-row">
          <p className="client-creation-dialog__value-name">
            {t("projects.client_email")}
          </p>
          <TeambookTextForm
            style={{ margin: 0 }}
            value={clientEmail}
            onChange={setClientEmail}
          />
          {errorMessage !== null && errorField === "email" ? (
            <FormErrorMessage
              style={{ marginTop: "10px" }}
              text={errorMessage}
              closeErrorMessage={closeErrorMessage}
            />
          ) : null}
        </div>
        <div className="client-creation-dialog__value-row">
          <p className="client-creation-dialog__value-name">
            {t("projects.client_phone")}
          </p>
          <TeambookTextForm
            style={{ margin: 0 }}
            type={"text"}
            fieldValue={clientPhone}
            onChange={(text) =>
              text.length > 0
                ? phoneRegex.test(text)
                  ? setClientPhone(text)
                  : false
                : setClientPhone(text)
            }
          />
          {errorMessage !== null && errorField === "phone_number" ? (
            <FormErrorMessage
              style={{ marginTop: "10px" }}
              text={errorMessage}
              closeErrorMessage={closeErrorMessage}
            />
          ) : null}
        </div>
      </DialogContent>
      <DialogActions>
        <TeambookBlueButton
          id="update_project"
          onClick={createClient}
          className="form__edit-button"
          text={t("create")}
        />
      </DialogActions>
    </Dialog>
  );
};

import React, { useState, useRef } from "react";
import "../../../default_styles/tb-colors.css";
import { useTranslation } from "react-i18next";
import FormErrorMessage from "../../../default_components/FormErrorMessage";
import { WhiteTooltip } from "./../../../default_components/Tooltips";
import TeambookColorPicker from "./../../../default_components/TeambookColorPicker";
import Check from "../../../default_images/check.svg";
import Cross from "../../../default_images/cross.svg";
import TeambookTextForm from "../../../default_components/TeambookTextForm";
import { icons } from "../../../default_images/IconsList";
import { TeambookIcon } from "../../../default_images/TeambookIcon";

function EditTag({
  tag,
  closeErrorMessage,
  updateTag,
  tagName,
  setTagName,
  tagColor,
  setTagColor,
  errorMessage,
  handleCloseEditing,
}) {
  const { t } = useTranslation();

  const [changeColorsOpened, setChangeColorsOpened] = useState(false);
  const anchorRef = useRef(null);

  if (tag.id === undefined) {
    return <div />;
  }

  return (
    <>
      <div
        className="tag-menu__tag-editing row"
        style={{ flexWrap: "wrap", minHeight: "60px" }}
      >
        <div className="col-md-3">
          <WhiteTooltip title={t("projects.change_color")}>
            <div
              className="tags-color-circle"
              style={{ backgroundColor: tagColor, cursor: "pointer" }}
              onClick={() => setChangeColorsOpened(true)}
              ref={anchorRef}
            />
          </WhiteTooltip>
        </div>

        <TeambookColorPicker
          color={tagColor}
          setColor={setTagColor}
          changeColorsOpened={changeColorsOpened}
          setChangeColorsOpened={setChangeColorsOpened}
          anchorRef={anchorRef}
        />

        <div className="col-md-3">
          <TeambookTextForm
            id={"tagName"}
            fieldValue={tagName}
            onChange={setTagName}
            autoFocus
          />
        </div>

        <div
          style={{ padding: "0px", justifyContent: "center" }}
          className="col-md-3 row"
        >
          <p style={{ marginBottom: "0px" }}>{tag.tag_users.length}</p>
        </div>

        <div
          style={{
            marginLeft: "auto",
            display: "flex",
            gap: 15,
            justifyContent: "flex-end",
          }}
          className="col-md-3"
        >
          <TeambookIcon
            id={"saveEditingTag"}
            name={icons.CHECK}
            tooltipTitle={t("save")}
            alt={t("save")}
            width
            onClick={updateTag}
            className={"manage-team-icon"}
            style={{ cursor: "pointer", width: 24 }}
            color="alternative_default"
          />

          <TeambookIcon
            name={icons.CROSS}
            style={{ cursor: "pointer", width: 24 }}
            onClick={handleCloseEditing}
            tooltipTitle={t("close")}
            color="alternative_default"
          />
        </div>
      </div>
      <div className="col-md-12">
        {errorMessage !== null ? (
          <FormErrorMessage
            text={errorMessage}
            closeErrorMessage={closeErrorMessage}
          />
        ) : null}
      </div>
    </>
  );
}

export default EditTag;

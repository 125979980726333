const LAST_TEAMID_KEY = "lastTeamId";
const LAST_PLANNER_TYPE = "tbLastPlannerType";
const LAST_TEAMS_ZOOM_KEY = "lastTeamsZoom";
const WEEKENDS_HIDDEN_KEY = "weekendsHidden";
const TOOLTIP_HIDDEN = "tooltipHidden";
const USER_ORDER = "plannerUserOrder";
const BOOKING_FORMAT = "bookingFormat";
const LAST_PLANNER_FILTERS = "lastFiltersTB";
const LAST_GROUPED_PLANNER_FILTERS = "tb-last-grouped-planner-filters";
const LAST_CAPACITY_USERS_FILTERS = "lastCapacityUsersFilters";
const LAST_CAPACITY_PROJECTS_FILTERS = "lastCapacityProjectsFilters";
const LAST_CAPACITY_OVERVIEW_FILTERS = "lastCapacityOverviewFilters";
const ACTUALS_MODAL = "tbActualsModal";
const ROLE = "tb-role";
const LANGUAGE = "tb-lang";
const AUTH_TOKEN = "Auth-Token";
const PLANNER_HIDE_OTHERS = "tb-planner-hide-others";
const LAST_REPORTING_FILTERS = "tb-last-reporting-filters";
const LAST_ACTUAL_DASHBOARD_DATE = "tb-last-actual-dashboard-date";
const LAST_CAPACITY_ZOOM = "tb-last-capacity-zoom";
const GROUP_CLIENTS_PLANNER = "tb-group-by-clients";
const DASHBOARD_GRAPH_ZOOM = "tb-dashboard-graph-zoom";
const PROJECTS_COLUMNS_DISPLAY = "tb-projects-columns-display";
const USERS_COLUMNS_DISPLAY = "tb-users-columns-display";
const GTM_MOVE_ACTION = "tb-gtm-move-action";
const THEME = "tb-theme";
const ONBOARDING_OPENED = "tb-onboarding";
const CAPACITY_ONBOARDING_OPENED = "capacity-tb-onboarding";
const REPORTING_USERS_FILTER = "tb-reporting-users-filter";
const REPORTING_PROJECTS_FILTER = "tb-reporting-projects-filter";
const REPORTING_CAPACITY_PROJECTS_FILTER = "tb-reporting-capacity-projects-filter";
const REPORTING_CAPACITY_USERS_FILTER = "tb-reporting-capacity-users-filter";
const REPORTING_CAPACITY_TAGS_FILTER = "tb-reporting-capacity-tags-filter";
const REPORTING_COMPARISON_PROJECTS_FILTER = "tb-reporting-comparison-projects-filter";
const REPORTING_COMPARISON_USERS_FILTER = "tb-reporting-comparison-users-filter";
const REPORTING_COMPARISON_TASKS_FILTER = "tb-reporting-comparison-tasks-filter";
const REPORTING_OVERVIEW_FILTER = "tb-reporting-overview-filter";
const REPORTING_SAT_OVERVIEW_FILTER = "tb-reporting-sat-overview-filter";
const REPORTING_ACTUALS_USER_FILTER = "tb-reporting-actuals-user-filter";
const REPORTING_ACTUALS_PROJECT_FILTER = "tb-reporting-actuals-project-filter";
const LAST_VISITED_PAGE = "tb-last-visited-page";
const LAST_OPENED_DASHBOARD = "tb-last-opened-dashboard";
const ACTUALS_ONBOARDING_OPENED = "tb-actuals-onboarding";
const DASHBOARD_RANGE = "dashboardRange";
const DASHBOARD_FROM = "dashboardFrom";
const BANNER_CLOSED_BY_USER = "banner-closed-by-user";
const STAGING_SETTINGS_ENABLED = "staging-settings-enabled";
const SETTINGS_POS = "settings-pos";
const ACTUALS_ZOOM = "actuals-zoom";
const ACTUALS_SHOW_WEEKEND_LOGS = "actuals-show-weekend-logs";
const ACTUALS_DISABLE_TOOLTIP = "actuals-disable-tooltip";
const LOGGED_OUT = "tb-logged-out";
const LAST_ACTUALS_FILTERS = "actuals-filter-values";
const SAVED_PROJECT = "saved-project";
const SAVED_TIME_OFF = "saved-time-off";
const SAVED_PROJECT_TYPE = "saved-project-type";
const USERS_PAGE_FILTERS = "users-page-filter-values";
const PROJECTS_PAGE_FILTERS = "projects-page-filter-values";
const USERS_SORT_FIELD = "users-sort-field";
const USERS_SORT_ORDER = "users-sort-order";
const PROJECTS_SORT_FIELD = "projects-sort-field";
const PROJECTS_SORT_ORDER = "projects-sort-order";
const REPORTING_ACTUALS_OVERVIEW_FILTER = "reporting-actuals-overview-filter";
const LATEST_WHATS_NEW = "tb-latest-whats-new-viewed";

const lsKeys = {
  LAST_TEAMID_KEY,
  LAST_PLANNER_TYPE,
  LAST_TEAMS_ZOOM_KEY,
  WEEKENDS_HIDDEN_KEY,
  TOOLTIP_HIDDEN,
  USER_ORDER,
  BOOKING_FORMAT,
  LAST_PLANNER_FILTERS,
  LAST_GROUPED_PLANNER_FILTERS,
  LAST_CAPACITY_USERS_FILTERS,
  LAST_CAPACITY_PROJECTS_FILTERS,
  LAST_CAPACITY_OVERVIEW_FILTERS,
  LAST_REPORTING_FILTERS,
  ACTUALS_MODAL,
  ROLE,
  LANGUAGE,
  AUTH_TOKEN,
  PLANNER_HIDE_OTHERS,
  LAST_ACTUAL_DASHBOARD_DATE,
  LAST_CAPACITY_ZOOM,
  GROUP_CLIENTS_PLANNER,
  DASHBOARD_GRAPH_ZOOM,
  PROJECTS_COLUMNS_DISPLAY,
  USERS_COLUMNS_DISPLAY,
  GTM_MOVE_ACTION,
  THEME,
  ONBOARDING_OPENED,
  CAPACITY_ONBOARDING_OPENED,
  REPORTING_USERS_FILTER,
  REPORTING_PROJECTS_FILTER,
  REPORTING_CAPACITY_PROJECTS_FILTER,
  REPORTING_CAPACITY_USERS_FILTER,
  REPORTING_CAPACITY_TAGS_FILTER,
  REPORTING_COMPARISON_PROJECTS_FILTER,
  REPORTING_COMPARISON_USERS_FILTER,
  REPORTING_COMPARISON_TASKS_FILTER,
  REPORTING_OVERVIEW_FILTER,
  REPORTING_SAT_OVERVIEW_FILTER,
  LAST_VISITED_PAGE,
  LAST_OPENED_DASHBOARD,
  ACTUALS_ONBOARDING_OPENED,
  DASHBOARD_RANGE,
  DASHBOARD_FROM,
  BANNER_CLOSED_BY_USER,
  STAGING_SETTINGS_ENABLED,
  SETTINGS_POS,
  ACTUALS_ZOOM,
  ACTUALS_SHOW_WEEKEND_LOGS,
  ACTUALS_DISABLE_TOOLTIP,
  LOGGED_OUT,
  LAST_ACTUALS_FILTERS,
  REPORTING_ACTUALS_OVERVIEW_FILTER,
  REPORTING_ACTUALS_USER_FILTER,
  REPORTING_ACTUALS_PROJECT_FILTER,
  SAVED_PROJECT,
  SAVED_TIME_OFF,
  SAVED_PROJECT_TYPE,
  USERS_PAGE_FILTERS,
  PROJECTS_PAGE_FILTERS,
  USERS_SORT_FIELD,
  USERS_SORT_ORDER,
  PROJECTS_SORT_FIELD,
  PROJECTS_SORT_ORDER,
  LATEST_WHATS_NEW,
};

export default lsKeys;

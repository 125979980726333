import React from "react";
import { useTranslation } from "react-i18next";

const NoTeamsPlanner = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="planner-error__container row">
        <div className="col-md-4 offset-md-4 col-xs-12">
          <p>{t("planning.no_teams_text_1")}</p>
          <p>{t("planning.no_teams_text_2")}</p>
        </div>
      </div>
    </>
  );
};

export default NoTeamsPlanner;

import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Api from "../../../../Api";
import TeambookTextForm from "../../../default_components/TeambookTextForm";
import TeambookBlueButton from "../../../default_components/TeambookBlueButton";
import FormErrorMessage from "../../../default_components/FormErrorMessage";
import GetAllMilestones from "../../requests/GetAllMilestones";
import moment from "moment";
import "moment/locale/de";
import "moment/locale/fr";
import { getUtcTime, ToDateString } from "../../../../lib/DateUtils";
import { DialogTitle } from "@mui/material";
import lsKeys from "../../../default_values/defaultKeys";
import TeambookOutlinedButton from "../../../default_components/TeambookOutlinedButton";
import { icons } from "../../../default_images/IconsList";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { DateTime } from "luxon";
import MuiDatePicker from "../../../default_components/MuiDatePicker";
import { shallow } from "zustand/shallow";
import { useAccountStore } from "../../../../stores/accountStore";

const MilestoneEditing = ({ milestone, setEditingMilestone, setMilestones }) => {
  const { t } = useTranslation();

  const canEditMilestone = !["regular", "self_planner", "contractor"].includes(localStorage.getItem(lsKeys.ROLE));
  const [milestoneName, setMilestoneName] = useState(milestone.name);
  const [milestoneStartDate, setMilestoneStartDate] = useState(DateTime.fromISO(milestone.start_date));
  const [milestoneEndDate, setMilestoneEndDate] = useState(DateTime.fromISO(milestone.end_date));
  const [errorMessage, setErrorMessage] = useState(null);
  const isCorrectDates = milestoneStartDate <= milestoneEndDate;

  const [account] = useAccountStore((state) => [state.account], shallow);

  if (account) {
    moment.updateLocale("en", {
      week: {
        dow: account.first_week_day === "Monday" ? 1 : 0,
      },
    });
  }

  const updateMilestone = () => {
    if (isCorrectDates) {
      setErrorMessage(null);

      if (milestoneName.length < 3 || milestoneName.length > 30) {
        setErrorMessage("Milestone name must be 3 to 30 characters long.");
      } else {
        Api.Milestones.update({
          id: milestone.id,
          name: milestoneName,
          start_date: milestoneStartDate,
          end_date: milestoneEndDate,
        })
          .then(() => {
            GetAllMilestones(setMilestones);
            setEditingMilestone(null);
          })
          .catch((error) => {
            setErrorMessage(error.response.data.error);
          });
      }
    }
  };

  const deleteMilestone = () => {
    Api.Milestones.delete(milestone.id).then(() => {
      GetAllMilestones(setMilestones);
      setEditingMilestone(null);
    });
  };

  return (
    <Dialog
      open={true}
      onClose={() => {
        setEditingMilestone(null);
      }}
      aria-labelledby="deactivation-dialog"
      fullWidth={true}
      className="tb-default-dialog"
    >
      <DialogTitle id="add-user-dialog">
        <p>{t("planning.milestones.edit")}</p>
        <TeambookIcon
          name={icons.CROSS}
          onClick={() => {
            setEditingMilestone(null);
          }}
        />
      </DialogTitle>
      <DialogContent style={{ margin: "20px 0px", width: "600px !important" }}>
        <div className="milestone-editing__editing">
          <TeambookTextForm
            disabled={!canEditMilestone}
            id="editingMilestoneName"
            fieldName={t("planning.milestones.name")}
            onChange={(text) => setMilestoneName(text)}
            type={"text"}
            fieldValue={milestoneName}
            width={"45%"}
            placeholder={"Milestone #1"}
            style={{ marginBottom: "30px" }}
          />
        </div>

        <div className="milestone-editing__info">
          <div className="milestone-editing__textfield">
            <div className="milestone-date-picker__container">
              <p>{`${t("projects.start_date")}:`}</p>
              <MuiDatePicker
                disabled={!canEditMilestone}
                format={"d MMM, yyyy"}
                value={milestoneStartDate}
                onChange={(date) => setMilestoneStartDate(date)}
                sx={{ width: "150px", input: { padding: "0px" } }}
                className="mui-date-picker"
              />
            </div>

            <div className="milestone-date-picker__container">
              <p>{`${t("projects.end_date")}:`}</p>
              <MuiDatePicker
                minDate={milestoneStartDate}
                disabled={!canEditMilestone}
                format={"d MMM, yyyy"}
                value={milestoneEndDate}
                onChange={(date) => setMilestoneEndDate(date)}
                sx={{ width: "150px", input: { padding: "0px" } }}
                className="mui-date-picker"
              />
            </div>
          </div>
        </div>

        {errorMessage !== null ? <FormErrorMessage text={errorMessage} closeErrorMessage={() => setErrorMessage(null)} /> : null}
      </DialogContent>
      <DialogActions>
        {canEditMilestone && (
          <>
            <TeambookBlueButton text={t("save")} disabled={!isCorrectDates} onClick={updateMilestone} />

            <TeambookOutlinedButton text={t("delete")} onClick={deleteMilestone} />
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default MilestoneEditing;

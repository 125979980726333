import React, { useEffect, useState } from "react";
import Api from "../../../Api";
import Header from "../../header/Header";
import ReportingControlBar from "./ReportingControlBar";
import ReportingTable from "./planning_and_actuals/by_entity/ReportingTable";
import LoadingComponent from "../../default_components/LoadingComponent";
import OverviewPage from "./planning_and_actuals/overview/OverviewPage";
import { alphabeticalSort } from "./lib/alphabeticalSort.js";
import SatOverviewPage from "./planning_and_actuals/satriun_overview/SatOverviewPage";
import { DateTime } from "luxon";
import { useBookingsCreatingStore, useTeambookFilter } from "../../../stores/planner";
import { useLocation, useSearchParams } from "react-router-dom";
import ReportingTags from "./capacity/reporting_users/reporting_tags/ReportingTags";
import lsKeys from "../../default_values/defaultKeys";
import { ReportingContext } from "./lib/ReportingContext";
import ReportingProjects from "./capacity/reporting_projects/ReportingProjects";
import ReportingUsers from "./capacity/reporting_users/ReportingUsers";
import CompareReporting from "./comparison/compare_reporting/CompareReporting";
import { sortDataBySurname } from "./lib/sortDataBySurname";
import { useTranslation } from "react-i18next";
import { useDashboardStore } from "../../../stores/dashboard";
import { shallow } from "zustand/shallow";
import { useAccountStore } from "../../../stores/accountStore";
import ActualsOverviewPage from "./planning_and_actuals/overview/ActualsOverviewPage";

const Reporting = ({ type = "default" }) => {
  const { t } = useTranslation();

  const [stats, setStats] = useState();
  const [from, setFrom] = useState({
    start: DateTime.now().startOf("month"),
    end: DateTime.now().endOf("month"),
  });
  const [selectedTeams, setSelectedTeams] = useState();
  const [allTeams, setAllTeams] = useState();
  const [reportingPage, setReportingPage] = useState(useDashboardStore.getState().reportingType || type);
  const [splitBy, setSplitBy] = useState(useDashboardStore.getState().reportingSplitBy || "project");
  const [sortOrder, setSortOrder] = useState("a");
  const [activeUsers, setActiveUsers] = useState([]);
  const [activeProjects, setActiveProjects] = useState([]);
  const [clients, setClients] = useState([]);
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(true);
  const [allProjects, setAllProjects] = useState();
  const [slaveDataType, setSlaveDataType] = useState("default");
  const [tasks, setTasks] = useState([]);
  const [range, setRange] = useState(1);
  const [selectedViewType, setSelectedViewType] = useState(
    parseInt(localStorage.getItem(lsKeys.LAST_CAPACITY_ZOOM)) || 12
  );
  const [account] = useAccountStore((state) => [state.account], shallow);

  const { filterValues } = useTeambookFilter();

  useEffect(() => {
    const scrollElement =
      document.querySelector(".reporting-capacity__component") || document.querySelector(".reporting__table-content");

    if (scrollElement) {
      scrollElement.scrollTo({ top: 0 });
    }
  }, [filterValues]);

  useEffect(() => {
    Api.Teams.all().then((res) => {
      setAllTeams(res.data);
      setSelectedTeams(res.data);
    });

    Api.Users.active().then((res) => {
      setActiveUsers(res.data);
    });

    Api.Projects.minimal().then((res) => {
      setActiveProjects(res.data.filter((pr) => pr.active));
      setAllProjects(res.data);
    });

    Api.Tags.all().then((res) => {
      setTags(res.data);
    });

    Api.Clients.all().then((res) => {
      setClients(res.data);
    });

    Api.Tasks.get().then((res) => {
      setTasks(res.data);
    });
  }, []);

  useEffect(() => {
    const teamIds = filterValues
      .filter((value) => value.type === t("planning.filter_teams"))
      .map((r) => {
        return { id: r.id };
      });

    const selectedIds = selectedTeams?.map(({ id }) => id);

    if (teamIds.length > 0) {
      selectedIds?.toString() !== teamIds.map(({ id }) => id)?.toString() && setSelectedTeams(teamIds);
    } else {
      selectedIds?.toString() !== allTeams?.map(({ id }) => id)?.toString() && setSelectedTeams(allTeams);
    }
  }, [filterValues, allTeams]);

  useEffect(() => {
    if (reportingPage === "capacity" || reportingPage === "comparison") {
      setLoading(false);
    }

    if (from.start && selectedTeams && reportingPage !== "capacity" && reportingPage !== "comparison") {
      Api.Dashboard.split(
        from.start.toISODate(),
        from.end.toISODate(),
        selectedTeams.map(({ id }) => id),
        splitBy,
        reportingPage,
        slaveDataType
      ).then((res) => {
        if (splitBy === "project" || splitBy === "user") {
          sortData({ ...res.data });
        } else {
          setStats(res.data);
        }
        setLoading(false);
      });
    }
  }, [from, selectedTeams, splitBy, slaveDataType]);

  useEffect(() => {
    if (!loading && stats) {
      sortData(stats);
    }
  }, [splitBy, sortOrder, loading]);

  const calculateCapacityCellWidth = () => {
    if (selectedViewType === 6) {
      return 180;
    } else {
      return 80;
    }
  };

  const dataPage = (splitBy) => {
    switch (splitBy) {
      case "overview":
        return <OverviewPage stats={stats} sortOrder={sortOrder} setSortOrder={setSortOrder} />;
      case "actuals_overview":
        return <ActualsOverviewPage stats={stats} sortOrder={sortOrder} setSortOrder={setSortOrder} />;
      case "sat_overview":
        return <SatOverviewPage stats={stats} />;

      case "capacity_user":
        return (
          <ReportingUsers
            date={from.start}
            users={activeUsers}
            tags={tags}
            projects={activeProjects}
            selectedViewType={selectedViewType}
            setSplitBy={setSplitBy}
          />
        );
      case "capacity_project":
        return (
          <ReportingProjects
            date={from.start}
            users={activeUsers}
            tags={tags}
            projects={activeProjects}
            selectedViewType={selectedViewType}
            clients={clients}
          />
        );
      case "capacity_tag":
        return (
          <ReportingTags
            date={from.start}
            users={activeUsers}
            tags={tags}
            projects={activeProjects}
            selectedViewType={selectedViewType}
            setSplitBy={setSplitBy}
          />
        );

      case "comparison_project":
      case "comparison_user":
      case "comparison_task":
        return (
          <CompareReporting
            from={from}
            splitBy={splitBy}
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
            projects={allProjects}
            selectedTeams={selectedTeams}
            alphabeticalSort={alphabeticalSort}
            isTimeOff={isTimeOff}
            setSplitBy={setSplitBy}
          />
        );

      default:
        return (
          stats && (
            <ReportingTable
              stats={stats}
              from={from.start}
              splitBy={splitBy}
              sortOrder={sortOrder}
              setSortOrder={setSortOrder}
              projects={allProjects}
              slaveDataType={slaveDataType}
              setSlaveDataType={setSlaveDataType}
            />
          )
        );
    }
  };

  const isTimeOff = (project) => project.kind === "time_off";

  const sortData = (statsData) => {
    if (statsData.users) {
      return;
    }

    switch (splitBy) {
      case "actuals_project":
      case "project":
        let time_off_projects = [];
        let non_time_off_projects = [];

        statsData.data.forEach((project) => {
          isTimeOff(project) ? time_off_projects.push(project) : non_time_off_projects.push(project);
        });

        setStats({
          ...statsData,
          data: [
            ...alphabeticalSort([...non_time_off_projects], sortOrder),
            ...alphabeticalSort([...time_off_projects], sortOrder),
          ].map((project) => {
            return {
              ...project,
              daily_entities: sortDataBySurname(project.daily_entities),
            };
          }),
        });

        break;
      case "actuals_user":
      case "user":
        setStats({
          ...statsData,
          data: sortDataBySurname([...statsData.data], sortOrder),
        });
        break;
      default:
        setStats(statsData);
    }
  };

  return (
    <div className="dashboard">
      <Header />

      {loading && <LoadingComponent />}

      {!loading && (
        <>
          <ReportingControlBar
            from={from}
            setFrom={setFrom}
            selectedTeams={selectedTeams}
            splitBy={splitBy}
            setSplitBy={setSplitBy}
            type={reportingPage}
            activeUsers={activeUsers}
            activeProjects={activeProjects}
            clients={clients}
            tags={tags}
            teams={allTeams}
            page={reportingPage}
            setSelectedViewType={setSelectedViewType}
            selectedViewType={selectedViewType}
            setReportingPage={setReportingPage}
            reporting_page={reportingPage}
            slaveDataType={slaveDataType}
            tasks={tasks}
            range={range}
            setRange={setRange}
          />

          {
            <ReportingContext.Provider value={{ cellWidth: calculateCapacityCellWidth() }}>
              {dataPage(splitBy)}
            </ReportingContext.Provider>
          }
        </>
      )}
    </div>
  );
};

export default Reporting;

import React from "react";
import "./my_week.scss";
import { defaultProjectIcons } from "../default_values/DefaultProjectIcons";
import { useTranslation } from "react-i18next";
import { WhiteTooltip } from "../default_components/Tooltips";

const BookingIcons = ({ booking, color, project }) => {
  const { t } = useTranslation();

  const linkToAnchor = (text) => {
    let urlRegex = /(?:(?:https?|ftp):\/\/)?[\w/\-?=%.]+\.[\w/\-&?=%.]+/gi;
    let httpRegex = /^(http|https):/;
    return (text = text.replace(
      urlRegex,
      (url) => `<a target='_blank' rel="noopener noreferrer" href=${httpRegex.test(url) ? url : `//${url}`}>${url}</a>`
    ));
  };

  return (
    <div className="booking__icons-container">
      {project.kind === "time_off" && (
        <WhiteTooltip title={t("planning.time_off")}>
          <div>{defaultProjectIcons(project.icon_id, booking.tentative ? "#7A7A7A" : "var(--background-1)", 0)}</div>
        </WhiteTooltip>
      )}

      {booking.repetition && (
        <WhiteTooltip title={t("planning.repetition")}>
          <p className="booking__repetition-text">R</p>
        </WhiteTooltip>
      )}

      {booking.location === 1 && (
        <WhiteTooltip title={t("planning.on_client_site")}>
          <div>{defaultProjectIcons(11, color, 0)}</div>
        </WhiteTooltip>
      )}

      {booking.location === 2 && (
        <WhiteTooltip title={t("planning.home_office")}>
          <div>{defaultProjectIcons(3, color, 0)}</div>
        </WhiteTooltip>
      )}

      {booking.comment.length > 0 && (
        <WhiteTooltip
          title={
            <span
              dangerouslySetInnerHTML={{
                __html: linkToAnchor(booking.comment),
              }}
            />
          }
        >
          {defaultProjectIcons(-3, color, 0)}
        </WhiteTooltip>
      )}

      {booking.using_budget === false && (
        <WhiteTooltip title={t("projects.non_billable")}>
          <div>{defaultProjectIcons(10, color, 0)}</div>
        </WhiteTooltip>
      )}

      {booking.start_time?.toString() && (
        <WhiteTooltip title={t("planning.start_time")}>
          <div className="booking__start-time">
            {defaultProjectIcons(-4, color, 0)}

            <p style={{ color }}>
              {Math.floor(booking.start_time / 100)}:{booking.start_time % 100 < 10 && "0"}
              {booking.start_time % 100}
            </p>
          </div>
        </WhiteTooltip>
      )}
    </div>
  );
};

export default BookingIcons;

import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Api from "../../../../Api";
import TeambookTextForm from "../../../default_components/TeambookTextForm";
import TeambookBlueButton from "../../../default_components/TeambookBlueButton";
import FormErrorMessage from "../../../default_components/FormErrorMessage";
import GetAllMilestones from "../../requests/GetAllMilestones";
import moment from "moment";
import { DialogTitle } from "@mui/material";
import { useMilestonesStore } from "../../../../stores/planner";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { icons } from "../../../default_images/IconsList";
import MuiDatePicker from "../../../default_components/MuiDatePicker";

const MilestoneCreation = ({ project, startDate, setStartDate, endDate, setEndDate, dialogOpened, closeDialog, setMilestones }) => {
  const { t } = useTranslation();

  const { creationOrder: order } = useMilestonesStore();

  const [milestoneName, setMilestoneName] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);

  const isCorrectDates = startDate < endDate?.plus({ days: 1 });

  moment.locale(t(localStorage.getItem("tb-lang")));

  const createMilestone = () => {
    if (isCorrectDates) {
      setErrorMessage(null);

      if (milestoneName.length < 3 || milestoneName.length > 30) {
        setErrorMessage("Milestone name must be 3 to 30 characters long.");
      } else {
        Api.Milestones.create({
          name: milestoneName,
          project_id: project.id,
          start_date: startDate,
          end_date: endDate,
          order: order,
        })
          .then(() => {
            GetAllMilestones(setMilestones);
            setMilestoneName("");
            closeDialog();
          })
          .catch((error) => {
            setErrorMessage(error.response.data.error);
          });
      }
    }
  };

  return (
    <Dialog
      open={dialogOpened}
      onClose={() => {
        closeDialog();
        setMilestoneName("");
        setErrorMessage(null);
      }}
      aria-labelledby="deactivation-dialog"
      fullWidth={true}
      maxWidth={"sm"}
      className="tb-default-dialog"
    >
      <DialogTitle id="add-user-dialog">
        <p>{t("planning.milestones.create")}</p>
        <TeambookIcon
          name={icons.CROSS}
          onClick={() => {
            closeDialog();
            setMilestoneName("");
            setErrorMessage(null);
          }}
        />
      </DialogTitle>
      <DialogContent style={{ margin: "20px 0px" }}>
        <div className="milestone-creating__creating">
          <TeambookTextForm
            id={"milestoneName"}
            fieldName={t("planning.milestones.name")}
            onChange={(text) => setMilestoneName(text)}
            type={"text"}
            fieldValue={milestoneName}
            width={"45%"}
            placeholder={"Milestone"}
            style={{ marginBottom: "30px" }}
          />
        </div>

        <div
          className="milestone-creating__info-milestone"
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <div className="milestone-creating__text-row">
            <p>
              {`${t("dashboard.project")}:`} <b style={{ marginLeft: "10px" }}>{`${project?.name}`}</b>
            </p>
            {project?.client_name && (
              <p>
                {`${t("projects.client")}:`} <b style={{ marginLeft: "10px" }}>{`${project?.client_name}`}</b>
              </p>
            )}
          </div>

          <div className="milestone-creating__text-row">
            <div className="milestone-date-picker__container">
              <p>{t("projects.start_date")}:</p>

              <MuiDatePicker
                format={"d MMM, yyyy"}
                value={startDate}
                onChange={(date) => setStartDate(date)}
                sx={{ width: "150px", input: { padding: "0px" } }}
                className="mui-date-picker"
              />
            </div>

            <div className="milestone-date-picker__container">
              <p>{t("projects.end_date")}:</p>

              <MuiDatePicker
                minDate={startDate}
                format={"d MMM, yyyy"}
                value={endDate}
                onChange={(date) => setEndDate(date)}
                sx={{ width: "150px", input: { padding: "0px" } }}
                className="mui-date-picker"
              />
            </div>
          </div>
        </div>

        {errorMessage !== null ? <FormErrorMessage text={errorMessage} closeErrorMessage={() => setErrorMessage(null)} /> : null}
      </DialogContent>
      <DialogActions className="milestone-creating__actions">
        <TeambookBlueButton id="createMilestoneButton" text={t("create")} disabled={!isCorrectDates} onClick={() => createMilestone()} />
      </DialogActions>
    </Dialog>
  );
};

export default MilestoneCreation;

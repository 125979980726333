import { useVirtualizer } from "@tanstack/react-virtual";
import { useCallback, useContext, useRef, useState, useEffect } from "react";
import { PlannerContext } from "../context/PlannerContext";
import GroupedDateRow from "./components/GroupedDateRow";
import { usersMaxScheduleTime } from "../../../lib/PlannerUtils";
import { _scrollPlannerToDate } from "../lib/plannerFunctions";
import $ from "jquery";
import { useZoomStore } from "../../../stores/planner";
import { shallow } from "zustand/shallow";

export const VirtualizedGroupedPlanner = ({
  rows,
  weekendsHidden,
  weekendStart,
  userOrder,
  changeOrder,
  users,
  cellSize,
  overscan,
  multipleSelect,
  groupClients,
}) => {
  const ref = useRef();
  const [isMiddleMouseDown, setIsMiddleMouseDown] = useState(false);
  const [zoom] = useZoomStore((state) => [state.zoom], shallow);
  const startPosition = useRef({
    x: 0,
    y: 0,
  });

  useEffect(() => {
    scrollPlannerToDate();
  }, []);

  const scrollPlannerToDate = () => {
    if ($("#planner-scroll-list")?.children?.length) {
      _scrollPlannerToDate(zoom, weekendsHidden);
    }
  };

  const virtualizer = useVirtualizer({
    count: rows.length,
    paddingEnd: 100,
    overscan: overscan,
    getScrollElement: () => ref.current,
    estimateSize: useCallback((index) => {
      let user = users.find((user) => user.id === rows[index].props?.user?.id);

      if (user) {
        return (usersMaxScheduleTime(user) / 60) * cellSize + 17;
      } else {
        return 50;
      }
    }),
  });

  const handleMiddleMouseDown = (e) => {
    if (e.button === 1) {
      setIsMiddleMouseDown(true);
      startPosition.current = {
        x: e.clientX,
        y: e.clientY,
      };
      e.preventDefault();
    }
  };

  const handleMouseMove = (e) => {
    if (isMiddleMouseDown) {
      const deltaX = e.clientX - startPosition.current.x;
      const deltaY = e.clientY - startPosition.current.y;

      ref.current.scrollBy(-deltaX, -deltaY);

      startPosition.current = {
        x: e.clientX,
        y: e.clientY,
      };
    }
  };

  const handleMiddleMouseUp = (e) => {
    if (e.button === 1) {
      setIsMiddleMouseDown(false);
    }
  };

  useEffect(() => {
    const handleMouseDown = (e) => {
      if (e.button === 1) {
        document.body.classList.add("grabbing-mode");
      }
    };

    const handleMouseUp = () => {
      document.body.classList.remove("grabbing-mode");
    };

    document.addEventListener("mousedown", handleMouseDown);
    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);

  return (
    <>
      <div
        className="planner-scroll-list"
        id="planner-scroll-list"
        ref={ref}
        style={{ width: "100%", height: "100%", overflow: "auto", cursor: isMiddleMouseDown ? "grabbing" : "auto" }}
        onMouseDown={handleMiddleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMiddleMouseUp}
        onMouseLeave={() => setIsMiddleMouseDown(false)}
      >
        <GroupedDateRow
          weekendsHidden={weekendsHidden}
          weekendStart={weekendStart}
          userOrder={userOrder}
          changeOrder={changeOrder}
          groupClients={groupClients}
        />

        <div
          style={{
            height: `${virtualizer.getTotalSize()}px`,
            width: "100%",
            position: "relative",
          }}
          ref={startPosition}
        >
          {multipleSelect}
          {virtualizer.getVirtualItems().map((virtualRow) => rows[virtualRow.index])}
        </div>
      </div>
    </>
  );
};

import React, { useEffect, useState } from "react";
import { useTeambookFilter } from "../../../../../stores/planner";
import { useTranslation } from "react-i18next";
import ProjectRow from "./ProjectRow";
import UserRows from "./UserRows";
import { useAccountStore } from "../../../../../stores/accountStore";
import { shallow } from "zustand/shallow";

const DataRows = ({ stats = [{}], splitBy, projects, slaveDataType }) => {
  const { t } = useTranslation();
  const [rows, setRows] = useState([]);
  const [account] = useAccountStore((state) => [state.account], shallow);

  const { filterValues } = useTeambookFilter();

  const startDate = new Date(stats?.start_date);

  useEffect(() => {
    const isProjects = Boolean(stats?.data?.[0]?.kind);
    let rowsArray = [];

    if (stats.data) {
      for (let i = 0; i < stats.data.length; i++) {
        if (filterValues.length === 0 || entityIsFiltered(i)) {
          rowsArray.push(
            <>
              <ProjectRow index={i} startDate={startDate} isProjects={isProjects} stats={stats} />

              <UserRows
                index={i}
                stats={stats}
                startDate={startDate}
                isProjects={isProjects}
                projects={projects}
                slaveDataType={slaveDataType}
              />
            </>
          );
        }
      }
    }

    setRows(rowsArray);
  }, [stats, filterValues, account]);

  const entityIsFiltered = (i) => {
    if (splitBy.includes("project")) {
      const filteredProjects = filterValues.filter((value) => value.type === t("planning.filter_projects"));

      if (filteredProjects.length > 0) {
        return filteredProjects.map((r) => r.id).includes(stats.data[i].id);
      } else {
        return true;
      }
    } else {
      const filteredUsers = filterValues.filter((value) => value.type === t("planning.filter_users"));

      if (filteredUsers.length > 0) {
        return filteredUsers.map((r) => r.id).includes(stats.data[i].id);
      } else {
        return true;
      }
    }
  };

  return <>{rows}</>;
};

export default DataRows;

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { TeambookCheckbox } from "../../default_components/TeambookCheckbox";
import lsKeys from "../../default_values/defaultKeys";
import { useAccountStore } from "../../../stores/accountStore";
import { shallow } from "zustand/shallow";

const SettingsLeftSide = ({ menuIndex, setMenuIndex }) => {
  const { t } = useTranslation();
  const [stagingSettingsEnabled, setStagingSettingsEnabled] = useState(
    localStorage.getItem(lsKeys.STAGING_SETTINGS_ENABLED) === "true"
  );

  const [account] = useAccountStore((state) => [state.account], shallow);

  const switchStagingSettings = () => {
    localStorage.setItem(lsKeys.STAGING_SETTINGS_ENABLED, !stagingSettingsEnabled);
    setStagingSettingsEnabled(!stagingSettingsEnabled);

    window.location.reload();
  };

  const PagesArray = [
    { name: t("settings.general_info.name") },
    { name: t("settings.time_off.name") },
    { name: t("settings.tasks.name") },
    { name: t("settings.schedule.name") },
    { name: t("settings.subscription.subscription_billing") },
    { name: t("settings.integration.name") },
    { name: t("settings.customization.name") },
    { name: t("settings.delete_account.name") },
  ];

  const renderPages = ({ name }, index) => {
    return (
      <div
        onClick={() => setMenuIndex(index)}
        className="settings-option"
        style={{
          backgroundColor: menuIndex === index ? "var(--stroke)" : "var(--background-1)",
        }}
        key={index}
      >
        <p style={{ color: menuIndex === index ? "var(--button-text-hover)" : "var(--text-2)" }}>{name}</p>
      </div>
    );
  };

  return (
    <div className="org-settings-main-container">
      <p className="profile__title">
        {t("settings.account_settings")}{" "}
        {process.env.REACT_APP_ENV !== "production" && process.env.REACT_APP_ENV !== "sandbox" ? account.id : ""}
        {process.env.REACT_APP_ENV !== "production" && process.env.REACT_APP_ENV !== "sandbox" ? (
          <div onClick={switchStagingSettings} className="staging-settings__checkbox pointer">
            <TeambookCheckbox checked={stagingSettingsEnabled} />
            <p style={{ margin: 0 }}>Enable Staging settings</p>
          </div>
        ) : (
          ""
        )}
      </p>

      <div className="org-settings-options">{PagesArray.map(renderPages)}</div>
    </div>
  );
};

export default SettingsLeftSide;

import { useState, useRef, memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import TeambookOutlinedButton from "./TeambookOutlinedButton";
import { shallow } from "zustand/shallow";
import { TeambookBanner } from "./TeambookBanner";
import { useAccountStore } from "../../stores/accountStore";

const FREE_BANNER_CLOSED_BY_USER = "free-banner-closed-by-user";
const FREE_BANNER_LAST_SHOW = "free-banner-last-show";

function getQuarter(date = new Date()) {
  return Math.floor(date.getMonth() / 3 + 1);
}

export const FreePlanClosingBanner = memo(({}) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  const [account] = useAccountStore((state) => [state.account], shallow);

  useEffect(() => {
    setShow(openModal());
  }, []);

  const bannerWasNotClosed = () => {
    return localStorage.getItem(FREE_BANNER_CLOSED_BY_USER) !== "true";
  };

  const closeBanner = () => {
    localStorage.setItem(FREE_BANNER_CLOSED_BY_USER, true);
    setShow(false);
  };

  const accountIsFree = () => {
    return account?.subscription?.subscription_id === "Free";
  };

  const openModal = () => {
    if (bannerWasNotClosed() && accountIsFree()) {
      return true;
    } else {
      return false;
    }
  };

  return <></>;

  return (
    <TeambookBanner
      bannerContent={
        <>
          <div
            style={{
              color: "#fff",
              fontSize: 18,
              margin: "0 auto",
              alignItems: "center",
              gap: 40,
            }}
          >
            <p style={{ margin: 0, marginBottom: "10px" }}>
              {t("auth.banner_end_of_free")}&nbsp;
              <a href="/settings?page=subscription" style={{ color: "white", textDecoration: "underline" }}>
                {t("planning.onboarding.header_8")}
              </a>
            </p>

            <TeambookOutlinedButton text={t("auth.banner_understood")} onClick={closeBanner} />
          </div>
        </>
      }
      bannerOpened={show}
      className={"end-of-free-banner"}
    />
  );
});

import React, { useEffect, useState, useRef } from "react";
import "../../../default_styles/tb-colors.css";
import * as DefaultColors from "../../../default_values/DefaultColors";
import { useTranslation } from "react-i18next";
import FormErrorMessage from "../../../default_components/FormErrorMessage";
import { WhiteTooltip } from "./../../../default_components/Tooltips";
import TeambookColorPicker from "../../../default_components/TeambookColorPicker";
import TeambookTextForm from "../../../default_components/TeambookTextForm";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { icons } from "../../../default_images/IconsList";

const findFirstFreeColor = (tags) => {
  let availableColors = [...DefaultColors.projectColors];

  tags.forEach((tag) => {
    let indexOfElement = availableColors.indexOf(tag.color);

    if (indexOfElement !== -1) {
      availableColors.splice(indexOfElement, 1);
    }
  });

  if (availableColors.length > 0) {
    return availableColors[0];
  } else {
    return DefaultColors.projectColors[0];
  }
};

function CreateTag({ tags, setCreateOpen, createTag, tagName, setTagName, tagColor, setTagColor, errorMessage, closeErrorMessage }) {
  const { t } = useTranslation();
  const [changeColorsOpened, setChangeColorsOpened] = useState(false);
  const anchorRef = useRef(null);

  useEffect(() => {
    setTagColor(findFirstFreeColor(tags));
    setTagName("New Tag");
  }, []);

  const closeCreation = () => {
    setTagName("New Tag");
    setTagColor(findFirstFreeColor(tags));
    closeErrorMessage();
    setCreateOpen(false);
  };

  const changeName = (e) => {
    setTagName(e.target.value);
  };
  const handleFocus = (event) => {
    event.target.select();
  };

  return (
    <div>
      <div className="tag-menu__tag-editing">
        <div style={{ minHeight: "60px" }} className="row tag-menu__tag-add">
          <div className="col-md-3">
            <WhiteTooltip title={t("projects.change_color")}>
              <div
                className="tags-color-circle"
                style={{ backgroundColor: tagColor, cursor: "pointer" }}
                onClick={() => setChangeColorsOpened(true)}
                ref={anchorRef}
              />
            </WhiteTooltip>
          </div>

          <TeambookColorPicker
            color={tagColor}
            setColor={setTagColor}
            changeColorsOpened={changeColorsOpened}
            setChangeColorsOpened={setChangeColorsOpened}
            anchorRef={anchorRef}
          />

          <div className="col-md-3">
            <TeambookTextForm id={"tagName"} fieldValue={tagName} onChange={setTagName} autoFocus />
          </div>

          <div style={{ padding: "0px", justifyContent: "center" }} className="col-md-3 row">
            <p style={{ marginBottom: "0px" }}>0</p>
          </div>

          <div
            style={{
              marginLeft: "auto",
              display: "flex",
              gap: 15,
              justifyContent: "flex-end",
              alignItems: "center",
            }}
            className="col-md-3"
          >
            <TeambookIcon
              id={"createTag"}
              name={icons.CHECK}
              alt={t("save")}
              width
              onClick={createTag}
              tooltipTitle={t("save")}
              className={"manage-team-icon"}
              style={{ cursor: "pointer" }}
              color="alternative_default"
            />
            <TeambookIcon
              alt={t("cancel")}
              onClick={closeCreation}
              name={icons.CROSS}
              tooltipTitle={t("cancel")}
              className={"manage-team-icon"}
              style={{ cursor: "pointer" }}
              color="alternative_default"
            />
          </div>
        </div>

        {errorMessage !== null && (
          <div className="col-md-12">
            <FormErrorMessage text={errorMessage} closeErrorMessage={closeErrorMessage} />
          </div>
        )}
      </div>
    </div>
  );
}

export default CreateTag;

import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import ProjectSelect from "../../ProjectSelect";
import { WhiteTooltip } from "../../../default_components/Tooltips";
import RichTextEditor from "../../../default_components/rich_text_editor/RichTextEditor";
import Slider from "@mui/material/Slider";
import BookingRepetition from "../BookingRepetition";
import "moment/locale/de";
import "moment/locale/it";
import "moment/locale/fr";
import HolidayInfo from "./HolidayInfo.js";
import { useBookingsCreatingStore, useSelectedProjectStore, useZoomStore } from "../../../../stores/planner";
import ProjectBudget from "../../../default_components/ProjectBudget";
import TeambookBlueButton from "../../../default_components/TeambookBlueButton";
import TeambookOutlinedButton from "../../../default_components/TeambookOutlinedButton";
import { ClickAwayListener } from "@mui/base";
import { shallow } from "zustand/shallow";
import { InputNumber } from "../../../default_components/InputNumber";
import { TeambookCheckbox } from "../../../default_components/TeambookCheckbox";
import lsKeys from "../../../default_values/defaultKeys";
import { NavLink } from "react-router-dom";
import { DateTime } from "luxon";
import { TeambookReactSelect } from "../../../default_components/TeambookReactSelect";
import Api from "../../../../Api";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { icons } from "../../../default_images/IconsList";
import { isWeekend } from "date-fns";
import { useAccountStore } from "../../../../stores/accountStore";
import { StartTimeBlock, parseStringTimeIntoInteger, calculateStartTime } from "./StartTimeBlock";
import { PlannerContext } from "../../context/PlannerContext";

const CreateWindow = ({
  projects,
  closeCreateBookings,
  createBookingsRequest,
  createBookingsRef,
  taskArray,
  setTaskArray,
  creationBookings,
}) => {
  const { t } = useTranslation();
  const role = localStorage.getItem("tb-role") || "regular";
  const editorRef = useRef();
  const { filteredUsers } = useContext(PlannerContext);

  const [zoom] = useZoomStore((state) => [state.zoom], shallow);

  const [createWindowOpened, bookingsDuration, setBookigsDuration, dayViewStartTime, bookingsCreating] =
    useBookingsCreatingStore(
      (state) => [
        state.createWindowOpened,
        state.bookingsDuration,
        state.setDuration,
        state.dayViewStartTime,
        state.bookingsCreating,
      ],
      shallow
    );

  const { selectedProject, selectedTimeOff, setSelectedProject, setSelectedTimeOff, projectsType, setProjectsType } =
    useSelectedProjectStore();

  const [account] = useAccountStore((state) => [state.account], shallow);

  const [tentative, setTentative] = useState(false);
  const [repetitionWindowOpened, setRepetitionWindowOpened] = useState(false);
  const [repetitionType, setRepetitionType] = useState(null);
  const [repetitionEvery, setRepetitionEvery] = useState({
    value: 1,
    label: 1,
  });
  const [repetitionEndsAt, setRepetitionEndsAt] = useState(false);
  const [repetitionEndsAtDate, setRepetitionEndsAtDate] = useState(new Date());
  const [repetitionEndsAfter, setRepetitionEndsAfter] = useState(true);
  const [repetitionEndsAfterNumber, setRepetitionEndsAfterNumber] = useState(5);
  const [repetitionIgnoreWeekends, setRepetitionIgnoreWeekends] = useState(true);
  const [saveButtonClicked, setSaveButtonClicked] = useState(false);
  const [startTime, setStartTime] = useState();
  const [startTimeWindowOpened, setStartTimeWindowOpened] = useState(false);
  const [location, setLocation] = useState(0);
  const [usesBudget, setUsesBudget] = useState(true);
  const [creationBookingsArray, setCreationBookingsArray] = useState([]);
  const [task, setTask] = useState();

  const hideTimeOffOptions = projectsType === "time_off" && !projects.some((pr) => pr.kind === "time_off");

  useEffect(() => {
    setStartTime(calculateStartTime(account, createBookingsRef));
  }, [createWindowOpened]);

  useEffect(() => {
    if (createWindowOpened) {
      setRepetitionEndsAtDate((pr) => {
        if (localStorage.getItem(lsKeys.LAST_PLANNER_TYPE) === "planners") {
          return createBookingsRef?.current?.find((ref) => ref?.bookings?.length > 0)?.bookings?.[0]?.[1] || new Date();
        } else {
          return bookingsCreating[0]?.[1] || new Date();
        }
      });
    }
  }, [createWindowOpened, repetitionWindowOpened]);

  useEffect(() => {
    if (!projects.find((pr) => selectedProject?.id === pr.id)) {
      setSelectedProject(projects.find((pr) => pr.active && pr.kind === selectedProject?.kind) || projects[0]);
    }
  }, [createWindowOpened]);

  useEffect(() => {
    if (localStorage.getItem(lsKeys.LAST_PLANNER_TYPE) === "planners") {
      setCreationBookingsArray(
        createBookingsRef?.current
          ?.filter((ref) => ref?.bookings?.length)
          .map((ref) => {
            if (ref.bookings.length) {
              return ref.bookings;
            }
          })
          .flat()
      );
    } else {
      setCreationBookingsArray(bookingsCreating);
    }
  }, [createWindowOpened, bookingsCreating]);

  useEffect(() => {
    setRepetitionEvery({ value: 1, label: 1 });
  }, [repetitionWindowOpened]);

  useEffect(() => {
    if (zoom === 1) {
      setStartTime(dayViewStartTime);
      setStartTimeWindowOpened(true);
    }
  }, [dayViewStartTime]);

  useEffect(() => {
    if (createWindowOpened) {
      document.getElementById("create-window").focus();
    } else {
      setSaveButtonClicked(false);
      resetToDefaultValues();
    }
  }, [createWindowOpened]);

  useEffect(() => {
    // setRepetitionType(null);
  }, [bookingsCreating]);

  const setDuration = (new_duration) => {
    createBookingsRef.current.forEach((ref) => {
      ref.updateDuration(new_duration);
    });

    setBookigsDuration(new_duration);
  };

  const onKeyPress = (e) => {
    if (e.key === "Escape") {
      closeCreateBookings();
    }
  };

  if (account) {
    moment.updateLocale("en", {
      week: {
        dow: account.first_week_day === "Monday" ? 1 : 0,
      },
    });
  }

  const sliderMarks = [
    {
      value: 0,
    },
    {
      value: 1,
    },
    {
      value: 2,
    },
  ];

  const resetToDefaultValues = () => {
    setTentative(false);
    setProjectsType(localStorage.getItem(lsKeys.SAVED_PROJECT_TYPE) || "default");
    setRepetitionWindowOpened(false);
    setRepetitionType(null);
    setRepetitionEvery({
      value: 1,
      label: 1,
    });
    setRepetitionEndsAt(false);
    setRepetitionEndsAtDate(new Date());
    setRepetitionEndsAfter(true);
    setRepetitionEndsAfterNumber(5);
    setRepetitionIgnoreWeekends(true);
    setStartTime(calculateStartTime(account, createBookingsRef));
    setStartTimeWindowOpened(false);
    setLocation(0);
    setUsesBudget(true);
    setTask(null);
  };

  const createBookingsClick = () => {
    const weekendsHidden = localStorage.getItem(lsKeys.WEEKENDS_HIDDEN_KEY) === "true";
    const weekendCreating =
      creationBookingsArray.length === 1 && weekendsHidden && isWeekend(creationBookingsArray[0][1]);

    if (saveButtonClicked || ["contractor", "regular"].includes(role)) {
      return;
    }

    if (account.subscription.subscription_id === "Free" && account.subscription.projects_available === 10) {
      closeCreateBookings();
    }

    setSaveButtonClicked(true);

    createBookingsRequest(
      projectsType === "default" ? selectedProject : selectedTimeOff,
      bookingsDuration,
      tentative,
      startTimeWindowOpened ? calculateIntegerStartTime(startTime) : null,
      editorRef.current.getComment() === "<p></p>" ? "" : editorRef.current.getComment(),
      location,
      {
        repetitionType: repetitionType,
        repetitionEvery: repetitionEvery.value || 1,
        repetitionEndsAt: repetitionEndsAt,
        repetitionEndsAtDate: repetitionEndsAtDate,
        repetitionEndsAfter: repetitionEndsAfter,
        repetitionEndsAfterNumber: repetitionEndsAfterNumber,
        ignoreWeekends: repetitionIgnoreWeekends,
      },
      usesBudget,
      task,
      weekendCreating
    );

    closeCreateBookings();
  };

  const clickedOutsideOfPlanner = (e) => {
    const n = e?.target?.className ?? "";
    if (typeof n !== "string") return false;

    return (
      !e?.target?.className?.split(" ").includes("booking") &&
      e.target.className !== "creation-booking" &&
      e.target.className !== "date-row__date-text" &&
      !Boolean(e.target.closest(".user-row"))
    );
  };

  const calculateIntegerStartTime = (time) => {
    return parseStringTimeIntoInteger(time);
  };

  const allowBookingRepetition = () => {
    const userDates = {};
    let result = true;

    creationBookingsArray.forEach((booking) => {
      userDates[booking[0]] ||= [];

      userDates[booking[0]].push(DateTime.fromJSDate(booking[1]).weekNumber);
    });

    Object.values(userDates).forEach((weekNumbers) => {
      if (weekNumbers.reduce((ps, a) => ps + a, 0) / weekNumbers[0] !== weekNumbers.length) {
        result = false;
      }
    });

    return result;
  };

  const selectDefaultProjects = () => {
    let activeProjects = projects.filter((project) => project.active && project.kind !== "time_off");

    if (activeProjects.length > 0) {
      let projectToSelect = activeProjects[0];

      setProjectsType("default");
      setDuration(account.default_booking_duration / 60);

      if (selectedProject === null || selectedProject.kind !== "default") {
        setSelectedProject(projectToSelect);
      }
    } else {
      alert("No Active projects found!");
    }
  };

  const selectTimeOffProjects = () => {
    setProjectsType("time_off");
    const schedule = account.schedule;
    const date = createBookingsRef.current.filter((ref) => ref.bookings.length)?.[0]?.bookings?.[0]?.[1];

    if (date) {
      const duration = (schedule[date.getDay()][0] + schedule[date.getDay()][2]) / 60;

      setDuration(duration || 4);
    } else {
      setDuration(8);
    }

    let projectToSelect = projects.filter((project) => project.active && project.kind === "time_off")[0];

    if (selectedTimeOff === null || selectedProject.kind !== "time_off") {
      setSelectedTimeOff(projectToSelect);
    }
  };

  const taskOptions = () => {
    const newTaskOptions = taskArray.filter(
      (t) =>
        t.project_id === null || t.project_id === (projectsType === "default" ? selectedProject : selectedTimeOff)?.id
    );

    if (newTaskOptions.length > 0) newTaskOptions.push({ name: "No Task", id: null, project_id: null });

    return newTaskOptions;
  };

  if (projects?.size === 0 || !createWindowOpened) {
    return <></>;
  }

  return (
    <ClickAwayListener
      onClickAway={(e) => {
        if (clickedOutsideOfPlanner(e)) {
          closeCreateBookings();
        }
      }}
    >
      <div className="booking-window" tabIndex="0" onKeyDown={onKeyPress} id="create-window">
        <div className="booking-window__header">
          <p> {t("planning.create_booking")}</p>
        </div>

        {hideTimeOffOptions ? (
          <div className="booking-window__empty_timeoffs">
            <p>
              {t("planning.no_timeoffs_1")}{" "}
              <NavLink
                to={{
                  pathname: "/settings",
                  search: "?page=time_offs",
                }}
              >
                {t("planning.time_off_link")}
              </NavLink>{" "}
              {t("planning.no_timeoffs_2")}
            </p>
          </div>
        ) : (
          <div className="booking-window__booking-data-block">
            <div className="booking-window__select-component">
              <div
                className={`booking-window__project-type-option ${projectsType === "default" ? "option-active" : ""}`}
                onClick={selectDefaultProjects}
              >
                <TeambookIcon color="darkblue_new" name={icons.PROJECT} />
                <p>{t("planning.project")}</p>
              </div>

              <div
                className={`booking-window__project-type-option ${projectsType === "time_off" ? "option-active" : ""}`}
                onClick={selectTimeOffProjects}
              >
                <TeambookIcon color="darkblue_new" name={icons.LEAVE} />
                <p>{t("planning.time_off")}</p>
              </div>
            </div>

            <div className="row">
              <ProjectSelect
                projects={projects}
                projectsType={projectsType}
                creationUsers={creationBookings()
                  .map((user) => filteredUsers[user[0]]?.id)
                  .filter(Number)}
              />
            </div>

            {projectsType === "time_off" && <HolidayInfo />}

            {projectsType === "default" &&
              (selectedProject?.estimated > 0 || selectedProject?.actual_logs_duration > 0) && (
                <div className="row booking-window__project-estimation">
                  <ProjectBudget project={selectedProject} actuals={true} />
                </div>
              )}

            <div className="row booking-window__data-element">
              <p className="col-md-8 booking-window__data-element-text">{t("planning.duration_hours")}</p>

              <InputNumber
                max={24}
                min={0.25}
                step={0.5}
                setValue={setDuration}
                value={bookingsDuration}
                roundValue={{
                  isRoundValue: true,
                  roundStep: 4,
                }}
              />
            </div>

            <div className="booking-window__grouped-data-element">
              <div className="row" style={{ justifyContent: "space-between" }}>
                <TeambookIcon
                  tooltipTitle={t("planning.at_office")}
                  onClick={() => setLocation(0)}
                  name={icons.AT_WORK_NEW}
                  placement="top"
                  style={{ opacity: location === 0 ? 1 : 0.3 }}
                />

                <TeambookIcon
                  id={"atClientSieBooking"}
                  alt={"on client"}
                  onClick={() => setLocation(1)}
                  name={icons.PLACE_NEW}
                  tooltipTitle={t("planning.on_client_site")}
                  placement="top"
                  style={{ opacity: location === 1 ? 1 : 0.3 }}
                />

                <TeambookIcon
                  alt={"home office"}
                  onClick={() => {
                    setLocation(2);
                  }}
                  name={icons.HOME_NEW}
                  tooltipTitle={t("planning.home_office")}
                  placement="top"
                  style={{ opacity: location === 2 ? 1 : 0.3 }}
                />
              </div>

              <div className="row locationPicker" style={{ padding: "0 12px" }}>
                <Slider
                  track={false}
                  max={2}
                  value={location}
                  aria-labelledby="discrete-slider-restrict"
                  step={null}
                  onChangeCommitted={(event, val) => setLocation(val)}
                  marks={sliderMarks}
                />
              </div>
            </div>

            <div style={{ marginTop: 16 }} className="row booking-window__grouped-data-element">
              <TeambookReactSelect
                fieldName={t("actuals.service")}
                value={taskArray.filter((subTask) => subTask.id === task && subTask.id)?.[0]}
                createOptionPosition={"first"}
                formatCreateLabel={(option) => `${t("projects.create_task")} ${option}`}
                onCreateOption={(option) => {
                  Api.Tasks.create({
                    project_id: (projectsType === "time_off" ? selectedTimeOff : selectedProject).id,
                    name: option,
                  }).then((res) => {
                    setTaskArray([...taskArray, res.data]);
                    setTask(res.data.id);
                  });
                }}
                onChange={(task) => {
                  setTask(task.value);
                }}
                isValidNewOption={(option) => ["admin", "planner", "self_planner"].includes(role) && option.length > 2}
                options={taskOptions().map((t) => ({ value: t.id, name: t.name }))}
                isSearchable
                nameStyles={{ fontSize: 14, textAlign: "left" }}
                grayPlaceholder
                placeholder={taskOptions().length === 0 ? t("planning.no_tasks") : "Select Booking Task"}
              />
            </div>

            <div style={{ marginTop: 16 }} className="row booking-window__grouped-data-element">
              <div className="row checkbox">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TeambookCheckbox
                    checked={tentative}
                    onChange={(event) => {
                      setTentative(!tentative);
                    }}
                    value={tentative}
                    color="primary"
                  />

                  <p className="booking-window__data-element-text" onClick={() => setTentative(!tentative)}>
                    {t("planning.tentative")}
                  </p>
                </div>

                {selectedProject?.kind === "billable" && (
                  <div style={{ display: "flex" }}>
                    <TeambookCheckbox
                      checked={!usesBudget}
                      onChange={(event) => {
                        setUsesBudget(!usesBudget);
                      }}
                      color="primary"
                    />

                    <WhiteTooltip title={t("planning.uses_budget_description")} placement="top">
                      <p className="booking-window__data-element-text" onClick={() => setUsesBudget(!usesBudget)}>
                        {t("planning.uses_budget")}
                      </p>
                    </WhiteTooltip>
                  </div>
                )}
              </div>

              <StartTimeBlock
                startTime={startTime}
                setStartTime={setStartTime}
                startTimeWindowOpened={startTimeWindowOpened}
                setStartTimeWindowOpened={setStartTimeWindowOpened}
                account={account}
                createBookingsRef={createBookingsRef}
                bookingsDuration={bookingsDuration}
              />

              {allowBookingRepetition() && (
                <div className="row checkbox">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <TeambookCheckbox
                      checked={repetitionWindowOpened}
                      onChange={() => {
                        if (repetitionWindowOpened) {
                          setRepetitionType(null);
                        } else {
                          setRepetitionType("daily");
                        }
                        setRepetitionWindowOpened(!repetitionWindowOpened);
                      }}
                    />
                    <p
                      onClick={() => {
                        if (repetitionWindowOpened) {
                          setRepetitionType(null);
                        } else {
                          setRepetitionType("daily");
                        }
                        setRepetitionWindowOpened(!repetitionWindowOpened);
                      }}
                      className="booking-window__data-element-text"
                    >
                      {t("planning.repetition")}
                    </p>
                  </div>
                </div>
              )}
            </div>

            {allowBookingRepetition() && repetitionWindowOpened && (
              <div className="booking-window__grouped-data-element">
                <BookingRepetition
                  repetitionType={repetitionType}
                  setRepetitionType={setRepetitionType}
                  repetitionEvery={repetitionEvery}
                  creationBookingsArray={creationBookingsArray}
                  setRepetitionEvery={setRepetitionEvery}
                  repetitionEndsAfter={repetitionEndsAfter}
                  setRepetitionEndsAfter={setRepetitionEndsAfter}
                  repetitionEndsAfterNumber={repetitionEndsAfterNumber}
                  setRepetitionEndsAt={setRepetitionEndsAt}
                  repetitionEndsAt={repetitionEndsAt}
                  setRepetitionEndsAfterNumber={setRepetitionEndsAfterNumber}
                  repetitionEndsAtDate={repetitionEndsAtDate}
                  setRepetitionEndsAtDate={setRepetitionEndsAtDate}
                  repetitionIgnoreWeekends={repetitionIgnoreWeekends}
                  setRepetitionIgnoreWeekends={setRepetitionIgnoreWeekends}
                />
              </div>
            )}

            <div style={{ marginTop: 16 }}>
              <div className="planner-editor">
                <RichTextEditor editorHeight={100} ref={editorRef} />
              </div>
            </div>
          </div>
        )}

        <div className="booking-window__control-buttons__create">
          <TeambookOutlinedButton
            text={t("cancel")}
            style={{ width: "100%" }}
            onClick={() => {
              closeCreateBookings();
            }}
          />

          <TeambookBlueButton
            onClick={createBookingsClick}
            variant="contained"
            disabled={saveButtonClicked || hideTimeOffOptions}
            text={t("create")}
            style={{ width: "100%" }}
          />
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default CreateWindow;

import React, { useEffect, useState } from "react";
import { isWeekend, OffsetDays } from "../../../../../lib/DateUtils";
import { DateTime } from "luxon";
import lsKeys from "../../../../default_values/defaultKeys";

const DayRow = ({ stats }) => {
  const [dayColumns, setDayColumns] = useState([]);
  const lang = localStorage.getItem(lsKeys.LANGUAGE);

  useEffect(() => {
    let columns = [];
    let startDate = new Date(stats.planningData.start_date);
    let currentDate = startDate;

    columns.push(
      <td
        className="day-row__table-column"
        style={{
          width: 90,
          maxWidth: 90,
          minWidth: 90,
          borderRight: "1px solid var(--stroke)",
          borderTop: "1px solid var(--stroke)",
        }}
      />
    );

    for (let i = 0; i < stats.planningData.amount_of_days; i++) {
      const isToday = DateTime.fromJSDate(currentDate).startOf("day").ts === DateTime.now().startOf("day").ts;

      columns.push(
        <td
          className="day-row__table-column"
          style={{
            borderRight: currentDate.getDay() === 0 ? "1px solid var(--stroke)" : "unset",
            zIndex: currentDate.getDay() === 0 && 10,
            background: isToday ? "var(--stroke)" : isWeekend(currentDate) ? "var(--background-2)" : "var(--background-1)",
            color: isToday ? "var(--button-text-hover)" : isWeekend(currentDate) ? "var(--accent-light)" : "var(--text-2)",
            borderTop: "1px solid var(--stroke)",
          }}
        >
          <p className="day-row__day-text">
            {currentDate.getDate() + " " + DateTime.fromJSDate(currentDate).setLocale(lang).weekdayShort.toUpperCase()}
          </p>
        </td>
      );

      currentDate = OffsetDays(currentDate, 1);
    }

    columns.push(<td className="compare-reporting__totals-column" />);

    setDayColumns(columns);
  }, [stats]);

  return (
    <tr className="day-row__main-row">
      <td className="day-row__fixed-column" style={{ borderRight: "unset" }} />

      {dayColumns}
    </tr>
  );
};

export default DayRow;

import { useTranslation } from "react-i18next";
import moment from "moment";
import { DateTime } from "luxon";

const pad = (n) => (n < 10 ? "0" + n : n);

const numberOfDay = (firstWeekDay) => {
  if (firstWeekDay === "Monday") {
    return [6, 0, 1, 2, 3, 4, 5];
  }

  return [0, 1, 2, 3, 4, 5, 6];
};

const DaysOfTheWeek = (firstWeekDay) => {
  const { t } = useTranslation();

  if (firstWeekDay === "Monday") {
    return [
      t("planning.monday"),
      t("planning.tuesday"),
      t("planning.wednesday"),
      t("planning.thursday"),
      t("planning.friday"),
      t("planning.saturday"),
      t("planning.sunday"),
    ];
  }

  return [
    t("planning.sunday"), //0
    t("planning.monday"), //1
    t("planning.tuesday"), //2
    t("planning.wednesday"), //3
    t("planning.thursday"), //4
    t("planning.friday"), //5
    t("planning.saturday"), //6
  ];
};

const FullDaysOfTheWeek = (firstWeekDay, showWeekends = true) => {
  const { t } = useTranslation();

  if (firstWeekDay === "Monday") {
    if (showWeekends) {
      return [
        t("actuals.week.monday"),
        t("actuals.week.tuesday"),
        t("actuals.week.wednesday"),
        t("actuals.week.thursday"),
        t("actuals.week.friday"),
        t("actuals.week.saturday"),
        t("actuals.week.sunday"),
      ];
    } else {
      return [
        t("actuals.week.monday"),
        t("actuals.week.tuesday"),
        t("actuals.week.wednesday"),
        t("actuals.week.thursday"),
        t("actuals.week.friday"),
      ];
    }
  } else {
    if (showWeekends) {
      return [
        t("actuals.week.sunday"),
        t("actuals.week.monday"),
        t("actuals.week.tuesday"),
        t("actuals.week.wednesday"),
        t("actuals.week.thursday"),
        t("actuals.week.friday"),
        t("actuals.week.saturday"),
      ];
    } else {
      return [
        t("actuals.week.monday"),
        t("actuals.week.tuesday"),
        t("actuals.week.wednesday"),
        t("actuals.week.thursday"),
        t("actuals.week.friday"),
      ];
    }
  }

  return [
    t("actuals.week.sunday"),
    t("actuals.week.monday"),
    t("actuals.week.tuesday"),
    t("actuals.week.wednesday"),
    t("actuals.week.thursday"),
    t("actuals.week.friday"),
    t("actuals.week.saturday"),
  ];
};

const MonthsOfTheYear = (t) => {
  return [
    t("planning.january"),
    t("planning.february"),
    t("planning.march"),
    t("planning.april"),
    t("planning.may"),
    t("planning.june"),
    t("planning.july"),
    t("planning.august"),
    t("planning.september"),
    t("planning.october"),
    t("planning.november"),
    t("planning.december"),
  ];
};

const getWeekNumber = (d) => {
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  let yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  let weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
  return weekNo;
};

const GetWeekMonday = (date) => {
  const date_copy = new Date(date);
  const day = date_copy.getDay();
  const diff = date_copy.getDate() - day + (day === 0 ? -6 : 1);

  return new Date(date_copy.setDate(diff));
};

const GetMonthBeginning = (date) => {
  let newDate = new Date(date);

  newDate.setUTCDate(1);

  return newDate;
};

const GetQuarterBeginning = (date) => {
  let newDate = new Date(date);

  let month = Math.floor(newDate.getMonth() / 3) * 3;

  newDate.setUTCDate(1);
  newDate.setUTCMonth(month);

  return newDate;
};

const GetYearBeginning = (date) => {
  let newDate = new Date(date);

  newDate.setUTCDate(1);
  newDate.setUTCMonth(0);

  return newDate;
};

const isWeekend = (date) => {
  return date.getDay() === 6 || date.getDay() === 0;
};

function CalcWeeksInMonth(date, startDayOfWeek) {
  const year = date.toDate().getFullYear();

  const month_number = date.toDate().getMonth() + 1;

  let firstDayOfWeek = startDayOfWeek === "Sunday" ? 0 : 1;

  let firstOfMonth = new Date(year, month_number - 1, 1);
  let lastOfMonth = new Date(year, month_number, 0);
  let numberOfDaysInMonth = lastOfMonth.getDate();
  let firstWeekDay = (firstOfMonth.getDay() - firstDayOfWeek + 7) % 7;

  let used = firstWeekDay + numberOfDaysInMonth;

  return Math.ceil(used / 7);
}

const OffsetDays = (date, days) => new Date(new Date(date).setDate(new Date(date).getDate() + days));

const ToDateString = (date) => {
  return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}`;
};

const StatsOffset = (date, range) => {
  let newDate = new Date(date);

  if (parseInt(range) === 1) {
    newDate.setUTCMonth(newDate.getMonth() + 1);
  } else if (parseInt(range) === 2) {
    newDate.setUTCMonth(newDate.getMonth() + 3);
  } else if (parseInt(range) === 3) {
    newDate.setUTCMonth(newDate.getMonth() + 12);
  }

  newDate.setUTCDate(0);

  return newDate;
};

const GetDaysInZoom = (zoom) => {
  if (zoom === 1) {
    return 1;
  } else if (zoom === 14) {
    return 7 * 9;
  } else if (zoom === 28) {
    return 7 * 12;
  } else {
    return 7 * 30;
  }
};

const GetStartDateForPlannerDate = (date, zoom) => {
  return date;
};

const changeDateTimeZoneToUTC = (date) => {
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
};

const isEndOfMonth = (date) => {
  return new Date(date.getTime() + 86400000).getDate() === 1;
};

const getNumberOfDays = (start, end) => {
  if (!start || !end) {
    return 0;
  }

  const date1 = new Date(typeof start === "string" ? start : ToDateString(start));
  const date2 = new Date(typeof end === "string" ? end : ToDateString(end));

  // One day in milliseconds
  const oneDay = 1000 * 60 * 60 * 24;

  // Calculating the time difference between two dates
  const diffInTime = date2.getTime() - date1.getTime();

  // Calculating the no. of days between two dates
  const diffInDays = Math.round(diffInTime / oneDay);

  return diffInDays;
};

const getCellWidthForZoom = (zoom) => {
  if (zoom === 14) {
    return 140;
  } else if (zoom === 28) {
    return 72;
  } else {
    return 20;
  }
};

const getUtcTime = (date) => {
  const d = new Date(date);
  const utc = d.getTime() + d.getTimezoneOffset() * 60000;
  return new Date(utc);
};

const IsDateInMonth = (day, date) => {
  return (
    new Date(day.date) > moment(date.toJSDate()).startOf("month").toDate() &&
    new Date(day.date) < moment(date.toJSDate()).endOf("month").toDate()
  );
};

const getWorkingDays = (year, month) => {
  let workingDays = 0;
  const daysInMonth = DateTime.local(year, month).daysInMonth;

  for (let day = 1; day <= daysInMonth; day++) {
    const weekday = DateTime.local(year, month, day).weekday;
    if (weekday > 0 && weekday < 6) {
      workingDays++;
    }
  }
  return workingDays;
};

export {
  DaysOfTheWeek,
  MonthsOfTheYear,
  GetWeekMonday,
  OffsetDays,
  ToDateString,
  GetMonthBeginning,
  StatsOffset,
  GetDaysInZoom,
  GetStartDateForPlannerDate,
  isWeekend,
  changeDateTimeZoneToUTC,
  getWeekNumber,
  isEndOfMonth,
  getNumberOfDays,
  getCellWidthForZoom,
  GetQuarterBeginning,
  GetYearBeginning,
  getUtcTime,
  FullDaysOfTheWeek,
  CalcWeeksInMonth,
  IsDateInMonth,
  numberOfDay,
  getWorkingDays,
};

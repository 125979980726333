import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Api from "../../../../../Api";
import { useTranslation } from "react-i18next";
import DangerImage from "../../../../default_images/danger_deletion.svg";
import TeambookBlueButton from "../../../../default_components/TeambookBlueButton";
import { TeambookIcon } from "../../../../default_images/TeambookIcon";
import { icons } from "../../../../default_images/IconsList";

export default function DeleteTask({
  setOpen,
  open,
  getTasks,
  selectedTimeOff,
}) {
  const { t } = useTranslation();

  const deleteTimeOff = (id) => {
    Api.Tasks.delete(id).then((response) => {
      getTasks();
      setOpen(false);
    });
  };

  return (
    <Dialog
      className="tb-default-dialog__delete"
      onClose={() => setOpen(false)}
      open={open}
    >
      <DialogTitle style={{ alignSelf: "center", paddingBottom: "35px" }}>
        <img src={DangerImage} alt={"danger"} />
        <TeambookIcon
          className="close-icon"
          name={icons.CROSS}
          onClick={() => setOpen(false)}
        />
      </DialogTitle>
      <DialogContent>
        <p>{t("settings.tasks.delete_task")}</p>
      </DialogContent>
      <DialogActions>
        <TeambookBlueButton
          id={"delete"}
          onClick={() => {
            deleteTimeOff(selectedTimeOff.id);
            setOpen(false);
          }}
          text={t("delete")}
        />
      </DialogActions>
    </Dialog>
  );
}

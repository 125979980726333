import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import UsersProfileImage from "../../default_components/UsersProfileImage";
import DropPhoto from "../../profile/sub_components/DropPhoto";
import { useTranslation } from "react-i18next";
import { icons } from "../../default_images/IconsList";
import { TeambookIcon } from "../../default_images/TeambookIcon";

const ChangeUserPhoto = ({ user, className, setUploadPhotoProps, getAllUsers }) => {
  const [open, setOpen] = useState(false);
  const [userImage, setUserImage] = useState(user.avatars["100"] || null);
  const { t } = useTranslation();

  const handleOpen = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={`change-photo-cmps ${className}`}>
      <div className="image-block">
        <div className="image upload-action">
          <div onClick={(e) => handleOpen(e)} className="user-image-change">
            <UsersProfileImage size={100} user={user} userImage={userImage} />
          </div>
        </div>
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
          marginTop: "30px",
        }}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ style: { background: "var(--mui-modal-backdrop)" } }}
      >
        <Fade in={open}>
          <div
            className="paper upload-photo-dialog"
            style={{
              overflowY: "auto",
              outline: "none",
              width: "520px",
              minHeight: "480px",
              backgroundColor: "var(--background-1)",
              padding: "5px",
              boxShadow: "0 5px 10px var(--box-shadow)",
              borderRadius: "20px",
            }}
          >
            <div className="upload-photo-dialog__header">
              <p className="upload-photo-dialog__title">{t("profile.general_info.upload_your_picture")}</p>

              <TeambookIcon className="cross-icon" onClick={handleClose} name={icons.CROSS} />
            </div>

            <DropPhoto
              user={user}
              setOpen={setOpen}
              setUserImage={setUserImage}
              setUploadPhotoProps={setUploadPhotoProps}
              getAllUsers={getAllUsers}
            />
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default ChangeUserPhoto;

import { create } from "zustand";

export const useDashboardStore = create((set) => ({
  reportingType: "default",
  reportingSplitBy: "project",
  setReportingType: (value) => set({ reportingType: value }),
  setReportingSplitBy: (value) => set({ reportingSplitBy: value }),
  dashboardType: "default",
  setDashboardType: (value) => set({ dashboardType: value }),
}));

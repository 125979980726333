import React from "react";

import TasksComponent from "./Tasks";
import PayrollComponent from "./PayrollIteams";

const TasksPayrollSettings = () => {
  return (
    <div className="settings__time-off">
      <TasksComponent />

      <PayrollComponent />
    </div>
  );
};

export default TasksPayrollSettings;

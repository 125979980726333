const advancedFilterOptions = (plannerType, t) => {
  if (plannerType === "planners") {
    return [
      {
        name: t("planning.filter.advanced_options.billable"),
        filter_type: "advanced",
        id: 1,
      },
      {
        name: t("planning.filter.advanced_options.non_billable"),
        filter_type: "advanced",
        id: 2,
      },
      {
        name: t("planning.filter.advanced_options.on_client"),
        filter_type: "advanced",
        id: 3,
      },
      {
        name: t("planning.at_office"),
        filter_type: "advanced",
        id: 4,
      },
      {
        name: t("planning.home_office"),
        filter_type: "advanced",
        id: 5,
      },
      {
        name: t("planning.filter.advanced_options.confirmed"),
        filter_type: "advanced",
        id: 6,
      },
      {
        name: t("planning.filter.advanced_options.not_confirmed"),
        filter_type: "advanced",
        id: 7,
      },
      {
        name: t("planning.filter.advanced_options.tentative"),
        filter_type: "advanced",
        id: 8,
      },
      {
        name: t("planning.filter.advanced_options.time_off"),
        filter_type: "advanced",
        id: 9,
      },
    ];
  } else {
    return [
      {
        name: t("planning.filter.advanced_options.billable"),
        filter_type: "advanced",
        id: 1,
      },
      {
        name: t("planning.filter.advanced_options.non_billable"),
        filter_type: "advanced",
        id: 2,
      },
      {
        name: t("planning.filter.advanced_options.on_client"),
        filter_type: "advanced",
        id: 3,
      },
      {
        name: t("planning.at_office"),
        filter_type: "advanced",
        id: 4,
      },
      {
        name: t("planning.home_office"),
        filter_type: "advanced",
        id: 5,
      },
      {
        name: t("planning.filter.advanced_options.tentative"),
        filter_type: "advanced",
        id: 8,
      },
    ];
  }
};

export default advancedFilterOptions;

import React, { useEffect } from "react";
import DatesRow from "./DatesRow";
import ProjectionUserRows from "./ProjectionUserRows";
import ProjectionTeambookAd from "./ProjectionTeambookAd";
import { SliceStringWithDots } from "../../lib/StringFormat";
import { WhiteTooltip } from "../default_components/Tooltips";

const ProjectionTable = ({ date, project, clientProjects }) => {
  useEffect(() => {
    document.getElementsByTagName("html")[0].scrollTo(1922, 0);
  }, []);

  return (
    <div className="projection__table-frame">
      <div className="projection__table">
        <DatesRow date={date} />

        {clientProjects &&
          clientProjects.map((obj, key) => (
            <div key={key} className="projection__project-section">
              {obj.bookings.length > 0 && (
                <div className="projection__project-column" style={{ background: obj.project.color }}>
                  <WhiteTooltip title={obj.project.name}>
                    <p>{SliceStringWithDots(obj.project.name, 14)}</p>
                  </WhiteTooltip>
                </div>
              )}

              <ProjectionUserRows users={obj.users} bookings={obj.bookings} date={date} isClientSharing />
            </div>
          ))}

        {project && <ProjectionUserRows users={project.users} bookings={project.bookings} date={date} />}

        <ProjectionTeambookAd />
      </div>
    </div>
  );
};

export default ProjectionTable;

import lsKeys from "../../default_values/defaultKeys";

export const colorsPerType = (type) => {
  switch (type) {
    case "default":
      return {
        baseColor: localStorage.getItem(lsKeys.THEME) === "light" ? "#4c54a0" : "#49587a",
      };
    case "actuals":
      return {
        baseColor: localStorage.getItem(lsKeys.THEME) === "light" ? "#9199e7" : "#99A0E0",
      };
    default:
      return {
        baseColor: localStorage.getItem(lsKeys.THEME) === "light" ? "#4c54a0" : "#49587a",
      };
  }
};

import React, { useState } from "react";
import { ClickAwayListener } from "@mui/base";
import { useTranslation } from "react-i18next";
import "../actuals.scss";
import Api from "../../../Api";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";

const ActualLogRightClick = React.forwardRef(({ updateActuals, changeTooltip, copyLog, page }, ref) => {
  const [open, setOpen] = useState(false);
  const [pos, setPos] = useState({ x: null, y: null });
  const [log, setLog] = useState(null);
  const screenSize = document.body.clientWidth;
  const { t } = useTranslation();

  const deleteLog = () => {
    changeTooltip({ open: false });
    Api.ActualLogs.delete({ id: log.id }).then(() => {
      updateActuals();
      setOpen(false);
    });
  };

  React.useImperativeHandle(ref, () => {
    return {
      open: open,
      setOpen: setOpen,
      setPos: setPos,
      setLog: setLog,
    };
  });

  return (
    open && (
      <>
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <div
            className="actuals__context-menu"
            style={{
              left: pos.x > screenSize - 130 ? pos.x - 130 : pos.x,
              top: pos.y,
            }}
          >
            {page === "actuals" && (
              <div onClick={() => copyLog(log.id)} className="planner__context-menu-option">
                <TeambookIcon style={{ width: 18, height: 18 }} name={icons.COPY} />
                <p> {t("my_week.copy")}</p>
              </div>
            )}
            <div onClick={deleteLog} className="planner__context-menu-option">
              <TeambookIcon style={{ width: 18, height: 18 }} name={icons.TRASH} />
              <p> {t("delete")}</p>
            </div>
          </div>
        </ClickAwayListener>
      </>
    )
  );
});

export default ActualLogRightClick;

import { useRef, useEffect } from "react";
import ReactDOM from "react-dom";

export const Portal = ({ onClick, children }) => {
  const shadowRef = useRef();

  useEffect(() => {
    shadowRef.current.focus();
  }, []);

  const handleKeyDown = (e) => {
    if (e.keyCode === 27) {
      onClick();
    }
  };

  return ReactDOM.createPortal(
    <div ref={shadowRef} tabIndex="-1" onClick={onClick} autoFocus onKeyDown={handleKeyDown} id="bgShadow" className="bg-shadow">
      {children}
    </div>,
    document.body
  );
};

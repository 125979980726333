import React, { useEffect, useState } from "react";
import { ThemeContext, themes } from "../contexts/ThemeContext";
import lsKeys from "../components/default_values/defaultKeys";

const getTheme = () => {
  const theme = `${window?.localStorage?.getItem("tb-theme")}`;
  if (Object.values(themes).includes(theme)) return theme;

  const userMedia = window.matchMedia("(prefers-color-scheme: light)");
  if (userMedia.matches) return themes.light;

  return themes.dark;
};

const ThemeProvider = ({ children }) => {
  const [isAdminTheme, setIsAdminTheme] = useState(false);
  const [theme, setTheme] = useState(getTheme);

  useEffect(() => {
    if (isAdminTheme) {
      document.documentElement.dataset.theme = "light";
    } else {
      document.documentElement.dataset.theme = theme;
      localStorage.setItem(lsKeys.THEME, theme);
    }
  }, [theme, isAdminTheme]);

  const changeTheme = (newValue) => {
    setTheme(newValue);
  };

  return <ThemeContext.Provider value={{ theme, setTheme, setIsAdminTheme, changeTheme }}>{children}</ThemeContext.Provider>;
};

export default ThemeProvider;

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useQuery from "../default_components/useQuery/useQuery";
import Api from "../../Api";
import slackLogo from "../default_images/slack_circle.png";
import tbLogo from "../default_images/tb_circle.png";
import failedConnectionLogo from "../default_images/failed_connection.png";

const SlackInstalled = () => {
  let query = useQuery();
  let code = query.get("code");
  const [status, setStatus] = useState(null);

  useEffect(() => {
    Api.Integrations.Slack.init(code)
      .then((response) => {
        setStatus(true);
      })
      .catch((error) => setStatus(false));
  }, []);

  const { t } = useTranslation();

  if (status === null) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {status ? (
        <div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img src={slackLogo} alt="Slack" />

            <img
              src={
                "https://teambook-assets-production.s3.amazonaws.com/images/hand_shake.gif"
              }
              alt="Fail"
              style={{ maxHeight: "200px", margin: "0 50px" }}
            />

            <img src={tbLogo} alt="Teambook" />
          </div>
          <div>Successful Connection!</div>
        </div>
      ) : (
        <div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img src={slackLogo} alt="Slack" />

            <img
              src={failedConnectionLogo}
              alt="Fail"
              style={{ maxHeight: "70px", margin: "75px 50px 0 50px" }}
            />

            <img src={tbLogo} alt="Teambook" />
          </div>
          <div>Connection Failed</div>
        </div>
      )}
    </div>
  );
};

export default SlackInstalled;

import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import "../dashboard.scss";
import * as TopsColors from "../../default_values/TopsColors";
import { WhiteTooltip } from "../../default_components/Tooltips";
import { useTranslation } from "react-i18next";

const DashboardTopsBar = ({ topData, type, view, entityType }) => {
  const { t } = useTranslation();

  const FormData = () => {
    let topArray = [];

    topData.forEach((entity, index) => {
      if (entity[2] > 0) {
        topArray.push({
          name: entity[0],
          title: `${entity[0]} - ${entity[1]}h`,
          width: entity[2],
          total: entity[1],
          color: TopsColors.topsColors[index],
        });
      }
    });

    return topArray;
  };

  const entityData = FormData();
  let textComponents = [];

  const textMapping = (percentage, total) => {
    return {
      default: `${t("dashboard.planned")} ${percentage}%`,
      actuals: `${t("dashboard.logged")} ${percentage}%`,
      capacity: `Ratio ${percentage}% (${total} ${t("planning.days")})`,
      compare: `${entityType === "bookings" ? t("dashboard.planned") : t("dashboard.logged")} ${percentage}%`,
    }[view];
  };

  entityData.forEach((userData, i) => {
    textComponents.push(
      <>
        <WhiteTooltip
          title={
            <div className="tops-tooltip" style={{ display: "flex" }}>
              <div className="tops-tooltip__name">
                <div
                  className="tops-tooltip__circle"
                  style={{
                    backgroundColor: userData.color,
                  }}
                />
                <p>{userData?.name || userData.title}</p>
              </div>
              <div className="tops-tooltip__planned-hours">{textMapping(userData.width, userData.total)}</div>
            </div>
          }
          key={`tooltip-${userData.title}`}
        >
          <div
            key={`text-${userData.title}`}
            style={{
              height: `20px`,
              width: `${userData.width}%`,
              backgroundColor: `${userData.color}`,
              borderRadius: i === 0 ? "4px 1px 1px 4px" : i === entityData.length - 1 ? "1px 4px 4px 1px" : "1px",
              marginRight: 1,
            }}
          />
        </WhiteTooltip>
      </>
    );
  });

  return (
    <>
      <div style={{ height: "20px", display: "flex" }}>{textComponents}</div>
    </>
  );
};

export default DashboardTopsBar;

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import UsersSubBlock from "./UsersSubBlock";
import { WhiteTooltip } from "../../../../default_components/Tooltips";
import { getCorrectTextLocale } from "../../../../capacity_planning";
import { calculateProjectHeatStyles } from "../../../../capacity_planning/lib/calculateHeatStyles";
import { recalculateCapacityArray } from "../../../../capacity_planning/lib/recalculateCapacityArray";
import { StickyTotal } from "../reporting_users/reporting_tags/StickyTotal";
import { SliceStringWithDots } from "../../../../../lib/StringFormat";
import { TeambookIcon } from "../../../../default_images/TeambookIcon";
import { icons } from "../../../../default_images/IconsList";

const ProjectsBlock = ({
  project,
  date,
  prReservations,
  reservations,
  projectShowedId,
  setProjectShowedId,
  users,
  selectedViewType,
  areAllProjectsExpanded,
}) => {
  const { t } = useTranslation();
  const [calculatedDurations, setCalculatedDurations] = useState([]);
  const [calculatedUserDurations, setCalculatedUserDurations] = useState([]);
  const [projectReservations, setProjectReservations] = useState(prReservations);
  const [isListDisplaying, setIsListDisplaying] = useState(false);

  useEffect(() => {
    setProjectReservations(prReservations);
  }, [prReservations]);

  useEffect(() => {
    updateCalculations();
  }, [projectReservations, date, reservations]);

  useEffect(() => {
    setIsListDisplaying(areAllProjectsExpanded);
  }, [areAllProjectsExpanded]);

  const sortUsers = (userIds) =>
    userIds
      .map((id) => {
        return users.find((project) => +project.id === +id);
      })
      .sort((a, b) => (a.last_name > b.last_name ? 1 : -1))
      .map((user) => user.id);

  const groupedUserCapacities = () => {
    return reservations
      .filter((r) => r.project_id === project.id)
      .reduce(function (r, a) {
        r[a.user_id] = r[a.user_id] || [];
        r[a.user_id].push(a);
        return r;
      }, Object.create(null));
  };

  const updateCalculations = () => {
    setCalculatedDurations(recalculateCapacityArray(date, prReservations, selectedViewType));
    setCalculatedUserDurations(recalculateCapacityArray(date, reservations, selectedViewType));
  };

  return (
    <div style={{ position: "relative" }} className="reporting-block">
      <div className="reporting__headline">
        <div className="reporting__headline__name">
          <div className="named-row">
            <div style={{ background: project.color }} className="project-list__project-color" />

            <p
              onClick={() => {
                setIsListDisplaying(!isListDisplaying);
              }}
              style={{ cursor: "pointer" }}
            >
              {SliceStringWithDots(project.name, 22)}
              {reservations.filter((r) => r.project_id === project.id).length > 0 && (
                <div title={t(`planning.capacity.${isListDisplaying ? "hide" : "show"}_users`)}>
                  <TeambookIcon name={isListDisplaying ? icons.ARROW_UP : icons.ARROW_DOWN} alt={"Show All Entities"} />
                </div>
              )}
            </p>
          </div>

          <p className="duration">
            {calculatedDurations.reduce((x, y) => x + y, 0)} {t("planning.days")}
          </p>
        </div>

        {[...Array(selectedViewType)].map((x, i) => {
          const styleBg = calculateProjectHeatStyles(
            project,
            date.plus({ months: i }),
            calculatedDurations[i] || 0,
            calculatedUserDurations[i] || 0
          );
          const toolTipMessage = getCorrectTextLocale(styleBg, t);

          return (
            <div className={`table-cell ${styleBg}`} title={toolTipMessage}>
              <p>{calculatedDurations[i] || "0"}</p>
            </div>
          );
        })}

        <StickyTotal style={{ width: 1, maxWidth: 1, minWidth: 1, borderRight: "unset" }} />
      </div>

      {isListDisplaying &&
        sortUsers(Object.keys(groupedUserCapacities())).map((userId) => (
          <UsersSubBlock
            user={users.find((u) => u.id === parseInt(userId))}
            date={date}
            reservations={reservations.filter((cr) => cr.user_id === parseInt(userId) && cr.project_id === project.id)}
            selectedViewType={selectedViewType}
          />
        ))}
    </div>
  );
};

export default ProjectsBlock;

import { forwardRef, useImperativeHandle, useState } from "react";
import { useShiftPressedStore } from "../../../../stores/planner";

export const SelectBookingsArea = forwardRef(({ mouseMoved }, ref) => {
  const [pos, setPos] = useState({ x: 0, y: 0 });
  const [size, setSize] = useState({ width: 0, height: 0 });
  const [open, setOpen] = useState(false);

  useImperativeHandle(ref, () => {
    return {
      open: open,
      setOpen: setOpen,

      pos: pos,
      setPos: setPos,
      size: size,
      setSize: setSize,
    };
  });

  return (
    open &&
    useShiftPressedStore.getState().shiftPressed && (
      <div
        className="selected-area"
        ref={ref}
        onMouseMove={mouseMoved}
        style={{
          position: "absolute",
          left: pos.x + 125,
          top: pos.y,
          opacity: 0.25,
          background: "var(--text-2)",
          width: size.width,
          height: size.height,
          zIndex: 10,
        }}
      />
    )
  );
});

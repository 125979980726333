import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import DialogContent from "@mui/material/DialogContent";
import invoiceLogo from "../../default_images/invoice-icon.png";
import moment from "moment";
import GetAppIcon from "@mui/icons-material/GetApp";
import { DialogTitle } from "@mui/material";
import { WhiteTooltip } from "../../default_components/Tooltips";
import { icons } from "../../default_images/IconsList";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import Api from "../../../Api";
import { InvoiceWrapper } from "../../invoice/InvoiceWrapper";

const InvoicesModal = ({ closeModal }) => {
  const { t } = useTranslation();
  const [invoices, setInvoces] = useState([]);
  const invoiceRef = useRef();

  useEffect(() => {
    Api.Billing.get_all_transactions().then((res) => {
      setInvoces(res.data.invoices);
    });
  }, []);

  const printInvoice = (i) => {
    invoiceRef[i].printInvoice();
  };

  return (
    <>
      <DialogTitle id="add-user-dialog">
        <p>{t("settings.subscription.invoices")}</p>
        <TeambookIcon name={icons.CROSS} onClick={closeModal} />
      </DialogTitle>

      <DialogContent style={{ margin: "10px" }}>
        {invoices.map((invoice, i) => (
          <div className="invoice__modal-container">
            <div style={{ display: "flex" }}>
              <img alt={"invoice"} src={invoiceLogo} style={{ height: "34px" }} />

              <p className="invoice__modal-text">{moment(invoice.issued_at).format("MMMM, YYYY")}</p>
            </div>

            <WhiteTooltip title={t("download")}>
              <GetAppIcon
                onClick={() => printInvoice(i)}
                style={{
                  color: "#454C9A",
                  marginTop: "5px",
                  cursor: "pointer",
                }}
              />

              <div style={{ display: "none" }}>
                <InvoiceWrapper invoice={invoice} ref={(el) => (invoiceRef[i] = el)} />
              </div>
            </WhiteTooltip>
          </div>
        ))}
      </DialogContent>
    </>
  );
};

export default InvoicesModal;

import Api from "../../../Api";

async function GetTeamsRequest(setTeams, isArray = false) {
  Api.Teams.all()
    .then((res) => {
      if (isArray) {
        setTeams(res.data);
      } else {
        const teamsMap = new Map();

        res.data.forEach((i) => {
          teamsMap.set(i.id.toString(), i);
        });

        setTeams(teamsMap);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export default GetTeamsRequest;

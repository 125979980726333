import React, { useEffect, useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Api from "../../../Api";
import { useTranslation } from "react-i18next";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import GetTeamsRequest from "../requests/GetTeamsRequest";
import EmptyExportWarning from "../../dashboard/modals/EmptyExportWarning";
import "moment/locale/de";
import "moment/locale/fr";
import { TeambookProjectsPicker, TeambookTeamsPicker, TeambookUsersPicker } from "../../default_components/TeambookDefaultPickers";
import { TeambookReactSelect } from "../../default_components/TeambookReactSelect";
import GetActiveUsersRequest from "../requests/GetActiveUsersRequest";
import { dateFormat } from "../../default_values/defaultDateFormats";
import ExportBookingButton from "./ExportBookingsButton";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";
import MuiDatePicker from "../../default_components/MuiDatePicker";
import { useAccountStore } from "../../../stores/accountStore";
import { shallow } from "zustand/shallow";

const billability = [
  { name: "All", value: "all" },
  { name: "Billable", value: "billable" },
  { name: "Non billable", value: "non_billable" },
];

const ExportBookingsDialog = ({ open, handleClose, date, exportType = "logs" }) => {
  const { t } = useTranslation();
  const [fromDate, setFromDate] = useState(date.start);
  const [toDate, setToDate] = useState(date.end);
  const [allUsers, setAllUsers] = useState([]);
  const [teams, setTeams] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [selectedBillability, setSelectedBillability] = useState({
    value: "all",
    name: "All",
  });
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [activeProjects, setActiveProjects] = useState([]);
  const [deactivatedProjects, setDeactivatedProjects] = useState([]);
  const [showParamsSpecification, setShowParamsSpecification] = useState(false);
  const [emptyExportWindowOpen, setEmptyExportWindowOpen] = useState(false);
  const [account] = useAccountStore((state) => [state.account], shallow);

  useEffect(() => {
    if (open) {
      GetActiveUsersRequest(setAllUsers);
      GetActiveProjects();
      GetDeactivatedProjects();
      GetTeamsRequest(setTeams);
    }
  }, [open]);

  const GetActiveProjects = () => {
    Api.Projects.active().then((response) => {
      setActiveProjects(response.data);
    });
  };

  const GetDeactivatedProjects = () => {
    Api.Projects.deactivated().then((response) => {
      setDeactivatedProjects(response.data);
    });
  };

  const title = () => {
    switch (exportType) {
      case "bookings":
        return t("planning.export_bookings");
      case "actuals":
        return t("actuals.export");
      case "all":
        return t("dashboard.export_bookings_and_logs");
    }
  };

  const filterUsers = () => allUsers.filter(({ active }) => active === true);

  const getTeamsArray = () => {
    let arrayTeams = Array.from(teams, ([name, value]) => ({
      value: value,
    })).map((team) => {
      team.value.value = team.value.name;
      return team.value;
    });
    return arrayTeams;
  };

  const toggleSpecification = () => {
    setShowParamsSpecification(!showParamsSpecification);
  };

  const getId = (obj) => obj.map((elem) => elem.id);

  const filterProjects = () => {
    const activeDefaultProjects = activeProjects.filter((p) => p.kind !== "time_off");
    const activeTimeOffProjects = activeProjects.filter((p) => p.kind === "time_off");

    return [...alphaSort(activeDefaultProjects), ...alphaSort(activeTimeOffProjects), ...alphaSort(deactivatedProjects)];
  };

  const alphaSort = (projects) => {
    return projects.sort((a, b) => {
      a.value = a.name;
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  };

  if (account) {
    moment.updateLocale("en", {
      week: {
        dow: account.first_week_day === "Monday" ? 1 : 0,
      },
    });
  }

  return (
    <Dialog
      className="tb-default-dialog export"
      open={open}
      onClose={() => {
        handleClose();
        setSelectedUsers([]);
        setSelectedProjects([]);
        setSelectedTeams([]);
        setSelectedBillability({ name: "All", value: "all" });
        setShowParamsSpecification(false);
      }}
      aria-labelledby="export-bookings-dialog"
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle className="export-booking-dialog__title" id="add-user-dialog">
        <p>{title()}</p>

        <TeambookIcon
          name={icons.CROSS}
          alt={"close"}
          onClick={() => {
            handleClose();
            setSelectedUsers([]);
            setSelectedProjects([]);
            setSelectedTeams([]);
            setSelectedBillability({ name: "All", value: "all" });
            setShowParamsSpecification(false);
          }}
        />
      </DialogTitle>

      <DialogContent
        style={{
          minHeight: "max-content",
          maxHeight: "content fit",
          scrollbarGutter: "stable",
          margin: "20px 0px",
          width: 400,
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p>{t("planning.from_export")}:</p>

          <MuiDatePicker
            variant="inline"
            format={"d MMM, yyyy"}
            value={fromDate}
            onChange={(date) => setFromDate(date)}
            autoOk={true}
            sx={{ width: "150px", input: { padding: "0px" } }}
            className="mui-date-picker"
          />
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p>{t("planning.to_export")}:</p>

          <MuiDatePicker
            variant="inline"
            format={"d MMM, yyyy"}
            value={toDate}
            onChange={(date) => setToDate(date)}
            minDate={fromDate}
            sx={{ width: "150px", input: { padding: "0px" } }}
            className="mui-date-picker"
          />
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            margin: "10px 0px",
            cursor: "pointer",
          }}
          onClick={toggleSpecification}
        >
          <p style={{ margin: 0 }}>{t("planning.select_export_params")}</p>
          {showParamsSpecification ? (
            <TeambookIcon style={{ cursor: "pointer" }} alt={"arrowTop"} name={icons.ARROW_UP} />
          ) : (
            <TeambookIcon style={{ cursor: "pointer" }} alt={"arrowDown"} name={icons.ARROW_DOWN} />
          )}
        </div>

        {showParamsSpecification && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            <div>
              <TeambookUsersPicker
                fieldName={t("planning.specify_users")}
                type="multiple"
                onChange={(newValue) => {
                  setSelectedUsers(newValue);
                }}
                filterSelectedOptions
                users={filterUsers()}
                placeholder={"All"}
                defaultValue={selectedUsers}
              />
            </div>

            <div>
              <TeambookProjectsPicker
                fieldName={t("planning.specify_projects")}
                projects={filterProjects()}
                type="multiple"
                defaultValue={selectedProjects}
                onChange={(newValue) => {
                  setSelectedProjects(newValue);
                }}
                id="tags-outlined"
                placeholder="All"
              />
            </div>

            <div>
              <TeambookReactSelect
                fieldName={t("planning.select_billability")}
                id="tags-outlined"
                options={billability}
                defaultValue={selectedBillability}
                getOptionLabel={(option) => option.name}
                onChange={(newValue) => {
                  setSelectedBillability(newValue);
                }}
                grayPlaceholder
              />
            </div>

            <div>
              <TeambookTeamsPicker
                fieldName={t("users.teams")}
                id="tags-outlined"
                placeholder={"All"}
                type="multiple"
                teams={getTeamsArray()}
                defaultValue={selectedTeams}
                onChange={(newValue) => {
                  setSelectedTeams(newValue);
                }}
              />
            </div>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <ExportBookingButton
          fromDate={fromDate}
          toDate={toDate}
          selectedUsers={selectedUsers.length === 0 ? getId(allUsers) : getId(selectedUsers)}
          selectedProjects={selectedProjects.length === 0 ? getId([...activeProjects, ...deactivatedProjects]) : getId(selectedProjects)}
          selectedBillability={selectedBillability}
          selectedTeams={selectedTeams.length === 0 ? getId(getTeamsArray()) : getId(selectedTeams)}
          setEmptyOpened={setEmptyExportWindowOpen}
          exportType={exportType}
        />
      </DialogActions>

      <EmptyExportWarning open={emptyExportWindowOpen} setOpen={setEmptyExportWindowOpen} type={"actuals"} />
    </Dialog>
  );
};

export default ExportBookingsDialog;

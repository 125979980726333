import React, { useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import { useTranslation } from "react-i18next";
import DialogActions from "@mui/material/DialogActions";
import TeambookBlueButton from "../../default_components/TeambookBlueButton";
import Api from "../../../Api";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TeambookTextForm from "../../default_components/TeambookTextForm";
import { DateTime } from "luxon";
import TeambookRadioButton from "../../default_components/TeambookRadioButton";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";
import MuiDatePicker from "../../default_components/MuiDatePicker";

const SpreadDialog = ({ setDialogOpened, date, project, updateReservations }) => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(
    project.start_date ? DateTime.fromISO(project.start_date) : date.startOf("month")
  );
  const [endDate, setEndDate] = useState(project.end_date ? DateTime.fromISO(project.end_date) : date.endOf("month"));
  const [daysToSpread, setDaysToSpread] = useState(project.estimated / 8 || 0);
  const [kind, setKind] = useState("inject");

  const spreadDays = () => {
    Api.CapacityReservations.spread_project_reservation({
      project_id: project.id,
      kind: kind,
      start_date: startDate.toISODate(),
      end_date: endDate.toISODate(),
      duration: daysToSpread,
    }).then(() => {
      updateReservations();
      setDialogOpened(false);
    });
  };

  return (
    <Dialog
      open={true}
      onClose={() => {
        setDialogOpened(false);
      }}
      aria-labelledby="form-dialog-title"
      className="tb-default-dialog"
    >
      <DialogTitle>
        <p>
          {t("planning.spread_for")} <b>{project.name}</b>
        </p>
        <TeambookIcon name={icons.CROSS} onClick={() => setDialogOpened(false)} />
      </DialogTitle>

      <DialogContent style={{ padding: "20px 40px" }}>
        <p>{t("planning.spread_dates")}:</p>

        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <p style={{ margin: 0 }}>{`${t("projects.start_date")}:`}</p>

          <MuiDatePicker
            variant="inline"
            format={"MMM, yyyy"}
            value={startDate}
            onChange={(date) => setStartDate(date.start_of("month"))}
            sx={{ width: "150px", input: { padding: "0px" } }}
            className="mui-date-picker"
            views={["month", "year"]}
          />

          {project.start_date && (
            <p style={{ margin: 0 }}>
              ({t("planning.project_start_date")}: {DateTime.fromISO(project.start_date).toISODate()})
            </p>
          )}
        </div>

        <div
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            marginTop: 10,
          }}
        >
          <p style={{ margin: 0 }}>{`${t("projects.end_date")}:`}</p>

          <MuiDatePicker
            variant="inline"
            format={"MMM, yyyy"}
            value={endDate}
            onChange={(date) => setEndDate(date.endOf("month"))}
            sx={{ width: "150px", input: { padding: "0px" } }}
            className="mui-date-picker"
            views={["month", "year"]}
          />

          {project.end_date && (
            <p style={{ margin: 0 }}>
              ({t("planning.project_end_date")}: {DateTime.fromISO(project.end_date).toISODate()})
            </p>
          )}
        </div>

        <p style={{ marginTop: 16 }}>{t("planning.spread_amount")}</p>
        <TeambookTextForm
          onChange={setDaysToSpread}
          fieldValue={daysToSpread}
          id="amount-to-spread"
          type={"number"}
          width={"30%"}
        />

        <p style={{ marginBottom: 8 }}>{t("planning.spread_settings")}:</p>
        <div style={{ display: "flex", gap: "10px", marginBottom: 8 }}>
          <TeambookRadioButton checked={kind === "inject"} onCheck={() => setKind("inject")} />
          <p style={{ marginBottom: 0 }}>{t("planning.overwrite_by_planner")}</p>
        </div>
        <div style={{ display: "flex", gap: "10px" }}>
          <TeambookRadioButton checked={kind === "add"} onCheck={() => setKind("add")} />
          <p style={{ margin: 0 }}>{t("planning.capacity_data_top")}</p>
        </div>
      </DialogContent>

      <DialogActions>
        <TeambookBlueButton text={"Spread"} onClick={spreadDays} />
      </DialogActions>
    </Dialog>
  );
};

export default SpreadDialog;

import React, { useState } from "react";
import { isWeekend } from "../../../lib/DateUtils";
import { useTranslation } from "react-i18next";
import ActualLog from "../ActualLog";
import EditTimeLoggingWindow from "../calendar/EditTimeLoggingWindow";
import UserLog from "./UserLog";
import { useAccountStore } from "../../../stores/accountStore";
import { shallow } from "zustand/shallow";
import { DateTime } from "luxon";
import lsKeys from "../../default_values/defaultKeys";

const ApprovalDay = ({
  date,
  beginningDate,
  projects,
  logs,
  updateActuals,
  activities,
  occupations,
  createOccupation,
  createActivity,
  changeContextMenu,
  changeTooltip,
  users,
  splitBy,
  isLast,
  isLastWeek,
}) => {
  const { t } = useTranslation();
  const [editWindowOpened, setEditWindowOpened] = useState(false);
  const [editingLog, setEditingLog] = useState();
  const [account] = useAccountStore((state) => [state.account], shallow);
  const isLastDayOfWweek = account.first_week_day === date.setLocale("en").weekdayLong;

  const logUser = (log) => {
    return users.find((u) => u.id === log.user_id);
  };

  const projectFor = (log) => {
    return projects.filter((p) => p.id === log.project_id)[0];
  };

  const openEditLoggingWindow = (log) => {
    setEditingLog(log);
    setEditWindowOpened(true);
  };

  return (
    <>
      <div
        key={`month-${date.month}-day-${date.day}`}
        style={{
          width: `${100 / 7}%`,
          borderBottom: isLastWeek && "1px solid var(--stroke)",
          borderBottomRightRadius: isLast && 7,
        }}
        last={date.weekday === 7 ? "true" : "false"}
        className={`actuals__date-cell ${beginningDate.month !== date.month && "past "}`}
      >
        <div className={`actuals__day-info ${isWeekend(date.toJSDate()) && "day-weekend"}`}>
          <div className="actuals__header">
            <p className="actuals__date">{date.day}</p>

            <p className="actuals__duration">{logs.reduce((ps, a) => ps + a.duration, 0) / 60}h</p>
          </div>

          <div className="flex">
            <div className="actuals__day-logs">
              {logs.map((log, index) =>
                splitBy === "by_user" ? (
                  <ActualLog
                    occupations={occupations}
                    activities={activities}
                    changeTooltip={changeTooltip}
                    changeContextMenu={changeContextMenu}
                    log={log}
                    index={index}
                    updateActuals={updateActuals}
                    projectFor={projectFor}
                    date={date}
                    type={"approve"}
                    openEditLoggingWindow={openEditLoggingWindow}
                  />
                ) : (
                  <UserLog
                    occupations={occupations}
                    activities={activities}
                    changeTooltip={changeTooltip}
                    changeContextMenu={changeContextMenu}
                    log={log}
                    index={index}
                    updateActuals={updateActuals}
                    projectFor={projectFor}
                    user={logUser(log)}
                    date={date}
                    type={"approve"}
                    openEditLoggingWindow={openEditLoggingWindow}
                  />
                )
              )}
            </div>
          </div>
        </div>
      </div>

      {editWindowOpened && (
        <EditTimeLoggingWindow
          setIsSelected={() => {}}
          projects={projects}
          editingLog={editingLog}
          updateActuals={updateActuals}
          activities={activities}
          occupations={occupations}
          createActivity={createActivity}
          createOccupation={createOccupation}
          setOpen={setEditWindowOpened}
        />
      )}
    </>
  );
};

export default ApprovalDay;

import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Portal } from "./Portal";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";
import Api from "../../../Api";
import { useBookingsStore, useCopyBookingsStore, useZoomStore } from "../../../stores/planner";
import { shallow } from "zustand/shallow";
import { PlannerContext } from "../context/PlannerContext";

const PlannerRightClick = React.forwardRef(({ plannerDispatcher, usersRef, teams }, ref) => {
  const [open, setOpen] = useState(false);
  const [posX, setPosX] = useState(null);
  const [posY, setPosY] = useState(null);
  const screenSize = document.body.clientWidth;
  const [copyUserId, setCopyUserId] = useState(null);
  const [copyDate, setCopyDate] = useState(null);
  const [copyUserIndex, setCopyUserIndex] = useState(null);
  const { t } = useTranslation();
  const { team } = useContext(PlannerContext);

  const [zoom] = useZoomStore((state) => [state.zoom], shallow);

  const [bookings, setBookings] = useBookingsStore((state) => [state.bookings, state.setBookings], shallow);

  React.useImperativeHandle(ref, () => {
    return {
      open: open,
      setOpen: setOpen,
      setPosX: setPosX,
      setPosY: setPosY,
      setCopyUserId: setCopyUserId,
      setCopyDate: setCopyDate,
      setCopyUserIndex: setCopyUserIndex,
    };
  });

  const closeMenu = () => {
    setOpen(false);
  };

  const onCopyOptionClickHandler = () => {
    finishCopyBooking(posX, posY);
    setOpen(false);
  };

  const finishCopyBooking = () => {
    Api.Bookings.copy(useCopyBookingsStore.getState().copyBookingIds, copyUserId, copyDate)
      .then((response) => {
        setBookings([...bookings, ...response.data]);

        plannerDispatcher.afterCreationBookingAssignment([copyUserIndex], usersRef, teams, team.id, bookings);
      })
      .catch((e) => console.log(e));
  };

  return (
    open &&
    zoom !== 1 && (
      <>
        <Portal onClick={closeMenu} />

        <div
          className="planner__context-menu"
          style={{
            left: posX > screenSize - 130 ? posX - 130 : posX,
            top: posY,
          }}
        >
          <div className="planner__context-menu-option" style={{ borderRadius: 10 }} onClick={onCopyOptionClickHandler}>
            <TeambookIcon style={{ width: 18, height: 18 }} name={icons.COPY} />
            <p>{t("paste")}</p>
          </div>
        </div>
      </>
    )
  );
});

export default PlannerRightClick;

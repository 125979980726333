import React, { useContext, useState } from "react";
import { getNumberOfDays, ToDateString, getCellWidthForZoom, OffsetDays } from "../../../lib/DateUtils";
import {
  PLANNER_USER_WIDTH,
  getPlannerOffsetX,
  getPlannerOffsetY,
  usersMaxScheduleTime,
} from "../../../lib/PlannerUtils";
import {
  useBookingsCreatingStore,
  useBookingsStore,
  useDateStore,
  useSelectedProjectStore,
  useZoomStore,
} from "../../../stores/planner";
import { PlannerContext } from "../context/PlannerContext";
import { isWeekend } from "date-fns";
import { DateTime } from "luxon";
import { useProfileStore } from "../../../stores/profileStore";
import { shallow } from "zustand/shallow";

const formatMapping = {
  0: 5,
  1: 10,
  2: 15,
};

const CreationBooking = React.forwardRef(({ userIndex, mouseMoved, weekendsHidden, bookingFormat }, ref) => {
  const userRole = localStorage.getItem("tb-role") || "regular";
  const { filteredUsers } = useContext(PlannerContext);
  const { bookings } = useBookingsStore();
  const [plannerDate] = useDateStore((state) => [state.date], shallow);
  const [zoom] = useZoomStore((state) => [state.zoom], shallow);

  const [profile] = useProfileStore((state) => [state.profile], shallow);

  const removeBooking = (index, date) => {
    updateBookings(
      bookingsCreation.filter((booking) => {
        const [creationIndex, creationDate] = booking;
        if (creationIndex === index && date === creationDate) {
          return false;
        } else {
          return true;
        }
      })
    );
  };

  const { selectedProject, selectedTimeOff, projectsType } = useSelectedProjectStore();

  const [duration, setDuration] = useState(useBookingsCreatingStore.getState().bookingsDuration || 0);

  const [bookingsCreation, setBookingsCreation] = useState([]);

  React.useImperativeHandle(ref, () => ({
    updateDuration: updateDuration,
    updateBookings: updateBookings,
    addBooking: addBooking,
    bookings: bookingsCreation,
    duration: duration,
  }));

  const addBooking = (booking) => {
    setBookingsCreation([booking]);
  };

  const updateDuration = (new_duration) => {
    setDuration(new_duration);
  };

  const updateBookings = (new_bookings) => {
    if (new_bookings) {
      setBookingsCreation(new_bookings.filter((b) => b[0] === userIndex));
      setDuration(useBookingsCreatingStore.getState().bookingsDuration);
    }
  };

  if (!filteredUsers[userIndex]) {
    return <></>;
  }

  let currentUserBookings = bookings.filter((booking) => booking.user_id === filteredUsers[userIndex].id);

  let userScheduleMax = usersMaxScheduleTime(filteredUsers[userIndex]);

  const getCurrentDateBookings = (date) =>
    currentUserBookings.filter((booking) => booking.date === ToDateString(date)) || [];

  const getCurrentDateDuration = (date) =>
    (getCurrentDateBookings(date)
      .filter((booking) => booking.deleted_at === null)
      .map((booking) => booking.duration)
      .reduce((x, y) => x + y, 0) /
      60) *
    formatMapping[bookingFormat];

  const calculateLeftOffset = (bookingDate) => {
    let dateOffset = getNumberOfDays(plannerDate, bookingDate);
    let finalOffset = PLANNER_USER_WIDTH;

    for (let i = 0; i < dateOffset; i++) {
      let isWeekend =
        weekendsHidden && (OffsetDays(plannerDate, i).getDay() === 0 || OffsetDays(plannerDate, i).getDay() === 6);
      finalOffset += isWeekend ? 10 : getCellWidthForZoom(zoom);
    }

    return finalOffset;
  };

  const calculateOffsetTop = (date) => {
    let topOffset = 0;

    for (let i = 0; i < userIndex; i++) {
      topOffset += (usersMaxScheduleTime(filteredUsers[i]) / 60) * formatMapping[bookingFormat] + 17;
    }

    return topOffset + getCurrentDateDuration(date);
  };

  const calculateHeight = (bookingDuration, date) => {
    let result = bookingDuration * formatMapping[bookingFormat];
    let dateEmptySpace = (userScheduleMax / 60) * formatMapping[bookingFormat] - getCurrentDateDuration(date);

    if (dateEmptySpace < result) {
      return dateEmptySpace > 0 ? dateEmptySpace : 0;
    }

    return result;
  };

  return bookingsCreation.map((b, i) => {
    const isFirstDayOfWeek = DateTime.fromJSDate(b[1] || new Date()).weekday === 1;

    return (
      !(isWeekend(b[1]) && weekendsHidden) && (
        <div
          key={"creation-" + b[0] + "booking-" + i}
          ref={ref}
          className="creation-booking"
          style={{
            width: `${getCellWidthForZoom(zoom) + (isFirstDayOfWeek ? -1 : 0)}px`,
            left: `${calculateLeftOffset(b[1]) + (isFirstDayOfWeek ? 1 : 0)}px`,
            height: `${calculateHeight(duration, b[1])}px`,
            top: `${calculateOffsetTop(b[1])}px`,
            backgroundColor: projectsType === "time_off" ? selectedTimeOff?.color : selectedProject?.color,
          }}
          onMouseMove={(e) => {
            if (
              ["contractor", "regular"].includes(userRole) ||
              (userRole === "self_planner" && filteredUsers[userIndex].id !== profile.id)
            ) {
              return;
            } else {
              mouseMoved(getPlannerOffsetX(e), getPlannerOffsetY(e, zoom), filteredUsers, e);
            }
          }}
          onClick={(e) => {
            removeBooking(userIndex, b[1]);
          }}
        >
          <div
            className="booking-line"
            style={{
              backgroundColor: projectsType === "time_off" ? selectedTimeOff?.color : selectedProject?.color,
              width: zoom === 90 ? "2px" : "4px",
            }}
          />
        </div>
      )
    );
  });
});

export default CreationBooking;

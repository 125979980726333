import { icons } from "../../default_images/IconsList";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import React from "react";

const onboardingData = (t) => {
  return {
    admin: {
      planning: [
        {
          header: t("planning.onboarding.header_4"),
          content: t("planning.onboarding.content_4"),
          url: "/planners",
          icon: <TeambookIcon color="onboarding_icon" name={icons.CALENDAR} />,
        },
        {
          header: t("planning.onboarding.header_5"),
          content: t("planning.onboarding.content_5"),
          url: "/users",
          icon: <TeambookIcon color="onboarding_icon" name={icons.USERS} />,
        },
        {
          header: t("planning.onboarding.header_6"),
          content: t("planning.onboarding.content_6"),
          url: "/projects",
          icon: <TeambookIcon color="onboarding_icon" name={icons.PROJECTS} />,
        },
        {
          header: t("planning.onboarding.header_9"),
          content: t("planning.onboarding.content_9"),
          url: "/dashboard",
          icon: <TeambookIcon style={{ opacity: 0.75 }} color="onboarding_icon" name={icons.DIAGRAM} />,
        },
        {
          header: t("planning.onboarding.header_7"),
          content: t("planning.onboarding.content_7"),
          url: "/profile",
          icon: <TeambookIcon style={{ opacity: 0.75 }} color="onboarding_icon" name={icons.GEAR} />,
        },
        {
          header: t("planning.onboarding.header_8"),
          content: t("planning.onboarding.content_8"),
          url: "/settings",
          icon: <TeambookIcon color="onboarding_icon" name={icons.PLACE} />,
        },
      ],
      capacity: [
        {
          header: t("planning.onboarding.header_5"),
          content: t("planning.onboarding.content_5"),
          url: "/users",
          icon: <TeambookIcon color="onboarding_icon" name={icons.USERS} />,
        },
        {
          header: t("planning.onboarding.header_6"),
          content: t("planning.onboarding.content_6"),
          url: "/projects",
          icon: <TeambookIcon color="onboarding_icon" name={icons.PROJECTS} />,
        },
        {
          header: t("planning.onboarding.header_9"),
          content: t("planning.onboarding.content_9"),
          url: "/dashboard",
          icon: <TeambookIcon style={{ opacity: 0.75 }} color="onboarding_icon" name={icons.DIAGRAM} />,
        },
        {
          header: t("planning.onboarding.header_7"),
          content: t("planning.onboarding.content_7"),
          url: "/profile",
          icon: <TeambookIcon style={{ opacity: 0.75 }} color="onboarding_icon" name={icons.GEAR} />,
        },
        {
          header: t("planning.onboarding.header_8"),
          content: t("planning.onboarding.content_8"),
          url: "/settings",
          icon: <TeambookIcon color="onboarding_icon" name={icons.PLACE} />,
        },
      ],
      actuals: [
        {
          header: t("planning.onboarding.header_5"),
          content: t("planning.onboarding.content_5"),
          url: "/users",
          icon: <TeambookIcon color="onboarding_icon" name={icons.USERS} />,
        },
        {
          header: t("planning.onboarding.header_6"),
          content: t("planning.onboarding.content_6"),
          url: "/projects",
          icon: <TeambookIcon color="onboarding_icon" name={icons.PROJECTS} />,
        },
        {
          header: t("planning.onboarding.header_9"),
          content: t("planning.onboarding.content_9"),
          url: "/dashboard",
          icon: <TeambookIcon style={{ opacity: 0.75 }} color="onboarding_icon" name={icons.DIAGRAM} />,
        },
        {
          header: t("planning.onboarding.header_7"),
          content: t("planning.onboarding.content_7"),
          url: "/profile",
          icon: <TeambookIcon style={{ opacity: 0.75 }} color="onboarding_icon" name={icons.GEAR} />,
        },
        {
          header: t("planning.onboarding.header_8"),
          content: t("planning.onboarding.content_8"),
          url: "/settings",
          icon: <TeambookIcon color="onboarding_icon" name={icons.PLACE} />,
        },
      ],
    },

    regular: {
      planning: [
        {
          header: t("planning.onboarding.header_1"),
          content: t("planning.onboarding.content_1"),
          url: "/planners",
          icon: <TeambookIcon color="onboarding_icon" name={icons.CALENDAR} />,
        },
        {
          header: t("planning.onboarding.header_14"),
          content: t("planning.onboarding.content_14"),
          url: "/my-week",
          icon: <TeambookIcon color="onboarding_icon" name={icons.CALENDAR} />,
        },
        {
          header: t("planning.onboarding.header_3"),
          content: t("planning.onboarding.content_3"),
          icon: <TeambookIcon style={{ opacity: 0.75 }} color="onboarding_icon" name={icons.SYNC} />,
        },
        {
          header: t("planning.onboarding.header_7"),
          content: t("planning.onboarding.content_7"),
          url: "/profile",
          icon: <TeambookIcon style={{ opacity: 0.75 }} color="onboarding_icon" name={icons.GEAR} />,
        },
        {
          header: t("planning.onboarding.header_10"),
          content: t("planning.onboarding.content_10"),
          url: t("header.kb_link"),
          new_tab: true,
          icon: <TeambookIcon color="onboarding_icon" name={icons.QUESTION} />,
        },
      ],
      capacity: [
        {
          header: t("planning.onboarding.header_7"),
          content: t("planning.onboarding.content_7"),
          url: "/profile",
          icon: <TeambookIcon style={{ opacity: 0.75 }} color="onboarding_icon" name={icons.GEAR} />,
        },
        {
          header: t("planning.onboarding.header_10"),
          content: t("planning.onboarding.content_10"),
          url: t("header.kb_link"),
          new_tab: true,
          icon: <TeambookIcon color="onboarding_icon" name={icons.QUESTION} />,
        },
      ],
      actuals: [
        {
          header: t("planning.onboarding.header_7"),
          content: t("planning.onboarding.content_7"),
          url: "/profile",
          icon: <TeambookIcon style={{ opacity: 0.75 }} color="onboarding_icon" name={icons.GEAR} />,
        },
        {
          header: t("planning.onboarding.header_10"),
          content: t("planning.onboarding.content_10"),
          url: t("header.kb_link"),
          new_tab: true,
          icon: <TeambookIcon color="onboarding_icon" name={icons.QUESTION} />,
        },
      ],
    },
  };
};

export default onboardingData;

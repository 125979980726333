import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Api from "../../../Api";
import { recalculateCapacityArray } from "../lib/recalculateCapacityArray";
import lsKeys from "../../default_values/defaultKeys";
import { useAccountStore } from "../../../stores/accountStore";
import { shallow } from "zustand/shallow";
import { icons } from "../../default_images/IconsList";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { useProfileStore } from "../../../stores/profileStore";
import SpreadDialog from "../users_planning/SpreadDialog";

const PlannedRow = ({ project, date, projectReservations, updateProjectReservations, selectedViewType }) => {
  const { t } = useTranslation();
  const [calculatedDurations, setCalculatedDurations] = useState([]);
  const fullAccessRoles = ["admin", "planner"];
  const role = localStorage.getItem(lsKeys.ROLE);
  const [account] = useAccountStore((state) => [state.account], shallow);
  const [spreadOpened, setSpreadOpened] = useState(false);
  const [profile] = useProfileStore((state) => [state.profile], shallow);

  useEffect(() => {
    setCalculatedDurations(recalculateCapacityArray(date, projectReservations, selectedViewType));
  }, [projectReservations, date, project]);

  const updateReservation = (newValue, index) => {
    if (account.subscription.subscription_id === "Free" && account.subscription.projects_available === 10) {
      return;
    }

    let newCalculated = [...calculatedDurations];

    newCalculated[index] = newValue;

    Api.CapacityReservations.create_project_reservations({
      project_id: project.id,
      days_reserved: newValue,
      date: date.plus({ months: index }).toISODate(),
    }).then(() => {
      updateProjectReservations(index);
    });

    setCalculatedDurations(newCalculated);
  };

  const handleFocus = (event) => event.target.select();

  const userHasAccessForInput = () => {
    return fullAccessRoles.includes(role);
  };

  const openSpreadMenu = () => {
    setSpreadOpened(true);
  };

  return (
    <div className="flex project-row">
      <div className="users-block__project-info planned-days">
        <p className="col-md-5">{t("planning.capacity.days_planned")}</p>
        {userHasAccessForInput() && (
          <TeambookIcon
            color="alternative_default"
            name={icons.SPREAD_NEW}
            onClick={openSpreadMenu}
            title={t("planning.spread")}
            style={{ cursor: "pointer", marginRight: "16px" }}
          />
        )}
      </div>

      {[...Array(selectedViewType)].map((x, i, arr) => (
        <div style={{ borderRight: arr.length - 1 === i ? "1px solid var(--stroke)" : "" }} className="table-cell">
          {fullAccessRoles.includes(role) ? (
            <input
              onChange={(event) => updateReservation(event.target.value >= 0 ? event.target.value : 0, i)}
              onFocus={handleFocus}
              onWheel={(e) => e.target.blur()}
              value={parseInt(calculatedDurations[i]) || 0}
              style={{ width: "100%" }}
              onDragOver={(e) => e.preventDefault()}
              onDrop={(e) => e.preventDefault()}
            />
          ) : (
            <p>{parseInt(calculatedDurations[i])}</p>
          )}
        </div>
      ))}

      {spreadOpened && (
        <SpreadDialog
          setDialogOpened={setSpreadOpened}
          date={date}
          project={project}
          updateReservations={updateProjectReservations}
        />
      )}
    </div>
  );
};

export default PlannedRow;

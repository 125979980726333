import Chart from "chart.js/auto";
import lsKeys from "../../default_values/defaultKeys";
import calculateDatasets from "./calculateDatasets";
import htmlLegendPlugin from "./htmlLegendPlugin";

let thisChart;

const CalculateChartData = (chartRef, stats, from, type, t, graphRange) => {
  const isDarkTheme = localStorage.getItem(lsKeys.THEME) === "dark";

  const myChartRef = chartRef.current.getContext("2d");

  const { datasets, labels } = calculateDatasets(stats, from, isDarkTheme, type, t, graphRange);

  if (thisChart) {
    thisChart.destroy();
  }

  thisChart = new Chart(myChartRef, {
    type: type === "capacity" ? "bar" : "line",
    data: {
      labels: labels,
      datasets: datasets,
    },
    options: {
      maintainAspectRatio: false,

      plugins: {
        htmlLegend: {
          // ID of the container to put the legend in
          containerID: "legend-container",
        },
        legend: {
          display: false,
          labels: {
            textAlign: "left",
            boxWidth: 50,
            boxHeight: 0,
            font: {
              size: 12,
              lineHeight: 10,
              family: "Montserrat",
              color: isDarkTheme ? "#FFFFFFBF" : "#474747",
            },
          },
        },

        tooltip: {
          backgroundColor: isDarkTheme ? "#1A1B23" : "#FFFFFF",
          borderColor: isDarkTheme ? "#484E5C" : "#c8d2e6",
          borderWidth: 1,
          titleColor: isDarkTheme ? "#FFFFFFBF" : "#474747",
          bodyColor: isDarkTheme ? "#FFFFFFBF" : "#474747",
          titleAlign: "left",

          callbacks: {
            labelColor: function (context) {
              return {
                borderColor: "rgba(0,0,0,0)",
                backgroundColor: context.dataset.pointHoverBackgroundColor,
                borderRadius: 5,
                borderDash: [2, 2],
              };
            },
          },
          bodyFont: {
            family: "Montserrat",
          },
          titleFont: {
            family: "Montserrat",
          },
        },
      },
      animations: {
        tension: {
          duration: 1000,
          easing: "linear",
          from: 0,
          to: 0.5,
        },
      },
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
        },
      },

      scales: {
        y: {
          suggestedMax: 120,
          beginAtZero: true,
          grid: {
            color: isDarkTheme ? "#252734" : "#F5F5F5",
          },
          ticks: {
            color: isDarkTheme ? "#FFFFFFBF" : "#474747",
          },
        },
        x: {
          grid: {
            color: "transparent",
          },
          ticks: {
            color: isDarkTheme ? "#FFFFFFBF" : "#474747",
          },
        },
      },
    },
    plugins: [htmlLegendPlugin(type)],
    chartType: type,
  });
};

export default CalculateChartData;

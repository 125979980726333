import React from "react";
import "./clients_dialog.scss";
import { useTranslation } from "react-i18next";
import { WhiteTooltip } from "../../../default_components/Tooltips";
import { IconWrapper } from "./ClientsDialog";
import { icons } from "../../../default_images/IconsList";

const Client = ({ client, editClient, deleteClient, shareCalendar }) => {
  const { t } = useTranslation();

  const ColumnText = (text) => {
    const isShowToolTip = text?.length > 19;

    return (
      <WhiteTooltip title={isShowToolTip && text}>
        <p>{text || "-"}</p>
      </WhiteTooltip>
    );
  };

  return (
    <div className="clients-modal__row">
      {ColumnText(client.name)}
      {ColumnText(client.email)}
      {ColumnText(client.phone_number)}

      <div className="clients-modal__icons-container">
        <IconWrapper
          t={t}
          toolTip="edit"
          onClick={() => editClient(client.id)}
          icon={icons.PENCIL}
        />

        <IconWrapper
          t={t}
          toolTip="delete"
          onClick={() => deleteClient(client.id, client.name)}
          icon={icons.TRASH}
        />

        <IconWrapper
          t={t}
          toolTip="projects.copy_client_calendar"
          onClick={() => shareCalendar(client.reference_id)}
          icon={icons.SHARE}
        />
      </div>
    </div>
  );
};

export default Client;

export const sortDataBySurname = (data, sortOrder = "a") => {
  const extractSurname = (fullName) => {
    const nameParts = fullName.split(" ");
    return nameParts[nameParts.length > 1 ? 1 : 0];
  };

  return data.sort((a, b) => {
    const surnameA = extractSurname(a.name);
    const surnameB = extractSurname(b.name);

    return sortOrder === "a"
      ? surnameA.localeCompare(surnameB)
      : surnameB.localeCompare(surnameA);
  });
};

import React from "react";
import { useTranslation } from "react-i18next";

const LeftSide = ({ menuIndex, setMenuIndex }) => {
  const { t } = useTranslation();

  const PagesArray = [
    { name: t("profile.general_info.name") },
    { name: t("profile.email.name") },
    { name: t("profile.schedule.name") },
    { name: t("profile.notifications.name") },
  ];

  const renderPages = ({ name }, index) => (
    <div
      className="profile__left-side__option"
      onClick={() => setMenuIndex(index)}
      style={{ backgroundColor: menuIndex === index ? "var(--stroke)" : "var(--background-1)" }}
      index={index}
      key={index}
    >
      <p style={{ color: menuIndex === index ? "var(--button-text-hover)" : "var(--text-2)" }}>{name}</p>
    </div>
  );

  return (
    <div className="profile__left-side">
      <p className="profile__title">{t("profile.profile_settings")}</p>
      <div className="left-side__border">{PagesArray.map(renderPages)}</div>
    </div>
  );
};

export default LeftSide;

import React from "react";
import Header from "../header/Header";
import { useTranslation } from "react-i18next";
import { TeambookIcon } from "../default_images/TeambookIcon";
import { icons } from "../default_images/IconsList";

const NoMatch = ({ role }) => {
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <div className="row header-error__container" id="404-page">
        <p className="error__header">{t("oops_error")}</p>
        <p className="text">{t("cant_find_page")}</p>
      </div>
      <TeambookIcon className="notfound_img" color="blue" name={icons.ERROR_404} />
    </>
  );
};

export default NoMatch;
